/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  GridWidget,
  CheckboxWidget,
  setValue,
  getData,
  getValue,
  setData,
  hide,
  show,
  goTo,
  enable,
  disable,
  setLabel,
  getLabel,
  getSelectedRowNumbers,
  getSelectedRowNumber,
  getSelectedGridValue
} from "../../shared/WindowImports";

import "./ViewUploads.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../Service/SettlementService";
import Loading from "../../../Loader/Loading";
import async from "react-bootstrap-typeahead/lib/behaviors/async";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function Settlements_ViewUploads(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewUploads",
    windowName: "ViewUploads",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "Settlements.ViewUploads",
    // START_USER_CODE-USER_ViewUploads_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Search Filters:",
      scrCode: "PN1060G",
    },
    // END_USER_CODE-USER_ViewUploads_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Close",
      CharWidth: "13",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnHide: {
      name: "btnHide",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Hide",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnHide_PROPERTIES

      // END_USER_CODE-USER_btnHide_PROPERTIES
    },
    btnResubmit: {
      name: "btnResubmit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Resubmit",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnResubmit_PROPERTIES

      // END_USER_CODE-USER_btnResubmit_PROPERTIES
    },
    btnSearch: {
      name: "btnSearch",
      type: "ButtonWidget",
      parent: "cmmndCntnrSearch",
      Label: "Search",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnSearch_PROPERTIES

      // END_USER_CODE-USER_btnSearch_PROPERTIES
    },
    chkboxChangetoResidueLoad: {
      name: "chkboxChangetoResidueLoad",
      type: "CheckBoxWidget",
      parent: "grpbxSearchData",
      Label: "Change to Residue Load",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxChangetoResidueLoad_PROPERTIES

      // END_USER_CODE-USER_chkboxChangetoResidueLoad_PROPERTIES
    },
    chkboxDisplayHidden: {
      name: "chkboxDisplayHidden",
      type: "CheckBoxWidget",
      parent: "grpbxViewUploads",
      Label: "Display Hidden",
      ColSpan: "4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDisplayHidden_PROPERTIES

      // END_USER_CODE-USER_chkboxDisplayHidden_PROPERTIES
    },
    chkboxExcludeErrors: {
      name: "chkboxExcludeErrors",
      type: "CheckBoxWidget",
      parent: "grpbxViewUploads",
      Label: "Exclude Errors",
      ColSpan: "4",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxExcludeErrors_PROPERTIES

      // END_USER_CODE-USER_chkboxExcludeErrors_PROPERTIES
    },
    chkboxOverride: {
      name: "chkboxOverride",
      type: "CheckBoxWidget",
      parent: "grpbxSearchData",
      Label: "Override",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOverride_PROPERTIES

      // END_USER_CODE-USER_chkboxOverride_PROPERTIES
    },
    chkboxResidueLoadsOnly: {
      name: "chkboxResidueLoadsOnly",
      type: "CheckBoxWidget",
      parent: "grpbxViewUploads",
      Label: "Show only Residue Loads",
      ColSpan: "2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxResidueLoadsOnly_PROPERTIES

      // END_USER_CODE-USER_chkboxResidueLoadsOnly_PROPERTIES
    },
    cmmndCntnrSearch: {
      name: "cmmndCntnrSearch",
      type: "CommandContainerWidget",
      parent: "grpbxViewUploads",
      ColSpan: "6",
      Height: "",
      Width: "",
      HasLabel: false,
      Cols: "6",
      // START_USER_CODE-USER_cmmndCntnrSearch_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrSearch_PROPERTIES
    },
    colBuyingPoint: {
      name: "colBuyingPoint",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Buy Pt",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_colBuyingPoint_PROPERTIES
    },
    colCompID: {
      name: "colCompID",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Comp ID",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCompID_PROPERTIES

      // END_USER_CODE-USER_colCompID_PROPERTIES
    },
    colCropYear: {
      name: "colCropYear",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Crop Year",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colCropYear_PROPERTIES

      // END_USER_CODE-USER_colCropYear_PROPERTIES
    },
    colErrorMessage: {
      name: "colErrorMessage",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Error Message",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colErrorMessage_PROPERTIES

      // END_USER_CODE-USER_colErrorMessage_PROPERTIES
    },
    colInspectionNum: {
      name: "colInspectionNum",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Inspect #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectionNum_PROPERTIES

      // END_USER_CODE-USER_colInspectionNum_PROPERTIES
    },
    colTranNum: {
      name: "colTranNum",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Tran #",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTranNum_PROPERTIES

      // END_USER_CODE-USER_colTranNum_PROPERTIES
    },
    colTransferFileName: {
      name: "colTransferFileName",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Transfer File Name",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferFileName_PROPERTIES

      // END_USER_CODE-USER_colTransferFileName_PROPERTIES
    },
    colTransferredRecord: {
      name: "colTransferredRecord",
      type: "GridColumnWidget",
      parent: "gridSearchResults",
      Label: "Transferred Record",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colTransferredRecord_PROPERTIES

      // END_USER_CODE-USER_colTransferredRecord_PROPERTIES
    },
    ddbuyingPoint: {
      name: "ddbuyingPoint",
      type: "DropDownFieldWidget",
      parent: "grpbxViewUploads",
      Label: "Buying Point:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddbuyingPoint_PROPERTIES

      // END_USER_CODE-USER_ddbuyingPoint_PROPERTIES
    },
    gridSearchResults: {
      name: "gridSearchResults",
      type: "GridWidget",
      parent: "grpbxViewUploads",
      gridCellsOrder:
        "txtcolTranNum,txtcolCompID,txtcolCropYear,txtcolBuyingPoint,txtcolInspectionNum,txtcolTransferFileName,txtcolErrorMessage,txtcolTransferredRecord",
      ColSpan: "6",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_gridSearchResults_PROPERTIES

      isMultiSelect: true,
      clientPagination: true,
      DisplaySize: 10,
      // isEditable: true,
      // needGridChange: true,

      // END_USER_CODE-USER_gridSearchResults_PROPERTIES
    },
    grpbxSearchData: {
      name: "grpbxSearchData",
      type: "GroupBoxWidget",
      parent: "grpbxViewUploads",
      ColSpan: "6",
      Height: "",
      Width: "",
      ColsForTabLandscape: "12",
      HasLabel: false,
      Cols: "12",
      ColsForTabPotrait: "12",
      ColsForLargeDesktop: "12",
      // START_USER_CODE-USER_grpbxSearchData_PROPERTIES

      // END_USER_CODE-USER_grpbxSearchData_PROPERTIES
    },
    txtcolBuyingPoint: {
      name: "txtcolBuyingPoint",
      type: "TextBoxWidget",
      colName: "colBuyingPoint",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolBuyingPoint_PROPERTIES
    },
    txtcolCompID: {
      name: "txtcolCompID",
      type: "TextBoxWidget",
      colName: "colCompID",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCompID_PROPERTIES

      // END_USER_CODE-USER_txtcolCompID_PROPERTIES
    },
    txtcolCropYear: {
      name: "txtcolCropYear",
      type: "TextBoxWidget",
      colName: "colCropYear",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolCropYear_PROPERTIES

      // END_USER_CODE-USER_txtcolCropYear_PROPERTIES
    },
    txtcolErrorMessage: {
      name: "txtcolErrorMessage",
      type: "TextBoxWidget",
      colName: "colErrorMessage",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolErrorMessage_PROPERTIES

      // END_USER_CODE-USER_txtcolErrorMessage_PROPERTIES
    },
    txtcolInspectionNum: {
      name: "txtcolInspectionNum",
      type: "TextBoxWidget",
      colName: "colInspectionNum",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspectionNum_PROPERTIES

      // END_USER_CODE-USER_txtcolInspectionNum_PROPERTIES
    },
    txtcolTranNum: {
      name: "txtcolTranNum",
      type: "TextBoxWidget",
      colName: "colTranNum",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTranNum_PROPERTIES

      // END_USER_CODE-USER_txtcolTranNum_PROPERTIES
    },
    txtcolTransferFileName: {
      name: "txtcolTransferFileName",
      type: "TextBoxWidget",
      colName: "colTransferFileName",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferFileName_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferFileName_PROPERTIES
    },
    txtcolTransferredRecord: {
      name: "txtcolTransferredRecord",
      type: "TextBoxWidget",
      colName: "colTransferredRecord",
      parent: "gridSearchResults",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolTransferredRecord_PROPERTIES

      // END_USER_CODE-USER_txtcolTransferredRecord_PROPERTIES
    },
    txtCounty: {
      name: "txtCounty",
      type: "TextBoxWidget",
      parent: "grpbxViewUploads",
      Label: "County:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCounty_PROPERTIES

      // END_USER_CODE-USER_txtCounty_PROPERTIES
    },
    txtCountyVal: {
      name: "txtCountyVal",
      type: "TextBoxWidget",
      parent: "grpbxSearchData",
      Label: "County:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCountyVal_PROPERTIES

      // END_USER_CODE-USER_txtCountyVal_PROPERTIES
    },
    txtFarm: {
      name: "txtFarm",
      type: "TextBoxWidget",
      parent: "grpbxViewUploads",
      Label: "Farm:",
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarm_PROPERTIES

      // END_USER_CODE-USER_txtFarm_PROPERTIES
    },
    txtFarmSuffix: {
      name: "txtFarmSuffix",
      type: "TextBoxWidget",
      parent: "grpbxViewUploads",
      Label: "FarmSuffix#:",
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffix_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffix_PROPERTIES
    },
    txtFarmSuffixVal: {
      name: "txtFarmSuffixVal",
      type: "TextBoxWidget",
      parent: "grpbxSearchData",
      Label: "Farm Suffix:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmSuffixVal_PROPERTIES

      // END_USER_CODE-USER_txtFarmSuffixVal_PROPERTIES
    },
    txtFarmVal: {
      name: "txtFarmVal",
      type: "TextBoxWidget",
      parent: "grpbxSearchData",
      Label: "Farm:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 7 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmVal_PROPERTIES

      // END_USER_CODE-USER_txtFarmVal_PROPERTIES
    },
    txtInspectionNum: {
      name: "txtInspectionNum",
      type: "TextBoxWidget",
      parent: "grpbxViewUploads",
      Label: "Inspection #:",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspectionNum_PROPERTIES

      // END_USER_CODE-USER_txtInspectionNum_PROPERTIES
    },
    txtState: {
      name: "txtState",
      type: "TextBoxWidget",
      parent: "grpbxViewUploads",
      Label: "State:",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtState_PROPERTIES

      // END_USER_CODE-USER_txtState_PROPERTIES
    },
    txtStateVal: {
      name: "txtStateVal",
      type: "TextBoxWidget",
      parent: "grpbxSearchData",
      Label: "State:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStateVal_PROPERTIES

      // END_USER_CODE-USER_txtStateVal_PROPERTIES
    },
    grpbxViewUploads: {
      name: "grpbxViewUploads",
      type: "GroupBoxWidget",
      parent: "ViewUploads",
      Height: "",
      Width: "",
      ColsForTabLandscape: "6",
      HasLabel: false,
      Cols: "6",
      ColsForTabPotrait: "6",
      ColsForLargeDesktop: "6",
      // START_USER_CODE-USER_grpbxViewUploads_PROPERTIES

      // END_USER_CODE-USER_grpbxViewUploads_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "ViewUploads",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  const [loading, setLoading] = useState(true)
  
  

  
  useEffect(() => {
    formLoad()
    
  }, []);
  
  // START_USER_CODE-USER_METHODS
  
  const formLoad = async () => {
    
    let prntDta = getData(thisObj,'ViewUploadsScreenData');
    
    if (prntDta != null) { 
      setValue(thisObj, 'txtInspectionNum', prntDta.inspection_number)
      // setValue(thisObj, 'ddbuyingPoint', prntDta.buying_point);
    } else {
      setValue(thisObj, 'txtInspectionNum', '')
    }

    setValue(thisObj, 'chkboxOverride', false)
    setData(thisObj, 'mbPermitVU', false)
    bFillBuyingPointList()
    setLoading(false)

    disable(thisObj, 'btnHide')
    disable(thisObj, 'btnResubmit')

    await ContractManagementService.RetrieveAccessPermissionDetails('PN1060', '008', 'U')
      .then(response => {
        let securityResp = response
        if (securityResp[0].permission == "Y") {
          setData(thisObj, 'mbPermitVU', true)
        } else {
          setData(thisObj, 'mbPermitVU', false)
        }
      })

    enableDisableOverrides()

  }

  const bFillBuyingPointList = () => {
    let jss = []
    let lblUploadFormatID = [];
    jss.push({ key: '', description: '>>>All Buying Points<<<' })
    ContractManagementService.RetrieveBuyingPointControlDetails('PN1060').then(response => {
      let data = response
      if (data.length > 1) {
        for (var i = 0; i < data.length; i++) {
          let obj = { key: data[i].buy_pt_id, description: data[i].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim() }
          jss.push(obj)
          let obj2 = { key: data[i].insp_upload_format_id }
          lblUploadFormatID.push(obj2)
        }
        thisObj.setState(current => {
          return {
            ...current,
            ddbuyingPoint: {
              ...state["ddbuyingPoint"],
              valueList: jss
            }
          }
        })
        setValue(thisObj, 'ddbuyingPoint', jss.at(0).key);
        setData(thisObj, 'allBuyingPtIDVU', jss)
        setData(thisObj, 'lbluplodID', lblUploadFormatID.at(0).key)
        setData(thisObj, 'lbluplodIDArray', lblUploadFormatID)
      }

      if (data.length == 1) {
        setValue(thisObj, 'ddbuyingPoint', data[0].buy_pt_id.trim() + '-' + data[i].buy_pt_name.trim());
      }

    })

  }

  const validateTextBox = (val) => {
    var res = ''
    for (var i = 0; i < val.length; i++) {
      if (val[i].charCodeAt(0) != 8 && val[i].charCodeAt(0) != 22 && val[i].charCodeAt(0) != 24) {
        if (val[i].charCodeAt(0) != 8 && val[i].charCodeAt(0) >= 48 && val[i].charCodeAt(0) <= 57) {
          res += val[i]
        }
      }
    }
    return res;
  }
  const ontxtStateChange = () => {
    try {
      setValue(thisObj, 'txtState', validateTextBox(txtState.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtStateChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtStateChange = ontxtStateChange;

  const ontxtCountyChange = () => {
    try {
      setValue(thisObj, 'txtCounty', validateTextBox(txtCounty.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtCountyChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtCountyChange = ontxtCountyChange;

  const ontxtFarmChange = () => {
    try {
      setValue(thisObj, 'txtFarm', validateTextBox(txtFarm.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFarmChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtFarmChange = ontxtFarmChange;

  const ontxtFarmSuffixChange = () => {
    try {
      setValue(thisObj, 'txtFarmSuffix', validateTextBox(txtFarmSuffix.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFarmSuffixChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtFarmSuffixChange = ontxtFarmSuffixChange;

  const ontxtStateValChange = () => {
    try {
      setValue(thisObj, 'txtStateVal', validateTextBox(txtStateVal.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtStateValChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtStateValChange = ontxtStateValChange;

  const ontxtCountyValChange = () => {
    try {
      setValue(thisObj, 'txtCountyVal', validateTextBox(txtCountyVal.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtCountyValChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtCountyValChange = ontxtCountyValChange;

  const ontxtFarmValChange = () => {
    try {
      setValue(thisObj, 'txtFarmVal', validateTextBox(txtFarmVal.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFarmValChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtFarmValChange = ontxtFarmValChange;

  const ontxtFarmSuffixValChange = () => {
    try {
      setValue(thisObj, 'txtFarmSuffixVal', validateTextBox(txtFarmSuffixVal.value))
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtFarmSuffixValChange Change event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtFarmSuffixValChange = ontxtFarmSuffixValChange;


  const enableDisableOverrides = () => {
    if (getData(thisObj, 'mbPermitVU')) {
      enable(thisObj, 'chkboxChangetoResidueLoad')
      enable(thisObj, 'chkboxOverride')
    } else {
      setValue(thisObj, 'chkboxChangetoResidueLoad', false)
      disable(thisObj, 'chkboxChangetoResidueLoad')
      setValue(thisObj, 'chkboxOverride', false)
      disable(thisObj, 'chkboxOverride')
      disable(thisObj, 'txtStateVal')
      disable(thisObj, 'txtCountyVal')
      disable(thisObj, 'txtFarmVal')
      disable(thisObj, 'txtFarmSuffixVal')
    }

    if (getValue(thisObj, 'chkboxChangetoResidueLoad') == true) {
      setValue(thisObj, 'chkboxOverride', false)
    }

    if (getValue(thisObj, 'chkboxOverride') == true) {
      enable(thisObj, 'txtStateVal')
      enable(thisObj, 'txtCountyVal')
      enable(thisObj, 'txtFarmVal')
      enable(thisObj, 'txtFarmSuffixVal')
    } else {
      disable(thisObj, 'txtStateVal')
      disable(thisObj, 'txtCountyVal')
      disable(thisObj, 'txtFarmVal')
      disable(thisObj, 'txtFarmSuffixVal')
    }
  }

  const onbtnSearchClick = () => {
    disable(thisObj, 'btnHide')
    disable(thisObj, 'btnResubmit')

    doSearch();
  }
  thisObj.onbtnSearchClick = onbtnSearchClick;

  const doSearch = () => {
    setLoading(true)
    let lstrHidden
    let lstrExclude
    let lstrResidueLoad
    let buy_pt_id = getValue(thisObj, 'ddbuyingPoint')
    let inspect_num = getValue(thisObj, 'txtInspectionNum')
    let state_id = getValue(thisObj, 'txtState')
    let county_id = getValue(thisObj, 'txtCounty')
    let farm_id = getValue(thisObj, 'txtFarm')
    let farm_suffix = getValue(thisObj, 'txtFarmSuffix')

    if (getValue(thisObj, 'chkboxDisplayHidden') == true) {
      lstrHidden = 'Y'
    } else {
      lstrHidden = 'N'
    }

    if (getValue(thisObj, 'chkboxExcludeErrors') == true) {
      lstrExclude = 'Y'
    } else {
      lstrExclude = 'N'
    }

    if (getValue(thisObj, 'chkboxResidueLoadsOnly') == true) {
      lstrResidueLoad = 'Y'
    } else {
      lstrResidueLoad = ''
    }

    let aa = getValue(thisObj, 'ddbuyingPoint')

    if ((getValue(thisObj, 'ddbuyingPoint') == '>>>All Buying Points<<<' || getValue(thisObj, 'ddbuyingPoint') == "" || getValue(thisObj, 'ddbuyingPoint') == undefined) && (inspect_num == '' || inspect_num == null || inspect_num == undefined)) {
      showMessage(thisObj, 'Either a Buying Point location must be selected or an Inspection Number must be entered.')
      setLoading(false)
      return
    }

    SettlementService.RetrieveInspectUploadGradeDetails('F', 'PN1060', lstrHidden, lstrExclude, buy_pt_id, inspect_num, state_id, county_id, farm_id, farm_suffix, lstrResidueLoad)
      .then(response => {
        setData(thisObj, 'gridDataForSlicing', response)
        let dat = response
        if (response.length > 0 && response != undefined) {
          let jsVP = []
          let obj = {}
          for (let i = 0; i < dat.length; i++) {
            obj.txtcolTranNum = dat[i].transaction_number
            obj.txtcolCompID = dat[i].comp_id
            obj.txtcolCropYear = dat[i].crop_year
            obj.txtcolBuyingPoint = dat[i].buy_pt_id
            obj.txtcolInspectionNum = dat[i].inspection_number
            obj.txtcolTransferFileName = dat[i].file_name
            obj.txtcolErrorMessage = dat[i].error_msg
            obj.txtcolTransferredRecord = dat[i].record.slice(0, 66)
            jsVP.push(obj)
            obj = {}
          }
          setValue(thisObj, 'gridSearchResults', jsVP)
          setLoading(false)
        } else {
          setLoading(false)
          setValue(thisObj, 'gridSearchResults', [])
        }
      })

    if (getValue(thisObj, 'chkboxDisplayHidden') == true) {
      document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText = 'Unhide'
      // setLabel(thisObj, 'btnHide', 'Unhide')
    } else {
      document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText = 'Hide'
      // setLabel(thisObj, 'btnHide', 'Hide')
       }

    setValue(thisObj, 'txtStateVal', '')
    setValue(thisObj, 'txtCountyVal', '')
    setValue(thisObj, 'txtFarmVal', '')
    setValue(thisObj, 'txtFarmSuffixVal', '')
    setValue(thisObj, 'chkboxOverride', false)
    enableDisableOverrides()
  }

  const onchkboxResidueLoadsOnlyChange = () => {
    if (getValue(thisObj, 'chkboxResidueLoadsOnly') == true) {
      setValue(thisObj, 'txtState', '')
      disable(thisObj, 'txtState')
      setValue(thisObj, 'txtCounty', '')
      disable(thisObj, 'txtCounty')
      setValue(thisObj, 'txtFarm', '')
      disable(thisObj, 'txtFarm')
      setValue(thisObj, 'txtFarmSuffix', '')
      disable(thisObj, 'txtFarmSuffix')
    } else {
      enable(thisObj, 'txtState')
      enable(thisObj, 'txtCounty')
      enable(thisObj, 'txtFarm')
      enable(thisObj, 'txtFarmSuffix')
    }
  }
  thisObj.onchkboxResidueLoadsOnlyChange = onchkboxResidueLoadsOnlyChange;

  const onbtnCloseClick = () => {
    document.getElementById("Settlements_ViewUploadsPopUp").childNodes[0].click()
  }
  thisObj.onbtnCloseClick = onbtnCloseClick

  const ongridSearchResultsRowSelect = (rowSelected) => {
    let data11 = rowSelected.selectedRows[0];
    let rowdataForSlicing = getData(thisObj, 'gridDataForSlicing')
    let rowNumVU

    rowNumVU = getSelectedRowNumber(thisObj, 'gridSearchResults')

    if (rowSelected.selectedRows.length > 0) {
      setData(thisObj, 'rowSelectedOrNot', true)
    } else {
      setData(thisObj, 'rowSelectedOrNot', false)
    }

    let strRecord
    let allBPTID = getData(thisObj, 'allBuyingPtIDVU')
    let lbluplodIDArr = getData(thisObj, 'lbluplodIDArray')
    let strState
    let strCounty
    let strFarm
    let strFarmSuffix
    let LintCounter

    enable(thisObj, 'btnHide')
    enable(thisObj, 'btnResubmit')

    if (rowSelected.selectedRows.length > 0) {

      strRecord = rowdataForSlicing[rowNumVU].record

      if (getValue(thisObj, 'ddbuyingPoint') == '') {
        for (let i = 1; i < allBPTID.length; i++) {
          if (allBPTID[i].key == data11.txtcolBuyingPoint) {
            setData(thisObj, 'lbluplodID', lbluplodIDArr[i].key)
          }
        }
      }

      let caseVar = getData(thisObj, 'lbluplodID')

      switch (caseVar) {
        case 'SE':
          strState = strRecord.slice(51, 53)
          strCounty = strRecord.slice(53, 56)
          strFarm = strRecord.slice(56, 63)
          strFarmSuffix = strRecord.slice(63, 66)
          break;
        case 'SW':
          strState = strRecord.slice(82, 84)
          strCounty = strRecord.slice(84, 87)
          strFarm = strRecord.slice(87, 94)
          strFarmSuffix = strRecord.slice(94, 97)
          break;
        case 'FL':
          strState = strRecord.slice(50, 52)
          strCounty = strRecord.slice(52, 55)
          strFarm = strRecord.slice(55, 62)
          strFarmSuffix = strRecord.slice(62, 65)
          break;
        case 'ST':
          strState = strRecord.slice(86, 88)
          strCounty = strRecord.slice(88, 91)
          strFarm = strRecord.slice(91, 98)
          strFarmSuffix = strRecord.slice(98, 101)

          break;

        default:
          break;
      }

      setValue(thisObj, 'txtStateVal', strState)
      setValue(thisObj, 'txtCountyVal', strCounty)
      setValue(thisObj, 'txtFarmVal', strFarm)
      setValue(thisObj, 'txtFarmSuffixVal', strFarmSuffix)
    }

  }
  thisObj.ongridSearchResultsRowSelect = ongridSearchResultsRowSelect;


  const onbtnHideClick = async () => {
    let rowSelectedIndicator = getData(thisObj, 'rowSelectedOrNot')
    let strInd

    let seltdRows = getSelectedRowNumbers(thisObj, 'gridSearchResults')
    let arry = []
    seltdRows.map((i) => {
      arry.push(getValue(thisObj, 'gridSearchResults')[i])
    })

    try {
      if (rowSelectedIndicator == false || rowSelectedIndicator == null) {
        showMessage(thisObj, `A grid row must be selected to ${document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText} .`, false) //getLabel(thisObj, 'btnHide')
        return
      }

      // if(getLabel(thisObj, 'btnHide') == 'Hide'){
      if (document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText == 'Hide') {
        strInd = 'Y'
      } else {
        strInd = 'N'
      }

      for (let i = 0; i < arry.length; i++) {
        await SettlementService.UpdateUploadHiddenIndicatorDetails(arry[i].txtcolTranNum, strInd)
          .then(resp => {
            if (resp.status != 200) {
              showMessage(thisObj, `Attempt to ${document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText} record failed! ${apiResult.message}`, false) // getLabel(thisObj, 'btnHide')
              return
            }
          })
      }

      showMessage(thisObj, `Record ${document.getElementsByClassName('btnHide')[0].childNodes[0].childNodes[0].innerText} Successful.`, true) //getLabel(thisObj, 'btnHide')

      doSearch();

    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnHide click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnHideClick = onbtnHideClick;


  const onbtnResubmitClick = async () => {
    let rowSelectedIndicator = getData(thisObj, 'rowSelectedOrNot')
    let stateVal = getValue(thisObj, 'txtStateVal')
    let countyVal = getValue(thisObj, 'txtCountyVal')
    let framVal = getValue(thisObj, 'txtFarmVal')
    let farmSuffixVal = getValue(thisObj, 'txtFarmSuffixVal')
    let formatVal = getData(thisObj, 'lbluplodID')
    let msgFlag
    let apiResult

    let seltdRows = getSelectedRowNumbers(thisObj, 'gridSearchResults')
    let arry = []
    seltdRows.map((i) => {
      arry.push(getValue(thisObj, 'gridSearchResults')[i])
    })

    try {
      if (rowSelectedIndicator == false || rowSelectedIndicator == null) {
        showMessage(thisObj, `A grid row must be selected to Resubmit .`, false)
        return
      }

      if (getValue(thisObj, 'chkboxOverride') == true) {
        if (stateVal == undefined || stateVal == '' || countyVal == undefined || countyVal == '' || framVal == undefined || framVal == '' || farmSuffixVal == undefined || farmSuffixVal == '') {
          showMessage(thisObj, 'State, County, and Farm ID, Suffix must be entered.', false)
          if (stateVal == undefined || stateVal == '') {
            document.getElementById('txtStateVal').focus();
          } else if (countyVal == undefined || countyVal == '') {
            document.getElementById('txtCountyVal').focus();
          } else if (framVal == undefined || framVal == '') {
            document.getElementById('txtFarmVal').focus();
          } else if (farmSuffixVal == undefined || farmSuffixVal == '') {
            document.getElementById('txtFarmSuffixVal').focus();
          }
          return
        }

        if (isNaN(stateVal) || isNaN(countyVal) || isNaN(framVal) || isNaN(farmSuffixVal)) {
          showMessage(thisObj, 'State, County, and Farm must be numeric.', false)
          if (isNan(stateVal)) {
            document.getElementById('txtStateVal').focus();
          } else if (isNaN(countyVal)) {
            document.getElementById('txtCountyVal').focus();
          } else if (isNaN(framVal)) {
            document.getElementById('txtFarmVal').focus();
          } else if (isNaN(farmSuffixVal)) {
            document.getElementById('txtFarmSuffixVal').focus();
          }
          return
        }

        if (stateVal.length != 2) {
          showMessage(thisObj, 'State must be 2 digits in length.', false)
          document.getElementById('txtStateVal').focus();
          return
        }
        if (countyVal.length != 3) {
          showMessage(thisObj, 'County must be 3 digits in length.', false)
          document.getElementById('txtcountyVal').focus();
          return
        }
        if (framVal.length != 7) {
          showMessage(thisObj, 'Farm must be 7 digits in length.', false)
          document.getElementById('txtframVal').focus();
          return
        }
        if (farmSuffixVal.length != 3) {
          showMessage(thisObj, 'Farm suffix must be 3 digits in length.', false)
          document.getElementById('txtfarmSuffixVal').focus();
          return
        }
      }

      for (let i = 0; i < arry.length; i++) {
        if (getValue(thisObj, 'chkboxOverride') == true) {
          let dataObj = {
            "chgtoresidue": "N",
            "state": stateVal,
            "county": countyVal,
            "farm": framVal,
            "farm_suffix": farmSuffixVal,
            "format": formatVal
          }
          await SettlementService.UpdateUploadResubmitDetails(arry[i].txtcolTranNum, dataObj)
            .then(respp => {
              apiResult = respp
              if (respp.status == 200) {
                msgFlag = false
              } else {
                msgFlag = true
              }
            })
        } else {
          if (getValue(thisObj, 'chkboxChangetoResidueLoad') == true) {
            let dataObj = {
              "chgtoresidue": "Y",
              "state": 0,
              "county": 0,
              "farm": 0,
              "farm_suffix": 0,
              "format": "NA"
            }
            await SettlementService.UpdateUploadResubmitDetails(arry[i].txtcolTranNum, dataObj)
              .then(respp => {
                apiResult = respp
                if (respp.status == 200) {
                  msgFlag = false
                } else {
                  msgFlag = true
                }
              })
          } else {
            let dataObj = {
              "chgtoresidue": "N",
              "state": 0,
              "county": 0,
              "farm": 0,
              "farm_suffix": 0,
              "format": "NA"
            }
            await SettlementService.UpdateUploadResubmitDetails(arry[i].txtcolTranNum, dataObj)
              .then(respp => {
                apiResult = respp
                if (respp.status == 200) {
                  msgFlag = false
                } else {
                  msgFlag = true
                }
              })
          }
        }
        if (msgFlag) {
          showMessage(thisObj, `Attempt to Resubmit record failed! ${apiResult.message}`, false)
          return
        }
      }


      showMessage(thisObj, `Record Resubmitted to processing.`, true)

      doSearch();

    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during btnResubmit click event"
        );
      }
      return false;
    }
  }
  thisObj.onbtnResubmitClick = onbtnResubmitClick;

  const onchkboxChangetoResidueLoadChange = () => {
    enableDisableOverrides();
  }
  thisObj.onchkboxChangetoResidueLoadChange = onchkboxChangetoResidueLoadChange;
  
  const onchkboxOverrideChange = () => {
    enableDisableOverrides();
  }
  thisObj.onchkboxOverrideChange = onchkboxOverrideChange;


  const ontxtInspectionNumChange = () => {
    try {
      setValue(thisObj, 'txtInspectionNum', txtInspectionNum.value)
    } catch (error) {
      if (error instanceof EvalError) {
        showMessage(thisObj, error.message);
      } else {
        showMessage(
          thisObj,
          "Error occurred during txtInspectionNum click event"
        );
      }
      return false;
    }
  }
  thisObj.ontxtInspectionNumChange = ontxtInspectionNumChange;



  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <Loading loading={loading}></Loading>
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewUploads*/}

              {/* END_USER_CODE-USER_BEFORE_ViewUploads*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewUploads*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewUploads*/}

              <GroupBoxWidget conf={state.grpbxViewUploads} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_ddbuyingPoint*/}

                {/* END_USER_CODE-USER_BEFORE_ddbuyingPoint*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddbuyingPoint}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddbuyingPoint*/}

                {/* END_USER_CODE-USER_AFTER_ddbuyingPoint*/}
                {/* START_USER_CODE-USER_BEFORE_txtState*/}

                {/* END_USER_CODE-USER_BEFORE_txtState*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtState}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtState*/}

                {/* END_USER_CODE-USER_AFTER_txtState*/}
                {/* START_USER_CODE-USER_BEFORE_txtInspectionNum*/}

                {/* END_USER_CODE-USER_BEFORE_txtInspectionNum*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtInspectionNum}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtInspectionNum*/}

                {/* END_USER_CODE-USER_AFTER_txtInspectionNum*/}
                {/* START_USER_CODE-USER_BEFORE_txtCounty*/}

                {/* END_USER_CODE-USER_BEFORE_txtCounty*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtCounty}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtCounty*/}

                {/* END_USER_CODE-USER_AFTER_txtCounty*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxExcludeErrors*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxExcludeErrors*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxExcludeErrors}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxExcludeErrors*/}

                {/* END_USER_CODE-USER_AFTER_chkboxExcludeErrors*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarm*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarm*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarm}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarm*/}

                {/* END_USER_CODE-USER_AFTER_txtFarm*/}
                {/* START_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_BEFORE_txtFarmSuffix*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtFarmSuffix}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_txtFarmSuffix*/}

                {/* END_USER_CODE-USER_AFTER_txtFarmSuffix*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxDisplayHidden*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxDisplayHidden*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxDisplayHidden}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxDisplayHidden*/}

                {/* END_USER_CODE-USER_AFTER_chkboxDisplayHidden*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxResidueLoadsOnly*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxResidueLoadsOnly*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxResidueLoadsOnly}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxResidueLoadsOnly*/}

                {/* END_USER_CODE-USER_AFTER_chkboxResidueLoadsOnly*/}
                {/* START_USER_CODE-USER_BEFORE_cmmndCntnrSearch*/}

                {/* END_USER_CODE-USER_BEFORE_cmmndCntnrSearch*/}

                <CommandContainerWidget
                  conf={state.cmmndCntnrSearch}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnSearch*/}

                  {/* END_USER_CODE-USER_BEFORE_btnSearch*/}

                  <ButtonWidget
                    conf={state.btnSearch}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnSearch*/}

                  {/* END_USER_CODE-USER_AFTER_btnSearch*/}
                </CommandContainerWidget>
                {/* START_USER_CODE-USER_AFTER_cmmndCntnrSearch*/}

                {/* END_USER_CODE-USER_AFTER_cmmndCntnrSearch*/}
                {/* START_USER_CODE-USER_BEFORE_gridSearchResults*/}

                {/* END_USER_CODE-USER_BEFORE_gridSearchResults*/}

                <GridWidget
                  conf={state.gridSearchResults}
                  screenConf={state}
                  linkClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  errors={errors}
                  touched={touched}
                  rows={values.gridSearchResults}
                  onEvent={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  refObject={thisObj}
                ></GridWidget>
                {/* START_USER_CODE-USER_AFTER_gridSearchResults*/}

                {/* END_USER_CODE-USER_AFTER_gridSearchResults*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxSearchData*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxSearchData*/}

                <GroupBoxWidget conf={state.grpbxSearchData} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_txtStateVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtStateVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtStateVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtStateVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtStateVal*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCountyVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCountyVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCountyVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCountyVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtCountyVal*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmVal*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFarmSuffixVal*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFarmSuffixVal*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFarmSuffixVal}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFarmSuffixVal*/}

                  {/* END_USER_CODE-USER_AFTER_txtFarmSuffixVal*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxOverride*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxOverride*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxOverride}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxOverride*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxOverride*/}
                  {/* START_USER_CODE-USER_BEFORE_chkboxChangetoResidueLoad*/}

                  {/* END_USER_CODE-USER_BEFORE_chkboxChangetoResidueLoad*/}

                  <CheckboxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={handleBlur}
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.chkboxChangetoResidueLoad}
                    screenConf={state}
                  ></CheckboxWidget>
                  {/* START_USER_CODE-USER_AFTER_chkboxChangetoResidueLoad*/}

                  {/* END_USER_CODE-USER_AFTER_chkboxChangetoResidueLoad*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxSearchData*/}

                {/* END_USER_CODE-USER_AFTER_grpbxSearchData*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewUploads*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewUploads*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnHide*/}

                {/* END_USER_CODE-USER_BEFORE_btnHide*/}

                <ButtonWidget
                  conf={state.btnHide}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnHide*/}

                {/* END_USER_CODE-USER_AFTER_btnHide*/}
                {/* START_USER_CODE-USER_BEFORE_btnResubmit*/}

                {/* END_USER_CODE-USER_BEFORE_btnResubmit*/}

                <ButtonWidget
                  conf={state.btnResubmit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnResubmit*/}

                {/* END_USER_CODE-USER_AFTER_btnResubmit*/}
                {/* START_USER_CODE-USER_BEFORE_btnClose*/}

                {/* END_USER_CODE-USER_BEFORE_btnClose*/}

                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnClose*/}

                {/* END_USER_CODE-USER_AFTER_btnClose*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewUploads*/}

              {/* END_USER_CODE-USER_AFTER_ViewUploads*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(Settlements_ViewUploads);
