/* eslint-disable*/
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  ButtonWidget,
  GridWidget,
  LabelWidget,
  RadioButtonGroupWidget,
  CheckboxWidget,
  setValue,
  getValue,
  setData,
  getData,
  disable,
  hide,
  enable,
  show,
  goTo,
  isEnabled,
  isVisible,
  hideColumn,
  DateWidget,
  setFieldValues,
  showWidgets,
  hideWidgets,
  enableWidgets
} from "../../shared/WindowImports";

import "./ViewWarehouseReceipt.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { WarehouseReceiptService } from "../Service/WarehouseReceiptService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { SystemMaintenanceMasterManagementService } from "../../SystemMaintenanceMasterManagement/Service/SystemMaintenanceMasterManagementServices";
import Loading from "../../../Loader/Loading";
import moment from "moment";
import { toPascalCase } from "../../Common/Constants";
import CommonContext from '../../Store/CommonContext';
import StripPic from "../../../../assets/img/PinStrip.png";

// END_USER_CODE-USER_IMPORTS
function WarehouseReceipts_ViewWarehouseReceipt(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const commonContext = useContext(CommonContext);
  // START_USER_CODE-USER_PROPERTIES
  const compIdFromLS = () => (sessionStorage.getItem('compId'));
  const cropYearFromLS = () => (JSON.parse(sessionStorage.getItem('year')));
  const useridFromLS = () => (sessionStorage.getItem('userid') || '');

  // let msAreaId = "";
  // let msFedBuyPtId = "";
  let mcol1007s = [];
  let mcol1007Contracts = [];
  // let mcolContracts = [];
  let mcolFinal1007List = [];
  let mcolContracts2 = [];
  let msWhouseNum = "";
  let msWhouseBin = "";
  let mdblSupportPerTon = 0;
  let mbLoadOutsExist = false;
  let msLoadOutMinDate = "";
  let msLoadOutMaxDate = "";
  let mbCreatePermissionDeny = false;
  let mbUnloadLocInqPermissionDeny = false;
  let mbShrinkPermissionDeny = false;
  let mbUpdatePermissionDeny = false;
  let mbPurchasePermissionDeny = false;
  let mbDelvOutPermissionDeny = false;
  let mbVoidPermissionDeny = false;
  let mbUnpurchasePermissionDeny = false;
  let mbRedemptionMethodPermissionDeny = false;
  // let mbEWRAccepted = false;
  // let mbFormLoad = false;
  // let msTent_cont_appl_ind = "";
  let msMode = "";
  let mbReceiptTypeChanged = false;
  let flg_cma_dma = false;
  let mbRepaymentOverrideDeny
  let mbSuppressOverrideLRRClick
  let msCCCVendorList = "";
  let msWireXferDataSaved = "";
  let mbEnableLoanFieldsPermission = false;
  // let mbFillGridError = false;
  let mboolElectronicWarehouseReceipt = false;
  let chkboxWireTransferBool = false

  // let lstrHolderID = ''
  let lblBasisGradeAmt = 0;
  let txtStatusTag = ""
  // let txtPeanutName = ""
  let lblRcptStatusTag = ""
  let lblLastChange = ""
  // let lblUnloadBuyPtId = ""
  let lblPeanutType = ""
  // let lblSpotPounds = 0
  // let lblRepaymentCalcHandling = 0
  // let lblBuyPtIdCaption = ""
  // let lblUnloadBuyPtIdCaption = ""
  // let lblGrossPounds = ''
  let lSeedPounds
  let dblValueOfSeg = 0
  let dblFarmerStockCost = 0;
  let hideShowControlDict = {}
  let objVal = {}
  let vendorType = ''

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "ViewWarehouseReceipt",
    windowName: "ViewWarehouseReceipt",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "WarehouseReceipts.ViewWarehouseReceipt",
    // START_USER_CODE-USER_ViewWarehouseReceipt_PROPERTIES
    horizontalForm:true,
    headerData: {
      scrName: "Warehouse Receipt",
      scrCode: "PN1090C",
    },
    // END_USER_CODE-USER_ViewWarehouseReceipt_PROPERTIES
    btnAcctDist: {
      name: "btnAcctDist",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Acct. Dist.",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAcctDist_PROPERTIES

      // END_USER_CODE-USER_btnAcctDist_PROPERTIES
    },
    btnApplications: {
      name: "btnApplications",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Applications",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnApplications_PROPERTIES

      // END_USER_CODE-USER_btnApplications_PROPERTIES
    },
    btnBeginDelivery: {
      name: "btnBeginDelivery",
      type: "ButtonWidget",
      parent: "grpbxBeginDelvBtnCntnr",
      Label: "Begin Delv.",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnBeginDelivery_PROPERTIES

      // END_USER_CODE-USER_btnBeginDelivery_PROPERTIES
    },
    btnCalculatePayment: {
      name: "btnCalculatePayment",
      type: "ButtonWidget",
      parent: "grpbxForfeitureStorage",
      Label: "Calculate Payment",
      CharWidth: "35",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCalculatePayment_PROPERTIES

      // END_USER_CODE-USER_btnCalculatePayment_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnCMAVendor: {
      name: "btnCMAVendor",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCMAVendor_PROPERTIES

      // END_USER_CODE-USER_btnCMAVendor_PROPERTIES
    },
    btncol1: {
      name: "btncol1",
      type: "ButtonWidget",
      colName: "col1",
      parent: "grid1007s",
      CharWidth: "4",
      Height: "",
      Width: "",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btncol1_PROPERTIES

      // END_USER_CODE-USER_btncol1_PROPERTIES
    },
    btnCompDelv: {
      name: "btnCompDelv",
      type: "ButtonWidget",
      parent: "grpbxCompDelvBtnCntnr",
      Label: "Comp. Delv.",
      CharWidth: "24",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCompDelv_PROPERTIES

      // END_USER_CODE-USER_btnCompDelv_PROPERTIES
    },
    btnDeductTrack: {
      name: "btnDeductTrack",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Deduct Track",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnDeductTrack_PROPERTIES

      // END_USER_CODE-USER_btnDeductTrack_PROPERTIES
    },
    btnEnableLoanFields: {
      name: "btnEnableLoanFields",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Enable Loan Fields",
      CharWidth: "37",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnEnableLoanFields_PROPERTIES

      // END_USER_CODE-USER_btnEnableLoanFields_PROPERTIES
    },
    btnLoadOut: {
      name: "btnLoadOut",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Loads Out",
      CharWidth: "21",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnLoadOut_PROPERTIES
      Visible : false
      // END_USER_CODE-USER_btnLoadOut_PROPERTIES
    },
    btnPayments: {
      name: "btnPayments",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Payments",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPayments_PROPERTIES

      // END_USER_CODE-USER_btnPayments_PROPERTIES
    },
    btnPremsDeduct: {
      name: "btnPremsDeduct",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr2",
      Label: "Prems/Deduct",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPremsDeduct_PROPERTIES

      // END_USER_CODE-USER_btnPremsDeduct_PROPERTIES
    },
    btnPrintFSADoc: {
      name: "btnPrintFSADoc",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print FSA Document",
      CharWidth: "38",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintFSADoc_PROPERTIES

      // END_USER_CODE-USER_btnPrintFSADoc_PROPERTIES
    },
    btnPrintWorksheet: {
      name: "btnPrintWorksheet",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Worksheet",
      CharWidth: "32",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPrintWorksheet_PROPERTIES

      // END_USER_CODE-USER_btnPrintWorksheet_PROPERTIES
    },
    btnPurchase: {
      name: "btnPurchase",
      type: "ButtonWidget",
      parent: "grpbxPrchsBtnCntnr",
      Label: "Purchase",
      CharWidth: "19",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnPurchase_PROPERTIES
    },
    btnRepayVendor: {
      name: "btnRepayVendor",
      type: "ButtonWidget",
      parent: "grpbxRepayment",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnRepayVendor_PROPERTIES

      // END_USER_CODE-USER_btnRepayVendor_PROPERTIES
    },
    btnReverseShrinkPayment: {
      name: "btnReverseShrinkPayment",
      type: "ButtonWidget",
      parent: "grpbxShrinkPayBtnCntnr",
      Label: "Reverse Shrink Payment",
      CharWidth: "45",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnReverseShrinkPayment_PROPERTIES

      // END_USER_CODE-USER_btnReverseShrinkPayment_PROPERTIES
    },
    btnShrinkPayment: {
      name: "btnShrinkPayment",
      type: "ButtonWidget",
      parent: "grpbxShrinkPayBtnCntnr",
      Label: "Shrink Payment",
      CharWidth: "30",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnShrinkPayment_PROPERTIES

      // END_USER_CODE-USER_btnShrinkPayment_PROPERTIES
    },
    btnStorageHandlingPayment: {
      name: "btnStorageHandlingPayment",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Storage Handling Payment",
      CharWidth: "48",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnStorageHandlingPayment_PROPERTIES

      // END_USER_CODE-USER_btnStorageHandlingPayment_PROPERTIES
    },
    btnTentApplication: {
      name: "btnTentApplication",
      type: "ButtonWidget",
      parent: "grpbxTentApplBtnCntnr",
      Label: "Tentative Application",
      CharWidth: "42",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTentApplication_PROPERTIES

      // END_USER_CODE-USER_btnTentApplication_PROPERTIES
    },
    btnTentApplicationReverse: {
      name: "btnTentApplicationReverse",
      type: "ButtonWidget",
      parent: "grpbxTentApplBtnCntnr",
      Label: "Reverse Tentative Application",
      CharWidth: "57",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnTentApplicationReverse_PROPERTIES

      // END_USER_CODE-USER_btnTentApplicationReverse_PROPERTIES
    },
    btnUndoBegDelvOut: {
      name: "btnUndoBegDelvOut",
      type: "ButtonWidget",
      parent: "grpbxBeginDelvBtnCntnr",
      Label: "Cancel Delv.",
      CharWidth: "26",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUndoBegDelvOut_PROPERTIES

      // END_USER_CODE-USER_btnUndoBegDelvOut_PROPERTIES
    },
    btnUndoCompDelvout: {
      name: "btnUndoCompDelvout",
      type: "ButtonWidget",
      parent: "grpbxCompDelvBtnCntnr",
      Label: "Undo Comp. Delv.",
      CharWidth: "34",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUndoCompDelvout_PROPERTIES

      // END_USER_CODE-USER_btnUndoCompDelvout_PROPERTIES
    },
    btnUnPurchase: {
      name: "btnUnPurchase",
      type: "ButtonWidget",
      parent: "grpbxPrchsBtnCntnr",
      Label: "UnPurchase",
      CharWidth: "23",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUnPurchase_PROPERTIES

      // END_USER_CODE-USER_btnUnPurchase_PROPERTIES
    },
    btnUnVoid: {
      name: "btnUnVoid",
      type: "ButtonWidget",
      parent: "grpbxVoidBtnCntnr",
      Label: "UnVoid",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUnVoid_PROPERTIES

      // END_USER_CODE-USER_btnUnVoid_PROPERTIES
    },
    btnUpdate: {
      name: "btnUpdate",
      type: "ButtonWidget",
      parent: "grpbxBtnCntnr1",
      Label: "Update",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnUpdate_PROPERTIES

      // END_USER_CODE-USER_btnUpdate_PROPERTIES
    },
    btnVendor: {
      name: "btnVendor",
      type: "ButtonWidget",
      parent: "grpbxReceiptInfo3",
      Label: "...",
      CharWidth: "9",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVendor_PROPERTIES

      // END_USER_CODE-USER_btnVendor_PROPERTIES
    },
    btnViewEWRLog: {
      name: "btnViewEWRLog",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "View EWR Log",
      CharWidth: "27",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnViewEWRLog_PROPERTIES

      // END_USER_CODE-USER_btnViewEWRLog_PROPERTIES
    },
    btnVoid: {
      name: "btnVoid",
      type: "ButtonWidget",
      parent: "grpbxVoidBtnCntnr",
      Label: "Void",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnVoid_PROPERTIES

      // END_USER_CODE-USER_btnVoid_PROPERTIES
    },
    chkbox211: {
      name: "chkbox211",
      type: "CheckBoxWidget",
      parent: "grpbx211CCC500",
      Label: "211?",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkbox211_PROPERTIES
      Visible : false,
      Enabled : false,
      // END_USER_CODE-USER_chkbox211_PROPERTIES
    },
    chkboxCCC500: {
      name: "chkboxCCC500",
      type: "CheckBoxWidget",
      parent: "grpbx211CCC500",
      Label: "CCC 500?",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCCC500_PROPERTIES

      // END_USER_CODE-USER_chkboxCCC500_PROPERTIES
    },
    chkboxCMA: {
      name: "chkboxCMA",
      type: "CheckBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "CMA:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCMA_PROPERTIES

      // END_USER_CODE-USER_chkboxCMA_PROPERTIES
    },
    chkboxCommittedToOther: {
      name: "chkboxCommittedToOther",
      type: "CheckBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Committed to Other:",
      ColSpan: "4",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCommittedToOther_PROPERTIES

      // END_USER_CODE-USER_chkboxCommittedToOther_PROPERTIES
    },
    chkboxDeferredRedemption: {
      name: "chkboxDeferredRedemption",
      type: "CheckBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Deferred Redemption?",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDeferredRedemption_PROPERTIES

      // END_USER_CODE-USER_chkboxDeferredRedemption_PROPERTIES
    },
    chkboxDMA: {
      name: "chkboxDMA",
      type: "CheckBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "DMA:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxDMA_PROPERTIES

      // END_USER_CODE-USER_chkboxDMA_PROPERTIES
    },
    chkboxOverrideLRR: {
      name: "chkboxOverrideLRR",
      type: "CheckBoxWidget",
      parent: "grpbxPrchs1",
      Label: "Override LRR:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxOverrideLRR_PROPERTIES

      // END_USER_CODE-USER_chkboxOverrideLRR_PROPERTIES
    },
    chkboxStoreAndHandlePaid: {
      name: "chkboxStoreAndHandlePaid",
      type: "CheckBoxWidget",
      parent: "grpbxStorage",
      Label: "Storage and Handling Paid ?",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxStoreAndHandlePaid_PROPERTIES
      Visible : false,
      // END_USER_CODE-USER_chkboxStoreAndHandlePaid_PROPERTIES
    },
    chkboxStoreAndHandleReceived: {
      name: "chkboxStoreAndHandleReceived",
      type: "CheckBoxWidget",
      parent: "grpbxStorage",
      Label: "Storage and handling Payment Received?",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxStoreAndHandleReceived_PROPERTIES
      Visible : false,
      // END_USER_CODE-USER_chkboxStoreAndHandleReceived_PROPERTIES
    },
    chkboxWireTransfer: {
      name: "chkboxWireTransfer",
      type: "CheckBoxWidget",
      parent: "grpbxRepayment",
      // HasLabel: false,
      Label: "Wire Transfer",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxWireTransfer_PROPERTIES

      // END_USER_CODE-USER_chkboxWireTransfer_PROPERTIES
    },
    col1: {
      name: "col1",
      type: "GridColumnWidget",
      parent: "grid1007s",
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1_PROPERTIES

      // END_USER_CODE-USER_col1_PROPERTIES
    },
    col1007Num: {
      name: "col1007Num",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "1007#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_col1007Num_PROPERTIES

      // END_USER_CODE-USER_col1007Num_PROPERTIES
    },
    colContract: {
      name: "colContract",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Contract",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colContract_PROPERTIES

      // END_USER_CODE-USER_colContract_PROPERTIES
    },
    colInspectionDate: {
      name: "colInspectionDate",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Inspection Date",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colInspectionDate_PROPERTIES

      // END_USER_CODE-USER_colInspectionDate_PROPERTIES
    },
    colLSKWeight: {
      name: "colLSKWeight",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "LSK Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colLSKWeight_PROPERTIES

      // END_USER_CODE-USER_colLSKWeight_PROPERTIES
    },
    colNetWeight: {
      name: "colNetWeight",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Net Weight",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWeight_PROPERTIES

      // END_USER_CODE-USER_colNetWeight_PROPERTIES
    },
    colNetWtLessLSK: {
      name: "colNetWtLessLSK",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Net Weight Less LSK",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colNetWtLessLSK_PROPERTIES

      // END_USER_CODE-USER_colNetWtLessLSK_PROPERTIES
    },
    colSC95Num: {
      name: "colSC95Num",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "SC95#",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colSC95Num_PROPERTIES

      // END_USER_CODE-USER_colSC95Num_PROPERTIES
    },
    colUnloadPoint: {
      name: "colUnloadPoint",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Unload Point",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colUnloadPoint_PROPERTIES

      // END_USER_CODE-USER_colUnloadPoint_PROPERTIES
    },
    colFarm: {
      name: "colFarm",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Farm",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colFarm_PROPERTIES

      // END_USER_CODE-USER_colFarm_PROPERTIES
    },
    colValue: {
      name: "colValue",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "Value",
      Height: "",
      Width: "",
      // START_USER_CODE-USER_colValue_PROPERTIES

      // END_USER_CODE-USER_colValue_PROPERTIES
    },
    ddCMARemit: {
      name: "ddCMARemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRepayment",
      Label: "CMA Remit:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddCMARemit_PROPERTIES

      // END_USER_CODE-USER_ddCMARemit_PROPERTIES
    },
    ddCurrentHolder: {
      name: "ddCurrentHolder",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Current Holder:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddCurrentHolder_PROPERTIES

      // END_USER_CODE-USER_ddCurrentHolder_PROPERTIES
    },
    ddRemit: {
      name: "ddRemit",
      type: "DropDownFieldWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Remit:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddRemit_PROPERTIES
      
      // END_USER_CODE-USER_ddRemit_PROPERTIES
    },
    ddRepayRemit: {
      name: "ddRepayRemit",
      type: "DropDownFieldWidget",
      parent: "grpbxRepayment",
      Label: "Repay Remit:",
      ColSpan: "4",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String,d_null",
      // START_USER_CODE-USER_ddRepayRemit_PROPERTIES

      // END_USER_CODE-USER_ddRepayRemit_PROPERTIES
    },
    grid1007s: {
      name: "grid1007s",
      type: "GridWidget",
      parent: "grpbx1007s",
      gridCellsOrder:
        "btncol1,txtcol1007Num,txtcolUnloadPoint,txtcolFarm,txtcolContract,txtcolSC95Num,txtcolInspectionDate,txtcolNetWeight,txtcolLSKWeight,txtcolNetWtLessLSK,txtcolValue,txtcolSmkPct,txtcolSSPct,txtcolOkPct,txtcolDamPct,txtcolFrezDamPct,txtcolCanclRmdPct,txtcolFlavusDisc,txtcolHullPct,txtcolFmPct,txtcolElkPct,txtcolFanPct,txtcolDcolShelPct,txtcolCrBrPct,txtcolMoistPct,txtcolLskPct,txtcolHulBritPct,txtcolJumboPct,txtcolDamSplitPct,txtcolVicamPpb,txtcolTkcPct,txtcolGrssWt,txtcolOblBuyId,txtcolOblWhseId,txtcolOblBinId,txtcolSeedGen,txtcolSegType,txtcolSeedMeetSpecInd",
      Pagination: false,
      HasLabel: false,
      Height: "",
      Width: "",
      // START_USER_CODE-USER_grid1007s_PROPERTIES

      // END_USER_CODE-USER_grid1007s_PROPERTIES
    },
    grpbx1007s: {
      name: "grpbx1007s",
      type: "GroupBoxWidget",
      parent: "grpbxViewWarehouseReceipt",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbx1007s_PROPERTIES

      // END_USER_CODE-USER_grpbx1007s_PROPERTIES
    },
    grpbx211CCC500: {
      name: "grpbx211CCC500",
      type: "GroupBoxWidget",
      parent: "grpbxLoan",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbx211CCC500_PROPERTIES

      // END_USER_CODE-USER_grpbx211CCC500_PROPERTIES
    },
    grpbxBeginDelvBtnCntnr: {
      name: "grpbxBeginDelvBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxBtnCntnr1",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxBeginDelvBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxBeginDelvBtnCntnr_PROPERTIES
    },
    grpbxBtnCntnr1: {
      name: "grpbxBtnCntnr1",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxBtnCntnr1_PROPERTIES

      // END_USER_CODE-USER_grpbxBtnCntnr1_PROPERTIES
    },
    grpbxBtnCntnr2: {
      name: "grpbxBtnCntnr2",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      ColsForTabPotrait: "2",
      // START_USER_CODE-USER_grpbxBtnCntnr2_PROPERTIES

      // END_USER_CODE-USER_grpbxBtnCntnr2_PROPERTIES
    },
    grpbxCompDelvBtnCntnr: {
      name: "grpbxCompDelvBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxBtnCntnr1",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxCompDelvBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxCompDelvBtnCntnr_PROPERTIES
    },
    grpbxFactorsForAllPeanuts: {
      name: "grpbxFactorsForAllPeanuts",
      type: "GroupBoxWidget",
      parent: "grpbxViewWarehouseReceipt",
      Height: "",
      Width: "",
      ColsForMobile: "10",
      ColsForTabLandscape: "10",
      HasLabel: false,
      Cols: "10",
      ColsForTabPotrait: "10",
      ColsForLargeDesktop: "10",
      // START_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_grpbxFactorsForAllPeanuts_PROPERTIES
    },
    grpbxForfeitureStorage: {
      name: "grpbxForfeitureStorage",
      type: "GroupBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxForfeitureStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxForfeitureStorage_PROPERTIES
    },
    grpbxLoan: {
      name: "grpbxLoan",
      type: "GroupBoxWidget",
      parent: "grpbxRepay",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoan_PROPERTIES

      // END_USER_CODE-USER_grpbxLoan_PROPERTIES
    },
    grpbxLoanNum: {
      name: "grpbxLoanNum",
      type: "GroupBoxWidget",
      parent: "grpbxLoan",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLoanNum_PROPERTIES

      // END_USER_CODE-USER_grpbxLoanNum_PROPERTIES
    },
    grpbxLstLbl: {
      name: "grpbxLstLbl",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo2",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxLstLbl_PROPERTIES

      // END_USER_CODE-USER_grpbxLstLbl_PROPERTIES
    },
    grpbxPayShrink: {
      name: "grpbxPayShrink",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForMobile: "3",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPayShrink_PROPERTIES

      // END_USER_CODE-USER_grpbxPayShrink_PROPERTIES
    },
    grpbxPrchs1: {
      name: "grpbxPrchs1",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrchs1_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchs1_PROPERTIES
    },
    grpbxPrchs2: {
      name: "grpbxPrchs2",
      type: "GroupBoxWidget",
      parent: "grpbxPurchase",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrchs2_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchs2_PROPERTIES
    },
    grpbxPrchsBtnCntnr: {
      name: "grpbxPrchsBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxBtnCntnr1",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxPrchsBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxPrchsBtnCntnr_PROPERTIES
    },
    grpbxPurchase: {
      name: "grpbxPurchase",
      type: "GroupBoxWidget",
      parent: "grpbxStrgPrchs",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxPurchase_PROPERTIES

      // END_USER_CODE-USER_grpbxPurchase_PROPERTIES
    },
    grpbxReceiptInfo: {
      name: "grpbxReceiptInfo",
      type: "GroupBoxWidget",
      parent: "grpbxViewWarehouseReceipt",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxReceiptInfo_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo_PROPERTIES
    },
    grpbxReceiptInfo1: {
      name: "grpbxReceiptInfo1",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxReceiptInfo1_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo1_PROPERTIES
    },
    grpbxReceiptInfo2: {
      name: "grpbxReceiptInfo2",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      ColSpan: "2",
      Height: "",
      Width: "",
      ColsForMobile: "5",
      ColsForTabLandscape: "5",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "5",
      ColsForLargeDesktop: "5",
      // START_USER_CODE-USER_grpbxReceiptInfo2_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo2_PROPERTIES
    },
    grpbxReceiptInfo3: {
      name: "grpbxReceiptInfo3",
      type: "GroupBoxWidget",
      parent: "grpbxReceiptInfo",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxReceiptInfo3_PROPERTIES

      // END_USER_CODE-USER_grpbxReceiptInfo3_PROPERTIES
    },
    grpbxRepay: {
      name: "grpbxRepay",
      type: "GroupBoxWidget",
      parent: "grpbxViewWarehouseReceipt",
      ColSpan: "2",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxRepay_PROPERTIES

      // END_USER_CODE-USER_grpbxRepay_PROPERTIES
    },
    grpbxRepayment: {
      name: "grpbxRepayment",
      type: "GroupBoxWidget",
      parent: "grpbxRepay",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxRepayment_PROPERTIES

      // END_USER_CODE-USER_grpbxRepayment_PROPERTIES
    },
    grpbxRepymnt: {
      name: "grpbxRepymnt",
      type: "GroupBoxWidget",
      parent: "grpbxRepayment",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "4",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxRepymnt_PROPERTIES

      // END_USER_CODE-USER_grpbxRepymnt_PROPERTIES
    },
    grpbxShrinkPayBtnCntnr: {
      name: "grpbxShrinkPayBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxShrinkPayBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxShrinkPayBtnCntnr_PROPERTIES
    },
    grpbxStorage: {
      name: "grpbxStorage",
      type: "GroupBoxWidget",
      parent: "grpbxStrgPrchs",
      Height: "",
      Width: "",
      ColsForMobile: "2",
      ColsForTabLandscape: "2",
      HasLabel: false,
      Cols: "2",
      ColsForTabPotrait: "2",
      ColsForLargeDesktop: "2",
      // START_USER_CODE-USER_grpbxStorage_PROPERTIES

      // END_USER_CODE-USER_grpbxStorage_PROPERTIES
    },
    grpbxStrgPrchs: {
      name: "grpbxStrgPrchs",
      type: "GroupBoxWidget",
      parent: "grpbxViewWarehouseReceipt",
      ColSpan: "1",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxStrgPrchs_PROPERTIES

      // END_USER_CODE-USER_grpbxStrgPrchs_PROPERTIES
    },
    grpbxTentApplBtnCntnr: {
      name: "grpbxTentApplBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxActions",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxTentApplBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxTentApplBtnCntnr_PROPERTIES
    },
    grpbxVoidBtnCntnr: {
      name: "grpbxVoidBtnCntnr",
      type: "GroupBoxWidget",
      parent: "grpbxBtnCntnr1",
      ColSpan: "1",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxVoidBtnCntnr_PROPERTIES

      // END_USER_CODE-USER_grpbxVoidBtnCntnr_PROPERTIES
    },
    lbl1007s: {
      name: "lbl1007s",
      type: "LabelWidget",
      parent: "grpbx1007s",
      Label: "1007's",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lbl1007s_PROPERTIES

      // END_USER_CODE-USER_lbl1007s_PROPERTIES
    },
    lblAddedBy: {
      name: "lblAddedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Added By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedBy_PROPERTIES

      // END_USER_CODE-USER_lblAddedBy_PROPERTIES
    },
    lblAddedByValue: {
      name: "lblAddedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblAddedByValue_PROPERTIES

      // END_USER_CODE-USER_lblAddedByValue_PROPERTIES
    },
    lblChangedBy: {
      name: "lblChangedBy",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: "Changed By:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedBy_PROPERTIES

      // END_USER_CODE-USER_lblChangedBy_PROPERTIES
    },
    lblChangedByValue: {
      name: "lblChangedByValue",
      type: "LabelWidget",
      parent: "grpbxActions",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblChangedByValue_PROPERTIES

      // END_USER_CODE-USER_lblChangedByValue_PROPERTIES
    },
    lblEWRHolder: {
      name: "lblEWRHolder",
      type: "LabelWidget",
      parent: "grpbxLstLbl",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEWRHolder_PROPERTIES

      // END_USER_CODE-USER_lblEWRHolder_PROPERTIES
    },
    lblEWRReceiptType: {
      name: "lblEWRReceiptType",
      type: "LabelWidget",
      parent: "grpbxLstLbl",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEWRReceiptType_PROPERTIES

      // END_USER_CODE-USER_lblEWRReceiptType_PROPERTIES
    },
    lblEWRTransmissionStatus: {
      name: "lblEWRTransmissionStatus",
      type: "LabelWidget",
      parent: "grpbxLstLbl",
      Label: ".",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblEWRTransmissionStatus_PROPERTIES
      Visible : false,
      // END_USER_CODE-USER_lblEWRTransmissionStatus_PROPERTIES
    },
    lblFactorsForAllPeanuts: {
      name: "lblFactorsForAllPeanuts",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Factors For All Peanuts",
      ColSpan: "10",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES

      // END_USER_CODE-USER_lblFactorsForAllPeanuts_PROPERTIES
    },
    lblForfeitureStorage: {
      name: "lblForfeitureStorage",
      type: "LabelWidget",
      parent: "grpbxForfeitureStorage",
      Label: "Forfeiture Storage",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblForfeitureStorage_PROPERTIES

      // END_USER_CODE-USER_lblForfeitureStorage_PROPERTIES
    },
    lblLoanNum: {
      name: "lblLoanNum",
      type: "LabelWidget",
      parent: "grpbxLoanNum",
      Label: "Loan Number:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblLoanNum_PROPERTIES

      // END_USER_CODE-USER_lblLoanNum_PROPERTIES
    },
    lblPaymentShrinkUsing: {
      name: "lblPaymentShrinkUsing",
      type: "LabelWidget",
      parent: "grpbxPayShrink",
      Label: "Payment Shrink Using:",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPaymentShrinkUsing_PROPERTIES

      // END_USER_CODE-USER_lblPaymentShrinkUsing_PROPERTIES
    },
    lblPct1: {
      name: "lblPct1",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct1_PROPERTIES

      // END_USER_CODE-USER_lblPct1_PROPERTIES
    },
    lblPct10: {
      name: "lblPct10",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct10_PROPERTIES

      // END_USER_CODE-USER_lblPct10_PROPERTIES
    },
    lblPct11: {
      name: "lblPct11",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct11_PROPERTIES

      // END_USER_CODE-USER_lblPct11_PROPERTIES
    },
    lblPct12: {
      name: "lblPct12",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct12_PROPERTIES

      // END_USER_CODE-USER_lblPct12_PROPERTIES
    },
    lblPct13: {
      name: "lblPct13",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct13_PROPERTIES

      // END_USER_CODE-USER_lblPct13_PROPERTIES
    },
    lblPct14: {
      name: "lblPct14",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct14_PROPERTIES

      // END_USER_CODE-USER_lblPct14_PROPERTIES
    },
    lblPct15: {
      name: "lblPct15",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct15_PROPERTIES

      // END_USER_CODE-USER_lblPct15_PROPERTIES
    },
    lblPct16: {
      name: "lblPct16",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct16_PROPERTIES

      // END_USER_CODE-USER_lblPct16_PROPERTIES
    },
    lblPct17: {
      name: "lblPct17",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct17_PROPERTIES

      // END_USER_CODE-USER_lblPct17_PROPERTIES
    },
    lblPct18: {
      name: "lblPct18",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct18_PROPERTIES

      // END_USER_CODE-USER_lblPct18_PROPERTIES
    },
    lblPct19: {
      name: "lblPct19",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      ColSpan: "3",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct19_PROPERTIES

      // END_USER_CODE-USER_lblPct19_PROPERTIES
    },
    lblPct2: {
      name: "lblPct2",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct2_PROPERTIES

      // END_USER_CODE-USER_lblPct2_PROPERTIES
    },
    lblPct3: {
      name: "lblPct3",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct3_PROPERTIES

      // END_USER_CODE-USER_lblPct3_PROPERTIES
    },
    lblPct4: {
      name: "lblPct4",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct4_PROPERTIES

      // END_USER_CODE-USER_lblPct4_PROPERTIES
    },
    lblPct5: {
      name: "lblPct5",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct5_PROPERTIES

      // END_USER_CODE-USER_lblPct5_PROPERTIES
    },
    lblPct6: {
      name: "lblPct6",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct6_PROPERTIES

      // END_USER_CODE-USER_lblPct6_PROPERTIES
    },
    lblPct7: {
      name: "lblPct7",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct7_PROPERTIES

      // END_USER_CODE-USER_lblPct7_PROPERTIES
    },
    lblPct8: {
      name: "lblPct8",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct8_PROPERTIES

      // END_USER_CODE-USER_lblPct8_PROPERTIES
    },
    lblPct9: {
      name: "lblPct9",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "%",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPct9_PROPERTIES

      // END_USER_CODE-USER_lblPct9_PROPERTIES
    },
    lblPPB: {
      name: "lblPPB",
      type: "LabelWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "PPB",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPPB_PROPERTIES

      // END_USER_CODE-USER_lblPPB_PROPERTIES
    },
    lblPurchaseInfo: {
      name: "lblPurchaseInfo",
      type: "LabelWidget",
      parent: "grpbxPurchase",
      Label: "Purchase Information",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblPurchaseInfo_PROPERTIES

      // END_USER_CODE-USER_lblPurchaseInfo_PROPERTIES
    },
    lblRcptValue: {
      name: "lblRcptValue",
      type: "LabelWidget",
      parent: "grpbxLstLbl",
      Label: "",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRcptValue_PROPERTIES

      // END_USER_CODE-USER_lblRcptValue_PROPERTIES
    },
    lblReceiptInfo: {
      name: "lblReceiptInfo",
      type: "LabelWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Receipt Information",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblReceiptInfo_PROPERTIES

      // END_USER_CODE-USER_lblReceiptInfo_PROPERTIES
    },
    lblRepayment: {
      name: "lblRepayment",
      type: "LabelWidget",
      parent: "grpbxRepymnt",
      Label: "Repayment",
      ColSpan: "4",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblRepayment_PROPERTIES

      // END_USER_CODE-USER_lblRepayment_PROPERTIES
    },
    lblStorage: {
      name: "lblStorage",
      type: "LabelWidget",
      parent: "grpbxStorage",
      Label: "Storage",
      ColSpan: "2",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblStorage_PROPERTIES

      // END_USER_CODE-USER_lblStorage_PROPERTIES
    },
    lstEWRHolder: {
      name: "lstEWRHolder",
      type: "TextBoxWidget",
      parent: "grpbxLstLbl",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstEWRHolder_PROPERTIES
      Visible : false,
      // END_USER_CODE-USER_lstEWRHolder_PROPERTIES
    },
    lstWhouseRemit: {
      name: "lstWhouseRemit",
      type: "TextBoxWidget",
      parent: "grpbxLstLbl",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lstWhouseRemit_PROPERTIES
      Visible : false,
      // END_USER_CODE-USER_lstWhouseRemit_PROPERTIES
    },
    radioElectronicPaper: {
      name: "radioElectronicPaper",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxReceiptInfo3",
      Options: "Electronic:1,Paper:2",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioElectronicPaper_PROPERTIES

      // END_USER_CODE-USER_radioElectronicPaper_PROPERTIES
    },
    radioLoanRepay: {
      name: "radioLoanRepay",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxPayShrink",
      Options: "Loan Repay:1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioLoanRepay_PROPERTIES

      // END_USER_CODE-USER_radioLoanRepay_PROPERTIES
    },
    radioMarketLoan: {
      name: "radioMarketLoan",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxPayShrink",
      Options: "Market Loan:1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioMarketLoan_PROPERTIES

      // END_USER_CODE-USER_radioMarketLoan_PROPERTIES
    },
    radioOtherRate: {
      name: "radioOtherRate",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxPayShrink",
      Options: "Other Rate:1",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioOtherRate_PROPERTIES

      // END_USER_CODE-USER_radioOtherRate_PROPERTIES
    },
    radioRedemptionMethod: {
      name: "radioRedemptionMethod",
      type: "RadioButtonGroupFieldWidget",
      parent: "grpbxPrchs1",
      Options: "Loan Payoff:1,Orig. 1007s:2,Receipt Value:3,Shrunk Receipt:4",
      Label: "Redemption Method:",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_radioRedemptionMethod_PROPERTIES

      // END_USER_CODE-USER_radioRedemptionMethod_PROPERTIES
    },
    txtCalCNetRepayAmt: {
      name: "txtCalCNetRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      Label: "Net Repay Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCalCNetRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtCalCNetRepayAmt_PROPERTIES
    },
    txtCCCBeginDate: {
      name: "txtCCCBeginDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "CCC Begin Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtCCCCode: {
      name: "txtCCCCode",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "CCC Code:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCCCCode_PROPERTIES

      // END_USER_CODE-USER_txtCCCCode_PROPERTIES
    },
    txtCCCStrgPaidThruDt: {
      name: "txtCCCStrgPaidThruDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "CCC Storage Paid Through Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtCMAVendor: {
      name: "txtCMAVendor",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMAVendor_PROPERTIES

      // END_USER_CODE-USER_txtCMAVendor_PROPERTIES
    },
    txtCMAVendorNum: {
      name: "txtCMAVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "CMA Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCMAVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtCMAVendorNum_PROPERTIES
    },
    txtCnclRMD: {
      name: "txtCnclRMD",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Cncl RMD:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCnclRMD_PROPERTIES

      // END_USER_CODE-USER_txtCnclRMD_PROPERTIES
    },
    txtcol1007Num: {
      name: "txtcol1007Num",
      type: "TextBoxWidget",
      colName: "col1007Num",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcol1007Num_PROPERTIES

      // END_USER_CODE-USER_txtcol1007Num_PROPERTIES
    },
    txtcolSmkPct: {
      name: "txtcolSmkPct",
      type: "TextBoxWidget",
      colName: "colSmkPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colSmkPct: {
      name: "colSmkPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolSSPct: {
      name: "txtcolSSPct",
      type: "TextBoxWidget",
      colName: "colSSPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colSSPct: {
      name: "colSSPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolOkPct: {
      name: "txtcolOkPct",
      type: "TextBoxWidget",
      colName: "colOkPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colOkPct: {
      name: "colOkPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolDamPct: {
      name: "txtcolDamPct",
      type: "TextBoxWidget",
      colName: "colDamPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colDamPct: {
      name: "colDamPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolFrezDamPct: {
      name: "txtcolFrezDamPct",
      type: "TextBoxWidget",
      colName: "colFrezDamPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colFrezDamPct: {
      name: "colFrezDamPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolCanclRmdPct: {
      name: "txtcolCanclRmdPct",
      type: "TextBoxWidget",
      colName: "colCanclRmdPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colCanclRmdPct: {
      name: "colCanclRmdPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolFlavusDisc: {
      name: "txtcolFlavusDisc",
      type: "TextBoxWidget",
      colName: "colFlavusDisc",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colFlavusDisc: {
      name: "colFlavusDisc",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolHullPct: {
      name: "txtcolHullPct",
      type: "TextBoxWidget",
      colName: "colHullPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colHullPct: {
      name: "colHullPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolFmPct: {
      name: "txtcolFmPct",
      type: "TextBoxWidget",
      colName: "colFmPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colFmPct: {
      name: "colFmPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolElkPct: {
      name: "txtcolElkPct",
      type: "TextBoxWidget",
      colName: "colElkPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colElkPct: {
      name: "colElkPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolFanPct: {
      name: "txtcolFanPct",
      type: "TextBoxWidget",
      colName: "colFanPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colFanPct: {
      name: "colFanPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolDcolShelPct: {
      name: "txtcolDcolShelPct",
      type: "TextBoxWidget",
      colName: "colDcolShelPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colDcolShelPct: {
      name: "colDcolShelPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolCrBrPct: {
      name: "txtcolCrBrPct",
      type: "TextBoxWidget",
      colName: "colCrBrPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colCrBrPct: {
      name: "colCrBrPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolMoistPct: {
      name: "txtcolMoistPct",
      type: "TextBoxWidget",
      colName: "colMoistPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colMoistPct: {
      name: "colMoistPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolLskPct: {
      name: "txtcolLskPct",
      type: "TextBoxWidget",
      colName: "colLskPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colLskPct: {
      name: "colLskPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolHulBritPct: {
      name: "txtcolHulBritPct",
      type: "TextBoxWidget",
      colName: "colHulBritPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colHulBritPct: {
      name: "colHulBritPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolJumboPct: {
      name: "txtcolJumboPct",
      type: "TextBoxWidget",
      colName: "colJumboPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colJumboPct: {
      name: "colJumboPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolDamSplitPct: {
      name: "txtcolDamSplitPct",
      type: "TextBoxWidget",
      colName: "colDamSplitPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colDamSplitPct: {
      name: "colDamSplitPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolVicamPpb: {
      name: "txtcolVicamPpb",
      type: "TextBoxWidget",
      colName: "colVicamPpb",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colVicamPpb: {
      name: "colVicamPpb",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolTkcPct: {
      name: "txtcolTkcPct",
      type: "TextBoxWidget",
      colName: "colTkcPct",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colTkcPct: {
      name: "colTkcPct",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolGrssWt: {
      name: "txtcolGrssWt",
      type: "TextBoxWidget",
      colName: "colGrssWt",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colGrssWt: {
      name: "colGrssWt",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolOblBuyId: {
      name: "txtcolOblBuyId",
      type: "TextBoxWidget",
      colName: "colOblBuyId",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colOblBuyId: {
      name: "colOblBuyId",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolOblWhseId: {
      name: "txtcolOblWhseId",
      type: "TextBoxWidget",
      colName: "colOblWhseId",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colOblWhseId: {
      name: "colOblWhseId",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolOblBinId: {
      name: "txtcolOblBinId",
      type: "TextBoxWidget",
      colName: "colOblBinId",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colOblBinId: {
      name: "colOblBinId",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolSeedGen: {
      name: "txtcolSeedGen",
      type: "TextBoxWidget",
      colName: "colSeedGen",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colSeedGen: {
      name: "colSeedGen",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolSegType: {
      name: "txtcolSegType",
      type: "TextBoxWidget",
      colName: "colSegType",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colSegType: {
      name: "colSegType",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolSeedMeetSpecInd: {
      name: "txtcolSeedMeetSpecInd",
      type: "TextBoxWidget",
      colName: "colSeedMeetSpecInd",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      Visible:false,
    },
    colSeedMeetSpecInd: {
      name: "colSeedMeetSpecInd",
      type: "GridColumnWidget",
      parent: "grid1007s",
      Label: "",
      Height: "",
      Width: "",
      Visible:false,
    },
    txtcolContract: {
      name: "txtcolContract",
      type: "TextBoxWidget",
      colName: "colContract",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolContract_PROPERTIES

      // END_USER_CODE-USER_txtcolContract_PROPERTIES
    },
    txtcolInspectionDate: {
      name: "txtcolInspectionDate",
      type: "TextBoxWidget",
      colName: "colInspectionDate",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolInspectionDate_PROPERTIES

      // END_USER_CODE-USER_txtcolInspectionDate_PROPERTIES
    },
    txtcolLSKWeight: {
      name: "txtcolLSKWeight",
      type: "TextBoxWidget",
      colName: "colLSKWeight",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolLSKWeight_PROPERTIES

      // END_USER_CODE-USER_txtcolLSKWeight_PROPERTIES
    },
    txtcolNetWeight: {
      name: "txtcolNetWeight",
      type: "TextBoxWidget",
      colName: "colNetWeight",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWeight_PROPERTIES

      // END_USER_CODE-USER_txtcolNetWeight_PROPERTIES
    },
    txtcolNetWtLessLSK: {
      name: "txtcolNetWtLessLSK",
      type: "TextBoxWidget",
      colName: "colNetWtLessLSK",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolNetWtLessLSK_PROPERTIES

      // END_USER_CODE-USER_txtcolNetWtLessLSK_PROPERTIES
    },
    txtcolSC95Num: {
      name: "txtcolSC95Num",
      type: "TextBoxWidget",
      colName: "colSC95Num",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolSC95Num_PROPERTIES

      // END_USER_CODE-USER_txtcolSC95Num_PROPERTIES
    },
    txtcolUnloadPoint: {
      name: "txtcolUnloadPoint",
      type: "TextBoxWidget",
      colName: "colUnloadPoint",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolUnloadPoint_PROPERTIES

      // END_USER_CODE-USER_txtcolUnloadPoint_PROPERTIES
    },
    txtcolFarm: {
      name: "txtcolFarm",
      type: "TextBoxWidget",
      colName: "colFarm",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolFarm_PROPERTIES

      // END_USER_CODE-USER_txtcolFarm_PROPERTIES
    },
    txtcolValue: {
      name: "txtcolValue",
      type: "TextBoxWidget",
      colName: "colValue",
      parent: "grid1007s",
      CharWidth: "48",
      Height: "",
      Width: "",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtcolValue_PROPERTIES

      // END_USER_CODE-USER_txtcolValue_PROPERTIES
    },
    txtCommittedTo: {
      name: "txtCommittedTo",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Committed To:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCommittedTo_PROPERTIES

      // END_USER_CODE-USER_txtCommittedTo_PROPERTIES
    },
    txtCrackBroken: {
      name: "txtCrackBroken",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Crack Broken:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCrackBroken_PROPERTIES

      // END_USER_CODE-USER_txtCrackBroken_PROPERTIES
    },
    txtCtrlNum: {
      name: "txtCtrlNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Ctrl Num",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtCtrlNum_PROPERTIES

      // END_USER_CODE-USER_txtCtrlNum_PROPERTIES
    },
    txtDateCancelled: {
      name: "txtDateCancelled",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Date Cancelled:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDateCancelled_PROPERTIES

      // END_USER_CODE-USER_txtDateCancelled_PROPERTIES
    },
    txtDeductAmt: {
      name: "txtDeductAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Deduct Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDeductAmt_PROPERTIES

      // END_USER_CODE-USER_txtDeductAmt_PROPERTIES
    },
    txtDelvCompletedDt: {
      name: "txtDelvCompletedDt",
      type: "DateWidget",
      parent: "grpbxStorage",
      Enabled: false,
      Label: "Delivery Completed Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtDisclrd: {
      name: "txtDisclrd",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Disclrd:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtDisclrd_PROPERTIES

      // END_USER_CODE-USER_txtDisclrd_PROPERTIES
    },
    txtDocDelvDt: {
      name: "txtDocDelvDt",
      type: "DateWidget",
      Enabled: false,
      parent: "grpbxStorage",
      Label: "Document Delivery Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtELK: {
      name: "txtELK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "ELK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtELK_PROPERTIES

      // END_USER_CODE-USER_txtELK_PROPERTIES
    },
    txtEndDate: {
      name: "txtEndDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "End Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtERecptNum: {
      name: "txtERecptNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "E-Rcpt Num:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtERecptNum_PROPERTIES

      // END_USER_CODE-USER_txtERecptNum_PROPERTIES
    },
    txtExpDate: {
      name: "txtExpDate",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Exp. Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtFancy: {
      name: "txtFancy",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Fancy:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFancy_PROPERTIES

      // END_USER_CODE-USER_txtFancy_PROPERTIES
    },
    txtFarmerStockCost: {
      name: "txtFarmerStockCost",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Farmer Stock Cost:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFarmerStockCost_PROPERTIES

      // END_USER_CODE-USER_txtFarmerStockCost_PROPERTIES
    },
    txtFlavus: {
      name: "txtFlavus",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Flavus:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFlavus_PROPERTIES

      // END_USER_CODE-USER_txtFlavus_PROPERTIES
    },
    txtFM: {
      name: "txtFM",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "FM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFM_PROPERTIES

      // END_USER_CODE-USER_txtFM_PROPERTIES
    },
    txtForfeitedStatus: {
      name: "txtForfeitedStatus",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Forfeited Status:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtForfeitedStatus_PROPERTIES

      // END_USER_CODE-USER_txtForfeitedStatus_PROPERTIES
    },
    txtFreezeDam: {
      name: "txtFreezeDam",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Freeze Dam:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtFreezeDam_PROPERTIES

      // END_USER_CODE-USER_txtFreezeDam_PROPERTIES
    },
    txtHandlingAmt: {
      name: "txtHandlingAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      Label: "Handling Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlingAmt_PROPERTIES

      // END_USER_CODE-USER_txtHandlingAmt_PROPERTIES
    },
    txtHandlingAmtOverride: {
      name: "txtHandlingAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHandlingAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtHandlingAmtOverride_PROPERTIES
    },
    txtHowStored: {
      name: "txtHowStored",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "How Stored:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHowStored_PROPERTIES

      // END_USER_CODE-USER_txtHowStored_PROPERTIES
    },
    txtHulls: {
      name: "txtHulls",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHulls_PROPERTIES

      // END_USER_CODE-USER_txtHulls_PROPERTIES
    },
    txtHullsBright: {
      name: "txtHullsBright",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Hulls Bright:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtHullsBright_PROPERTIES

      // END_USER_CODE-USER_txtHullsBright_PROPERTIES
    },
    txtInCharges: {
      name: "txtInCharges",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "In Charges:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInCharges_PROPERTIES

      // END_USER_CODE-USER_txtInCharges_PROPERTIES
    },
    txtInChargesAndAsstCosts: {
      name: "txtInChargesAndAsstCosts",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "In Charges and Associated Costs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInChargesAndAsstCosts_PROPERTIES

      // END_USER_CODE-USER_txtInChargesAndAsstCosts_PROPERTIES
    },
    txtInspeactionFees: {
      name: "txtInspeactionFees",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Inspection Fees:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInspeactionFees_PROPERTIES

      // END_USER_CODE-USER_txtInspeactionFees_PROPERTIES
    },
    txtInterestAmt: {
      name: "txtInterestAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      Label: "Interest Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInterestAmt_PROPERTIES

      // END_USER_CODE-USER_txtInterestAmt_PROPERTIES
    },
    txtInterestAmtOverride: {
      name: "txtInterestAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtInterestAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtInterestAmtOverride_PROPERTIES
    },
    txtIssuedDate: {
      name: "txtIssuedDate",
      type: "DateWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Issued Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      Enabled : false,
    },
    txtJumbo: {
      name: "txtJumbo",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Jumbo:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtJumbo_PROPERTIES

      // END_USER_CODE-USER_txtJumbo_PROPERTIES
    },
    txtLastAction: {
      name: "txtLastAction",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Last Action:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLastAction_PROPERTIES

      // END_USER_CODE-USER_txtLastAction_PROPERTIES
    },
    txtLicenseNum: {
      name: "txtLicenseNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "License #:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLicenseNum_PROPERTIES

      // END_USER_CODE-USER_txtLicenseNum_PROPERTIES
    },
    txtLnCounty: {
      name: "txtLnCounty",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln County:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtLnCounty_PROPERTIES

      // END_USER_CODE-USER_txtLnCounty_PROPERTIES
    },
    txtLnNumber: {
      name: "txtLnNumber",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln Number:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtLnNumber_PROPERTIES

      // END_USER_CODE-USER_txtLnNumber_PROPERTIES
    },
    txtLnRpyLockInDt: {
      name: "txtLnRpyLockInDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Loan Repay Lock In Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLnState: {
      name: "txtLnState",
      type: "TextBoxWidget",
      parent: "grpbxLoanNum",
      Label: "Ln State:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_int",
      // START_USER_CODE-USER_txtLnState_PROPERTIES

      // END_USER_CODE-USER_txtLnState_PROPERTIES
    },
    txtLoanAmt: {
      name: "txtLoanAmt",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Loan Amt.:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanAmt_PROPERTIES

      // END_USER_CODE-USER_txtLoanAmt_PROPERTIES
    },
    lblLoanAmtVal: {
      name: "lblLoanAmtVal",
      type: "LabelWidget",
      parent: "grpbxLoan",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"
    },  
    lblLoanMaturityVal: {
      name: "lblLoanMaturityVal",
      type: "LabelWidget",
      parent: "grpbxLoan",
      ColSpan: "5",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String"
    },  
    txtLoanDt: {
      name: "txtLoanDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Loan Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLoanForfeitedDt: {
      name: "txtLoanForfeitedDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Loan Forfeited Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLoanMaturityDt: {
      name: "txtLoanMaturityDt",
      type: "DateWidget",
      parent: "grpbxLoan",
      Label: "Loan Maturity Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtLoanReductionPayment: {
      name: "txtLoanReductionPayment",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Loan Reduction Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanReductionPayment_PROPERTIES

      // END_USER_CODE-USER_txtLoanReductionPayment_PROPERTIES
    },
    txtLoanRepayAmt: {
      name: "txtLoanRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      Label: "Loan Repay Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayAmt_PROPERTIES
    },
    txtLoanRepayAmtOverride: {
      name: "txtLoanRepayAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayAmtOverride_PROPERTIES
    },
    txtLoanRepayRate: {
      name: "txtLoanRepayRate",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Loan Repay Rate:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLoanRepayRate_PROPERTIES

      // END_USER_CODE-USER_txtLoanRepayRate_PROPERTIES
    },
    txtLSK: {
      name: "txtLSK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "LSK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSK_PROPERTIES

      // END_USER_CODE-USER_txtLSK_PROPERTIES
    },
    txtLSKLbs: {
      name: "txtLSKLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "LSK Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbs_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbs_PROPERTIES
    },
    txtLSKLbsShrunk: {
      name: "txtLSKLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtLSKLbsShrunk_PROPERTIES
    },
    txtMarketGain1: {
      name: "txtMarketGain1",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Label: "Market Gain:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketGain1_PROPERTIES

      // END_USER_CODE-USER_txtMarketGain1_PROPERTIES
    },
    txtMarketGain2: {
      name: "txtMarketGain2",
      type: "TextBoxWidget",
      parent: "grpbxLoan",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMarketGain2_PROPERTIES

      // END_USER_CODE-USER_txtMarketGain2_PROPERTIES
    },
    txtMoist: {
      name: "txtMoist",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Moist:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtMoist_PROPERTIES

      // END_USER_CODE-USER_txtMoist_PROPERTIES
    },
    txtNetLbs: {
      name: "txtNetLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Net Lbs:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbs_PROPERTIES

      // END_USER_CODE-USER_txtNetLbs_PROPERTIES
    },
    txtNetLbsShrunk: {
      name: "txtNetLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtNetLbsShrunk_PROPERTIES
    },
    txtNetRepayAmt: {
      name: "txtNetRepayAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtNetRepayAmt_PROPERTIES

      // END_USER_CODE-USER_txtNetRepayAmt_PROPERTIES
    },
    txtObligationLocation: {
      name: "txtObligationLocation",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Obligation Location:",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtObligationLocation_PROPERTIES

      // END_USER_CODE-USER_txtObligationLocation_PROPERTIES
    },
    txtOptionPayment: {
      name: "txtOptionPayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Option Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOptionPayment_PROPERTIES

      // END_USER_CODE-USER_txtOptionPayment_PROPERTIES
    },
    txtOriginalValue: {
      name: "txtOriginalValue",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Original Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOriginalValue_PROPERTIES

      // END_USER_CODE-USER_txtOriginalValue_PROPERTIES
    },
    txtOtherKernels: {
      name: "txtOtherKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Other Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherKernels_PROPERTIES

      // END_USER_CODE-USER_txtOtherKernels_PROPERTIES
    },
    txtOtherRate: {
      name: "txtOtherRate",
      type: "TextBoxWidget",
      parent: "grpbxPayShrink",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOtherRate_PROPERTIES

      // END_USER_CODE-USER_txtOtherRate_PROPERTIES
    },
    txtOverrideFarm: {
      name: "txtOverrideFarm",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Override Farm:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtOverrideFarm_PROPERTIES

      // END_USER_CODE-USER_txtOverrideFarm_PROPERTIES
    },
    txtPeanutType: {
      name: "txtPeanutType",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Peanut Type:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPeanutType_PROPERTIES

      // END_USER_CODE-USER_txtPeanutType_PROPERTIES
    },
    txtPremiumAmt: {
      name: "txtPremiumAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Premium Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtPremiumAmt_PROPERTIES

      // END_USER_CODE-USER_txtPremiumAmt_PROPERTIES
    },
    txtProceedsAmt: {
      name: "txtProceedsAmt",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Proceeds Amt:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtProceedsAmt_PROPERTIES

      // END_USER_CODE-USER_txtProceedsAmt_PROPERTIES
    },
    txtProducerBeginDate: {
      name: "txtProducerBeginDate",
      type: "DateWidget",
      parent: "grpbxStorage",
      Label: "Producer Begin Date:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtRabatePayment: {
      name: "txtRabatePayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Rebate Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRabatePayment_PROPERTIES

      // END_USER_CODE-USER_txtRabatePayment_PROPERTIES
    },
    txtRcptNum: {
      name: "txtRcptNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Rcpt Num:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRcptNum_PROPERTIES

      // END_USER_CODE-USER_txtRcptNum_PROPERTIES
    },
    txtRepayVendor: {
      name: "txtRepayVendor",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendor_PROPERTIES

      // END_USER_CODE-USER_txtRepayVendor_PROPERTIES
    },
    txtRepayVendorNum: {
      name: "txtRepayVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      Label: "Repay Vendor:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtRepayVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtRepayVendorNum_PROPERTIES
    },
    txtSeg: {
      name: "txtSeg",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Seg:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSeg_PROPERTIES

      // END_USER_CODE-USER_txtSeg_PROPERTIES
    },
    txtShelled: {
      name: "txtShelled",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shelled:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShelled_PROPERTIES

      // END_USER_CODE-USER_txtShelled_PROPERTIES
    },
    txtShrinkPay: {
      name: "txtShrinkPay",
      type: "TextBoxWidget",
      parent: "grpbxPayShrink",
      Label: "Shrink Payment:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPay_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPay_PROPERTIES
    },
    txtShrinkPayLRR: {
      name: "txtShrinkPayLRR",
      type: "TextBoxWidget",
      parent: "grpbxPayShrink",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPayLRR_PROPERTIES
      Enabled : false,
      Visible : false,
      // END_USER_CODE-USER_txtShrinkPayLRR_PROPERTIES
    },
    lblShrinkPayLRR: {
      name: "lblShrinkPayLRR",
      type: "TextBoxWidget",
      parent: "grpbxPayShrink",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_lblShrinkPayLRR_PROPERTIES
      Enabled : false,
      // END_USER_CODE-USER_lblShrinkPayLRR_PROPERTIES
    },
    txtShrinkPayment: {
      name: "txtShrinkPayment",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Shrink Payment:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPayment_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPayment_PROPERTIES
    },
    txtShrinkPayPct: {
      name: "txtShrinkPayPct",
      type: "TextBoxWidget",
      parent: "grpbxPayShrink",
      Label: "Shrink Pay %:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPayPct_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPayPct_PROPERTIES
    },
    txtShrinkPct: {
      name: "txtShrinkPct",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shrink %:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrinkPct_PROPERTIES

      // END_USER_CODE-USER_txtShrinkPct_PROPERTIES
    },
    txtShrnkPct: {
      name: "txtShrnkPct",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Shrink %",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrnkPct_PROPERTIES

      // END_USER_CODE-USER_txtShrnkPct_PROPERTIES
    },
    txtShrunkValue: {
      name: "txtShrunkValue",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Shrunk Value:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtShrunkValue_PROPERTIES

      // END_USER_CODE-USER_txtShrunkValue_PROPERTIES
    },
    txtSMK: {
      name: "txtSMK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SMK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSMK_PROPERTIES

      // END_USER_CODE-USER_txtSMK_PROPERTIES
    },
    txtSpotPounds: {
      name: "txtSpotPounds",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Spot Pounds:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpotPounds_PROPERTIES

      // END_USER_CODE-USER_txtSpotPounds_PROPERTIES
    },
    txtSpotPrice: {
      name: "txtSpotPrice",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Spot Price:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSpotPrice_PROPERTIES

      // END_USER_CODE-USER_txtSpotPrice_PROPERTIES
    },
    txtSS: {
      name: "txtSS",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "SS:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSS_PROPERTIES

      // END_USER_CODE-USER_txtSS_PROPERTIES
    },
    txtStatus: {
      name: "txtStatus",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Status",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStatus_PROPERTIES

      // END_USER_CODE-USER_txtStatus_PROPERTIES
    },
    txtStorageCharge: {
      name: "txtStorageCharge",
      type: "TextBoxWidget",
      parent: "grpbxStorage",
      Label: "Storage Charge:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStorageCharge_PROPERTIES

      // END_USER_CODE-USER_txtStorageCharge_PROPERTIES
    },
    txtStoragePaidAmt: {
      name: "txtStoragePaidAmt",
      type: "TextBoxWidget",
      parent: "grpbxForfeitureStorage",
      Label: "Storage Paid:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStoragePaidAmt_PROPERTIES
      Enabled : false,
      // END_USER_CODE-USER_txtStoragePaidAmt_PROPERTIES
    },
    txtStrgCreditAmt: {
      name: "txtStrgCreditAmt",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      Label: "Storage Credit Amt:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStrgCreditAmt_PROPERTIES

      // END_USER_CODE-USER_txtStrgCreditAmt_PROPERTIES
    },
    txtStrgCreditAmtOverride: {
      name: "txtStrgCreditAmtOverride",
      type: "TextBoxWidget",
      parent: "grpbxRepymnt",
      ColSpan: "2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtStrgCreditAmtOverride_PROPERTIES

      // END_USER_CODE-USER_txtStrgCreditAmtOverride_PROPERTIES
    },
    txtStrgPaidThroughDt: {
      name: "txtStrgPaidThroughDt",
      type: "DateWidget",
      parent: "grpbxStorage",
      Enabled: false,
      Label: "Storage Paid Through Date: ",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
    },
    txtSupportPercent: {
      name: "txtSupportPercent",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Support Percent:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtSupportPercent_PROPERTIES

      // END_USER_CODE-USER_txtSupportPercent_PROPERTIES
    },
    txtTotalDamage: {
      name: "txtTotalDamage",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Damage:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalDamage_PROPERTIES

      // END_USER_CODE-USER_txtTotalDamage_PROPERTIES
    },
    txtTotalKernels: {
      name: "txtTotalKernels",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Kernels:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernels_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernels_PROPERTIES
    },
    txtTotalKernHulls: {
      name: "txtTotalKernHulls",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total Kern. Hulls:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalKernHulls_PROPERTIES

      // END_USER_CODE-USER_txtTotalKernHulls_PROPERTIES
    },
    txtTotalLbs: {
      name: "txtTotalLbs",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Total Lbs:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbs_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbs_PROPERTIES
    },
    txtTotalLbsShrunk: {
      name: "txtTotalLbsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtTotalLbsShrunk_PROPERTIES
    },
    txtTotalSMK: {
      name: "txtTotalSMK",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "Total SMK:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalSMK_PROPERTIES

      // END_USER_CODE-USER_txtTotalSMK_PROPERTIES
    },
    txtTotalTons: {
      name: "txtTotalTons",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Label: "Total Tons:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTons_PROPERTIES

      // END_USER_CODE-USER_txtTotalTons_PROPERTIES
    },
    txtTotalTonsShrunk: {
      name: "txtTotalTonsShrunk",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo2",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtTotalTonsShrunk_PROPERTIES

      // END_USER_CODE-USER_txtTotalTonsShrunk_PROPERTIES
    },
    txtValueOfSeg: {
      name: "txtValueOfSeg",
      type: "TextBoxWidget",
      parent: "grpbxPurchase",
      Label: "Value of Seg:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValueOfSeg_PROPERTIES

      // END_USER_CODE-USER_txtValueOfSeg_PROPERTIES
    },
    txtValuePerTon: {
      name: "txtValuePerTon",
      type: "TextBoxWidget",
      parent: "grpbxPrchs2",
      Label: "Value Per Ton:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtValuePerTon_PROPERTIES

      // END_USER_CODE-USER_txtValuePerTon_PROPERTIES
    },
    txtVendor: {
      name: "txtVendor",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Vendor",
      ColSpan: "3",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendor_PROPERTIES

      // END_USER_CODE-USER_txtVendor_PROPERTIES
    },
    txtVendorNum: {
      name: "txtVendorNum",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Label: "Vendor No:",
      Enabled: true,
      LengthRange: { MinLength: 0, MaxLength: 6 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVendorNum_PROPERTIES

      // END_USER_CODE-USER_txtVendorNum_PROPERTIES
    },
    txtVICAM: {
      name: "txtVICAM",
      type: "TextBoxWidget",
      parent: "grpbxFactorsForAllPeanuts",
      Label: "VICAM:",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVICAM_PROPERTIES

      // END_USER_CODE-USER_txtVICAM_PROPERTIES
    },
    txtVndr: {
      name: "txtVndr",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo3",
      Enabled: false,
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtVndr_PROPERTIES

      // END_USER_CODE-USER_txtVndr_PROPERTIES
    },
    txtWhseBin: {
      name: "txtWhseBin",
      type: "TextBoxWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Whse/Bin:",
      ColSpan: "2",
      Enabled: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWhseBin_PROPERTIES

      // END_USER_CODE-USER_txtWhseBin_PROPERTIES
    },
    txtWireTransfer: {
      name: "txtWireTransfer",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      //Label: "Wire Transfer:",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 50 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData_PROPERTIES
    },
    txtWireTransferData1: {
      name: "txtWireTransferData1",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData2_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData2_PROPERTIES
    },
    txtWireTransferData2: {
      name: "txtWireTransferData2",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 2 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData2_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData2_PROPERTIES
    },
    txtWireTransferData3: {
      name: "txtWireTransferData3",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 3 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData3_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData3_PROPERTIES
    },
    txtWireTransferData4: {
      name: "txtWireTransferData4",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 1 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData4_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData4_PROPERTIES
    },
    txtWireTransferData5: {
      name: "txtWireTransferData5",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData5_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData5_PROPERTIES
    },
    txtWireTransferData6: {
      name: "txtWireTransferData6",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 4 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData5_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData5_PROPERTIES
    },
    txtConcordia1: {
      name: "txtConcordia1",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 5 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    txtConcordia2: {
      name: "txtConcordia2",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 1 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    txtConcordia3: {
      name: "txtConcordia3",
      type: "TextBoxWidget",
      parent: "grpbxRepayment",
      HasLabel: false,
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtWireTransferData6_PROPERTIES

      // END_USER_CODE-USER_txtWireTransferData6_PROPERTIES
    },
    // txtWireTransfer: {
    //   name: "txtWireTransfer",
    //   type: "TextBoxWidget",
    //   parent: "grpbxRepayment",
    //   Label: "Wire Transfer:",
    //   ColSpan: "3",
    //   Enabled: false,
    //   LengthRange: { MinLength: 0, MaxLength: 256 },
    //   ofTypeDomain: "d_String",
    //   // START_USER_CODE-USER_txtWireTransfer_PROPERTIES

    //   // END_USER_CODE-USER_txtWireTransfer_PROPERTIES
    // },
    txtWtdAvgStorageDt: {
      name: "txtWtdAvgStorageDt",
      type: "DateWidget",
      parent: "grpbxReceiptInfo1",
      Label: "Weighted Avg. Storage Date:",
      LengthRange: { MinLength: 0, MaxLength: 8 },
      ofTypeDomain: "d_date",
      Enabled : false,
    },
    grpbxViewWarehouseReceipt: {
      name: "grpbxViewWarehouseReceipt",
      type: "GroupBoxWidget",
      parent: "ViewWarehouseReceipt",
      Height: "",
      Width: "",
      clonedExtId: "87340",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxViewWarehouseReceipt_PROPERTIES

      // END_USER_CODE-USER_grpbxViewWarehouseReceipt_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ViewWarehouseReceipt",
      Height: "",
      Width: "",
      ColsForMobile: "11",
      ColsForTabLandscape: "11",
      HasLabel: false,
      Cols: "11",
      ColsForTabPotrait: "11",
      ColsForLargeDesktop: "11",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
    _dataCopy: {
      FORWARD: {
        "WarehouseReceipts#ViewWRPremiumDeductions": { },
        "WarehouseReceipts#ViewWRPayments":{},
        "WarehouseReceipts#ReportPreviewWR":{},
        "WarehouseReceipts#ViewEWRLog":{},
        "WarehouseReceipts#WhouseApplications":{}

      },
      REVERSE: {
        "WarehouseReceipts#ViewWRPremiumDeductions": { },
        "WarehouseReceipts#ViewWRPayments":{},
        "WarehouseReceipts#ReportPreviewWR":{},
        "WarehouseReceipts#ViewEWRLog":{},
        "WarehouseReceipts#WhouseApplications":{}
      },
    },
  };

  let _buttonServices = { ViewWRPremiumDeductions: {} };

  let _buttonNavigation = {};

  let _winServices = {};

  const [state, setState] = useState(states);
  const [loading, setLoading] = useState(true);

  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };

  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setData(thisObj, 'hideShowControlDict', {})
    setData(thisObj, 'objVal', {})
    document.getElementById('radioElectronicPaper1').setAttribute('disabled','')
    FormLoad();
    setEventHandlers();
    return () => {
      removeEventHandlers();
    }
    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
  }, []);

  useEffect(() => {
    let flag = getData(thisObj, "WhouseReceiptStorageForViewScreen")
    if(flag){
      setLoading(true)
      FormLoad();
      setData(thisObj, 'WhouseReceiptStorageForViewScreen', null)
    }
  }, [getData(thisObj, "WhouseReceiptStorageForViewScreen")])

  useEffect(() => {
    let data = getData(thisObj, "frmWhouseApplicationOKFlag")
    if(![ null, undefined ].includes(data)){
      if(!data?.flag){
        frmViewWarehouseReceiptReLoad()
      }
      else{
        if(data?.mode == "PURCHASE"){       
          frmWhouseApplicationPurchaseCall(data?.lstNewContractLine);
        }
        else if(data?.mode == "TENTATIVE_APPLICATION"){       
          frmWhouseApplicationTentativeCall(data?.lstNewContractLine)
        }
      }
      setData(thisObj, "frmWhouseApplicationOKFlag", null)
    }   
  }, [getData(thisObj, "frmWhouseApplicationOKFlag")])

  useEffect(() => {
    let data = getData(thisObj, "frmPremiumDeductionsWRPurchOKFlag")
    if(![ null, undefined ].includes(data)){
      if(data?.flag){
        frmViewWarehouseReceiptReLoad()
      }
      setData(thisObj, "frmPremiumDeductionsWRPurchOKFlag", null)
    }   
  }, [getData(thisObj, "frmPremiumDeductionsWRPurchOKFlag")])

  useEffect(() => {
    let data = getData(thisObj, 'vendorDetails')
    let flag = getData(thisObj, "vendorType")
    
    if(data !== null && data != undefined){
      let objVal = getData(thisObj, 'objVal')
      
      if(flag == "Vendor"){
        objVal['txtVendorNum'] = data?.VendorNumber
        objVal['txtVndr'] = toPascalCase(data?.vendorName)

        ontxtVendorNumChange( { target : { value : data?.VendorNumber } })      
      }
      else if(flag == "Repay Vendor"){
        objVal['txtRepayVendorNum'] = data?.VendorNumber
        objVal['txtRepayVendor'] = toPascalCase(data?.vendorName)

        ontxtRepayVendorNumChange( { target : { value : data?.VendorNumber } })             
      }
      else if(flag == "CMA Vendor"){
        objVal['txtCMAVendorNum'] = data?.VendorNumber
        objVal['txtCMAVendor'] = toPascalCase(data?.vendorName)

        ontxtCMAVendorNumChange( { target : { value : data?.VendorNumber } })      
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);
      
    }
    setData(thisObj, 'vendorType', null)
  }, [getData(thisObj, 'vendorDetails')]);

  useEffect(() => {
    let data = getData(thisObj, "frmBeginDelvOutFlag")
    
    if(![ null, undefined ].includes(data)){
      if(data?.flag){
        frmBeginDeliveryAfterCall(data?.lblAgreePresent, data?.methodName)
      }
      
      setData(thisObj, "frmBeginDelvOutFlag", null)
    }   
  }, [getData(thisObj, "frmBeginDelvOutFlag")])

  useEffect(() => {
    let data = getData(thisObj, "frmViewWarehouseReceiptFormReLoad")
    
    if(![ null, undefined ].includes(data)){
      if(data?.flag){
        frmViewWarehouseReceiptReLoad()
      }
      
      setData(thisObj, "frmViewWarehouseReceiptFormReLoad", null)
    }   
  }, [getData(thisObj, "frmViewWarehouseReceiptFormReLoad")])

  const setEventHandlers = () => {
    document.getElementById('txtLnCounty').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtLnState').addEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtLnNumber').addEventListener('keypress', allowOnlyNumbers);
  };

  const removeEventHandlers = () => {
    document.getElementById('txtLnCounty').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtLnState').removeEventListener('keypress', allowOnlyNumbers);
    document.getElementById('txtLnNumber').removeEventListener('keypress', allowOnlyNumbers);
  };

  const allowOnlyNumbers = (e) => {
    try {
      if (!((e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode === 8)) {
        e.preventDefault();
      }
    } catch (err) {
      errorHandler(err, "allowOnlyNumbers")
    }
  };
  
  // START_USER_CODE-USER_METHODS
  async function FormLoad(){
    try{
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      setData(thisObj, 'chkboxWireTransferBool', false)

      let x = new Date()
      if(Number(cropYearFromLS()) >= 2011){
        // disable(thisObj, 'chkbox211')
        // hideShowControlDict['chkbox211'] = false
      }
      
      enable(thisObj, 'chkboxCMA')
      enable(thisObj, 'chkboxDMA')
      
      await bFillHolderList()
      
      setData(thisObj, 'mboolElectronicWarehouseReceipt', true)
      setData(thisObj, 'mbReceiptTypeChanged', false)
      
      setData(thisObj, 'mbFormLoad', true)
      
      await bFillGrid()
      await Form_Activate()
      
      // disable(thisObj, 'txtStoragePaidAmt')
      
      hideShowControlDict['chkbox211'] = false
      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      
      // disable(thisObj, 'txtIssuedDate')
      // disable(thisObj, 'txtWtdAvgStorageDt')
      // disable(thisObj, 'txtShrinkPayLRR')
      // disable(thisObj, 'lblShrinkPayLRR')
      enable(thisObj, 'txtDocDelvDt')
      enable(thisObj, 'txtStrgPaidThroughDt')
      enable(thisObj, 'txtMarketGain1')
      
      // hide(thisObj, 'btnLoadOut')
      // hide(thisObj, 'lstEWRHolder')
      // hide(thisObj, 'lstWhouseRemit')
      // hide(thisObj, 'lblEWRTransmissionStatus')
      // hide(thisObj, 'chkboxStoreAndHandleReceived', false)
      // hide(thisObj, 'chkboxStoreAndHandlePaid', false)
      // hide(thisObj, 'txtShrinkPayLRR', false)
      hide(thisObj, 'btnStorageHandlingPayment')
    }
    catch(err){
      errorHandler(err, "Form Load")
    }
    finally{
      setLoading(false)
    }
  }

  async function bFillGrid(){
    try{
      let x = new Date()
      let LstrList = []
      let LstrBPxml = []
      let lstrxml = []      
      let bFillGridBool = true

      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      
      objVal['txtHowStored'] = "Bulk"
      objVal['txtShelled'] = "Unshelled"
      
      LstrList = await WarehouseReceiptService.RetrieveWareHouseReciptHeaderDetails(data?.unlBuyingPtId, data?.wrNum, null, null, null, null, null, null, null, null)
      if(LstrList?.length > 0){
        objVal['txtRcptNum'] = data?.wrNum
        objVal['txtERecptNum'] = LstrList[0]?.e_whse_rcpt_num
        
        objVal['lblAddedByValue'] = LstrList[0]?.add_user + " " + LstrList[0]?.add_date_time
        objVal['lblChangedByValue'] = LstrList[0]?.chg_user + " " + LstrList[0]?.chg_date_time
        
        setData(thisObj, 'lblLastChange', LstrList[0]?.chg_date_time)
        setData(thisObj ,'msCCCVendorList', LstrList[0]?.ccc_vendor_list)
        
        objVal['txtLastAction'] = ""
        switch(LstrList[0]?.last_action_code){
          case "MNT":
            objVal['txtLastAction'] = "Maintenance" 
            break;
          case "ISU":
            objVal['txtLastAction'] = "Issued" 
            break;
          case "CAN": 
            objVal['txtLastAction'] = "Cancel"
            break;
          case "CGH": 
            objVal['txtLastAction'] = "Change Holder"  
            break;
          case "UNC": 
            objVal['txtLastAction'] = "Uncancel"  
            break;
          case "E2P": 
            objVal['txtLastAction'] = "Electronic to Paper"
            break;          
        }
        
        objVal['txtDateCancelled'] = LstrList[0]?.date_cancelled
        
        setData(thisObj ,'msWhouseNum', LstrList[0]?.whouse_num)
        setData(thisObj ,'msWhouseBin', LstrList[0]?.bin_num)

        objVal['txtIssuedDate'] = moment(LstrList[0]?.issued_date).format("MM/DD/YYYY")
        objVal['txtVendor'] = LstrList[0]?.rcpt_vendor + " " + LstrList[0]?.vendor_name
        
        // setData(thisObj, 'lblUnloadBuyPtId', LstrList[0]?.unld_buy_pt_id)
        objVal['txtObligationLocation'] = LstrList[0]?.buy_pt_id + " - " + toPascalCase(LstrList[0]?.buy_pt_name)
        
        // setData(thisObj, 'lblBuyPtIdCaption', LstrList[0]?.buy_pt_id + " - " + toPascalCase(LstrList[0]?.buy_pt_name))
        // setData(thisObj, 'lblUnloadBuyPtIdCaption', (LstrList[0]?.unld_buy_pt_id || '') + " - " + (toPascalCase(LstrList[0]?.unld_buy_pt_name || '') || ''))

        LstrBPxml = await SettlementService.RetrieveBuyingPointControlDetails(null, null, null, null, LstrList[0]?.buy_pt_id)
        if(LstrBPxml?.length > 0){
          setData(thisObj, 'msAreaId', LstrBPxml[0]?.areaId)
          setData(thisObj, 'msFedBuyPtId', LstrBPxml[0]?.fedWhouseLicense)
        }
        if(LstrList?.status == 500){
          alert("Error Communicating with the database. Please try again in a few moments. If the problem persists please contact support.")
        }
        
        objVal['txtPeanutType'] = LstrList[0]?.pnut_type_name
        setData(thisObj, 'lblPeanutType', LstrList[0]?.pnut_type_id)
 
        objVal['txtSeg'] = LstrList[0]?.seg_type
        objVal['txtGen'] = LstrList[0]?.seed_gen
        objVal['txtVariety'] = LstrList[0]?.pnut_variety_id
        objVal['lblEWRTransmissionStatus'] = LstrList[0]?.ewr_transmission_status
        
        setData(thisObj, 'lblRcptStatusTag', LstrList[0]?.rcpt_status)

        objVal['txtStatus'] = ""
        switch(LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase()){  
          case "I":
            objVal['txtStatus'] = "Issued"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "P":
            objVal['txtStatus'] = "Purchased"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "D":
            objVal['txtStatus'] = "Delv. Out"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "L":
            objVal['txtStatus'] = "Delivery In Process"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "V":
            objVal['txtStatus'] = "Voided"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "X":
            objVal['txtStatus'] = "Pending Void"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
          case "F":
            objVal['txtStatus'] = "Pending Forfeited Purchase"
            setData(thisObj, 'txtStatusTag', LstrList[0]?.rcpt_status?.trim()?.toLocaleUpperCase())
            break;
        }
        
        await ontxtStatusChange({ target : { value : objVal['txtStatus'] }})
        
        if(LstrList[0]?.storeandhandlepayind?.trim()?.toLocaleUpperCase() == "Y" && LstrList[0]?.storeandhandlereceiveind?.trim()?.toLocaleUpperCase() == "N"){
          hideShowControlDict['btnStorageHandlingPayment'] = false
          // hideShowControlDict['btnUndoStorehandlePayment'] = false          
        }
        
        if(LstrList[0]?.storeandhandlepayind?.trim()?.toLocaleUpperCase() == "Y" && LstrList[0]?.storeandhandlereceiveind?.trim()?.toLocaleUpperCase() == "Y"){
          hideShowControlDict['btnStorageHandlingPayment'] = false
          // hideShowControlDict['btnUndoStorehandlePayment'] = false
        }
        
        objVal['txtOriginalValue'] = Number(LstrList[0]?.original_rcpt_value)?.toFixed(2)
        objVal['txtShrunkValue'] = Number(LstrList[0]?.rcpt_value)?.toFixed(2)
        objVal['txtShrinkPct'] = Number(LstrList[0]?.value_shrink_pct)?.toFixed(2)
        
        // objVal['lblNetTons'] =  Number((Number(LstrList[0].net_wt) || 0)/2000)?.toFixed(2)
        
        objVal['txtLSKLbs'] =  LstrList[0].lsk_wt
        objVal['txtTotalLbs'] =  LstrList[0].net_wt
        objVal['txtNetLbs'] =  (Number(LstrList[0].net_wt) || 0) - (Number(LstrList[0].lsk_wt) || 0)
        setData(thisObj, 'lblGrossPounds', LstrList[0].gross_wt)
        
        let value1 = Number(Number((Number(LstrList[0].net_wt) || 0) - (Number(LstrList[0].lsk_wt) || 0))/2000)?.toFixed(2)
        let value2 = Number(LstrList[0].lsk_wt || 0)/2000
        // setData(thisObj, 'LdblNet_Wt_less_Lsk_wt', value1)
        // setData(thisObj, 'LdblLsk_wt', value2)
        
        objVal['txtTotalTons'] =  Number(Number(value1) + Number(Math.round(value2*100)/100))?.toFixed(2)
        objVal['txtShrnkPct'] =  Number(LstrList[0].shrink_pct)?.toFixed(2)
        
        // objVal['lblGrossPoundsShrunk'] = LstrList[0].shrink_gross_wt
        
        objVal['txtLSKLbsShrunk'] = LstrList[0].shrink_lsk_wt
        objVal['txtNetLbsShrunk'] = Number((Number(LstrList[0].net_wt) - Number(LstrList[0].lsk_wt)) * (100 - Number(LstrList[0].shrink_pct))/ 100).toFixed(0)
        objVal['txtTotalLbsShrunk'] = Number(LstrList[0].shrink_net_wt).toFixed(0) //Number((Number(LstrList[0].shrink_net_wt) - Number(LstrList[0].shrink_lsk_wt)) * (100 - Number(LstrList[0].shrink_pct))/ 100).toFixed(0)
        
        value1 = Number(Number(Number(LstrList[0].shrink_net_wt) - Number(LstrList[0].shrink_lsk_wt)) / 2000)?.toFixed(2)
        value2 = Number(Number(LstrList[0].shrink_lsk_wt || 0)/2000)?.toFixed(2)
        
        value1 = Number(Number(Number(LstrList[0].shrink_net_wt) - Number(LstrList[0].shrink_lsk_wt)) / 2000)?.toFixed(2)
        value2 = Number(LstrList[0].shrink_lsk_wt) / 2000
        objVal['txtTotalTonsShrunk'] = Number(Number(value1) + Number(Math.round(value2*100)/100)).toFixed(2)
        
        objVal['txtWtdAvgStorageDt'] = moment(LstrList[0].store_date).format("MM/DD/YYYY")
        objVal['txtFM'] = Number(LstrList[0].wa_fm_pct)?.toFixed(2)
        objVal['txtMoist'] = Number(LstrList[0].wa_moist_pct)?.toFixed(2)
        objVal['txtELK'] = Number(LstrList[0].wa_elk_pct)?.toFixed(2)
        objVal['txtFancy'] = Number(LstrList[0].wa_fan_pct)?.toFixed(2)
        objVal['txtCrackBroken'] = Number(LstrList[0].wa_cr_br_pct)?.toFixed(2)
        objVal['txtDisclrd'] = Number(LstrList[0].wa_dcol_shel_pct)?.toFixed(2)
        objVal['txtSMK'] = Number(LstrList[0].wa_smk_pct)?.toFixed(2)
        // objVal['lblTKC'] = Number(LstrList[0].wa_tkc_pct)?.toFixed(2)
        objVal['txtSS'] = Number(LstrList[0].wa_ss_pct)?.toFixed(2)
        objVal['txtTotalSMK'] = Number(Number(LstrList[0].wa_smk_pct || 0) + Number(LstrList[0].wa_ss_pct || 0))?.toFixed(2)
        objVal['txtOtherKernels'] = Number(LstrList[0].wa_ok_pct)?.toFixed(2)
        objVal['txtTotalDamage'] = Number(LstrList[0].wa_dam_pct)?.toFixed(2)
        objVal['txtTotalKernels'] = Number(Number(LstrList[0].wa_smk_pct || 0) + Number(LstrList[0].wa_ss_pct || 0) + Number(LstrList[0].wa_ok_pct || 0) + Number(LstrList[0].wa_dam_pct || 0))?.toFixed(2)
        objVal['txtHulls'] = Number(LstrList[0].wa_hull_pct)?.toFixed(2)
        objVal['txtTotalKernHulls'] = Number(Number(LstrList[0].wa_smk_pct || 0) + Number(LstrList[0].wa_ss_pct || 0) + Number(LstrList[0].wa_ok_pct || 0) + Number(LstrList[0].wa_dam_pct || 0) + Number(LstrList[0].wa_hull_pct || 0))?.toFixed(2)
        
        objVal['txtFlavus'] = LstrList[0].flavus_ind?.trim()?.toLocaleUpperCase() == "Y" ? 'Yes' : 'No'
        
        objVal['txtFreezeDam'] = Number(LstrList[0].wa_frez_dam_pct)?.toFixed(2)
        objVal['txtCnclRMD'] = Number(LstrList[0].wa_cncl_rmd_pct)?.toFixed(2)
        objVal['txtLSK'] = Number(LstrList[0].wa_lsk_pct)?.toFixed(2)
        objVal['txtHullsBright'] = Number(LstrList[0].wa_hul_brit_pct)?.toFixed(2)
        objVal['txtVICAM'] = LstrList[0].wa_vicam_ppb
        
        objVal['txtCtrlNum'] = '0'.repeat(6 - LstrList[0].rcpt_cntl_num?.toString()?.length) + LstrList[0].rcpt_cntl_num
        objVal['txtJumbo'] = Number(LstrList[0].wa_jumbo_pct)?.toFixed(2)
        
        // setValue(thisObj, 'lblLoanNumber', "0".repeat(10 - LstrList[0].loan_number?.length) + LstrList[0].loan_number)
        // setData(thisObj, 'lblLoanNumber', "0".repeat(10 - LstrList[0].loan_number?.length) + LstrList[0].loan_number)

        objVal['txtLnState'] = LstrList[0].loan_number?.slice(0,2)
        objVal['txtLnCounty'] = LstrList[0].loan_number?.slice(2,5)
        objVal['txtLnNumber'] = LstrList[0].loan_number?.slice(5,10)
        
        objVal['txtOptionPayment'] = isNumeric(LstrList[0].option_payment) ? Number(LstrList[0].option_payment)?.toFixed(2) :  '0'
        objVal['txtLoanAmt'] = isNumeric(LstrList[0].loan_amount) ? Number(LstrList[0].loan_amount)?.toFixed(2) :  ''
        objVal['txtLoanReductionPayment'] = isNumeric(LstrList[0].loan_reduct_amt) ? Number(LstrList[0].loan_reduct_amt)?.toFixed(2) :  ''
        objVal['txtMarketGain1'] = isNumeric(LstrList[0].market_gain) ? Number(LstrList[0].market_gain)?.toFixed(2) :  ''
        objVal['txtSpotPrice'] = isNumeric(LstrList[0].spot_price) ? Number(LstrList[0].spot_price)?.toFixed(2) : ''
        objVal['txtSpotPounds'] = isNumeric(LstrList[0].spot_pounds) ? Number(LstrList[0].spot_pounds)?.toFixed(0) :  '0'
        
        objVal['txtHowStored'] = "Bulk"
        objVal['txtShelled'] = "Unshelled"
        
        objVal['chkbox211'] = LstrList[0].signed_211_ind?.trim()?.toLocaleUpperCase() == "Y" ? 1 : 0
        objVal['chkboxCCC500'] = LstrList[0].ccc_500_ind?.trim()?.toLocaleUpperCase() == "Y" ? 1 : 0
        objVal['chkboxDeferredRedemption'] = LstrList[0].deferred_redemption_ind?.trim()?.toLocaleUpperCase() == "Y" ? 1 : 0        
        
        objVal['txtProducerBeginDate'] = ''
        if(LstrList[0].chrg_beg_date?.trim() !== ""){
          objVal['txtProducerBeginDate'] = moment(new Date(LstrList[0].chrg_beg_date)).format('MM/DD/YYYY')          
        }          
        else{
          if(Number(cropYearFromLS()) < 2007){
            var dt = new Date(LstrList[0].store_date)
            dt.setDate(dt?.getDate() + 21)
            objVal['txtProducerBeginDate'] = moment(new Date(dt)).format('MM/DD/YYYY') 
          }            
          else{
            var dt = new Date(LstrList[0].issued_date)
            dt.setDate(dt?.getDate() + 21)
            objVal['txtProducerBeginDate'] = moment(new Date(dt)).format('MM/DD/YYYY') 
          }            
        }
        
        objVal['txtCCCBeginDate'] = LstrList[0].ccc_beg_date?.trim() !== "" ? moment(new Date(LstrList[0].ccc_beg_date)).format('MM/DD/YYYY') :""
        objVal['txtEndDate'] = LstrList[0].chrg_end_date?.trim() !== "" ? moment(new Date(LstrList[0].chrg_end_date)).format('MM/DD/YYYY') :""
        objVal['txtLoanMaturityDt'] = LstrList[0].loan_maturity_date?.trim() !== "" ? moment(new Date(LstrList[0].loan_maturity_date)).format('MM/DD/YYYY') :""
        objVal['lblLoanMaturityVal'] = LstrList[0].adj_loan_maturity_date?.trim() !== "" ? moment(new Date(LstrList[0].adj_loan_maturity_date)).format('MM/DD/YYYY') :""
        
        if((process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == "DEV"  || process.env.REACT_APP_ENVIR == 'UAT') && objVal['txtLoanMaturityDt'] !== objVal['lblLoanMaturityVal']){
          show(thisObj, 'lblLoanMaturityVal')
          disable(thisObj, 'lblLoanMaturityVal')
        }
        else{
          hide(thisObj, 'lblLoanMaturityVal', false)
          disable(thisObj, 'lblLoanMaturityVal')
        }
        hide(thisObj, 'lblLoanAmtVal', false)
        
        objVal['txtStrgPaidThroughDt'] = LstrList[0].storepaidthrudate?.trim() !== "" ? moment(new Date(LstrList[0].storepaidthrudate)).format('MM/DD/YYYY') :""
        
        if(Number(cropYearFromLS()) >= 2010){
          objVal['txtLoanForfeitedDt'] = LstrList[0].ccc_loan_forfeited_date?.trim() !== "" ? moment(new Date(LstrList[0].ccc_loan_forfeited_date)).format('MM/DD/YYYY') :""
          objVal['txtCCCStrgPaidThruDt'] = LstrList[0].ccc_storage_paid_thru_date?.trim() !== "" ? moment(new Date(LstrList[0].ccc_storage_paid_thru_date)).format('MM/DD/YYYY') :""          
        }
        
        objVal['chkboxStoreAndHandleReceived'] = LstrList[0].storeandhandlereceiveind == "Y" ? 1 : 0
        objVal['chkboxStoreAndHandlePaid'] = LstrList[0].storeandhandlereceiveind == "Y" ? 1 : 0
        
        objVal['txtInspeactionFees'] = LstrList[0].inspectionfees
        objVal['txtInCharges'] = parseFloat(LstrList[0].incharges)
        
        objVal['txtForfeitedStatus'] = ""
        let endDate = LstrList[0].chrg_end_date?.trim() !== "" ? moment(new Date(LstrList[0].chrg_end_date)).format('MM/DD/YYYY') :""
        if(LstrList[0].adj_loan_maturity_date?.trim() == ""){
          objVal['txtForfeitedStatus'] = ""
          
          hide(thisObj, 'grpbxForfeitureStorage', false)
        }
        else{
          if(endDate.replace('/')?.trim() == ""){
            if(new Date() > new Date(LstrList[0].adj_loan_maturity_date?.trim())){
              objVal['txtForfeitedStatus'] = "Forfeited"
              show(thisObj, 'grpbxForfeitureStorage')
            }
            else{
              objVal['txtForfeitedStatus'] = ""
              hide(thisObj, 'grpbxForfeitureStorage', false) 
            }
          }
          else{
            if(new Date(endDate) > new Date(LstrList[0].adj_loan_maturity_date?.trim())){
              objVal['txtForfeitedStatus'] = "Forfeited"
              show(thisObj, 'grpbxForfeitureStorage') 
            }
            else{
              objVal['txtForfeitedStatus'] = ""
              hide(thisObj, 'grpbxForfeitureStorage', false)
            }
          }
        }
        setData(thisObj, 'hideShowControlDict', hideShowControlDict)
        
        objVal['txtDocDelvDt'] = LstrList[0].doc_delv_date?.trim() !== "" ? moment(new Date(LstrList[0].doc_delv_date)).format('MM/DD/YYYY') :""
        objVal['txtDelvCompletedDt'] = LstrList[0].delv_out_complete_date?.trim() !== "" ? moment(new Date(LstrList[0].delv_out_complete_date)).format('MM/DD/YYYY') :""
        
        objVal['txtLoanRepayAmt'] = LstrList[0].calc_loan_repay_amt?.trim() !== "" ? Number(LstrList[0].calc_loan_repay_amt)?.toFixed(2) : ""
        objVal['txtLoanRepayRate'] = LstrList[0].loan_repay_rate?.trim() !== "" ? Number(LstrList[0].loan_repay_rate)?.toFixed(2) : ""
        
        setData(thisObj, 'mbSuppressOverrideLRRClick', true)
        objVal['chkboxOverrideLRR'] = LstrList[0].lrr_override_ind?.trim() == "Y" ? 1 : 0
        
        objVal['txtValueOfSeg'] = LstrList[0].value_of_seg?.trim() !== "" ? Number(LstrList[0].value_of_seg)?.toFixed(2) : "0.00"
        objVal['txtSupportPercent'] = LstrList[0].support_pct?.trim() !== "" && LstrList[0].support_pct?.trim() !== "0" ? Number(LstrList[0].support_pct)?.toFixed(2) : "0.00"
        objVal['txtValuePerTon'] = LstrList[0].value_per_ton?.trim() !== "" && LstrList[0].value_per_ton?.trim() !== "0" ? Number(LstrList[0].value_per_ton)?.toFixed(2) : "0.00"
        objVal['txtPremiumAmt'] = LstrList[0].premium_amt?.trim() !== "" ? Number(LstrList[0].premium_amt)?.toFixed(2) : ""

        objVal['txtDeductAmt'] = LstrList[0].deduct_amt !== "" ? Number(LstrList[0].deduct_amt)?.toFixed(2) : ""

        objVal['txtProceedsAmt'] = ''
        if(LstrList[0].proceeds_amt?.trim() !== ""){
          objVal['txtProceedsAmt'] = Number(LstrList[0].proceeds_amt)?.toFixed(2)

          if(LstrList[0].shrink_payment !== "" && LstrList[0].rcpt_status?.trim()?.toLocaleUpperCase() !== "P"){
            objVal['txtProceedsAmt'] = Number(Number(LstrList[0].proceeds_amt) + Number(LstrList[0].shrink_payment))?.toFixed(2)
          }            
        }
        else{
          objVal['txtProceedsAmt'] = ""
        } 

        objVal['txtLoanRepayAmtOverride'] = LstrList[0].loan_repay_amt?.trim() !== "" ? Number(LstrList[0].loan_repay_amt)?.toFixed(2) : "0.00"
        objVal['txtStrgCreditAmtOverride'] = LstrList[0].store_repay_amt?.trim() !== "" ? Number(LstrList[0].store_repay_amt)?.toFixed(2) : "0.00"
        objVal['txtInterestAmtOverride'] = LstrList[0].interest_amt?.trim() !== "" ? Number(LstrList[0].interest_amt)?.toFixed(2) : "0.00"
        objVal['txtInterestAmt'] = LstrList[0].calc_interest_amt?.trim() !== "" ? Number(LstrList[0].calc_interest_amt)?.toFixed(2) : "0.00"
        objVal['txtHandlingAmtOverride'] = LstrList[0].handling_repay_amt?.trim() !== "" ? Number(LstrList[0].handling_repay_amt)?.toFixed(2) : "0.00"
        objVal['txtRepayVendorNum'] = LstrList[0].repay_vendor?.trim() !== "" ? LstrList[0].repay_vendor : ""
        objVal['chkboxWireTransfer'] = LstrList[0].wire_xfer_ind?.toLocaleUpperCase() == "Y" ? 1 : 0

        setData(thisObj, 'msWireXferDataSaved', LstrList[0].wire_xfer_data?.trim())
        
        await DisplayWireTransfer(LstrList[0].repay_vendor?.trim(), LstrList[0].wire_xfer_data?.trim())
        
        objVal['txtCMAVendorNum'] = LstrList[0].dma_vendor?.trim() !== '' ? LstrList[0].dma_vendor : ""
        await bFillDMARemitToList(LstrList[0].dma_vendor)
        objVal['txtOverrideFarm'] = LstrList[0].overide_farm?.trim() !== '' ? LstrList[0].overide_farm : ""
        
        objVal['txtLnRpyLockInDt'] = ''
        objVal['txtExpDate'] = ''
        if(LstrList[0].lrr_lock_date?.trim() !== ""){
          objVal['txtLnRpyLockInDt'] = moment(LstrList[0].lrr_lock_date).format('MM/DD/YYYY')
          
          var d = new Date(LstrList[0].lrr_lock_date)
          d.setDate(new Date(LstrList[0].lrr_lock_date).getDate() + 59)
          objVal['txtExpDate'] = LstrList[0].lrr_lock_date !== "" ? moment(d).format('MM/DD/YYYY') : ""
        }
        else{
          objVal['txtLnRpyLockInDt'] = ''
          objVal['txtExpDate'] = ''
          var d = new Date(LstrList[0].lrr_lock_date)          
        }

        setData(thisObj, 'msTent_cont_appl_ind', LstrList[0].tent_cont_appl_ind?.trim()?.toLocaleUpperCase())

        objVal['chkboxCommittedToOther'] = LstrList[0].tent_delv_out_ind?.trim()?.toLocaleUpperCase() == "Y" ? 1 : 0
        objVal['txtCommittedTo'] = LstrList[0].tent_delv_out_to !== "" ? LstrList[0].tent_delv_out_to : ""
        
        setData(thisObj, 'mbLoadOutsExist', Number(LstrList[0].load_out_count) > 0 ? true : false)

        setData(thisObj, 'msLoadOutMinDate', LstrList[0].load_out_min_date !== "" ? moment(LstrList[0].load_out_min_date).format('MM/DD/YYYY') : "")
        setData(thisObj, 'msLoadOutMaxDate', LstrList[0].load_out_max_date !== "" ? moment(LstrList[0].load_out_max_date).format('MM/DD/YYYY') : "")
        
        // setData(thisObj, 'lstrHolderID', LstrList[0].current_holder)

        objVal['ddCurrentHolder'] = LstrList[0].current_holder
        
        disable(thisObj, 'btnPrintFSADoc')

        objVal['radioElectronicPaper'] = 0
        switch(LstrList[0].receipt_type){
          case "":
            objVal['radioElectronicPaper'] = "1"
            setData(thisObj, 'mbReceiptTypeChanged', false)

            if(!getData(thisObj, 'mbCreatePermissionDeny')){
              if(LstrList[0].ewr_transmission_status == "A"  && LstrList[0].rcpt_status !== "V"){
                enable(thisObj, "btnPrintFSADoc")
              }
            }
            else{
              if(!getData(thisObj, 'mbUnloadLocInqPermissionDeny')){
                if(LstrList[0].ewr_transmission_status == "A" && LstrList[0].fsa_doc_ready_ind == "Y" && LstrList[0].rcpt_status !== "V"){
                  enable(thisObj, "btnPrintFSADoc")
                }
              }
            }
            break;
          case "E":
            objVal['radioElectronicPaper'] = "1"
            setData(thisObj, 'mbReceiptTypeChanged', false)

            if(!getData(thisObj, 'mbCreatePermissionDeny')){
              if(LstrList[0].ewr_transmission_status == "A"  && LstrList[0].rcpt_status !== "V"){
                enable(thisObj, "btnPrintFSADoc")
              }
            }
            else{
              if(!getData(thisObj, 'mbUnloadLocInqPermissionDeny')){
                if(LstrList[0].ewr_transmission_status == "A" && LstrList[0].fsa_doc_ready_ind == "Y" && LstrList[0].rcpt_status !== "V"){
                  enable(thisObj, "btnPrintFSADoc")
                }
              }
            }
            break;
          case "P":
            disable(thisObj, 'radioElectronicPaper')
            setData(thisObj, 'mbReceiptTypeChanged', false)
            break;
        }
        
        let dblValOfSeg = 0
        let dblOptPayment = 0
        let dblShrinkPay = 0
        let dblRebatePay = 0

        if(LstrList[0].shrink_payment?.trim() !== ""){
          dblShrinkPay = Number(LstrList[0].shrink_payment)?.toFixed(2)
        }

        if(LstrList[0].rebate_payment?.trim() !== ""){
          dblRebatePay = Number(LstrList[0].rebate_payment)?.toFixed(2)
        }

        objVal['txtFarmerStockCost'] = ''
        if(LstrList[0].rcpt_status?.trim()?.toLocaleUpperCase() == "I"){
          if(LstrList[0].option_payment !== ""){
            objVal['txtFarmerStockCost'] = Number(Number(LstrList[0].option_payment || 0) + Number(dblShrinkPay || 0) + Number(dblRebatePay || 0))?.toFixed(2)
          }
            
          else{
            objVal['txtFarmerStockCost'] = ""
          }
            
        }
        else if(LstrList[0].rcpt_status?.trim()?.toLocaleUpperCase() == "P"){
          if(LstrList[0].value_of_seg !== ""){
            dblValOfSeg = Number(LstrList[0].value_of_seg || 0)?.toFixed(2)
            objVal['txtFarmerStockCost'] = Number(Number(dblValOfSeg  || 0) + Number(dblRebatePay || 0))?.toFixed(2)
          }
          else{
            objVal['txtFarmerStockCost'] = ""
          } 

          objVal['txtOptionPayment'] = ""
          if(LstrList[0].option_payment !== ""){
            objVal['txtOptionPayment'] = Number(LstrList[0].option_payment) == 0 ? '0' : Number(LstrList[0].option_payment)?.toFixed(2)
            dblOptPayment = Number(LstrList[0].option_payment || 0)?.toFixed(2)
          }
          else{
            objVal['txtOptionPayment'] = "0"
          }            
          
          objVal['txtValueOfSeg'] = ""
          if(Number(Number(dblValOfSeg || 0) - Number(dblOptPayment || 0))?.toFixed(2) !== 0){
            objVal['txtValueOfSeg'] = Number(Number(dblValOfSeg || 0) - Number(dblOptPayment || 0) - Number(dblShrinkPay || 0))?.toFixed(2)
          }            
        }

        objVal['radioRedemptionMethod'] = 1
        switch(LstrList[0].redeem_method_ind){
          case "L":
            objVal['radioRedemptionMethod'] = "1"
            break;
          case "S":
            objVal['radioRedemptionMethod'] = "2"
            break;
          case "R":
            objVal['radioRedemptionMethod'] = "3"
            break;
          case "U":
            objVal['radioRedemptionMethod'] = "4"
            break;
        } 
      } 
      else{
        alert("Error Communicating with the database. Please try again later.")
        bFillGridBool = false
        return bFillGridBool;
      }

      objVal['chkboxCMA'] = LstrList[0].cma_dma_ind?.trim()?.toLocaleUpperCase() == "C" ? 1 : 0
      objVal['chkboxDMA'] = LstrList[0].cma_dma_ind?.trim()?.toLocaleUpperCase() == "D" ? 1 : 0

      if(LstrList[0].rcpt_status?.trim()?.toLocaleUpperCase() !== "P"){
        if(objVal['chkboxCMA']){
          disable(thisObj, 'chkboxDMA')
        }            
        
        if(objVal['chkboxDMA']){
          disable(thisObj, 'chkboxCMA')
        }            
      }

      if(LstrList[0].rcpt_status?.trim()?.toLocaleUpperCase() == "P"){
        disable(thisObj, 'chkboxCMA')
        disable(thisObj, 'chkboxDMA')
        disable(thisObj, 'txtVendorNum')
        disable(thisObj, 'ddRemit')
        disable(thisObj, 'btnVendor')
      }

      objVal['txtVendorNum'] = LstrList[0].cma_dma_vendor_num?.trim() !== "" ? LstrList[0].cma_dma_vendor_num : ""
      
      await bFillRemitToList(objVal['txtRepayVendorNum'] !== '' ? Number(objVal['txtRepayVendorNum'])?.toFixed(0) : objVal['txtRepayVendorNum'])
      await CFillRemitToList(objVal['txtVendorNum'] !== '' ? Number(objVal['txtVendorNum'])?.toFixed(0) : objVal['txtVendorNum'])
      objVal['ddRemit'] = LstrList[0]?.cma_dma_vendor_remit
      objVal['txtLoanDt'] = LstrList[0].loan_date?.trim() !== "" ? moment(LstrList[0].loan_date).format("MM/DD/YYYY") :""
      
      objVal['radioMarketLoan'] = 0
      objVal['radioOtherRate'] = 0
      objVal['radioLoanRepay'] = 0
      objVal['txtOtherRate'] = ""
      if(LstrList[0].shrink_pay_method_ind?.toLocaleUpperCase() == "MLR"){
        objVal['radioMarketLoan'] = "1"
        objVal['txtOtherRate'] = LstrList[0].shrink_pay_method_ind
      }
      else if(LstrList[0].shrink_pay_method_ind?.toLocaleUpperCase() == "OTH"){
        objVal['radioOtherRate'] = "1"
        objVal['txtOtherRate'] = LstrList[0].shrink_pay_method_ind
      }
      else{
        objVal['txtOtherRate'] = ""
        objVal['radioLoanRepay'] = "1"
      }

      objVal['lblShrinkPayLRR'] = LstrList[0].shrink_pay_lrr_rate == '0.00' ? '0' : LstrList[0].shrink_pay_lrr_rate
      objVal['txtShrinkPayPct'] = LstrList[0].shrink_pay_pct
      objVal['txtShrinkPay'] = LstrList[0].shrink_payment
      
      objVal['txtShrinkPayment'] = ''
      if(LstrList[0].shrink_payment?.trim() !== ""){
        objVal['txtShrinkPayment'] = Number(LstrList[0].shrink_payment || 0)?.toFixed(2)
      }
       
      objVal['txtRabatePayment'] = ''
      if(LstrList[0].rebate_payment?.trim() !== ""){
        objVal['txtRabatePayment'] = Number(LstrList[0].shrink_payment || 0)?.toFixed(2)
      }

      setData(thisObj, 'lblBasisGradeAmt', 0)

      objVal['txtOptionPayment'] = 0
      
      LstrList = await WarehouseReceiptService.RetrieveInspectHeaderListDetails(data.buyingPtId, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, data.wrNum)
      
      let js = []
      let basicGradeAmt = 0
      let optionPayment = 0
      if(LstrList?.length > 0){
        for(var i=0; i<LstrList?.length; i++){
          js.push({
            txtcol1007Num        : '0'.repeat(7 - LstrList[i].settlement_num?.toString()?.length) + LstrList[i].settlement_num,
            txtcolUnloadPoint    : LstrList[i].buy_pt_id,
            txtcolContract       : LstrList[i].contract_num,
            txtcolSC95Num        : LstrList[i].insp_num,
            txtcolInspectionDate : moment(LstrList[i].insp_date_time).format("MM/DD/YYYY"),
            txtcolNetWeight      : LstrList[i].net_wt,
            txtcolLSKWeight      : LstrList[i].lsk_wt,
            txtcolNetWtLessLSK   : Number(LstrList[i].net_wt) - Number(LstrList[i].lsk_wt),
            txtcolFarm           : LstrList[i].farm_name,
            txtcolValue          : LstrList[i].basis_grade_amt,
            txtcolSmkPct         : LstrList[i].smk_pct,
            txtcolSSPct          : LstrList[i].ss_pct,
            txtcolOkPct          : LstrList[i].ok_pct,
            txtcolDamPct         : LstrList[i].dam_pct,
            txtcolFrezDamPct     : LstrList[i].frez_dam_pct,
            txtcolCanclRmdPct    : LstrList[i].cncl_rmd_pct,
            txtcolFlavusDisc     : LstrList[i].flavus_discount,
            txtcolHullPct        : LstrList[i].hull_pct,
            txtcolFmPct          : LstrList[i].fm_pct,
            txtcolElkPct         : LstrList[i].elk_pct,
            txtcolFanPct         : LstrList[i].fan_pct,
            txtcolDcolShelPct    : LstrList[i].dcol_shel_pct,
            txtcolCrBrPct        : LstrList[i].cr_br_pct,
            txtcolMoistPct       : LstrList[i].moist_pct,
            txtcolLskPct         : LstrList[i].lsk_pct,
            txtcolHulBritPct     : LstrList[i].hul_brit_pct,
            txtcolJumboPct       : LstrList[i].jumbo_pct,
            txtcolDamSplitPct    : LstrList[i].dam_split_pct,
            txtcolVicamPpb       : LstrList[i].vicam_ppb,
            txtcolTkcPct         : LstrList[i].tkc_pct,
            txtcolGrssWt         : LstrList[i].gross_wt,
            txtcolOblBuyId       : LstrList[i].obl_buy_id,
            txtcolOblWhseId      : LstrList[i].obl_whse_id,
            txtcolOblBinId       : LstrList[i].obl_bin_id,
            txtcolSeedGen        : LstrList[i].seed_gen,
            txtcolSegType        : LstrList[i].seg_type,
            txtcolSeedMeetSpecInd: LstrList[i].seed_meeting_spec_ind
          })
          basicGradeAmt += Number(LstrList[i].basis_grade_amt)
          optionPayment += Number((Number(LstrList[i].value_of_seg) + Number(LstrList[i].opT_VALUE_OF_SEG)))
        }
      }
      
      // hideColumn(thisObj,'grid1007s','txtcolSmkPct')
      // hideColumn(thisObj,'grid1007s','txtcolSSPct')
      // hideColumn(thisObj,'grid1007s','txtcolOkPct')
      // hideColumn(thisObj,'grid1007s','txtcolDamPct')
      // hideColumn(thisObj,'grid1007s','txtcolFrezDamPct')
      // hideColumn(thisObj,'grid1007s','txtcolCanclRmdPct')
      // hideColumn(thisObj,'grid1007s','txtcolFlavusDisc')
      // hideColumn(thisObj,'grid1007s','txtcolHullPct')
      // hideColumn(thisObj,'grid1007s','txtcolFmPct')
      // hideColumn(thisObj,'grid1007s','txtcolElkPct')
      // hideColumn(thisObj,'grid1007s','txtcolFanPct')
      // hideColumn(thisObj,'grid1007s','txtcolDcolShelPct')
      // hideColumn(thisObj,'grid1007s','txtcolCrBrPct')
      // hideColumn(thisObj,'grid1007s','txtcolMoistPct')
      // hideColumn(thisObj,'grid1007s','txtcolLskPct')
      // hideColumn(thisObj,'grid1007s','txtcolHulBritPct')
      // hideColumn(thisObj,'grid1007s','txtcolJumboPct')
      // hideColumn(thisObj,'grid1007s','txtcolDamSplitPct')
      // hideColumn(thisObj,'grid1007s','txtcolVicamPpb')
      // hideColumn(thisObj,'grid1007s','txtcolTkcPct')
      // hideColumn(thisObj,'grid1007s','txtcolGrssWt')
      // hideColumn(thisObj,'grid1007s','txtcolOblBuyId')
      // hideColumn(thisObj,'grid1007s','txtcolOblWhseId')
      // hideColumn(thisObj,'grid1007s','txtcolOblBinId')
      // hideColumn(thisObj,'grid1007s','txtcolSeedGen')
      // hideColumn(thisObj,'grid1007s','txtcolSegType')
      // hideColumn(thisObj,'grid1007s','txtcolSeedMeetSpecInd')
      // let elements = ['txtcolSmkPct', 'txtcolSSPct', 'txtcolOkPct', 'txtcolDamPct', 'txtcolFrezDamPct', 'txtcolCanclRmdPct', 'txtcolFlavusDisc',
      //                 'txtcolHullPct', 'txtcolFmPct', 'txtcolElkPct', 'txtcolFanPct', 'txtcolDcolShelPct', 'txtcolCrBrPct', 'txtcolMoistPct', 'txtcolLskPct',
      //                 'txtcolHulBritPct', 'txtcolJumboPct', 'txtcolDamSplitPct', 'txtcolVicamPpb', 'txtcolTkcPct', 'txtcolGrssWt', 'txtcolOblBuyId', 'txtcolOblWhseId',
      //                 'txtcolOblBinId', 'txtcolSeedGen', 'txtcolSegType', 'txtcolSeedMeetSpecInd']
      // hideWidgets(thisObj, elements)
      
      
      objVal['txtOptionPayment'] = Number(optionPayment) !== 0 ? Number(optionPayment)?.toFixed(2) : "0"
      setData(thisObj, 'lblBasisGradeAmt', basicGradeAmt)
      
      objVal['grid1007s'] = js
      
      lstrxml = await WarehouseReceiptService.RetrievePricingDataWithFailoverTestDetails(getData(thisObj, 'msAreaId'), getData(thisObj, 'lblPeanutType'), 0, 0, 0)
      if(lstrxml?.length > 0){
        setData(thisObj, 'mdblSupportPerTon', lstrxml[0].support_per_ton)
      }
      
      if(LstrList?.status == 500){
        alert("Error communication with the database. Please try again in a few moments. If the problem persists please contact support.")
      }
      
      lstrxml = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', null, null, null, data.buyingPtId)
      if(lstrxml?.length <= 0){
        bFillGridBool = false
        return;
      }
      
      objVal['txtLicenseNum'] = LstrBPxml[0].fedWhouseLicense
      objVal['txtCCCCode'] = LstrBPxml[0].cccLocationId
      objVal['txtWhseBin'] = (getData(thisObj, 'msWhouseNum') || "") + '--' + (getData(thisObj, 'msWhouseBin') || 0)
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);
      
      await bCalStoragePaid()
      await CalcStorage()
      // await EnableDisableControls()
      
      if(getData(thisObj, 'msTent_cont_appl_ind') == "I"){
        disable(thisObj, 'chkboxStoreAndHandleReceived')
      }
  
      if((getData(thisObj, 'msTent_cont_appl_ind') == "O" || getData(thisObj, 'msTent_cont_appl_ind') == "N") && (objVal['chkboxStoreAndHandleReceived'] == 1 && objVal['chkboxStoreAndHandlePaid'] == 0)){
        hideShowControlDict['btnStorageHandlingPayment'] = false
        // hideShowControlDict['btnUndoStorehandlePayment'] = false        
      }

      if((getData(thisObj, 'msTent_cont_appl_ind') == "O" || getData(thisObj, 'msTent_cont_appl_ind') == "N") && (objVal['chkboxStoreAndHandleReceived'] == 1 && objVal['chkboxStoreAndHandlePaid'] == 1)){
        hideShowControlDict['btnStorageHandlingPayment'] = false
        // hideShowControlDict['btnUndoStorehandlePayment'] = false
      }

      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      // let showWidgetList = []
      // let hideWidgetList = []

      // for(var [key, value] of Object.entries(hideShowControlDict)){
      //   if(value){
      //     showWidgetList.push(key)
      //   }
      //   else{
      //     hideWidgetList.push(key)
      //   }
      // }
      
      // showWidgets(thisObj, showWidgetList)
      // hideWidgets(thisObj, hideWidgetList, false)

      bFillGridBool = true
      return bFillGridBool
    }
    catch(err){
      errorHandler(err, "bFillGrid")
      return false
    }
  }
  
  async function Form_Activate(){
    try{
      let lstrxml = []
      let objVal = getData(thisObj, 'objVal')
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      setData(thisObj, 'mbEWRAccepted', true)

      if(Number(cropYearFromLS()) >= 2011){
        // disable(thisObj, 'chkbox211')
        // hideShowControlDict['chkbox211'] = false
        // hide(thisObj, 'chkbox211', false)
      }
      
      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      await GetPermissions()

      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      switch(objVal['lblEWRTransmissionStatus']){
        case "":
          if(getData(thisObj, 'mbFormLoad')){
            let message = "An acknowledgement from EWR for this warehouse receipt has not been received or has not been processed.\n"
            message += "The following actions are not allowed until the warehouse receipt has been accepted by EWR:\n"
            message += "Purchase \nUn-Purchase \nVoid \nBegin Deliver Out \nUndo Begin Deliver Out \nComplete Deliver Out \nUndo Complete Deliver Out"
            alert(message)
          }
          
          setData(thisObj, 'mbEWRAccepted', false)
          
          disable(thisObj, 'btnPurchase')
          disable(thisObj, 'btnUnPurchase')
          disable(thisObj, 'btnVoid')
          disable(thisObj, 'btnBeginDelivery')
          disable(thisObj, 'btnCompDelv')
          disable(thisObj, 'btnUndoBegDelvOut')
          disable(thisObj, 'btnUndoCompDelvout')
          break;
        case "R":
          if(getData(thisObj, 'mbFormLoad')){
            let message = "This warehouse receipt was rejected by EWR. \nThe following actions are not allowed until the warehouse receipt has been corrected and accepted by EWR:\n"
            message += "Purchase \nUn-Purchase \nVoid \nBegin Deliver Out \nUndo Begin Deliver Out \nComplete Deliver Out \nUndo Complete Deliver Out"
            alert(message)
          }

          setData(thisObj, 'mbEWRAccepted', false)
          
          disable(thisObj, 'btnPurchase')
          disable(thisObj, 'btnUnPurchase')
          disable(thisObj, 'btnVoid')
          disable(thisObj, 'btnBeginDelivery')
          disable(thisObj, 'btnCompDelv')
          disable(thisObj, 'btnUndoBegDelvOut')
          disable(thisObj, 'btnUndoCompDelvout')
          break;
      }

      await EnableDisableControls()
      await bCheckRTP()

      setData(thisObj, 'mbFormLoad', false)
      enable(thisObj, 'btnDeductTrack')

      lstrxml = await WarehouseReceiptService.RetrieveCheck1007FreeStatusDetails(data?.unlBuyingPtId, data?.wrNum)
      if(lstrxml[0]?.in_1007_process?.trim() == 'Y'){
        alert("The Corresponding 1007 is freed up for either correction or supersede. No updates are allowed on this warehouse receipt until the correction or supercede process is complete.")
        await DisableWrButtons()
      }
    }
    catch(err){
      errorHandler(err, "Form Activate");
    }
  }
  
  async function DisableWrButtons(){
    try{
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      
      if(hideShowControlDict['btnUpdate']){
        disable(thisObj, 'btnUpdate')
      }
      
      if(hideShowControlDict['btnPurchase']){
        disable(thisObj, 'btnPurchase')
      }
      
      if(hideShowControlDict['btnUnPurchase']){
        disable(thisObj, 'btnUnPurchase')
      }
      
      if(hideShowControlDict['btnTentApplication']){
        disable(thisObj, 'btnTentApplication')
      }
      
      if(hideShowControlDict['btnTentApplicationReverse']){
        disable(thisObj, 'btnTentApplicationReverse')
      }
      
      if(hideShowControlDict['btnBeginDelivery']){
        disable(thisObj, 'btnBeginDelivery')
      }
      
      if(hideShowControlDict['btnCompDelv']){
        disable(thisObj, 'btnCompDelv')
      }
      
      if(hideShowControlDict['btnUndoBegDelvOut']){
        disable(thisObj, 'btnUndoBegDelvOut')
      }
      
      if(hideShowControlDict['btnUndoCompDelvout']){
        disable(thisObj, 'btnUndoCompDelvout')
      }
      
      if(hideShowControlDict['btnVoid']){
        disable(thisObj, 'btnVoid')
      }
      
      if(hideShowControlDict['btnUnVoid']){
        disable(thisObj, 'btnUnVoid')
      }
    }
    catch(err){
      errorHandler(err, "DisableWrButtons")
    }
  }

  const bFillHolderList = async() =>{
    try{
      let js = []
      let objVal = getData(thisObj, 'objVal')
      let LstrList = await WarehouseReceiptService.RetrieveVendorHolderTransferDetails(compIdFromLS(), cropYearFromLS())
      if(LstrList?.length <= 0){
        return false
      }

      LstrList?.forEach(holder => {
        js.push({
          key  : holder?.holder_id,
          description : holder?.holder_name,
        })
      })
      
      if(js?.length == 0){
        js = [{ 
          key : '', 
          description : '' 
        }]
      }
      
      
      thisObj.setState(current => {
        return {
          ...current,
          ddCurrentHolder: {
            ...current["ddCurrentHolder"],
            valueList: js
          }
        }
      })
      objVal['ddCurrentHolder'] = js.at(0).key
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);
      return true
    }
    catch(err){
      errorHandler(err, 'Filling holder list')
      return false
    }
  }

  const GetPermissions = async() => {
    try {
      let lstrxml = []
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let buyPtId = data?.buyingPtId
      let unlBuyPtId = data?.unlBuyingPtId
      let p = new Date()
      setData(thisObj, 'mbCreatePermissionDeny', false)
      setData(thisObj, 'mbShrinkPermissionDeny', false)
      setData(thisObj, 'mbUpdatePermissionDeny', false)
      setData(thisObj, 'mbPurchasePermissionDeny', false)
      setData(thisObj, 'mbUnpurchasePermissionDeny', false)
      setData(thisObj, 'mbDelvOutPermissionDeny', false)
      setData(thisObj, 'mbVoidPermissionDeny', false)
      setData(thisObj, 'mbRedemptionMethodPermissionDeny', false)
      setData(thisObj, 'mbUnloadLocInqPermissionDeny', false)
      setData(thisObj, 'mbRepaymentOverrideDeny', false)
      
      if (buyPtId !== '' && unlBuyPtId !== '') {
        if (buyPtId !== unlBuyPtId) {
          lstrxml = await SystemMaintenanceMasterManagementService.RetrieveUserFunctionLocationTransferDetails(useridFromLS(),'PN1090', '015', unlBuyPtId)
          if (lstrxml?.length > 0) {
            if (lstrxml[0].access_level?.toLocaleUpperCase() == "I") {
              setData(thisObj, 'mbCreatePermissionDeny', true)
              setData(thisObj, 'mbShrinkPermissionDeny', true)
              setData(thisObj, 'mbUpdatePermissionDeny', true)
              setData(thisObj, 'mbPurchasePermissionDeny', true)
              setData(thisObj, 'mbUnpurchasePermissionDeny', true)
              setData(thisObj, 'mbDelvOutPermissionDeny', true)
              setData(thisObj, 'mbVoidPermissionDeny', true)
              setData(thisObj, 'mbRedemptionMethodPermissionDeny', true)
              setData(thisObj, 'mbUnloadLocInqPermissionDeny', true)
              setData(thisObj, 'mbRepaymentOverrideDeny', true)
              return;
            }
          }
        }
      }
    
      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', null, 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbCreatePermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '001', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbShrinkPermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '002', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbUpdatePermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '003', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbPurchasePermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '004', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbUnpurchasePermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '005', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbDelvOutPermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '006', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbVoidPermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '007', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbRedemptionMethodPermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '008', 'I', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbUnloadLocInqPermissionDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '011', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'N')
          setData(thisObj, 'mbRepaymentOverrideDeny', true)
      }

      lstrxml = await ContractManagementService.RetrieveAccessPermissionDetails('PN1090', '017', 'U', null)
      if (lstrxml?.length > 0) {
        if (lstrxml[0].permission?.toLocaleUpperCase() == 'Y')
          setData(thisObj, 'mbEnableLoanFieldsPermission', true)
      }
    }
    catch (err) {
      setData(thisObj, 'mbShrinkPermissionDeny', true)
      setData(thisObj, 'mbUpdatePermissionDeny', true)
      setData(thisObj, 'mbPurchasePermissionDeny', true)
      setData(thisObj, 'mbUnpurchasePermissionDeny', true)
      setData(thisObj, 'mbDelvOutPermissionDeny', true)
      setData(thisObj, 'mbVoidPermissionDeny', true)
      setData(thisObj, 'mbRedemptionMethodPermissionDeny', true)
      errorHandler(err, 'GetPermissions')
    }
  }

  const bCalStoragePaid = async() => {
    try{
      let TotalStorageAmt = 0;
      let LstrList = [];
      let bCalStoragePaidBool = false;
      let objVal = getData(thisObj, 'objVal')

      objVal['txtStoragePaidAmt'] = ''

      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      LstrList = await WarehouseReceiptService.RetrieveStoragePaidList(data?.unlBuyingPtId, data?.wrNum, null)
      if(LstrList?.length > 0){
        for(var i=0; i<LstrList?.length; i++){
          if(LstrList[i].storage_payment?.trim() !== ""){
            if(isNumeric(LstrList[i].storage_payment)){
              TotalStorageAmt += Number(LstrList[i].storage_payment || 0)
            }
          }
        }

        objVal['txtStoragePaidAmt'] = TotalStorageAmt == 0 ? "0.00" : Number(TotalStorageAmt)?.toFixed(2)

        let value1 = objVal['txtCalCNetRepayAmt']
        let value2 = objVal['txtStoragePaidAmt']
        let value3 = objVal['txtInterestAmt']
        objVal['txtCalCNetRepayAmt'] = Number(value1 || 0) + Number(value2 || 0) + Number(value3 || 0)

        bCalStoragePaidBool = true
      }
      else{
        objVal['txtStoragePaidAmt'] = "0.00"
        
        bCalStoragePaidBool = false
      }
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);
      return bCalStoragePaidBool
    }
    catch(err){
      errorHandler(err, "bCalStoragePaid")
      return false
    }
  }

  const CalcStorage = async() => {
    try{
      let dtStore = new Date()
      let dtBeg = new Date()
      let dtEnd = new Date()
      let dtCalcBeg = new Date()
      let nDaysOfStorage = 0
      let dblWRTons      = 0
      let dblTotalStorage  = 0
      let dblCCCStorage  = 0
      // let dblFarmerStorage = 0
      let sFedLic = ""
      let dblStorageRate = 0
      let lstrxml = []
      let sRatePerTon = ""

      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let endDate = objVal['txtEndDate']
      let expDate = objVal['txtExpDate']
      let lockInDate = objVal['txtLnRpyLockInDt']
      let peanutType = getData(thisObj, 'lblPeanutType')

      let value1 = 0
      let value2 = 0
      let value3 = 0
      let value4 = 0
      if(new Date(endDate) instanceof Date){
        if(new Date(endDate).getFullYear() < 2000){
          alert("Invalid End date")
          if(isEnabled(thisObj, 'txtEndDate')){
            // document.getElementById('txtEndDate').focus()
            return;
          }
        }
      }

      if(objVal['chkboxOverrideLRR']){ 
        sRatePerTon = objVal['txtLoanRepayRate']
      }
      else{
        if(endDate instanceof Date){
          if(new Date(expDate) instanceof Date){
            if(new Date(expDate) > new Date(endDate)){
              lstrxml = await SettlementService.RetrieveLoanRePaymentControlDetails(peanutType, null, moment(endDate).format("MM/DD/YYYY HH:MM A"))
              if(lstrxml?.length <= 0){
                return;
              }                
            }
            else{
              lstrxml = await SettlementService.RetrieveLoanRePaymentControlDetails(peanutType, null, moment(lockInDate).format("MM/DD/YYYY HH:MM A"))
              if(lstrxml?.length <= 0){
                return;
              }
            }
          }
          else{
            lstrxml = await SettlementService.RetrieveLoanRePaymentControlDetails(peanutType, null, moment(endDate).format("MM/DD/YYYY HH:MM A"))
            if(lstrxml?.length <= 0){
              return;
            }
          }
        }
        else{
          lstrxml = await SettlementService.RetrieveLoanRePaymentControlDetails(peanutType, null, moment(new Date()).format("MM/DD/YYYY HH:MM A"))
          if(lstrxml?.length <= 0){
            return;
          }
        }
        sRatePerTon = lstrxml[0].ratePerTon
      }
      
      if(!isNumeric(sRatePerTon)){
        sRatePerTon = "0"
      }

      objVal['txtLoanRepayRate'] = Number(sRatePerTon)?.toFixed(2)

      let storageDate = objVal['txtWtdAvgStorageDt']
      let producerBeginDate = objVal['txtProducerBeginDate']
      if(!(new Date(storageDate) instanceof Date && new Date(producerBeginDate) instanceof Date)){
        objVal['txtStorageCharge'] = Number(dblTotalStorage)?.toFixed(2)
        objVal['txtStrgCreditAmt'] = Number(dblCCCStorage)?.toFixed(2)

        value1 = Number(getData(thisObj, 'mdblSupportPerTon'))
        value2 = Number(objVal['txtLoanRepayRate']) 
        value3 = Number(objVal['txtTotalTonsShrunk'])
        objVal['txtMarketGain2'] = Number((value1 - value2) * value3) <= 0 ? "0.00": Number((value1 - value2) * value3)?.toFixed(2)
      
        value1 = Number(getData(thisObj, 'lblTotalPoundsShrunk'))
        value2 = Number(getData(thisObj, 'mdblSupportPerTon'))
        objVal['txtLoanRepayAmt'] = value1 * value2 * 0.35
        return;
      }

      dtStore = objVal['txtWtdAvgStorageDt']
      dtCalcBeg = objVal['txtProducerBeginDate']

      dtBeg = isDateValid(objVal['txtCCCBeginDate']) ? objVal['txtCCCBeginDate'] : dtCalcBeg
      if(new Date(dtBeg) < new Date(dtCalcBeg)){
        dtCalcBeg = dtBeg
      }

      dtEnd = isDateValid(endDate) ? endDate : moment(new Date()).format("MM/DD/YYYY")
      dblWRTons = objVal['txtTotalTonsShrunk']
      sFedLic = objVal['txtLicenseNum']
      if(Number(cropYearFromLS()) < 2007){
        nDaysOfStorage = new Date(dtEnd).getDate() - new Date(dtCalcBeg).getDate() + 1
      }
      else{
        let txtStrgPaidThroughDt = objVal['txtStrgPaidThroughDt']
        let prodBeginDate = objVal['txtProducerBeginDate']

        if(new Date(txtStrgPaidThroughDt) instanceof Date && new Date(prodBeginDate) instanceof Date){
          if(getData(thisObj, 'msTent_cont_appl_ind') !== "N" && !(objVal['chkboxDeferredRedemption'])){
            prodBeginDate = new Date(prodBeginDate)
            var adjustedDate = new Date(prodBeginDate)
            var end_Date = new Date(prodBeginDate.setMonth(prodBeginDate.getMonth()+10))
            end_Date = new Date(end_Date.setDate(0))
            nDaysOfStorage = Math.floor((Date.UTC(end_Date.getFullYear(), end_Date.getMonth(), end_Date.getDate()) - Date.UTC(adjustedDate.getFullYear(), adjustedDate.getMonth(), adjustedDate.getDate()) ) /(1000 * 60 * 60 * 24))
          }
          else{
            var stgPaidThroughDt = new Date(objVal['txtStrgPaidThroughDt'])
            prodBeginDate = new Date(objVal['txtProducerBeginDate'])
            nDaysOfStorage = Math.floor((Date.UTC(stgPaidThroughDt.getFullYear(), stgPaidThroughDt.getMonth(), stgPaidThroughDt.getDate()) - Date.UTC(prodBeginDate.getFullYear(), prodBeginDate.getMonth(), prodBeginDate.getDate()) ) /(1000 * 60 * 60 * 24))
          }
        }
        else{
          nDaysOfStorage = 0
        }
      }
      
      lstrxml = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(sFedLic, null) //data.buyingPtId)
      if(lstrxml?.length <= 0){
        return;
      }
      
      dblStorageRate = lstrxml[0].whouse_license_controls[0].shorage_rate
      dblTotalStorage = Number(nDaysOfStorage) * Number(dblWRTons) *  Number(dblStorageRate)  
      objVal['txtStorageCharge'] = (dblTotalStorage)?.toFixed(2)
      
      var v1 = Date.UTC(new Date(dtEnd).getFullYear(), new Date(dtEnd).getMonth(), new Date(dtEnd).getDate())
      var v2 = Date.UTC(new Date(dtBeg).getFullYear(), new Date(dtBeg).getMonth(), new Date(dtBeg).getDate())

      value1 = (v1 - v2) / (1000 * 60 * 60 * 24)
      dblCCCStorage = ((value1 || 0) + 1) * Number(dblWRTons) * Number(dblStorageRate)
      
      objVal['txtStrgCreditAmt'] = Number(dblCCCStorage)?.toFixed(2)
      
      // dblFarmerStorage = Number(dblTotalStorage || 0) - Number(dblCCCStorage || 0)

      value1 = Number(getData(thisObj, 'mdblSupportPerTon'))
      value2 = Number(objVal['txtLoanRepayRate']) 
      value3 = Number(objVal['txtTotalTonsShrunk'])

      objVal['txtMarketGain2'] = Number((value1-value2)*value3) > 0 ? Number((value1-value2)*value3)?.toFixed(2) : "0.00"
      
      objVal['txtLoanRepayAmt'] = ''
      switch(objVal['txtSeg']){
        case "1":
          value1 = getData(thisObj, 'lblBasisGradeAmt')
          value2 = objVal['txtShrnkPct']
          value3 = objVal['txtLoanRepayRate']
          value4 = getData(thisObj, 'mdblSupportPerTon')
          if(Number(value3) < Number(value4)){
            objVal['txtLoanRepayAmt'] = Number(Number(value1) * ((100 - Number(value2)) / 100) * Number(Number(value3)/Number(value4)).toFixed(4))?.toFixed(2)
          }
          else{
            objVal['txtLoanRepayAmt'] = Number(Number(value1) * ((100 - Number(value2)) / 100))?.toFixed(2)
          }
          break;
        case "2":
          value1 = objVal['txtTotalLbsShrunk']
          objVal['txtLoanRepayAmt'] = Number((value1 / 2000) * value4 * 0.35)?.toFixed(2)
          break;
        case "3":
          value1 = objVal['txtTotalLbsShrunk']
          objVal['txtLoanRepayAmt'] = Number((value1 / 2000) * value4 * 0.35)?.toFixed(2)
          break;
      }

      value1 = Number(Number(objVal['txtInspeactionFees']) + Number(objVal['txtInCharges'])).toFixed(2)      
      objVal['txtInChargesAndAsstCosts'] = value1

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true);
      await ontxtInChargesAndAsstCostsChange({ target : { value : value1 }})
    }
    catch(err){
      errorHandler(err, "CalcStorage")
    }
  }

  const CalcNetRepayment = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      let loanRepayAmt = objVal['txtLoanRepayAmt']
      let strgCreditAmt = objVal['txtStrgCreditAmt']
      let repayCalcHandling = objVal['txtHandlingAmt']
      let calcInterestAmt = objVal['txtInterestAmt']

      let value = 0
      if(isNumeric(loanRepayAmt) && isNumeric(strgCreditAmt) && isNumeric(repayCalcHandling) && isNumeric(calcInterestAmt)){
        value = Number(Number(loanRepayAmt || 0) - Number(strgCreditAmt || 0) + Number(repayCalcHandling || 0) + Number(calcInterestAmt || 0))?.toFixed(2)
      }
      else{
        value = 0
      }
      objVal['txtCalCNetRepayAmt'] = value
      
      let loanRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
      let strgCreditAmtOverride = objVal['txtStrgCreditAmtOverride']
      let interestAmtOverride = objVal['txtInterestAmtOverride']
      let handlingAmtOverride = objVal['txtHandlingAmtOverride']
      
      value = 0
      if(isNumeric(loanRepayAmtOverride) && isNumeric(strgCreditAmtOverride) && isNumeric(interestAmtOverride) && isNumeric(handlingAmtOverride)){
        value = Number(Number(loanRepayAmtOverride || 0) + Number(strgCreditAmtOverride || 0) + Number(interestAmtOverride || 0) + Number(handlingAmtOverride || 0))?.toFixed(2)
      }
      
      objVal['txtNetRepayAmt'] = value
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "CalcNetRepayment")
    }
  }

  const EnableDisableControls = async() => {
    try{
      let e1 = new Date()
      let objVal = getData(thisObj, 'objVal')
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      let disableEnableDict = {}

      hideShowControlDict['btnTentApplication'] = false
      disableEnableDict['btnTentApplication'] = false
      // hide(thisObj, 'btnTentApplication')
      // disable(thisObj, 'btnTentApplication')

      hideShowControlDict['btnTentApplicationReverse'] = false
      disableEnableDict['btnTentApplicationReverse'] = false
      // hide(thisObj, 'btnTentApplicationReverse')
      // disable(thisObj, 'btnTentApplicationReverse')

      disableEnableDict['btnApplications'] = false
      disableEnableDict['btnPayments'] = false
      disableEnableDict['btnPremsDeduct'] = false
      disableEnableDict['btnAcctDist'] = false
      disableEnableDict['btnPrintWorksheet'] = false
      // disable(thisObj, 'btnApplications')
      // disable(thisObj, 'btnPayments')
      // disable(thisObj, 'btnPremsDeduct')
      // disable(thisObj, 'btnAcctDist')
      // disable(thisObj, 'btnPrintWorksheet')
      
      hideShowControlDict['btnPurchase'] = false
      disableEnableDict['btnPurchase'] = false
      // hide(thisObj, 'btnPurchase')
      // disable(thisObj, 'btnPurchase')

      hideShowControlDict['btnUnPurchase'] = false
      disableEnableDict['btnUnPurchase'] = false
      // hide(thisObj, 'btnUnPurchase')
      // disable(thisObj, 'btnUnPurchase')
      
      hideShowControlDict['btnVoid'] = false
      disableEnableDict['btnVoid'] = false
      // hide(thisObj, 'btnVoid')
      // disable(thisObj, 'btnVoid')

      hideShowControlDict['btnUnVoid'] = false
      disableEnableDict['btnUnVoid'] = false
      // hide(thisObj, 'btnUnVoid')
      // disable(thisObj, 'btnUnVoid')

      hideShowControlDict['btnBeginDelivery'] = false
      disableEnableDict['btnBeginDelivery'] = false
      // hide(thisObj, 'btnBeginDelivery')
      // disable(thisObj, 'btnBeginDelivery')

      hideShowControlDict['btnUndoBegDelvOut'] = false
      disableEnableDict['btnUndoBegDelvOut'] = false
      // hide(thisObj, 'btnUndoBegDelvOut')
      // disable(thisObj, 'btnUndoBegDelvOut')

      hideShowControlDict['btnCompDelv'] = false
      disableEnableDict['btnCompDelv'] = false
      // hide(thisObj, 'btnCompDelv')
      // disable(thisObj, 'btnCompDelv')
    
      hideShowControlDict['btnUndoCompDelvout'] = false
      disableEnableDict['btnUndoCompDelvout'] = false
      // hide(thisObj, 'btnUndoCompDelvout')
      // disable(thisObj, 'btnUndoCompDelvout')

      hideShowControlDict['btnShrinkPayment'] = false
      disableEnableDict['btnShrinkPayment'] = false
      // hide(thisObj, 'btnShrinkPayment')
      // disable(thisObj, 'btnShrinkPayment')

      hideShowControlDict['btnReverseShrinkPayment'] = false
      disableEnableDict['btnReverseShrinkPayment'] = false
      // hide(thisObj, 'btnReverseShrinkPayment')
      // disable(thisObj, 'btnReverseShrinkPayment')

      disableEnableDict['radioLoanRepay'] = false
      disableEnableDict['radioMarketLoan'] = false
      disableEnableDict['radioOtherRate'] = false
      disableEnableDict['txtShrinkPayPct'] = false
      disableEnableDict['txtShrinkPay'] = false
      disableEnableDict['txtOtherRate'] = false

      disable(thisObj, 'grpbxPayShrink')
      // disable(thisObj, 'radioLoanRepay')
      // disable(thisObj, 'radioMarketLoan')
      // disable(thisObj, 'radioOtherRate')
      // disable(thisObj, 'txtShrinkPayPct')
      // disable(thisObj, 'txtShrinkPay')
      // disable(thisObj, 'txtOtherRate')
      document.getElementById('txtOtherRate').style.backgroundColor = '#F8F4F4' // &H8000000B

      disableEnableDict['txtExpDate'] = false
      disableEnableDict['txtCCCBeginDate'] = false
      disableEnableDict['txtEndDate'] = false
      disableEnableDict['txtLnRpyLockInDt'] = false
      disableEnableDict['txtInterestAmt'] = false
      disableEnableDict['txtHandlingAmt'] = false
      disableEnableDict['txtProducerBeginDate'] = false
      disableEnableDict['txtLoanRepayAmtOverride'] = false
      disableEnableDict['txtLoanRepayRate'] = false
      disableEnableDict['txtRepayVendorNum'] = false

      // disable(thisObj, 'txtExpDate')
      // disable(thisObj, 'txtCCCBeginDate')
      // disable(thisObj, 'txtEndDate')
      // disable(thisObj, 'txtLnRpyLockInDt')
      // disable(thisObj, 'txtInterestAmt')
      // disable(thisObj, 'txtHandlingAmt')
      // disable(thisObj, 'txtProducerBeginDate')
      // disable(thisObj, 'txtLoanRepayAmtOverride')
      // disable(thisObj, 'txtLoanRepayRate')
      disable(thisObj, 'chkboxOverrideLRR')
      // disable(thisObj, 'txtRepayVendorNum')
      disable(thisObj, 'chkboxWireTransfer')
      setData(thisObj, 'chkboxWireTransferBool', false)

      await DisplayWireTransfer(Number(objVal['txtRepayVendorNum']).toFixed(0), getData(thisObj, 'msWireXferDataSaved') || '')

      disableEnableDict['txtCMAVendorNum'] = false
      disableEnableDict['txtStrgCreditAmtOverride'] = false
      disableEnableDict['btnCMAVendor'] = false
      disableEnableDict['btnRepayVendor'] = false

      disableEnableDict['btnApplications'] = true
      disableEnableDict['btnPayments'] = true
      disableEnableDict['btnPremsDeduct'] = true
      disableEnableDict['btnAcctDist'] = true

      // disable(thisObj, 'txtCMAVendorNum')
      // disable(thisObj, 'txtStrgCreditAmtOverride')
      // disable(thisObj, 'btnCMAVendor')
      // disable(thisObj, 'btnRepayVendor')

      // enable(thisObj, 'btnApplications')
      // enable(thisObj, 'btnPayments')
      // enable(thisObj, 'btnPremsDeduct')
      // enable(thisObj, 'btnAcctDist')
      
      let status = objVal['txtStatus']
      if(status?.toLocaleUpperCase()?.trim() == "PENDING FORFEITED PURCHASE"){  
        if(getData(thisObj, 'msTent_cont_appl_ind') == "O"){
          hideShowControlDict['btnTentApplicationReverse'] = true
          // show(thisObj, 'btnTentApplicationReverse')

          disableEnableDict['btnTentApplicationReverse'] = true
          disableEnableDict['btnPurchase'] = true
          // enable(thisObj, 'btnTentApplicationReverse')
          // enable(thisObj, 'btnPurchase')
          hideShowControlDict['btnPurchase'] = true
          // show(thisObj, 'btnPurchase')
        }
        else if(getData(thisObj, 'msTent_cont_appl_ind') == "N"){
          disableEnableDict['btnTentApplication'] = true
          //enable(thisObj, 'btnTentApplication')
          hideShowControlDict['btnTentApplication'] = true
          // show(thisObj, 'btnTentApplication')
          disableEnableDict['btnPurchase'] = true
          // enable(thisObj, 'btnPurchase')
          hideShowControlDict['btnPurchase'] = true
          // show(thisObj, 'btnPurchase')
        }
        else{
          disableEnableDict['btnPurchase'] = true
          // enable(thisObj, 'btnPurchase')
          hideShowControlDict['btnPurchase'] = true
          // show(thisObj, 'btnPurchase')
        }

        disableEnableDict['btnUpdate'] = true
        // enable(thisObj, 'btnUpdate')
        hideShowControlDict['btnUpdate'] = true
        // show(thisObj, 'btnUpdate')
        disableEnableDict['btnVoid'] = true
        // enable(thisObj, 'btnVoid')
        hideShowControlDict['btnVoid'] = true
        // show(thisObj, 'btnVoid')
        disableEnableDict['btnPrintWorksheet'] = true
        // enable(thisObj, 'btnPrintWorksheet')

        if(isDateValid(objVal['txtDocDelvDt'])){
          hideShowControlDict['btnBeginDelivery'] = true
          // show(thisObj, 'btnBeginDelivery')
          disableEnableDict['btnBeginDelivery'] = true
          // enable(thisObj, 'btnBeginDelivery')
        }

        disableEnableDict['txtCCCBeginDate'] = true
        disableEnableDict['txtEndDate'] = true
        disableEnableDict['txtLnRpyLockInDt'] = true
        disableEnableDict['txtInterestAmtOverride'] = true
        disableEnableDict['txtHandlingAmtOverride'] = true
        disableEnableDict['txtProducerBeginDate'] = true
        disableEnableDict['txtLoanRepayAmtOverride'] = true

        // enable(thisObj, 'txtCCCBeginDate')
        // enable(thisObj, 'txtEndDate')
        // enable(thisObj, 'txtLnRpyLockInDt')
        // enable(thisObj, 'txtInterestAmtOverride')
        // enable(thisObj, 'txtHandlingAmtOverride')
        // enable(thisObj, 'txtProducerBeginDate')
        // enable(thisObj, 'txtLoanRepayAmtOverride')
        enable(thisObj, 'chkboxOverrideLRR')
        
        if(objVal['chkboxOverrideLRR'] == 1){
          disableEnableDict['txtLoanRepayRate'] = true
          //enable(thisObj, 'txtLoanRepayRate')
        }
        disableEnableDict['txtRepayVendorNum'] = true
        // enable(thisObj, 'txtRepayVendorNum')

        await  DisplayWireTransfer(Number(objVal['txtRepayVendorNum']).toFixed(0), getData(thisObj, 'msWireXferDataSaved'))

        disableEnableDict['txtCMAVendorNum'] = true
        disableEnableDict['txtStrgCreditAmtOverride'] = true
        disableEnableDict['btnCMAVendor'] = true
        disableEnableDict['btnRepayVendor'] = true
        // enable(thisObj, 'txtCMAVendorNum')
        // enable(thisObj, 'txtStrgCreditAmtOverride')
        // enable(thisObj, 'btnCMAVendor')
        // enable(thisObj, 'btnRepayVendor')
      }
      else if(status?.toLocaleUpperCase()?.trim() == "ISSUED"){
        if(getData(thisObj, 'msTent_cont_appl_ind') == "O"){
          hideShowControlDict['btnTentApplicationReverse'] = true
          // show(thisObj, 'btnTentApplicationReverse')

          disableEnableDict['btnTentApplicationReverse'] = true
          disableEnableDict['btnPurchase'] = true
          // enable(thisObj, 'btnTentApplicationReverse')
          // enable(thisObj, 'btnPurchase')

          hideShowControlDict['btnPurchase'] = true
          // show(thisObj, 'btnPurchase')
        }
        else if(getData(thisObj, 'msTent_cont_appl_ind') == "N"){
          disableEnableDict['btnTentApplication'] = true
          //enable(thisObj, 'btnTentApplication')
          hideShowControlDict['btnTentApplication'] = true
          // show(thisObj, 'btnTentApplication')
          disableEnableDict['btnPurchase'] = false
          // disable(thisObj, 'btnPurchase')

          hideShowControlDict['btnPurchase'] = false
          // hide(thisObj, 'btnPurchase')
        }
        else{
          disableEnableDict['btnPurchase'] = true
          // enable(thisObj, 'btnPurchase')
          hideShowControlDict['btnPurchase'] = true
          // show(thisObj, 'btnPurchase')
        }

        disableEnableDict['btnUpdate'] = true
        // enable(thisObj, 'btnUpdate')
        hideShowControlDict['btnUpdate'] = true
        // show(thisObj, 'btnUpdate')

        disableEnableDict['btnVoid'] = true
        // enable(thisObj, 'btnVoid')
        hideShowControlDict['btnVoid'] = true
        // show(thisObj, 'btnVoid')

        disableEnableDict['btnPrintWorksheet'] = true
        // enable(thisObj, 'btnPrintWorksheet')

        if(isDateValid(objVal['txtDocDelvDt'])){
          hideShowControlDict['btnBeginDelivery'] = true
          // show(thisObj, 'btnBeginDelivery')
          disableEnableDict['btnBeginDelivery'] = true
          // enable(thisObj, 'btnBeginDelivery')
        }

        disableEnableDict['txtCCCBeginDate'] = true
        disableEnableDict['txtEndDate'] = true
        disableEnableDict['txtLnRpyLockInDt'] = true
        disableEnableDict['txtInterestAmtOverride'] = true
        disableEnableDict['txtHandlingAmtOverride'] = true
        disableEnableDict['txtProducerBeginDate'] = true
        disableEnableDict['txtLoanRepayAmtOverride'] = true
        // enable(thisObj, 'txtCCCBeginDate')
        // enable(thisObj, 'txtEndDate')
        // enable(thisObj, 'txtLnRpyLockInDt')
        // enable(thisObj, 'txtInterestAmtOverride')
        // enable(thisObj, 'txtHandlingAmtOverride')
        // enable(thisObj, 'txtProducerBeginDate')
        // enable(thisObj, 'txtLoanRepayAmtOverride')
        // enable(thisObj, 'chkboxOverrideLRR')

        if(objVal['chkboxOverrideLRR'] == 1){
          disableEnableDict['txtLoanRepayRate'] = true
          // enable(thisObj, 'txtLoanRepayRate')
        }          
        
        disableEnableDict['txtRepayVendorNum'] = true
        disableEnableDict['chkboxWireTransfer'] = true
        // enable(thisObj, 'txtRepayVendorNum')
        // enable(thisObj, 'chkboxWireTransfer')
        setData(thisObj, 'chkboxWireTransferBool', true)

        if(objVal['chkboxWireTransfer'] == 1){
          await  DisplayWireTransfer(objVal['txtRepayVendorNum']?.trim(), getData(thisObj, 'msWireXferDataSaved'))
        }          
        else{
          await  DisplayWireTransfer("", "")
          disableEnableDict['txtWireTransfer'] = false
          // disable(thisObj, 'txtWireTransfer')
          document.getElementById('txtWireTransfer').style.backgroundColor = 'white' //&H8000000B
        }

        disableEnableDict['txtCMAVendorNum'] = true
        disableEnableDict['txtStrgCreditAmtOverride'] = true
        disableEnableDict['btnCMAVendor'] = true
        disableEnableDict['btnRepayVendor'] = true
        // enable(thisObj, 'txtCMAVendorNum')
        // enable(thisObj, 'txtStrgCreditAmtOverride')
        // enable(thisObj, 'btnCMAVendor')
        // enable(thisObj, 'btnRepayVendor')
      }
      else if(status?.toLocaleUpperCase()?.trim() == "PURCHASED"){
        disableEnableDict['btnUnPurchase'] = true
        // enable(thisObj, 'btnUnPurchase')
        hideShowControlDict['btnUnPurchase'] = true
        // show(thisObj, 'btnUnPurchase')

        disableEnableDict['btnApplications'] = true
        disableEnableDict['btnPayments'] = true
        disableEnableDict['btnPremsDeduct'] = true
        disableEnableDict['btnAcctDist'] = true
        disableEnableDict['btnPrintWorksheet'] = true
        // enable(thisObj, 'btnApplications')
        // enable(thisObj, 'btnPayments')
        // enable(thisObj, 'btnPremsDeduct')
        // enable(thisObj, 'btnAcctDist')
        // enable(thisObj, 'btnPrintWorksheet')
      }
      else if(status?.toLocaleUpperCase()?.trim() == "DELV. OUT"){
        disableEnableDict['btnUndoCompDelvout'] = true
        // enable(thisObj, 'btnUndoCompDelvout')
        hideShowControlDict['btnUndoCompDelvout'] = true
        // show(thisObj, 'btnUndoCompDelvout')

        disableEnableDict['btnApplications'] = true
        disableEnableDict['btnPayments'] = true
        disableEnableDict['btnPremsDeduct'] = true
        disableEnableDict['btnAcctDist'] = true
        disableEnableDict['btnPrintWorksheet'] = true
        // enable(thisObj, 'btnApplications')
        // enable(thisObj, 'btnPayments')
        // enable(thisObj, 'btnPremsDeduct')
        // enable(thisObj, 'btnAcctDist')
        // enable(thisObj, 'btnPrintWorksheet')
      }
      else if(status?.toLocaleUpperCase()?.trim() == "DELIVERY IN PROCESS"){
        disableEnableDict['btnApplications'] = true
        disableEnableDict['btnPayments'] = true
        disableEnableDict['btnPremsDeduct'] = true
        disableEnableDict['btnAcctDist'] = true
        disableEnableDict['btnPrintWorksheet'] = true

        // enable(thisObj, 'btnApplications')
        // enable(thisObj, 'btnPayments')
        // enable(thisObj, 'btnPremsDeduct')
        // enable(thisObj, 'btnAcctDist')
        // enable(thisObj, 'btnPrintWorksheet')

        if(!getData(thisObj, 'mbLoadOutsExist')){
          disableEnableDict['btnUndoBegDelvOut'] = true
          // enable(thisObj, 'btnUndoBegDelvOut')
          hideShowControlDict['btnUndoBegDelvOut'] = true
          // show(thisObj, 'btnUndoBegDelvOut')
        }
        else{
          if(new Date(objVal['txtDocDelvDt']) instanceof Date){
            hideShowControlDict['btnBeginDelivery'] = true
            // show(thisObj, 'btnBeginDelivery')
            disableEnableDict['btnBeginDelivery'] = true
            // enable(thisObj, 'btnBeginDelivery')
          }
        }
      }
      else if(status?.toLocaleUpperCase()?.trim() == "VOIDED"){
        disableEnableDict['btnApplications'] = true
        disableEnableDict['btnPayments'] = true
        disableEnableDict['btnPremsDeduct'] = true
        disableEnableDict['btnAcctDist'] = true
        disableEnableDict['btnUpdate'] = false
        disableEnableDict['btnUnVoid'] = true
        // enable(thisObj, 'btnApplications')
        // enable(thisObj, 'btnPayments')
        // enable(thisObj, 'btnPremsDeduct')
        // enable(thisObj, 'btnAcctDist')
        // disable(thisObj, 'btnUpdate')
        // enable(thisObj, 'btnUnVoid')
        hideShowControlDict['btnUnVoid'] = true
        // show(thisObj, 'btnUnVoid')
      }
      else if(status?.toLocaleUpperCase()?.trim() == "PENDING VOID"){
        disableEnableDict['btnApplications'] = true
        disableEnableDict['btnPayments'] = true
        disableEnableDict['btnPremsDeduct'] = true
        disableEnableDict['btnAcctDist'] = true
        disableEnableDict['btnUpdate'] = false
        // enable(thisObj, 'btnApplications')
        // enable(thisObj, 'btnPayments')
        // enable(thisObj, 'btnPremsDeduct')
        // enable(thisObj, 'btnAcctDist')
        // disable(thisObj, 'btnUpdate')
      }

      status = objVal['txtStatus'] == undefined ? ' ' :  objVal['txtStatus']
      if(status?.trim()?.toLocaleUpperCase() !== "PURCHASED" && status?.trim()?.toLocaleUpperCase() !== "VOIDED" && status?.trim()?.toLocaleUpperCase() !== "PENDING VOID"){
        if(isNumeric(objVal['txtShrnkPct'])){
          if(Number(objVal['txtShrnkPct']) > 0){
            if(isNumeric(objVal['txtShrinkPay'])){
              if(!getData(thisObj, 'mbUnpurchasePermissionDeny')){
                disableEnableDict['btnReverseShrinkPayment'] = true
                // enable(thisObj, 'btnReverseShrinkPayment')
                hideShowControlDict['btnReverseShrinkPayment'] = true
                // show(thisObj, 'btnReverseShrinkPayment')
              }
            }
            else{
              if(!getData(thisObj, 'mbPurchasePermissionDeny') && getData(thisObj, 'msTent_cont_appl_ind') !== 'N'){
                disableEnableDict['btnShrinkPayment'] = true
                //enable(thisObj, 'btnShrinkPayment')
                hideShowControlDict['btnShrinkPayment'] = true
                // show(thisObj, 'btnShrinkPayment')
              }
              
              if(!getData(thisObj, 'mbRedemptionMethodPermissionDeny')){
                disableEnableDict['grpbxPayShrink'] = true
                disableEnableDict['radioLoanRepay'] = true
                disableEnableDict['radioMarketLoan'] = true
                disableEnableDict['radioOtherRate'] = true
                
                // enable(thisObj, 'grpbxPayShrink')
                // enable(thisObj, 'radioLoanRepay')
                // enable(thisObj, 'radioMarketLoan')
                // enable(thisObj, 'radioOtherRate')
                if(objVal['radioOtherRate'] == "1"){
                  disableEnableDict['txtOtherRate'] = true
                  // enable(thisObj, 'txtOtherRate')
                  document.getElementById('txtOtherRate').style.backgroundColor = 'white' //  &H80000005
                }                
              }
            }
          }
        }
      }

      if(getData(thisObj, 'mbUpdatePermissionDeny')){
        disableEnableDict['btnTentApplicationReverse'] = false
        disableEnableDict['btnTentApplication'] = false
        disableEnableDict['btnUpdate'] = false
        // disable(thisObj, 'btnTentApplicationReverse')
        // disable(thisObj, 'btnTentApplication')
        // disable(thisObj, 'btnUpdate')
      }

      if(getData(thisObj, 'mbPurchasePermissionDeny')){
        disableEnableDict['btnPurchase'] = false
        disableEnableDict['btnBeginDelivery'] = false
        disableEnableDict['btnUndoBeginDelivery'] = false
        disableEnableDict['btnCompDelvOut'] = false
        disableEnableDict['btnUndoCompDelvout'] = false
        // disable(thisObj, 'btnPurchase')
        // disable(thisObj, 'btnBeginDelivery')
        // disable(thisObj, 'btnUndoBeginDelivery')
        // disable(thisObj, 'btnCompDelvOut')
        // disable(thisObj, 'btnUndoCompDelvout')
      }

      if(getData(thisObj, 'mbUnpurchasePermissionDeny')){
        disableEnableDict['btnUnPurchase'] = false
        disableEnableDict['btnBeginDelivery'] = false
        disableEnableDict['btnUndoBegDelvOut'] = false
        disableEnableDict['btnCompDelv'] = false
        disableEnableDict['btnUndoCompDelvout'] = false
        // disable(thisObj, 'btnUnPurchase')  
        // disable(thisObj, 'btnBeginDelivery')
        // disable(thisObj, 'btnUndoBegDelvOut')

        // disable(thisObj, 'btnCompDelv')
        // disable(thisObj, 'btnUndoCompDelvout')
      }

      if(getData(thisObj, 'mbDelvOutPermissionDeny')){
        disableEnableDict['btnBeginDelivery'] = false
        disableEnableDict['btnUndoBegDelvOut'] = false
        disableEnableDict['btnCompDelv'] = false
        disableEnableDict['btnUndoCompDelvout'] = false
        // disable(thisObj, 'btnBeginDelivery')
        // disable(thisObj, 'btnUndoBegDelvOut')
        // disable(thisObj, 'btnCompDelv')
        // disable(thisObj, 'btnUndoCompDelvout')
      }

      if(getData(thisObj, 'mbVoidPermissionDeny')){
        disableEnableDict['btnVoid'] = false
        disableEnableDict['btnUnVoid'] = false
        // disable(thisObj, 'btnVoid')
        // disable(thisObj, 'btnUnVoid')
      }
      
      if(getData(thisObj, 'mbRedemptionMethodPermissionDeny') || getData(thisObj, 'txtStatusTag') !== 'I'){
        disableEnableDict['radioRedemptionMethod'] = false
        //disable(thisObj, 'radioRedemptionMethod') // optRMOrig1007s optRMReceiptValue optRMLoanPayoff OptUnshrunk
      }
      else if(!getData(thisObj, 'mbRedemptionMethodPermissionDeny') && getData(thisObj, 'txtStatusTag') == 'I'){
        disableEnableDict['radioRedemptionMethod'] = true
        // enable(thisObj, 'radioRedemptionMethod')
      }
      
      if(getData(thisObj, 'mbRepaymentOverrideDeny')){
        disableEnableDict['chkboxOverrideLRR'] = false
        disableEnableDict['txtLoanRepayRate'] = false
        // disable(thisObj, 'chkboxOverrideLRR')
        // disable(thisObj, 'txtLoanRepayRate')
      }
      
      if(!getData(thisObj, 'mbEWRAccepted')){
        disableEnableDict['btnPurchase'] = false
        disableEnableDict['btnUnPurchase'] = false
        disableEnableDict['btnVoid'] = false
        disableEnableDict['btnBeginDelivery'] = false
        disableEnableDict['btnCompDelv'] = false
        disableEnableDict['btnUndoBegDelvOut'] = false
        disableEnableDict['btnUndoCompDelvout'] = false
        // disable(thisObj, 'btnPurchase')
        // disable(thisObj, 'btnUnPurchase')
        // disable(thisObj, 'btnVoid')
        // disable(thisObj, 'btnBeginDelivery')
        // disable(thisObj, 'btnCompDelv')
        // disable(thisObj, 'btnUndoBegDelvOut')
        // disable(thisObj, 'btnUndoCompDelvout')
      }
      
      if(Number(cropYearFromLS()) >= 2010){
        disableEnableDict['txtLoanForfeitedDt'] = false
        //disable(thisObj, 'txtLoanForfeitedDt')
        hideShowControlDict['txtLoanForfeitedDt'] = true
        // show(thisObj, 'txtLoanForfeitedDt')
        //document.getElementById('txtLoanForfeitedDt').style.backgroundColor = 'white' // &H8000000F 

        disableEnableDict['txtCCCStrgPaidThruDt'] = false
        // disable(thisObj, 'txtCCCStrgPaidThruDt')
        hideShowControlDict['txtCCCStrgPaidThruDt'] = true
        // show(thisObj, 'txtCCCStrgPaidThruDt')
        //document.getElementById('txtCCCStrgPaidThruDt').style.backgroundColor = 'white' // &H8000000F 

        disableEnableDict['txtLoanAmt'] = false
        // disable(thisObj, 'txtLoanAmt')
        document.getElementById('txtLoanAmt').style.backgroundColor = 'white' // &H8000000F 

        disableEnableDict['txtLoanMaturityDt'] = false
        // disable(thisObj, 'txtLoanMaturityDt')
        //document.getElementById('txtLoanMaturityDt').style.backgroundColor = 'white' // &H8000000F 

        if(getData(thisObj, 'mbEnableLoanFieldsPermission')){
          hideShowControlDict['btnEnableLoanFields'] = true
          // show(thisObj,'btnEnableLoanFields')
          disableEnableDict['btnEnableLoanFields'] = true
          // enable(thisObj, 'btnEnableLoanFields')
        }
        else {
          hideShowControlDict['btnEnableLoanFields'] = false
          // hide(thisObj,'btnEnableLoanFields')
          disableEnableDict['btnEnableLoanFields'] = false
          // disable(thisObj, 'btnEnableLoanFields')
        }
      }
      else{
        hideShowControlDict['txtLoanForfeitedDt'] = false
        hideShowControlDict['txtCCCStrgPaidThruDt'] = false
        hideShowControlDict['btnEnableLoanFields'] = false
        // hide(thisObj, 'txtLoanForfeitedDt')
        // hide(thisObj, 'txtCCCStrgPaidThruDt')
        // hide(thisObj, 'btnEnableLoanFields')
      }

      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      
      document.getElementsByClassName('txtLoanForfeitedDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'black'
      document.getElementsByClassName('txtCCCStrgPaidThruDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'black'
      document.getElementById('txtLoanAmt').style.color = 'black'
      document.getElementsByClassName('txtLoanMaturityDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'black'

      let showWidgetList = []
      let hideWidgetList = []

      for(var [key, value] of Object.entries(hideShowControlDict)){
        if(value){
          showWidgetList.push(key)
        }
        else{
          hideWidgetList.push(key)
        }
      }
      
      showWidgets(thisObj, showWidgetList)
      hideWidgets(thisObj, hideWidgetList)

      let enableWidgetList = []
      let disableWidgetList = []

      for(var [key, value] of Object.entries(disableEnableDict)){
        if(value){
          enableWidgetList.push(key)
        }
        else{
          disableWidgetList.push(key)
        }
      }
      
      enableWidgets(thisObj, enableWidgetList)
      enableWidgets(thisObj, disableWidgetList, false)

      if(Number(cropYearFromLS()) > 2006){
        disable(thisObj, 'txtStrgCreditAmtOverride')
        disable(thisObj, 'txtStrgCreditAmtOverride')
      }
      console.log("Time taken for EnableDisableControls method: ", Math.abs(e1 - new Date()) / 1000)
    }
    catch(err){
      errorHandler(err, "EnableDisableControls")
    }
  }

  const DisplayWireTransfer = async(sVendor, sInfo) => {
    try{
      let sTempInfo = "";
      let sTemp = "";
      let n = 0;          
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      let objVal = getData(thisObj, 'objVal')
      let e1 = new Date()
      // hide(thisObj, 'txtWireTransferData1')
      // disable(thisObj, 'txtWireTransferData1')    
      objVal['txtWireTransferData1'] = ''

      // hide(thisObj, 'txtWireTransferData2')
      // disable(thisObj, 'txtWireTransferData2')    
      objVal['txtWireTransferData2'] = ''

      // hide(thisObj, 'txtWireTransferData3')
      // disable(thisObj, 'txtWireTransferData3')    
      objVal['txtWireTransferData3'] = '' 

      // hide(thisObj, 'txtWireTransferData4')
      // disable(thisObj, 'txtWireTransferData4')    
      objVal['txtWireTransferData4'] = ''

      // hide(thisObj, 'txtWireTransferData5')
      // disable(thisObj, 'txtWireTransferData5')    
      objVal['txtWireTransferData5'] = ''

      // hide(thisObj, 'txtWireTransferData6')
      // disable(thisObj, 'txtWireTransferData6')    
      objVal['txtWireTransferData6'] = ''

      // hide(thisObj, 'txtConcordia1') 
      // disable(thisObj, 'txtConcordia1')    
      objVal['txtConcordia1'] = '' 

      // hide(thisObj, 'txtConcordia2') 
      // disable(thisObj, 'txtConcordia2')    
      objVal['txtConcordia2'] = '' 

      // hide(thisObj, 'txtConcordia3') 
      // disable(thisObj, 'txtConcordia3')    
      objVal['txtConcordia3'] = ''

      hideShowControlDict['btnEnableLoanFields'] = false
      // hide(thisObj, 'btnEnableLoanFields')
      // hide(thisObj, 'txtWireTransfer') 
      // disable(thisObj, 'txtWireTransfer')    
      objVal['txtWireTransfer'] = ""
      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      let widgetsToHideShow = ['txtWireTransferData1', 'txtWireTransferData2', 'txtWireTransferData3', 'txtWireTransferData4',
        'txtWireTransferData5', 'txtWireTransferData6', 'txtConcordia1', 'txtConcordia2', 'txtConcordia3', 'txtWireTransfer', 'btnEnableLoanFields'
      ]
      hideWidgets(thisObj, widgetsToHideShow)
      enableWidgets(thisObj, widgetsToHideShow, false)

      if(await CheckForCCCVendor(sVendor)){
        if(sVendor == "134969"){
          // show(thisObj, 'txtWireTransferData1')
          // document.getElementById('txtWireTransferData1').style.backgroundColor = 'black'
          // show(thisObj, 'txtWireTransferData2')
          // document.getElementById('txtwiretransferdata2').style.backgroundColor = 'black'
          // show(thisObj, 'txtWireTransferData3')
          // document.getElementById('txtwiretransferdata3').style.backgroundColor = 'black'
          // show(thisObj, 'txtWireTransferData4')
          // document.getElementById('txtWireTransferData4').style.backgroundColor = 'black'
          // show(thisObj, 'txtWireTransferData5')
          // document.getElementById('txtWireTransferData5').style.backgroundColor = 'black'
          // show(thisObj, 'txtWireTransferData6')
          // document.getElementById('txtWireTransferData6').style.backgroundColor = 'black'
          widgetsToHideShow = [ 'txtWireTransferData1', 'txtWireTransferData2', 'txtWireTransferData3',
                                'txtWireTransferData4','txtWireTransferData5', 'txtWireTransferData6' ]
          showWidgets(thisObj, widgetsToHideShow)

          if(getData(thisObj, 'chkboxWireTransferBool')){
            enable(thisObj, 'txtWireTransferData4')
            enable(thisObj, 'txtWireTransferData6')
          }

          if(sInfo?.trim() !== ""){
            n = 0
            sTempInfo = sInfo?.concat("/")
            let sTempInfoSplit = sInfo?.trim()?.split("/")
            for(var i=0; i<sTempInfoSplit?.length; i++){
              sTemp = sTempInfoSplit[i]?.trim()
              
              switch(n){
                case 0:
                case 1:
                case 2:
                  objVal['txtWireTransferData1'] +=  sTemp?.concat("/") || ''
                  break;
                case 3:
                  if(sTemp?.length == 6){
                    objVal['txtWireTransferData2'] = sTemp?.slice(0,2) || ''
                    objVal['txtWireTransferData3'] = sTemp?.slice(2,5) || ''
                    objVal['txtWireTransferData4'] = sTemp?.slice(5,6) || ''
                  }
                  else{
                    alert("Incorrect wire transfer format for vendor " + sVendor)
                    objVal['txtWireXferData'] = sInfo
                    
                    setData(thisObj, 'objVal', objVal)
                    setFieldValues(thisObj, objVal, true)
                    return
                  }
                  break;
                case 4:
                  if(sTemp?.length == 8){
                    objVal['txtWireTransferData5'] = "/" + sTemp?.slice(0,4) || ''
                    objVal['txtWireTransferData6'] = sTemp?.slice(4,8) || ''
                  }
                  else{
                    alert("Incorrect wire transfer format for vendor " + sVendor)
                    objVal['txtWireXferData'] = sInfo
                    
                    setData(thisObj, 'objVal', objVal)
                    setFieldValues(thisObj, objVal, true)
                    return
                  }
                  break;
              }
              n++;
            }
          }
          else{
            objVal['txtWireTransferData1'] = "BNF=/AC-4992OBI=CCC/1/"
            objVal['txtWireTransferData2'] = Number(objVal['txtLnState']) ? objVal['txtLnState'] : "XX"
            objVal['txtWireTransferData3'] = Number(objVal['txtLnState']) ? objVal['txtLnCounty'] : "XXX"
            objVal['txtWireTransferData4'] = "X"
            objVal['txtWireTransferData5'] = "/SCH#"
            objVal['txtWireTransferData6'] = "XXXX"
          }

        }
        else if(sVendor == "745134"){
          // show(thisObj, 'txtConcordia1')
          // show(thisObj, 'txtConcordia2')
          // show(thisObj, 'txtConcordia3')
          widgetsToHideShow = [ 'txtConcordia1', 'txtConcordia2', 'txtConcordia3' ]
          showWidgets(thisObj, widgetsToHideShow)
          // document.getElementById('txtWireTransferData5').style.backgroundColor = 'black' // &H8000000B

          if(getData(thisObj, 'chkboxWireTransferBool')){
            enable(thisObj, 'txtConcordia1')
            enable(thisObj, 'txtConcordia3')
          }
          
          if(sInfo?.trim() !== ""){
              n = getData(thisObj, 'msWireXferDataSaved')?.indexOf("/") + 1
              if(n !== -1){
                sTemp = sInfo?.slice(0, n-1)                
                if(sTemp?.length == 5){
                  objVal['txtConcordia1'] = sTemp 
                  objVal['txtConcordia2'] = sInfo?.slice(n-1, n)
                  objVal['txtConcordia3'] = sInfo?.slice(n)
                }
                else{
                  alert(`Incorrect wire transfer format for vendor  ${sVendor}.`)
                  await ERROR_HANDLER(sVendor)
                }
              }
              else{
                alert(`Incorrect wire transfer format for vendor  ${sVendor}.`)
                await ERROR_HANDLER(sVendor)
              }
          }
          else{
            objVal['txtConcordia1'] = 'XXXXX'
            objVal['txtConcordia2'] = '/' 
            objVal['txtConcordia3'] = await GetCollectionInfomation(getData(thisObj, 'ViewWarehouseRecieptData')?.unlBuyingPtId)
          }
        }
      }
      else{
        hideShowControlDict['txtWireTransfer'] = true
        show(thisObj, 'txtWireTransfer')
        document.getElementById('txtWireTransfer').style.backgroundColor = '#e4e4e4' // &H80000005

        if(getData(thisObj, 'chkboxWireTransferBool')){
          enable(thisObj, 'txtWireTransfer')
        }

        objVal['txtWireTransfer'] = sInfo !== "" ? sTempInfo : ''
      }

      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
      setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
    }
    catch(err){
      errorHandler(err, "DisplayWireTransfer");
    }
  }

  const ERROR_HANDLER = async(sVendor) =>{
    try{
      let objVal = getData(thisObj, 'objVal')
      if(sVendor == "134969"){
        objVal['txtWireTransferData1'] = "BNF=/AC-4992OBI=CCC/1/"
        objVal['txtWireTransferData2'] = Number(objVal['txtLnState']) ? objVal['txtLnState'] : "XX"
        objVal['txtWireTransferData3'] = Number(objVal['txtLnCounty']) ? objVal['txtLnCounty'] : "XXX"
        objVal['txtWireTransferData4'] = "X"
        objVal['txtWireTransferData5'] = "/SCH#"
        objVal['txtWireTransferData6'] = "XXXX"
      }
      else if(sVendor == "745134"){
        objVal['txtConcordia1'] = 'XXXXX'
        objVal['txtConcordia2'] = '/'
        objVal['txtConcordia3'] = getData(thisObj, 'ViewWarehouseRecieptData')?.unlBuyingPtId
      }
      else{
        objVal['txtWireTransfer'] = ''
      }
      setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ERROR_HANDLER")
    }
  }

  const bCheckRTP = async() => {
    try{
      let LstrHTTP = ""
      let lstrxml = []
      // let nIndex = 0
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(getData(thisObj, 'msTent_cont_appl_ind') == "O" || getData(thisObj, 'lblRcptStatusTag') == "P"){
        lstrxml = await ContractManagementService.RetrieveBatchCheckReadyToPayDetails(null, null, null, data?.wrNum, data?.unlBuyingPtId, null)
        if(lstrxml?.status == 500){
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
          return
        }
        else{
          if(Number(lstrxml[0]?.record_count) > 0){
            LstrHTTP = ""
            for(var i=0; i<lstrxml[0]?.batch?.length; i++){
              LstrHTTP += lstrxml[0]?.batch[i].payee_vendor + " "
            }

            LstrHTTP = "Pending Batched Check: \nYou must release the pending payment to the following vendor(s) ... " + LstrHTTP
            LstrHTTP += "\nbefore the following function(s) can be allowed."

            if(getData(thisObj, 'msTent_cont_appl_ind') == "O"){
              disable(thisObj, 'cmdTentativeApplicationReverse')
              LstrHTTP += "\n- Reverse Tentative Applications"
            }

            if(getData(thisObj, 'lblRcptStatusTag') == "P"){
              disable(thisObj, 'cmdUnPurchase')
              LstrHTTP += "\n- Un-Purchase"
            }
            
            alert(LstrHTTP)
          }
        }

      }
      return true
    }
    catch(err){
      errorHandler(err, "bCheckRTP")
      return false
    }

  }

  const bFillRemitToList = async(vendorId) => {
    try{
      if([ undefined, null, '' ].includes(vendorId)){
        return
      }

      let objVal = getData(thisObj, 'objVal')
      
      let LstrList = await ContractManagementService.RetrieveVendorByNumber(vendorId)
      if(LstrList?.length > 0){
        objVal['txtRepayVendor'] = toPascalCase(LstrList[0].name)
        objVal['txtVndr'] = toPascalCase(LstrList[0].name)
        show(thisObj, 'txtRepayVendor')
        show(thisObj, 'txtVndr')
      }
      else{
        objVal['txtRepayVendor'] = ''
        objVal['txtVndr'] = ''
        hide(thisObj, 'txtRepayVendor', false)
        hide(thisObj, 'txtVndr', false)
      }
      
      LstrList = await ContractManagementService.RetieveRemitDetails(vendorId)
      
      let js = []

      LstrList?.forEach(item => {
        js.push({
          key  : item.remittoid,
          description : item.remittoid + " - " + toPascalCase(item.name) + " - " + toPascalCase(item.city) + ", " + toPascalCase(item.state)
        })
      })
      
      if(js?.length == 0){
        js = [ { key : '', description : '' } ]
      }
      
      thisObj.setState(current => {
        return {
          ...current,
          ddRepayRemit: {
            ...current["ddRepayRemit"],
            valueList: js
          }
        }
      })

      objVal['ddRepayRemit'] = js.at(0).key

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "bFillRemitToList")
    }
  }

  const CFillRemitToList = async(vendorId) => {
    try{
      if([ undefined, null, '' ].includes(vendorId)){
        return
      }
      
      let objVal = getData(thisObj, 'objVal')
      
      let LstrList = await ContractManagementService.RetrieveVendorByNumber(vendorId)
      if(LstrList?.length > 0){
        objVal['txtVndr'] = toPascalCase(LstrList[0].name)
        show(thisObj, 'txtVndr')
      }
      else{
        objVal['txtVndr'] = ''
        hide(thisObj, 'txtVndr', false)
      }

      LstrList = await ContractManagementService.RetieveRemitDetails(vendorId)
      let js = []

      if(LstrList?.length <= 0){
        return false
      }
      
      thisObj.setState(current => {
        return {
          ...current,
          ddRepayRemit: {
            ...current["ddRepayRemit"],
            valueList: []
          }
        }
      })
      objVal['ddRepayRemit'] = ''

      LstrList?.forEach(item => {
        js.push({
          key  : item.remittoid,
          description : item.remittoid + " - " + toPascalCase(item.name + " - " + item.city + ", " + item.state)
        })
      })
            
      if(js?.length == 0){
        js = [ { key : '', description : '' } ]
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddRemit: {
            ...current["ddRemit"],
            valueList: js
          }
        }
      })

      objVal['ddRemit'] = js.at(0).key

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      return true
    }
    catch(err){
      errorHandler(err, "CFillRemitToList")
      return false
    }
  }

  const bGetControlNumber = async() => {
    try{
      let strUserBuyPtID = ""
      let objVal = getData(thisObj, 'objVal')

      let LstrList = await ContractManagementService.RetrieveUserControlDetails(useridFromLS(), null, null, null)
      if(LstrList?.length > 0){
        strUserBuyPtID = LstrList[0].buyPtId
        LstrList = await SettlementService.RetrieveBuyingPointControlDetails('PN1090', null, null, null, strUserBuyPtID)
        if(LstrList?.length > 0){
          let val = '0'.repeat(6 - LstrList[0]?.next_whse_cntl?.toString()?.length) + LstrList[0]?.next_whse_cntl
          objVal['txtCtrlNum'] = val
        }
      }
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "bGetControlNumber")
    }
  }

  const GetCollectionInfomation = async(sBuyPt) => {
    try{
      let lstrxml = []
      let sCollPt = ""
      let objVal = getData(thisObj, 'objVal')
      
      let unloadId = objVal['txtObligationLocation']
      if(unloadId !== ""){
        lstrxml = await SettlementService.RetrieveBuyingPointControlDetails('PN1090', null, null, null, sBuyPt)
        if(lstrxml?.length > 0){
          sCollPt = lstrxml[0]?.collPtId

          lstrxml = await SettlementService.RetrieveBuyingPointControlDetails('PN1090', null, null, sCollPt, sCollPt)
          return lstrxml?.length > 0 ? lstrxml[0].physicalCity : ''
        }
      }
    }
    catch(err){
      errorHandler(err, "GetCollectionInfomation")
      return ''
    }
  }

  const bFormValid = async() => {
    try{
      let lstrxml = []
      let nDays = 0
      let X
      let LstrDflt = []
      let bFormValidBool = false
      let objVal = getData(thisObj, 'objVal')
      setData(thisObj, 'flg_cma_dma', false)

      if(Number(cropYearFromLS()) >= 2010){
        let loanMtDate = objVal['txtLoanMaturityDt']
        let loanDt = objVal['txtLoanMaturityDt']

        if(isEnabled(thisObj, 'txtLoanMaturityDt')){
          if(loanMtDate?.replace('/', '')?.trim() !== "" && loanDt?.replace('/', '')?.trim() !== ""){
            if(new Date(loanMtDate) < new Date(loanDt)){
              alert("Loan Maturity Date can not be less than Loan Date")
              // document.getElementById('txtLoanMaturityDt').focus()
              objVal['txtLoanMaturityDt'] = ''
              return              
            }
          }
        }

        if(loanDt?.replace("/", "")?.trim() !== "" && loanMtDate?.replace("/", "")?.trim() !== ""){
          if(new Date(loanMtDate) < new Date(loanDt)){
            alert("Loan Date cannot be greater than Loan Maturity Date ")
            // document.getElementById('txtLoanDt').focus()
            objVal['txtLoanDt'] = ''
            return          
          }
        }
      }
      else{
        let loanMtDate = objVal['txtLoanMaturityDt']
        let loanDt = objVal['txtLoanDt']

        if(loanMtDate?.replace("/", '')?.trim() !== ''){
          if(new Date(loanMtDate) < new Date(loanDt)){
            alert("Loan Maturity Date can not be less than Loan Date")
            // document.getElementById('txtLoanMaturityDt').focus()
            objVal['txtLoanMaturityDt'] = ''
            return 
          }
        }
      }

      let txtLnState = objVal['txtLnState']
      let txtLnCounty = objVal['txtLnCounty']
      let txtLnNumber = objVal['txtLnNumber']

      if(txtLnState?.trim()?.length !== 0 || txtLnCounty?.trim()?.length !== 0 || txtLnNumber?.trim()?.length !== 0){
        if(txtLnState?.trim()?.length !== 2){
          alert("The Length of the Loan State field has to be 2 digits.")
          document.getElementById("txtLnState").focus();
          return;
        }
        if(txtLnCounty?.trim()?.length !== 3){
          alert("The Length of the Loan County field has to be 3 digits.")
          document.getElementById("txtLnCounty").focus();
          return;
        }
        if(txtLnNumber?.trim()?.length !== 5){
          alert("The Length of the Loan Number field has to be 5 digits.")
          document.getElementById("txtLnNumber").focus();
          return;
        }
      }
      else{
        if((objVal['chkboxWireTransfer'])){
          alert("Loan number must be entered on wire transfers.")
          objVal['chkboxWireTransfer'] = 0
          document.getElementById('txtLnState').focus()
          return;
        }
      }

      if((objVal['chkboxDMA']) || (objVal['chkboxDMA'])){
        setData(thisObj, 'flg_cma_dma', true)
      }

      if(txtLnState?.trim() !== "" && txtLnCounty?.trim() !== ""){
        lstrxml = await WarehouseReceiptService.RetrieveWareHouseCheckStateCounty(txtLnState?.trim(), txtLnCounty?.trim(), compIdFromLS(), cropYearFromLS(), getData(thisObj, 'flg_cma_dma'))
        if(lstrxml?.message == "False"){
          document.getElementById('txtLnState').focus()
          return
        }
      }

      let prodBeginDate = objVal['txtProducerBeginDate']
      if(prodBeginDate?.replace('/', '')?.trim() !== ""){
        if(!new Date(prodBeginDate) instanceof Date){
          alert("Producer Begin Date not valid date.")
          // document.getElementById('txtProducerBeginDate').focus()
          objVal['txtProducerBeginDate'] = ''
          return;
        }
      }

      if(Number(cropYearFromLS()) >= 2010){
        let loanMtDate = objVal['txtLoanMaturityDt']
        if(isEnabled(thisObj, 'txtLoanMaturityDt')){
          if(loanMtDate?.replace("/", "")?.trim() !== ""){
            if(!new Date(loanMtDate) instanceof Date){
              alert("Loan Maturity Date not valid date.")
              // document.getElementById('txtLoanMaturityDt').focus()
              objVal['txtLoanMaturityDt'] = ''
              return;
            }
          }
        }

        let loanForfeitDate = objVal['txtLoanForfeitedDt']
        if(isEnabled(thisObj, 'txtLoanForfeitedDt')){
          if(loanForfeitDate?.replace("/", "")?.trim() !== ""){
            if(!new Date(loanForfeitDate) instanceof Date){
              alert("Loan Forfeited Date not valid date.")
              // document.getElementById('txtLoanForfeitedDt').focus()
              objVal['txtLoanForfeitedDt'] = ''
              return;
            }
          }
        }

        let cccStrgPaidThruDate = objVal['txtCCCStrgPaidThruDt']
        if(isEnabled(thisObj, 'txtCCCStrgPaidThruDt')){
          if(cccStrgPaidThruDate?.replace("/", "")?.trim() !== ""){
            if(!new Date(cccStrgPaidThruDate) instanceof Date){
              alert("CCC Store Paid Through Date not valid date.")
              // document.getElementById('txtCCCStrgPaidThruDt').focus()
              objVal['txtCCCStrgPaidThruDt'] = ''
              return;
            }
          }
        }
      }
      else{
        let loanMtDate = objVal['txtLoanMaturityDt']
        if(loanMtDate?.replace("/", "")?.trim() !== ""){
          if(!new Date(loanMtDate) instanceof Date){
            alert("Loan Maturity Date not valid date.")
            // document.getElementById('txtLoanMaturityDt').focus()
            objVal['txtLoanMaturityDt'] = ''
            return;
          }
        }
      }

      prodBeginDate = objVal['txtProducerBeginDate']
      if(new Date(prodBeginDate) instanceof Date){
        if(Number(new Date(prodBeginDate).getFullYear()) < 2000){
          alert("Invalid Producer Begin Date")
          if(isEnabled(thisObj, 'txtProducerBeginDate')){
            // document.getElementById('txtProducerBeginDate').focus()
          }
          return
        }
      }

      prodBeginDate = objVal['txtProducerBeginDate']
      if(new Date(prodBeginDate) instanceof Date){
        if(new Date(prodBeginDate) < new Date(objVal['txtIssuedDate'])){
          if((new Date(prodBeginDate).getDate() - new Date(objVal['txtIssuedDate']).getDate()) > 180 ){
            X = confirm("Producer Begin Date is less than the Issued Date. Do you want to continue?")
            if(X == null){
              if(isEnabled(thisObj, 'txtProducerBeginDate')){
                // document.getElementById('txtProducerBeginDate').focus()
                objVal['txtProducerBeginDate'] = ''
              }
              return
            }
          }
        }
      }

      let txtLnRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
      if(txtLnRepayAmtOverride?.trim() !== ""){
        if(isNumeric(txtLnRepayAmtOverride)){
          if(Number(txtLnRepayAmtOverride) > 999999999.99 || Number(txtLnRepayAmtOverride) < 0){
            alert('Repayment Amt must be of format #########.## .')
            document.getElementById('txtLoanRepayAmtOverride').focus()
            objVal['txtLoanRepayAmtOverride'] = ''
            return
          }
        }
        else{
          alert('Repayment Amt must be of format #########.## .')
          document.getElementById('txtLoanRepayAmtOverride').focus()
          objVal['txtLoanRepayAmtOverride'] = ''
          return
        }
      }

      let txtStCreditAmtOverride = objVal['txtStrgCreditAmtOverride']
      if(txtStCreditAmtOverride?.trim() !== ""){
        if(!isNaN(txtStCreditAmtOverride)){
          if(Number(txtStCreditAmtOverride) > 999999999.99 || Number(txtStCreditAmtOverride) < 0){
            alert('Storage Credit Amt must be of format #########.## .')
            document.getElementById('txtStrgCreditAmtOverride').focus()
            objVal['txtStrgCreditAmtOverride'] = ''
            return
          }
        }
        else{
          alert('Storage Credit Amt must be of format #########.## .')
          document.getElementById('txtStrgCreditAmtOverride').focus()
          objVal['txtStrgCreditAmtOverride'] = ''
          return
        }
      }

      let txtInsAmt = objVal['txtInterestAmtOverride']
      if(txtInsAmt?.trim() !== ""){
        if(isNumeric(txtInsAmt)){
          if(Number(txtInsAmt) > 999999999.99 || Number(txtInsAmt) < 0){
            alert('Interest Amt must be of format #########.## .')
            document.getElementById('txtInterestAmtOverride').focus()
            objVal['txtInterestAmtOverride'] = ''
            return
          }
        }
        else{
          alert('Interest Amt must be of format #########.## .')
          document.getElementById('txtInterestAmtOverride').focus()
          objVal['txtInterestAmtOverride'] = ''
          return
        }
      }

      let txtHandlingAmt = objVal['txtHandlingAmtOverride']
      if(txtHandlingAmt?.trim() !== ""){
        if(isNumeric(txtHandlingAmt)){
          if(Number(txtHandlingAmt) > 999999999.99 || Number(txtHandlingAmt) < 0){
            alert('Handling Amt must be of format #########.## .')
            document.getElementById('txtHandlingAmtOverride').focus()
            objVal['txtHandlingAmtOverride'] = ''
            return
          }
        }
        else{
          alert('Handling Amt must be of format #########.## .')
          document.getElementById('txtHandlingAmtOverride').focus()
          objVal['txtHandlingAmtOverride'] = ''
          return
        }
      }

      let lockIn = objVal['txtLnRpyLockInDt']
      if(lockIn?.replace('/', '')?.trim() !== ''){
        if(!new Date(lockIn) instanceof Date){
          alert('Lock In Date not valid date.')
          // document.getElementById('txtLnRpyLockInDt').focus()
          objVal['txtLnRpyLockInDt'] = ''
          return
        }        
      }

      let cccBeginDate = objVal['txtCCCBeginDate']
      if(cccBeginDate?.replace('/', '')?.trim() !== ''){
        if(!new Date(objVal['txtCCCBeginDate']) instanceof Date){
          alert('CCC Begin Date not valid date.')
          // document.getElementById('txtCCCBeginDate').focus()
          objVal['txtCCCBeginDate'] = ''
          return
        }
        else{
          if(new Date(cccBeginDate) > new Date()){
            if(process.env.REACT_APP_ENVIR?.toLocaleUpperCase() !== 'PROD'){
              LstrDflt = await ContractManagementService.RetrievePeanutStaticValues()
              if(LstrDflt[0].pps_defaults.bypass_curr_date_edit == "TRUE"){
                alert("CCC Begin Date cannot be in future.")
                // document.getElementById('txtCCCBeginDate').focus()
                objVal['txtCCCBeginDate'] = ''
                return
              }
            }
          }
        }       
      }

      cccBeginDate = objVal['txtCCCBeginDate']
      if(new Date(cccBeginDate) instanceof Date){
        if(Number(new Date(cccBeginDate).getFullYear()) < 2000){
          alert("Invalid CCC date")
          if(isEnabled(thisObj, 'txtCCCBeginDate'))
            // document.getElementById('txtCCCBeginDate').focus()
          return          
        }
      }

      cccBeginDate = objVal['txtCCCBeginDate']
      if(new Date(cccBeginDate) instanceof Date){
        if(new Date(cccBeginDate) < new Date(objVal['txtIssuedDate'])){
          X = confirm("CCC Begin Date is less than the Issued Date. Do you want to continue?")
          if(X == null){
            if(isEnabled(thisObj, 'txtCCCBeginDate')){
              // document.getElementById(thisObj, 'txtCCCBeginDate').focus()
              objVal['txtCCCBeginDate'] = ''
            }
            return;
          }
        }
      }

      let endDate = objVal['txtEndDate']
      if(endDate?.replace("/", "")?.trim() !== ""){
        if(!new Date(endDate) instanceof Date){
          alert("End Date not valid date.")
          // document.getElementById('txtEndDate').focus()
          objVal['txtEndDate'] = ''
          return;
        }
        else{
          nDays = 5
          lstrxml = await ContractManagementService.RetrievePeanutStaticValues()
          if(lstrxml?.length > 0){
            if(isNumeric(lstrxml[0].pps_defaults.wr_max_end_days)){
              nDays = lstrxml[0].pps_defaults.wr_max_end_days
            }            
          }

          var d = new Date()
          if(new Date(endDate) > new Date(d.setDate(d.getDate() + nDays))){
            alert("End Date cannot be more than " + nDays + " days in future.")
            // document.getElementById('txtEndDate').focus()
            objVal['txtEndDate'] = ''
            return
          }

          cccBeginDate = objVal['txtCCCBeginDate']
          if(new Date() instanceof Date){
            if(new Date(cccBeginDate) > new Date(objVal['txtEndDate'])){
              alert("CCC Begin Date cannot be greater than End Date.")
              // document.getElementById('txtEndDate').focus()
              objVal['txtEndDate'] = ''
              return
            }
          }
        }
      }
      // ???????

      let delvDate = objVal['txtDocDelvDt']
      if(delvDate?.replace("/", "")?.trim() !== ""){
        if(new Date(delvDate) > new Date()){
          alert("Document Delivered Date must be less than or equal to Today's date")
          // document.getElementById('txtDocDelvDt').focus()
          objVal['txtDocDelvDt'] = ''
          return
        }

        let endDate = objVal['txtEndDate']
        if(endDate?.replace("/", "")?.trim() == ""){
          alert('Must enter an End Date when Document Delivered Date exists.')
          if(isEnabled(thisObj, 'txtEndDate')){
            // document.getElementById('txtEndDate').focus()
            objVal['txtEndDate'] = ''
          }
          return
        }
      }

      let docDelvDate = objVal['txtDocDelvDt']
      if(docDelvDate?.replace("/", "")?.trim() !== ""){
        if(docDelvDate instanceof Date){
          alert('Document Delivered Date not valid date.')
          // document.getElementById('txtDocDelvDt').focus()
          objVal['txtDocDelvDt'] = ''
          return
        }        
      }

      docDelvDate = objVal['txtDocDelvDt']
      if(docDelvDate instanceof Date){
        if(Number(new Date(docDelvDate).getFullYear()) < 2000){
          alert("Invalid Doc Delv date")
          if(isEnabled(thisObj, 'txtDocDelvDt')){
            // document.getElementById('txtDocDelvDt').focus()
          }            
          return
        }
      }

      docDelvDate = objVal['txtDocDelvDt']
      if(docDelvDate instanceof Date){
        if(new Date(docDelvDate) < new Date(docDelvDate)){
          X = confirm("Document Delv. Date is less than the Issued Date. Do you want to continue?")
          if(X == null){
            if(isEnabled(thisObj, 'txtDocDelvDt')){
              // document.getElementById('txtDocDelvDt').focus()
              objVal['txtDocDelvDt'] = ''
            }
            return
          }
        }
      }

      docDelvDate = objVal['txtDocDelvDt']
      if(getData(thisObj, 'mbLoadOutsExist')){
        if(new Date(docDelvDate) > new Date(getData(thisObj, 'msLoadOutMinDate'))){
          alert("Document Delivered Date must be less than all load out dates.")
          // document.getElementById('txtDocDelvDt').focus()
          objVal['txtDocDelvDt'] = ''
          return
        }
      }
      else{
        if(getData(thisObj, 'txtStatusTag') == "L" || getData(thisObj, 'txtStatusTag') == "D"){
          if(docDelvDate?.replace("/", "")?.trim() == ""){
            alert("Document Delivered Date required while delivery is in process or completed.")
            // document.getElementById('txtDocDelvDt').focus()
            objVal['txtDocDelvDt'] = ''
            return
          }
        }
      }

      let delvCompleteDate = objVal['txtDelvCompletedDt']
      if(delvCompleteDate?.replace('/', '')?.trim() !== ""){
        if(!(new Date(delvCompleteDate) instanceof Date)){
          alert("Delivery Complete Date not valid date.")
          // document.getElementById(thisObj, 'txtDelvCompletedDt').focus()
          objVal['txtDelvCompletedDt'] = ''
          return
        }

        if(new Date(delvCompleteDate) instanceof Date){
          if(new Date(delvCompleteDate) < new Date(objVal['txtIssuedDate'])){
            X = confirm("Delivery Complete Date is less than the Issued Date. Do you want to continue?")
            if(X == null){
              if(isEnabled(thisObj, 'txtDelvCompletedDt')){
                // document.getElementById('txtDelvCompletedDt').focus()
                objVal['txtDelvCompletedDt'] = ''
              }
              return
            }
          }
        }

        if(getData(thisObj, 'mbLoadOutsExist')){
          if(new Date(delvCompleteDate) < new Date(getData(thisObj, 'msLoadOutMaxDate'))){
            alert("Delivery Date must be greater than all load out dates.")
            // document.getElementById('txtDelvCompletedDt').focus()
            objVal['txtDelvCompletedDt'] = ''
            return
          }
        }
      }
      else{
        if(getData(thisObj, 'txtStatusTag') == "D"){
          alert("Delivery Complete Date required when delivery is complete.")
          // document.getElementById('txtDelvCompletedDt').focus()
          objVal['txtDelvCompletedDt'] = ''
          return
        }
      }
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      bFormValidBool = true
      return bFormValidBool
    }
    catch(err){
      errorHandler(err, "bFormValid")
      return false
    }
  }

  const CheckForCCCVendor = async(sVendor) => {
    try{
      let n = 0;
      let v = "";
      
      if(sVendor?.toString()?.trim() == ""){
        return
      }

      let ccVendorList = getData(thisObj, 'msCCCVendorList')?.split("|")
      for(var i=0; i<ccVendorList?.length; i++){
        v = ccVendorList[i]?.trim()
        if(v == sVendor?.toString()?.trim()){
          return true
        }
        n++;
      }
      return false
    }
    catch(err){
      errorHandler(err, "CheckForCCCVendor")
      return false
    }
  }

  const BuildWireTransfer = async(sVendor) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      if(sVendor == ""){
        return objVal['txtWireTransfer']?.trim()
      }
      else{
        if(await CheckForCCCVendor(sVendor)){
          if(objVal['txtRepayVendorNum'] == "134969"){
            return objVal['txtWireTransferData1']?.trim() + objVal['txtWireTransferData2']?.trim() + objVal['txtWireTransferData3']?.trim() + 
                   objVal['txtWireTransferData4']?.trim() + objVal['txtWireTransferData5']?.trim() + objVal['txtWireTransferData6']?.trim()            
          }
          else if(objVal['txtRepayVendorNum'] == "745134"){
            return objVal['txtConcordia1']?.trim() +   objVal['txtConcordia2']?.trim() +  objVal['txtConcordia3']?.trim()
          }
          else{
            return objVal['txtWireTransfer']?.trim() 
          }
        }
      }
    }
    catch(err){
      errorHandler(err, "BuildWireTransfer")
    }
  }

  const CalculateSeg = async() => { 
    try{
      let arrContractLine = ""
      let arr1007Line = ""
      let nListIndex = 0
      let dblPurchTotalWeights = 0
      let dblPurchPriceTimesWt = 0
      let dblWeightedAverage = 0
      let dblVendorPurchSeg = 0
      let lstrxml = []
      let LstrXMLApps = []
      let LstrXMLApps2 = []
      let LstrInspContApps = ""
      let LstrInspContApps2 = ""
      let dblLoanPrice = 0
      let lngSpotPounds = 0
      let dblSpotPrice = 0
      let lngPounds = 0
      let lPoundsToDistrubute = 0
      let sItemData = ""
      let sKey = ""
      let bRcptTimeAppl = false
      let sPayoffInd = ""
      let sHoldInspCont = ""
      let mcol1007 = []
      let mcol1007Contracts = []
      let mcolContracts = []
      
      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      
      setData(thisObj, 'mcol1007s', [])
      setData(thisObj, 'mcol1007Contracts', [])
      objVal['txtSpotPounds'] = 0

      let optRMLoanPayoff = objVal['radioRedemptionMethod']
      if(optRMLoanPayoff == '1'){
        sPayoffInd = 'L'
      }
      if(optRMLoanPayoff == '2'){
        sPayoffInd = 'O'
      }
      if(optRMLoanPayoff == '3'){
        sPayoffInd = 'R'
      }
      if(optRMLoanPayoff == '4'){
        sPayoffInd = 'U'
      }

      let receiptValue = objVal['txtOriginalValue']
      let loanRateDate = await GetLoanRateDate()
      loanRateDate = [ undefined, null, ''].includes(loanRateDate) ? '' : loanRateDate?.split(" ")[0]
      
      LstrXMLApps = await WarehouseReceiptService.RetrievecalCulatePurchaseValuePreAppliedDetails(data?.unlBuyingPtId, data?.wrNum, 'R', null, null, loanRateDate, receiptValue)
      if(LstrXMLApps?.status == 500){
        return
      }
      
      LstrInspContApps = LstrXMLApps
      
      let gridData = objVal['grid1007s']
      gridData?.forEach(data => {
        mcolContracts = []

        if(Number(data?.txtcolContract) == 0){
          bRcptTimeAppl = true

          if(LstrInspContApps?.length != 0){
            LstrInspContApps?.forEach(item => {
              if(data?.txtcolSC95Num == item?.insp_num){
                sKey = data?.txtcolSC95Num + item?.contract_num
                
                if(data?.txtcolSeedMeetSpecInd == 'Y'){
                  if(mcol1007s?.length == 0){
                    mcol1007s.push(data?.txtcolSC95Num + "|Y|" + item?.pounds + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey
                    mcol1007Contracts.push(mcol1007s) // ,sKey
                  }
                  else{
                    mcol1007s.push(data?.txtcolSC95Num + "|Y|" + item?.pounds + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey, 1
                    mcol1007Contracts.push(mcol1007s) // ,sKey, 1
                  }
                }
                else{
                  if(mcol1007s?.length == 0){
                    mcol1007s.push(data?.txtcolSC95Num + "|N|" + item?.pounds + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey
                    mcol1007Contracts.push(mcol1007s) // ,sKey
                  }
                  else{
                    mcol1007s.push(data?.txtcolSC95Num + "|N|" + item?.pounds + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey, , mcol1007s.Count
                    mcol1007Contracts.push(mcol1007s) // ,sKey, mcol1007Contracts.Count
                  }
                }
              }
            })
          }
          else{
            sKey = data?.txtcolSC95Num
            
            if(data?.txtcolSeedMeetSpecInd == 'Y'){
              if(mcol1007s?.length == 0){
                mcol1007s.push(data?.txtcolSC95Num + "|Y|" + data?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey
                mcol1007Contracts.push(mcol1007s) // ,sKey
              }
              else{
                mcol1007s.push(data?.txtcolSC95Num + "|Y|" + item?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey, 1
                mcol1007Contracts.push(mcol1007s) // ,sKey, 1
              }
            }
            else{
              if(mcol1007s?.length == 0){
                mcol1007s.push(data?.txtcolSC95Num + "|N|" + item?.txtcolNetWeight + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey
                mcol1007Contracts.push(mcol1007s) // ,sKey
              }
              else{
                mcol1007s.push(data?.txtcolSC95Num + "|N|" + item?.txtcolNetWeight + "|" + item?.basis_grade_amt + "|" + sKey) // ,sKey, , mcol1007s.Count
                mcol1007Contracts.push(mcol1007s) // ,sKey, mcol1007Contracts.Count
              }
            }
          }
        }
        else{
          bRcptTimeAppl = false
          sKey = data?.txtcolSC95Num + data?.txtcolContract
          
          if(data?.txtcolSeedMeetSpecInd == 'Y'){
            if(mcol1007s?.length == 0){
              mcol1007s.push(data?.txtcolSC95Num + "|Y|" + data?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey
              mcol1007Contracts.push(mcol1007s) // ,sKey
            }
            else{
              mcol1007s.push(data?.txtcolSC95Num + "|Y|" + data?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey, 1
              mcol1007Contracts.push(mcol1007s) // ,sKey, 1
            }
          }
          else{
            if(mcol1007s?.length == 0){
              mcol1007s.push(data?.txtcolSC95Num + "|N|" + data?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey
              mcol1007Contracts.push(mcol1007s) // ,sKey
            }
            else{
              mcol1007s.push(data?.txtcolSC95Num + "|N|" + data?.txtcolNetWeight + "|" + data?.txtcolValue + "|" + sKey) // ,sKey, , mcol1007s.Count
              mcol1007Contracts.push(mcol1007s) // ,sKey, mcol1007Contracts.Count
            }
          }
        }
      })

      setData(thisObj, 'mcol1007s', mcol1007s)
      setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
      setData(thisObj, 'mcolContracts', mcolContracts)

      let lstNewContLine = getData(thisObj, 'lstNewContractLine')
      
      for(var i=0; i<lstNewContLine?.length; i++){
        arrContractLine = lstNewContLine[i]?.split("|")
        lPoundsToDistrubute = arrContractLine[2]

        if(arrContractLine[0] !== 'SPOT'){
          if(arrContractLine[5] == 'Y'){
            for(var j=0; j< mcol1007s?.length; j++){
              
              lngPounds = 0
              arr1007Line = mcol1007s[j]?.split("|")
              
              for(var x = 0; x< mcol1007Contracts[j]?.length; x++){
                lngPounds += Number(mcol1007Contracts[x][0]?.toString()?.split("|")[2])
              }

              if(Number(lngPounds) < Number(arr1007Line[2])){
                if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                  mcolContracts = []

                  if(arrContractLine[1] == "Basis"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]))
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else{
                    mcolContracts.push(arrContractLine[0] + "|F|" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]))
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                }
                else{
                  mcolContracts = []
                  if(arrContractLine[1] == "Basis"){
                    if(getData(thisObj, 'msMode') == "TENTATIVE_APPLICATION"){
                      mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    }
                    else{
                      mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    }

                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else{
                    mcolContracts.push(arrContractLine[0] + "|F|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }

                }
              }

              if(Number(lPoundsToDistrubute) == 0){
                break
              }

            }
          }
          else{
            for(var j=mcol1007s?.length - 1; j>=0 ; j--){
              lngPounds = 0
              arr1007Line = mcol1007s[j]?.split("|")
              
              for(var x = 0; x< mcol1007Contracts[j+1]?.length; x++){
                lngPounds += Number(mcol1007Contracts[j+1][x]?.split("|")[2])
              }
              
              if(Number(lngPounds) < Number(arr1007Line[2])){
                if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                  mcolContracts = []

                  if(arrContractLine[1] == "Basis"){
                    if(getData(thisObj, 'msMode') == "TENTATIVE_APPLICATION"){
                      mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]))
                    }
                    else{
                      mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]))
                    }
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else{
                    mcolContracts.push(arrContractLine[0] + "|F|" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]))
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }

                }
                else{
                  mcolContracts = []
                  if(arrContractLine[1] == "Basis"){
                    if(getData(thisObj, 'msMode') == "TENTATIVE_APPLICATION"){
                      mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    }
                    else{
                      mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    }

                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else{
                    mcolContracts.push(arrContractLine[0] + "|F|" + Number(Number(arr1007Line[2]) - Number(lngPounds)) + "|" + arrContractLine[4])
                    mcol1007Contracts.push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                }
              }

              if(Number(lPoundsToDistrubute) == 0){
                break
              }
            }
          }
        }
        else{
          for(var j=0; j<mcol1007s?.length; j++){
            lngPounds = 0
            arr1007Line = mcol1007s[j]?.split("|")
            
            for(var x = 0; x< mcol1007Contracts?.length; x++){
              lngPounds += Number(mcol1007Contracts[x]?.split("|")[2])
            }

            let spotPrice = Number(arrContractLine(4)).toFixed(4)
            objVal['txtSpotPrice'] = spotPrice
            
            if(Number(lngPounds) < Number(arr1007Line[2])){
              if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                mcolContracts = []
                mcolContracts.push(arrContractLine[0] + "||" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                mcol1007Contracts.push(mcolContracts)
                objVal['txtSpotPrice'] = Number(spotPrice) + Number(lPoundsToDistrubute)
                lPoundsToDistrubute = 0
              }
              else{
                mcolContracts = []
                mcolContracts.push(arrContractLine[0] + "||" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])
                mcol1007Contracts.push(mcolContracts)
                objVal['txtSpotPrice'] = Number(spotPrice) + Number(Number(Number(arr1007Line[2]) - Number(lngPounds)))
                lPoundsToDistrubute -= (Number(Number(arr1007Line[2]) - Number(lngPounds)))
              }
            }

            if(Number(lPoundsToDistrubute) == 0){
              break
            }
          }
        }
      }
      
      let dblPctOf1007Load = 0
      let dblPctBasisGradeAmt = 0
      let dblPctOfLoad = 0
      let dblPctLoanRepay = 0
      let dblPctLoanValue = 0
      let dblLoanRepayRate = 0
      let dblFirmPrice = 0
      let dblMarketGain = 0
      let dblLoanRepayTimesMarketGain = 0
      let dblPctSupport = 0
      let dblBasisValue = 0
      let dblBasisPrice = 0
      let dbl1007BGAValue = 0
      let dbl1007Pounds  = 0
      let dbl1007ContractPounds  = 0
      let dbl1007ContractPurchPrice  = 0
      let dbl1007TotalBGA = 0
      let dblValueOfSeg = 0
      let dblValuePerTon = 0
      let dblTotPctSupport = 0
      let dblBasisGradePerTon = 0
      let dblBasisGradeTimesMarketGain = 0
      let dblPctRcptValue = 0
      let dblRcptValueTimesMarketGain = 0
      let dblFinalSupportPct = 0
      let dblFarmerStockCost = 0
      let dblTotValueofSeg = 0
      let dblTot1007BGAValue = 0

      sHoldInspCont = ""

      for(var i=0; i<mcol1007s?.length; i++){
        if(bRcptTimeAppl){
          if(sHoldInspCont !== mcol1007s[i]?.split('|')[4]){
            dbl1007TotalBGA += Number(mcol1007s[i]?.split('|')[3] || '')
            sHoldInspCont = mcol1007s[i]?.split('|')[4]
          }
        }
        else{
          dbl1007TotalBGA += Number(mcol1007s[i]?.split('|')[3])
        }
      }
      
      mcolFinal1007List = []

      let redemptionMethod = objVal['radioRedemptionMethod']
      
      if(redemptionMethod == "1"){
        switch(getData(thisObj, 'msTent_cont_appl_ind')){
          case "Y":
          case "N":
            for(var i=0; i<mcol1007s?.length; i++){
              dbl1007BGAValue = Number(mcol1007s[i]?.split('|')[3] || '')
              dbl1007Pounds = Number(mcol1007s[i]?.split('|')[2] || '')
              mcolContracts = mcol1007Contracts[i] || []
              
              let txtRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
              let txtLoanAmt = objVal['txtLoanAmt']
              let loanRepayRate = objVal['txtLoanRepayRate']
              mcolContracts?.forEach(mcolContracts2 => {
                dbl1007ContractPounds = Number(mcolContracts2?.split("|")[2] || '')
                dblPctOf1007Load = Number(Number(dbl1007ContractPounds) / Number(dbl1007Pounds) || 0)
                dblPctBasisGradeAmt = Number(dbl1007BGAValue) * Number(dblPctOf1007Load)
                dblPctOfLoad = Number(dblPctBasisGradeAmt) / Number(dbl1007TotalBGA)
                dblPctLoanRepay = Number(dblPctOfLoad) * Number(txtRepayAmtOverride)
                dblPctLoanValue = Number(dblPctOfLoad) * Number(txtLoanAmt)

                if(mcolContracts2?.split('|')[1] == 'F' || mcolContracts2?.split('|')[0] == 'SPOT'){
                  dblFirmPrice = mcolContracts2?.split('|')[2]
                  dblMarketGain = 0
                  dblLoanRepayTimesMarketGain = 0
                  dblPctSupport = (Number(dblFirmPrice || 0) * 100) / Number(getData(thisObj, 'mdblSupportPerTon'))
                  dblBasisValue = 0
                  dblValueOfSeg += Number(Number(dblPctLoanValue || 0) * Number(dblPctSupport || 0) / 100)?.toFixed(2)
                }
                else if(mcolContracts2?.split('|')[1] == 'B'){
                  dblBasisPrice = mcolContracts2?.split('|')[2]
                  if(getData(thisObj, 'mdblSupportPerTon') < (loanRepayRate)){
                    dblMarketGain = 1
                  }
                  else{
                    dblMarketGain = Number(Number(loanRepayRate) / Number(getData(thisObj, 'mdblSupportPerTon'))).toFixed(4)

                    dblLoanRepayTimesMarketGain = Number(dblPctLoanRepay) * Number(dblMarketGain)
                    dblPctSupport = Number(Number(dblBasisPrice) / Number(getData(thisObj, 'mdblSupportPerTon'))).toFixed(2)
                    dblBasisValue = Number(dblPctSupport) * Number(dblPctBasisGradeAmt)
                    dblValueOfSeg += Number(Number(dblPctLoanRepay) + Number(dblBasisValue)).toFixed(2)
                  }
                }

              })
              
              dblFinalSupportPct = Number(Number(dblValueOfSeg) / Number(dbl1007BGAValue) * 100)?.toFixed(2)
              dblValuePerTon = Number(Number(getData(thisObj, 'mdblSupportPerTon')) * (Number(dblFinalSupportPct) * 0.01))?.toFixed(2)
              mcolFinal1007List.push(mcol1007s[i] + "|" + dblValuePerTon + "|" + dblFinalSupportPct + "|" + Number(dblValueOfSeg)?.toFixed(2)) + "|" + Number(dblValueOfSeg)?.toFixed(2)
              dblValueOfSeg = 0
              dblValuePerTon = 0
              dblTotPctSupport = 0
            }
            setData(thisObj, 'mcolFinal1007List', mcolFinal1007List)
            break;

          case "I":
          case "O":
            await CalculatePurchaseValueByLoanRepayment()
            break
        }
      }
      else if(redemptionMethod == "4"){
        switch(getData(thisObj, 'msTent_cont_appl_ind')){
          case "Y":
          case "N":
            for(var i=0; i<mcol1007s?.length; i++){
              dbl1007BGAValue = Number(mcol1007s[i]?.split('|')[3] || '')
              dbl1007Pounds = Number(mcol1007s[i]?.split('|')[2] || '')
              mcolContracts = mcol1007Contracts[i] || []
              
              let txtRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
              let txtLoanAmt = objVal['txtLoanAmt']
              let loanRepayRate = objVal['txtLoanRepayRate']
              mcolContracts?.forEach(mcolContracts2 => {
                dbl1007ContractPounds = Number(mcolContracts2?.split("|")[2] || '')
                dblPctOf1007Load = Number(Number(dbl1007ContractPounds) / Number(dbl1007Pounds) || 0)
                dblPctBasisGradeAmt = Number(dbl1007BGAValue) * Number(dblPctOf1007Load)
                dblPctOfLoad = Number(dblPctBasisGradeAmt) / Number(dbl1007TotalBGA)
                dblPctLoanRepay = Number(dblPctOfLoad) * Number(txtRepayAmtOverride)
                dblPctLoanValue = Number(dblPctOfLoad) * Number(txtLoanAmt)

                if(mcolContracts2?.split('|')[1] == 'F' || mcolContracts2?.split('|')[0] == 'SPOT'){
                  dblFirmPrice = mcolContracts2?.split('|')[3]
                  dblMarketGain = 0
                  dblLoanRepayTimesMarketGain = 0
                  dblPctSupport = (Number(dblFirmPrice || 0) * 100) / Number(getData(thisObj, 'mdblSupportPerTon'))
                  dblBasisValue = 0
                  dblValueOfSeg += Number(Number(dblPctLoanValue || 0) * Number(dblPctSupport || 0) / 100)?.toFixed(2)
                }
                else if(mcolContracts2?.split('|')[1] == 'B'){
                  dblBasisPrice = mcolContracts2?.split('|')[2]
                  if(getData(thisObj, 'mdblSupportPerTon') < (loanRepayRate)){
                    dblMarketGain = 1
                  }
                  else{
                    dblMarketGain = Number(Number(loanRepayRate) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(4)
                  }
                  
                  dblLoanRepayTimesMarketGain = Number(dblPctLoanRepay) * Number(dblMarketGain)
                  dblPctSupport = Number(Number(dblBasisPrice) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(2)
                  dblBasisValue = Number(dblPctSupport) * Number(dblPctBasisGradeAmt)
                  dblValueOfSeg += Number(Number(dblPctLoanRepay) + Number(dblBasisValue))?.toFixed(2)
                }
              })

              dblFinalSupportPct = Number(Number(dblValueOfSeg) / Number(dbl1007BGAValue) * 100)?.toFixed(2)
              dblValuePerTon = Number(Number(getData(thisObj, 'mdblSupportPerTon')) * (Number(dblFinalSupportPct) * 0.01))?.toFixed(2)
              mcolFinal1007List.push(mcol1007s[i] + "|" + dblValuePerTon + "|" + dblFinalSupportPct + "|" + Number(dblValueOfSeg)?.toFixed(2)) + "|" + Number(dblValueOfSeg)?.toFixed(2)
              dblValueOfSeg = 0
              dblValuePerTon = 0
              dblTotPctSupport = 0
            }
            setData(thisObj, 'mcolFinal1007List', mcolFinal1007List)
            break;

          case "I":
          case "O":
            await CalculatePurchaseValueByUnshrunk()
            break
        }
      }
      else if(redemptionMethod == "2"){
        switch(getData(thisObj, 'msTent_cont_appl_ind')){
          case "Y":
          case "N":
            for(var i=0; i<mcol1007s?.length; i++){
              dbl1007BGAValue = Number(mcol1007s[i]?.split('|')[3] || '')
              dbl1007Pounds = Number(mcol1007s[i]?.split('|')[2] || '')
              mcolContracts = mcol1007Contracts[i] || []
              
              let txtRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
              let txtLoanAmt = objVal['txtLoanAmt']
              let loanRepayRate = objVal['txtLoanRepayRate']
              mcolContracts?.forEach(mcolContracts2 => {
                dbl1007ContractPounds = Number(mcolContracts2?.split("|")[2] || '')
                dblPctOf1007Load = Number(Number(dbl1007ContractPounds) / Number(dbl1007Pounds) || 0)
                dblPctBasisGradeAmt = Number(dbl1007BGAValue) * Number(dblPctOf1007Load)
                dblPctOfLoad = Number(dblPctBasisGradeAmt) / Number(dbl1007TotalBGA)
                dblPctLoanRepay = Number(dblPctOfLoad) * Number(txtRepayAmtOverride)
                dblPctLoanValue = Number(dblPctOfLoad) * Number(txtLoanAmt)

                if(mcolContracts2?.split('|')[1] == 'F' || mcolContracts2?.split('|')[0] == 'SPOT'){
                  dblFirmPrice = mcolContracts2?.split('|')[3]
                  dblMarketGain = 0
                  dblLoanRepayTimesMarketGain = 0
                  dblPctSupport = (Number(dblFirmPrice || 0) * 100) / Number(getData(thisObj, 'mdblSupportPerTon'))
                  dblBasisValue = 0
                  dblValueOfSeg += Number(Number(dblPctLoanValue || 0) * Number(dblPctSupport || 0) / 100)?.toFixed(2)
                }
                else if(mcolContracts2?.split('|')[1] == 'B'){
                  dblBasisPrice = mcolContracts2?.split('|')[2]
                  if(getData(thisObj, 'mdblSupportPerTon') < (loanRepayRate)){
                    dblMarketGain = 1
                  }
                  else{
                    dblMarketGain = Number(Number(loanRepayRate) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(4)
                  }
                  
                  dblLoanRepayTimesMarketGain = Number(dblPctLoanRepay) * Number(dblMarketGain)
                  dblPctSupport = Number(Number(dblBasisPrice) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(2)
                  dblBasisValue = Number(dblPctSupport) * Number(dblPctBasisGradeAmt)
                  dblValueOfSeg += Number(Number(dblPctLoanRepay) + Number(dblBasisValue))?.toFixed(2)
                }
              })

              dblFinalSupportPct = Number(Number(dblValueOfSeg) / Number(dbl1007BGAValue) * 100)?.toFixed(2)
              dblValuePerTon = Number(Number(getData(thisObj, 'mdblSupportPerTon')) * (Number(dblFinalSupportPct) * 0.01))?.toFixed(2)
              mcolFinal1007List.push(mcol1007s[i] + "|" + dblValuePerTon + "|" + dblFinalSupportPct + "|" + Number(dblValueOfSeg)?.toFixed(2)) + "|" + Number(dblValueOfSeg)?.toFixed(2)
              dblValueOfSeg = 0
              dblValuePerTon = 0
              dblTotPctSupport = 0
            }
            setData(thisObj, 'mcolFinal1007List', mcolFinal1007List)
            break;

          case "I":
          case "O":
            await CalculatePurchaseValueByOriginal1007()
            break
        }
      }
      else if(redemptionMethod == "3"){
        switch(getData(thisObj, 'msTent_cont_appl_ind')){
          case "Y":
          case "N":
            for(var i=0; i<mcol1007s?.length; i++){
              dbl1007BGAValue = Number(mcol1007s[i]?.split('|')[3] || '')
              dbl1007Pounds = Number(mcol1007s[i]?.split('|')[2] || '')
              mcolContracts = mcol1007Contracts[i] || []
              
              let txtRepayAmtOverride = objVal['txtLoanRepayAmtOverride']
              let txtLoanAmt = objVal['txtLoanAmt']
              let loanRepayRate = objVal['txtLoanRepayRate']
              mcolContracts?.forEach(mcolContracts2 => {
                dbl1007ContractPounds = Number(mcolContracts2?.split("|")[2] || '')
                dblPctOf1007Load = Number(Number(dbl1007ContractPounds) / Number(dbl1007Pounds) || 0)
                dblPctBasisGradeAmt = Number(dbl1007BGAValue) * Number(dblPctOf1007Load)
                dblPctOfLoad = Number(dblPctBasisGradeAmt) / Number(dbl1007TotalBGA)
                dblPctLoanRepay = Number(dblPctOfLoad) * Number(txtRepayAmtOverride)
                dblPctLoanValue = Number(dblPctOfLoad) * Number(txtLoanAmt)

                if(mcolContracts2?.split('|')[1] == 'F' || mcolContracts2?.split('|')[0] == 'SPOT'){
                  dblFirmPrice = mcolContracts2?.split('|')[3]
                  dblMarketGain = 0
                  dblLoanRepayTimesMarketGain = 0
                  dblPctSupport = (Number(dblFirmPrice || 0) * 100) / Number(getData(thisObj, 'mdblSupportPerTon'))
                  dblBasisValue = 0
                  dblValueOfSeg += Number(Number(dblPctLoanValue || 0) * Number(dblPctSupport || 0) / 100)?.toFixed(2)
                }
                else if(mcolContracts2?.split('|')[1] == 'B'){
                  dblBasisPrice = mcolContracts2?.split('|')[2]
                  if(getData(thisObj, 'mdblSupportPerTon') < (loanRepayRate)){
                    dblMarketGain = 1
                  }
                  else{
                    dblMarketGain = Number(Number(loanRepayRate) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(4)
                  }
                  
                  dblLoanRepayTimesMarketGain = Number(dblPctLoanRepay) * Number(dblMarketGain)
                  dblPctSupport = Number(Number(dblBasisPrice) / Number(getData(thisObj, 'mdblSupportPerTon')))?.toFixed(2)
                  dblBasisValue = Number(dblPctSupport) * Number(dblPctBasisGradeAmt)
                  dblValueOfSeg += Number(Number(dblPctLoanRepay) + Number(dblBasisValue))?.toFixed(2)
                }
              })
              
              dblFinalSupportPct = Number(Number(dblValueOfSeg) / Number(dbl1007BGAValue) * 100)?.toFixed(2)
              dblValuePerTon = Number(Number(getData(thisObj, 'mdblSupportPerTon')) * (Number(dblFinalSupportPct) * 0.01))?.toFixed(2)
              mcolFinal1007List.push(mcol1007s[i] + "|" + dblValuePerTon + "|" + dblFinalSupportPct + "|" + Number(dblValueOfSeg)?.toFixed(2)) + "|" + Number(dblValueOfSeg)?.toFixed(2)
              dblValueOfSeg = 0
              dblValuePerTon = 0
              dblTotPctSupport = 0
            }
            setData(thisObj, 'mcolFinal1007List', mcolFinal1007List)
            break;

          case "I":
          case "O":
            await CalculatePurchaseValueByReceiptValue()
            break
        }
      }
      
      setData(thisObj, 'dblValueOfSeg', dblValueOfSeg)

      dblPurchTotalWeights = 0
      dblPurchPriceTimesWt = 0
      dblTot1007BGAValue = 0
      dblTotValueofSeg = 0
      
      mcolFinal1007List = getData(thisObj, 'mcolFinal1007List')
      
      mcolFinal1007List?.forEach(sItemData => {
        dblTotValueofSeg += Number(sItemData?.split('|')[8] || 0)
      })
      
      if(getData(thisObj, 'lblRcptStatusTag')?.toLocaleUpperCase() == 'F'){
        dblTotValueofSeg = Number(objVal['txtLoanRepayAmtOverride']) // Number(getValue(thisObj, 'txtLoanRepayAmtOverride') || getData(thisObj, 'txtLoanRepayAmtOverride'))
      }
      
      let supportPct = Number(Number(dblTotValueofSeg) / Number(dbl1007TotalBGA) * 100)?.toFixed(2)
      objVal['txtSupportPercent'] = supportPct
      
      setData(thisObj, 'lblValPerTon', Number(
                                            Number(getData(thisObj, 'mdblSupportPerTon')) / Number(supportPct) * 100
                                        )?.toFixed(2))
      
      dblPurchTotalWeights = 0
      dblPurchPriceTimesWt = 0
      objVal['txtValueOfSeg'] = 0

      mcolFinal1007List?.forEach(sItemData => {
        let val = [undefined, null].includes(sItemData?.split('|')[7]) ? sItemData?.split('|')[3] : sItemData?.split('|')[7]
        dblFarmerStockCost += Number(val)
      })
      
      objVal['txtFarmerStockCost'] = Number(dblFarmerStockCost)?.toFixed(2)
      objVal['txtValueOfSeg'] = Number(dblTotValueofSeg)?.toFixed(2)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "CalculateSeg")
    }
  }

  const GetLoanRateDate = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      let endDate = objVal['txtEndDate']
      let expDate = objVal['txtExpDate']
      let lockInDate = objVal['txtLnRpyLockInDt']
      
      if(isDateValid(endDate)){
        if(isDateValid(expDate)){
          if(new Date(expDate) > new Date(endDate)){
            return moment(new Date(endDate)).format('MM/DD/YYYY HH:MM A')
          }
          else{
            return moment(new Date(lockInDate)).format('MM/DD/YYYY HH:MM A')
          }
        }
        else{
          return moment(new Date(endDate)).format('MM/DD/YYYY HH:MM A')
        }
      }
      else{
        return moment(new Date()).format('MM/DD/YYYY HH:MM A')
      }
    }
    catch(err){
      errorHandler(err, "GetLoanRateDate")
    }
  }

  const bFillDMARemitToList = async(vendorId) => {
    try{
      if([ undefined, null, '' ].includes(vendorId)){
        return
      }
      
      let objVal = getData(thisObj, 'objVal')
      let LstrList = await ContractManagementService.RetrieveVendorByNumber(vendorId)

      if(LstrList?.length > 0){
        objVal['txtCMAVendor'] = toPascalCase(LstrList[0].name)
        show(thisObj, 'txtCMAVendor')
      }
      else{
        objVal['txtCMAVendor'] = ''
        hide(thisObj, 'txtCMAVendor', false)
      }

      thisObj.setState(current => {
        return {
          ...current,
          ddCMARemit: {
            ...current["ddCMARemit"],
            valueList: []
          }
        }
      })
      objVal['ddCMARemit'] = ''

      LstrList = await ContractManagementService.RetieveRemitDetails(vendorId)
      if(LstrList?.length > 0){
        
        let js = []

        LstrList?.forEach(remit => {
          js.push({
            key : remit?.remittoid,
            description : `${remit?.remittoid} - ${toPascalCase(remit?.name)} - ${toPascalCase(remit?.city)}, ${toPascalCase(remit.state)}`
          })
        })

        thisObj.setState(current => {
          return {
            ...current,
            ddCMARemit: {
              ...current["ddCMARemit"],
              valueList: js
            }
          }
        })
        
        if(js?.length > 0){
          objVal['ddCMARemit'] = js[0].key
          return true
        }
        else{
          objVal['ddCMARemit'] = ''
          return false
        }
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "bFillDMARemitToList")
      return false
    }
  }

  const bSaveWarehouseReceipt = async(strSaveType = "") => {
    try{
      let lstrxml = ""
      let sCmdString = ""
      let nPrompt = ""
      let sControlNumberPrompt = ""
      let LstrXML2 = ""
      let LstrList = ""
      let strUserBuyPtID  = ""
      let bTest = false
      let nTempContolNumber = 0
      let bSaveWarehouseReceiptBool = false
      let str1 = ""
      let str2 = ""
      let str3 = ""
      let str4 = ""
      let lblControlNumberTag = ""

      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      LstrList = await ContractManagementService.RetrieveUserControlDetails(useridFromLS(), null, null, null)
      if(LstrList?.length > 0){
        strUserBuyPtID = LstrList[0]?.buyPtId?.trim()
      }
      if(LstrList?.status == 500){
        alert("An error occured while communicating with the database.  Please retry.  Contact your system administrator if this problem persists.\n\n" + LstrList?.message)
        return
      }

      let eWRReceiptType = objVal['lblEWRReceiptType']
      if(!getData(thisObj, 'mboolElectronicWarehouseReceipt') && eWRReceiptType == 'E' && strSaveType == "Update" && getData(thisObj, 'mbReceiptTypeChanged')){
        lblControlNumberTag = objVal['txtCtrlNum']

        if(!isNumeric(lblControlNumberTag)){
          await bGetControlNumber()
        }
        else{
          if(Number(lblControlNumberTag) == 0){
            await bGetControlNumber()
          }
        }

        sControlNumberPrompt = lblControlNumberTag

        while(sControlNumberPrompt == ""){
          sControlNumberPrompt = prompt("Please Verify Receipt Control Number: " + lblControlNumberTag)
          if(isNumeric(sControlNumberPrompt)){
            if(Number.isInteger(sControlNumberPrompt)){
              objVal['txtCtrlNum'] = '0'.repeat(6 - sControlNumberPrompt?.toString()?.length) + sControlNumberPrompt?.toString()
            }
            else{
              alert("Receipt Control Number must be of format ######.")
              sControlNumberPrompt = ""
            }
          }
          else{
            alert("Receipt Control Number must be of format ######.")
            sControlNumberPrompt = ""
          }
        }
      }
      
      let whseRcptNum = data?.wrNum
      let buyingPt = data?.unlBuyingPtId
      let chrgBegDate = objVal['txtProducerBeginDate']
      let wireXferInd = objVal['chkboxWireTransfer'] ? 'Y' : 'N'

      let wireXferData = objVal['chkboxWireTransfer'] ? getData(thisObj, 'msWireXferDataSaved') : ''
      let cccBeginDate = new Date(objVal['txtCCCBeginDate']) instanceof Date ? objVal['txtCCCBeginDate'] : ''
      let chrgEndDate = new Date(objVal['txtEndDate']) instanceof Date ? objVal['txtEndDate'] : ''
      let docDelvDate = new Date(objVal['txtDocDelvDt']) instanceof Date ? objVal['txtDocDelvDt'] : ''
      let docDelvCompDate = new Date(objVal['txtDelvCompletedDt']) instanceof Date ? objVal['txtDelvCompletedDt'] : ''
      let loanRepayAmt = isNumeric(objVal['txtLoanRepayAmtOverride']) ? objVal['txtLoanRepayAmtOverride'] : ''
      let loanRepayRate = isNumeric(objVal['txtLoanRepayRate']) ? objVal['txtLoanRepayRate'] : ''
      let interestAmt = isNumeric(objVal['txtInterestAmtOverride']) ? objVal['txtInterestAmtOverride'] : ''
      let handlingRepayAmt = isNumeric(objVal['txtHandlingAmtOverride']) ? objVal['txtHandlingAmtOverride'] : ''
      let storeRepayAmt = isNumeric(objVal['txtStrgCreditAmtOverride']) ? objVal[''] : ''
      let calcLoanRepayAmt = isNumeric(objVal['txtLoanRepayAmt']) ? objVal['txtLoanRepayAmt'] : ''
      let storageCharge = objVal['txtStorageCharge']
      let calcStoreRepayAmt = objVal['txtStrgCreditAmt']

      let repayVendor = objVal['txtRepayVendorNum']?.trim() !== "" ? objVal['txtRepayVendorNum'] : ''
      let repayRemit = objVal['ddRepayRemit']?.trim() !== "" ? objVal['ddRepayRemit'] : ''
      let dMAVendor = objVal['txtCMAVendorNum']?.trim() !== "" ? objVal['txtCMAVendorNum'] : ''
      let dMARemit = objVal['ddCMARemit']?.trim() !== "" ? objVal['ddCMARemit'] : ''
      let lockInDate = new Date(objVal['txtLnRpyLockInDt']) instanceof Date !== "" ? objVal['txtLnRpyLockInDt'] : ''
      let loanAmt = objVal['txtLoanAmt']?.trim() !== "" ? objVal['txtLoanAmt'] : ''

      let lnState = objVal['txtLnState']
      let lnCountry = objVal['txtLnCounty']
      let lnNumber = objVal['txtLnNumber']
      
      let loanNumber = lnState?.toString()?.trim() + lnCountry?.toString()?.trim() + lnNumber?.toString()?.trim()
      let marketGain = objVal['txtMarketGain1']?.trim() !== "" ? objVal['txtMarketGain1'] : "" 
      let signed211Ind = objVal['chkbox211'] !== 0 ? "Y" : "N"
      let ccc500Ind = objVal['chkboxCCC500'] !== 0 ? "Y" : "N"
      let deferredRedemptionInd = objVal['chkboxDeferredRedemption'] !== 0 ? "Y" : "N"

      let tentContApplInd = getData(thisObj, 'msTent_cont_appl_ind')
      let tentDelvOutInd = objVal['chkboxCommittedToOther'] ? 'Y' : 'N'
      let tentDelvOutTo = objVal['txtCommittedTo']?.trim() !== "" ? objVal['txtCommittedTo'] : ""
      let receiptType = getData(thisObj, 'mboolElectronicWarehouseReceipt') && strSaveType ? 'E' : 'P'
      
      let value1 = objVal['lblEWRReceiptType']
    
      let ewrReceiptType = value1?.trim() !== '' && strSaveType == "Update" ? value1 : ''
      let currentHolder = objVal['ddCurrentHolder']?.trim() !== "" ? objVal['ddCurrentHolder'] : ""

      let redeemMethodInd = ''
      let value = objVal['radioRedemptionMethod']
      if(value == "1"){
        redeemMethodInd = 'L'
      }
      else if(value == "2"){
        redeemMethodInd = 'S'
      }
      else if(value == "3"){
        redeemMethodInd = 'R'
      }
      else if(value == "4"){
        redeemMethodInd = 'U'
      }

      let cmaDmaVendorNum = objVal['txtVendorNum']?.trim() !== '' ? objVal['txtVendorNum'] : ''
      let cmaDmaInd = ''
      if(objVal['chkboxCMA']){
        cmaDmaInd = 'C'
      }

      if(objVal['chkboxDMA']){
        cmaDmaInd = 'D'
      }

      if(objVal['chkboxCMA'] == 0 && objVal['chkboxDMA'] == 0){
        cmaDmaInd = ''
      }
      let lrrOverrideInd = objVal['chkboxOverrideLRR'] ? 'Y' : 'N'

      let shrinkPayMethodInd = ''
      let shrinkPayLrrRate = ''
      let shrinkPayOtherRate = ''
      let weightShrinkPct = objVal['txtShrnkPct']
      if(isNumeric(weightShrinkPct)){
        if(Number(weightShrinkPct) > 0){
          if(objVal['radioMarketLoan']){
            shrinkPayMethodInd = 'MLR'
            shrinkPayLrrRate = ''
            shrinkPayOtherRate = ''
          } 
          else if(objVal['radioOtherRate']){
            shrinkPayMethodInd = 'OTH'
            shrinkPayLrrRate = ''
            shrinkPayOtherRate = objVal['radioOtherRate']
          }
          else{
            shrinkPayMethodInd = 'LRR'
            shrinkPayLrrRate = objVal['txtLoanRepayRate']
            shrinkPayOtherRate = ''
          }          
        }
      }


      let cmdDmaVendorRemit = objVal['ddRemit']?.toString()?.trim() ? objVal['ddRemit'] : ''

      let loanDate = objVal['txtLoanDt']
      if(loanDate?.replace('/', '')?.trim() !== ""){
        if(!new Date(loanDate) instanceof Date){
          alert("Loan Date not valid date.")
          objVal['txtLoanDt'] = ''
          return
        }
      }
      loanDate = new Date(loanDate) instanceof Date ? loanDate : ''

      if(Number(cropYearFromLS()) >= 2010){
        if(isEnabled(thisObj, 'txtLoanMaturityDt')){
          let loanMtDate = objVal['txtLoanMaturityDt']
          if(loanMtDate?.replace("/", "")?.trim() !== ""){
            if(!new Date(loanMtDate) instanceof Date){
              alert("Loan Maturity Date not valid date.")
              // document.getElementById('txtLoanMaturityDt').focus()
              objVal['txtLoanMaturityDt'] = ''
              return;
            }
          }
        }

        if(isEnabled(thisObj, 'txtLoanForfeitedDt')){
          let loanForfeitDate = objVal['txtLoanForfeitedDt']
          if(loanForfeitDate?.replace("/", "")?.trim() !== ""){
            if(!new Date(loanForfeitDate) instanceof Date){
              alert("Loan Forfeited Date not valid date.")
              // document.getElementById('txtLoanForfeitedDt').focus()
              objVal['txtLoanForfeitedDt'] = ''
              return;
            }
          }
        }
      }
      else{
        let loanMtDate = objVal['txtLoanMaturityDt']
        if(loanMtDate?.replace("/", "")?.trim() !== ""){
          if(!new Date(loanMtDate) instanceof Date){
            alert("Loan Maturity Date not valid date.")
            // document.getElementById('txtLoanMaturityDt').focus()
            objVal['txtLoanMaturityDt'] = ''
            return;
          }
        }
      }
      
      let loadMtDate = new Date(objVal['txtLoanMaturityDt']) ? objVal['txtLoanMaturityDt'] : ''
      
      let receiptTypeChanged = getData(thisObj, 'mbReceiptTypeChanged') ? 'T' : ''
      let lastDateTime = getData(thisObj, 'lblLastChange')?.trim() !== '' ? getData(thisObj, 'lblLastChange') : ''

      value1 = objVal['txtStrgPaidThroughDt']
      let storePaidThruDate = new Date(value1) instanceof Date ? value1 : ''
      
      let storeHandleInd = objVal['chkboxStoreAndHandleReceived'] ? 'Y' : 'N'

      let cccLoanForfeitedDate = ''
      let cccStoragePaidThruDate = ''
      let saveloanFields = ''
      if(Number(cropYearFromLS()) >= 2010){
        let dateTime = objVal['txtLoanForfeitedDt']
        if(new Date(dateTime) instanceof Date){
          cccLoanForfeitedDate = dateTime
        }

        dateTime = objVal['txtCCCStrgPaidThruDt']
        if(new Date(dateTime) instanceof Date){
          cccStoragePaidThruDate = dateTime
        }

        if(isEnabled(thisObj, 'txtCCCStrgPaidThruDt') && isEnabled(thisObj, 'txtLoanForfeitedDt') && isEnabled(thisObj, 'txtLoanAmt') && isEnabled(thisObj, 'txtLoanMaturityDt')){
          saveloanFields = 'YES'
        }
      }

      let dataObj = {
        chrg_beg_date                : chrgBegDate == "" ? null : new Date(chrgBegDate)?.toISOString(),
        chrg_end_date                : chrgEndDate == "" ? null : new Date(chrgEndDate)?.toISOString(),
        doc_delv_date                : docDelvDate == "" ? null : new Date(docDelvDate)?.toISOString(),
        delv_out_complete_date       : docDelvCompDate == "" ? null : new Date(docDelvCompDate)?.toISOString(),
        storage_charge               : Number(storageCharge || 0).toFixed(2), // 
        loan_repay_amt               : Number(loanRepayAmt || 0).toFixed(2),
        interest_amt                 : Number(interestAmt || 0).toFixed(2),
        loan_repay_rate              : Number(loanRepayRate || 0).toFixed(2),
        store_repay_amt              : Number(storeRepayAmt || 0).toFixed(2),
        repay_vendor                 : repayVendor,
        repay_remit                  : repayRemit,
        dma_vendor                   : dMAVendor,
        dma_remit                    : dMARemit || '',
        lrr_lock_date                : lockInDate == "" ? null : new Date(lockInDate)?.toISOString(),
        ccc_beg_date                 : cccBeginDate == "" ? null : new Date(cccBeginDate)?.toISOString(),
        calc_loan_repay_amt          : Number(calcLoanRepayAmt || 0).toFixed(2),
        calc_store_repay_amt         : Number(calcStoreRepayAmt || 0).toFixed(2), //
        signed_211_ind               : signed211Ind,
        ccc_500_ind                  : ccc500Ind,
        deferred_redemption_ind      : deferredRedemptionInd,
        loan_number                  : loanNumber,
        loan_amount                  : Number(loanAmt || 0).toFixed(2),
        market_gain                  : marketGain,
        tent_cont_appl_ind           : tentContApplInd,
        tent_delv_out_ind            : tentDelvOutInd,
        tent_delv_out_to             : tentDelvOutTo,
        chg_user                     : useridFromLS(),
        receipt_type                 : receiptType,
        ewr_receipt_type             : ewrReceiptType || '',
        holder_id                    : currentHolder,
        redeem_method_ind            : redeemMethodInd,
        cma_dma_ind                  : cmaDmaInd,
        cma_dma_vendor_num           : cmaDmaVendorNum,
        cma_dma_vendor_remit         : cmdDmaVendorRemit || '',
        loan_date                    : loanDate == "" ? null : new Date(loanDate)?.toISOString(),
        loan_mat_date                : loadMtDate == "" ? null : new Date(loadMtDate)?.toISOString(),
        receipt_type_changed         : receiptTypeChanged,
        lasT_DATE_TIME               : lastDateTime,
        lrr_override_ind             : lrrOverrideInd,
        shrink_pay_method_ind        : shrinkPayMethodInd,
        shrink_pay_other_rate        : Number(shrinkPayLrrRate || 0).toFixed(2),
        shrink_pay_lrr_rate          : Number(shrinkPayOtherRate || 0).toFixed(2),
        wire_xfer_ind                : wireXferInd,
        wire_xfer_data               : wireXferData || '',
        storage_paid_thru_date       : storePaidThruDate == "" ? null : new Date(storePaidThruDate)?.toISOString(),
        storage_handling_recvd_ind   : storeHandleInd,
        handling_repay_amt           : Number(handlingRepayAmt || 0).toFixed(2),
        loan_maturity_date           : loadMtDate == "" ? null : new Date(loadMtDate)?.toISOString(),
        ccc_storage_paid_thru_date   : [ "", 'Invalid Date' ].includes(cccStoragePaidThruDate) ? null : new Date(cccStoragePaidThruDate).toISOString(),
        ccc_loan_forfeited_date      : [ "", 'Invalid Date' ].includes(cccLoanForfeitedDate) ? null : new Date(cccLoanForfeitedDate).toISOString(),
      }
      
      lstrxml = await WarehouseReceiptService.UpdateWareHouseReceipt(buyingPt, whseRcptNum, saveloanFields, dataObj)
      if(lstrxml?.status !== 200){
        if(lstrxml?.status == 500 && lstrxml?.message.includes('The information from your screen is no longer current')){
          dataObj.lasT_DATE_TIME = ConvertDateTime(lastDateTime)
          lstrxml = await WarehouseReceiptService.UpdateWareHouseReceipt(buyingPt, whseRcptNum, saveloanFields, dataObj)
          if(lstrxml?.status == 200){
            setData(thisObj, 'mbReceiptTypeChanged', true)
            setData(thisObj, 'lblControlNumber', nTempContolNumber)
          }
          else{
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
            return
          }
        }
        else{
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
          return
        }        
      }
      else{
        setData(thisObj, 'mbReceiptTypeChanged', true)
        setData(thisObj, 'lblControlNumber', nTempContolNumber)
        // if(isNumeric(lstrxml[0]?.receipt_number)){
        //   if(getData(thisObj, 'mbReceiptTypeChanged')){
        //     nTempContolNumber = ""  //getData(thisObj, 'lblControlNumber')
        //     objVal['txtRcptNum'] = lstrxml[0]?.receipt_number
        
        //     setData(thisObj, 'mbReceiptTypeChanged', true)
        //     setData(thisObj, 'lblControlNumber', nTempContolNumber)
        //   }
        // }
      }

      eWRReceiptType = objVal['lblEWRReceiptType']
      if(!getData(thisObj, 'mboolElectronicWarehouseReceipt') && eWRReceiptType == 'E' && getData(thisObj, 'mbReceiptTypeChanged')){
        LstrXML2 = await WarehouseReceiptService.Retrieve1007GroupList(compIdFromLS(), cropYearFromLS(), data?.buyingPtId, data?.wrNum, null, null, null, null, null, null, null, null, null, null, null, null, null, strUserBuyPtID, null, null, null, null, null, null, null, null, null, null)
        if(LstrXML2?.length >= 0){
          sControlNumberPrompt = ''
        }
        else{
          sControlNumberPrompt = objVal['txtCtrlNum']
        }

        lblControlNumberTag = objVal['txtCtrlNum']
        while(sControlNumberPrompt == ""){
          sControlNumberPrompt = prompt("Please Verify Receipt Control Number: " + lblControlNumberTag)
          if(isNumeric(sControlNumberPrompt)){
            if(Number.isInteger(sControlNumberPrompt)){
              objVal['txtCtrlNum'] = '0'.repeat(6 - sControlNumberPrompt?.toString()?.length) + sControlNumberPrompt?.toString()
            }
            else{
              alert("Receipt Control Number must be of format ######.")
              sControlNumberPrompt = ""
            }
          }
          else{
            alert("Receipt Control Number must be of format ######.")
            sControlNumberPrompt = ""
          }
        }
      }

      
      // Load frmReportPreviewWR
        
      //   ' Print the receipt
      //   frmReportPreviewWR.rptWarehouseReceipt lblBuyingPtId.Tag, lblWRNum
      //   Unload frmReportPreviewWR
      //   Set frmReportPreviewWR = Nothing
      
      //await bSaveWarehouseReceiptAfterLoad(data,lblControlNumberTag, strUserBuyPtID)
      
      //   While nPrompt = vbNo
      //       While sControlNumberPrompt = ""
      //           If IsNumeric(sControlNumberPrompt) Then
      //               If sControlNumberPrompt = CLng(sControlNumberPrompt) Then
      //                   lblControlNumber.Caption = String(6 - Len(sControlNumberPrompt), "0") & sControlNumberPrompt
      //                   If Trim(lblControlNumber.Tag) <> Trim(lblControlNumber.Caption) Then
      //                       If Not bSendWebRequest("cmd=WR_REPRINT&COMP_ID=" & GstrCompanyID & "&CROP_YEAR=" & GstrCropYear & "&buy_pt_id=" & lblBuyingPtId.Tag & "&WHSE_RCPT_NUM=" & lblWRNum & "&UBUY_PT_ID=" & strUserBuyPtID & "&RCPT_CNTL_NUM=" & lblControlNumber.Caption, LstrXML2) Then
      //                           sControlNumberPrompt = ""
      //                       End If
      //                   End If
      //               Else
      //                   MsgBox "Receipt Control Number must be of format ######.", vbExclamation, "Verify Control Number"
      //                   sControlNumberPrompt = ""
      //               End If
      //           Else
      //               MsgBox "Receipt Control Number must be of format ######.", vbExclamation, "Verify Control Number"
      //               sControlNumberPrompt = ""
      //           End If
      //       Wend
            
      //       lblControlNumber.Tag = lblControlNumber.Caption
            
      //       Load frmReportPreviewWR
            
      //       ' Print the receipt
      //       frmReportPreviewWR.rptWarehouseReceipt lblBuyingPtId.Tag, lblWRNum
      //       Unload frmReportPreviewWR
      //       Set frmReportPreviewWR = Nothing
            
      //       nPrompt = MsgBox("Did the Receipt print correctly?", vbYesNo, "Print Warehouse Receipt")
      //   Wend

      setData(thisObj, 'mbReceiptTypeChanged', false)

    //   If cmdBegDelvOut.Visible = True And mbEWRAccepted Then   'infra ticket 406002
    //     cmdBegDelvOut.enabled = True
    // End If
    
    // If cmdCompDelvOut.Visible = True And mbEWRAccepted Then   'infra ticket 406002
    //     cmdCompDelvOut.enabled = True
    // End If
    
    // If cmdPurchase.Visible = True And mbEWRAccepted Then   
    //     cmdPurchase.enabled = True
    // End If
    
    // If cmdTentativeApplication.Visible = True Then
    //     cmdTentativeApplication.enabled = True
    // End If
    
    // If cmdTentativeApplicationReverse.Visible = True Then
    //     cmdTentativeApplicationReverse.enabled = True
    // End If
    
    // If cmdUndoBegDelvOut.Visible = True And mbEWRAccepted Then   
    //     cmdUndoBegDelvOut.enabled = True
    // End If
    
    // If cmdUndoCompDelvOut.Visible = True And mbEWRAccepted Then  
    //     cmdUndoCompDelvOut.enabled = True
    // End If
    
    // If cmdUnPurchase.Visible = True And mbEWRAccepted Then   
    //     cmdUnPurchase.enabled = True
    // End If
    
    // If cmdVoid.Visible = True And mbEWRAccepted Then   
    //     cmdVoid.enabled = True
    // End If

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      bSaveWarehouseReceiptBool = true
      return bSaveWarehouseReceiptBool
    }
    catch(err){
      errorHandler(err, "bSaveWarehouseReceipt")
    }
  }

  const bSaveWarehouseReceiptAfterLoad = async(data, lblControlNumber, strUserBuyPtID) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      let LstrXML2 = []
      let nPrompt = confirm("Did the Receipt print correctly?")
      while(nPrompt !== null){
        sControlNumberPrompt = ""
        while(sControlNumberPrompt == ""){
          sControlNumberPrompt = prompt("Please Verify Receipt Control Number: " + lblControlNumber)
          if(isNumeric(sControlNumberPrompt)){
            if(Number.isInteger(sControlNumberPrompt)){
              let value1 = '0'.repeat(6 - sControlNumberPrompt?.toString()?.length) + sControlNumberPrompt?.toString()
              objVal['txtCtrlNum'] = value1
              
              if(value1?.trim() !== lblControlNumber?.trim()){
                LstrXML2 = await WarehouseReceiptService.Retrieve1007GroupList(compIdFromLS(), cropYearFromLS(), data?.buyingPtId, null, null, null, null, null, null, null, null, null, null, null, null, null, null, strUserBuyPtID, null, null, null, null, null, null, null, data?.wrNum, null, null, null)
                if(LstrXML2?.length !== 0){
                  sControlNumberPrompt = ""
                }
              }
            }
            else{
              alert("Receipt Control Number must be of format ######.")
              sControlNumberPrompt = ""
            }
          }
          else{
            alert("Receipt Control Number must be of format ######.")
            sControlNumberPrompt = ""
          }
        }

        // Load frmReportPreviewWR
            
        //     ' Print the receipt
        //     frmReportPreviewWR.rptWarehouseReceipt lblBuyingPtId.Tag, lblWRNum
        //     Unload frmReportPreviewWR
        //     Set frmReportPreviewWR = Nothing

        nPrompt = confirm("Did the Receipt print correctly?")
      }
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "bSaveWarehouseReceiptAfterLoad")
    }
  }

  const CalculatePurchaseValueByLoanRepayment = async() =>{
    try{
      let strLoanRateDate = await GetLoanRateDate()
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let objVal = getData(thisObj, 'objVal')

      let buyingPt = data?.unlBuyingPtId
      let whseRcpt = data?.wrNum
      let loanRepay = objVal['txtLoanRepayAmtOverride']
      let loanValue = objVal['txtLoanAmt']

      let strLstXml = await WarehouseReceiptService.RetrievecalCulatePurchaseValuePreAppliedDetails(buyingPt, whseRcpt, 'L', loanRepay, loanValue, strLoanRateDate, null)
      if(strLstXml?.length > 0){
        await BuildCollectionList(strLstXml)
      }
    }
    catch(err){
      errorHandler(err, "CalculatePurchaseValueByLoanRepayment")
    }
  }

  const CalculatePurchaseValueByOriginal1007 = async() =>{
    try{
      let strLoanRateDate = await GetLoanRateDate()
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      let buyingPt = data?.unlBuyingPtId
      let whseRcpt = data?.wrNum
      
      let strLstXml = await WarehouseReceiptService.RetrievecalCulatePurchaseValuePreAppliedDetails(buyingPt, whseRcpt, 'S', null, null, strLoanRateDate, null)
      if(strLstXml?.length > 0){
        await BuildCollectionList(strLstXml)
      }
    }
    catch(err){
      errorHandler(err, "CalculatePurchaseValueByOriginal1007")
    }
  }

  const CalculatePurchaseValueByReceiptValue = async() =>{
    try{
      let strLoanRateDate = await GetLoanRateDate()
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let objVal = getData(thisObj, 'objVal')

      let buyingPt = data?.unlBuyingPtId
      let whseRcpt = data?.wrNum
      let recieptValue = objVal['txtOriginalValue']
      
      let strLstXml = await WarehouseReceiptService.RetrievecalCulatePurchaseValuePreAppliedDetails(buyingPt, whseRcpt, 'R', null, null, strLoanRateDate, recieptValue)
      if(strLstXml?.length > 0){
        await BuildCollectionList(strLstXml)
      }
    }
    catch(err){
      errorHandler(err, "CalculatePurchaseValueByReceiptValue")
    }
  }

  const CalculatePurchaseValueByUnshrunk = async() =>{
    try{
      let LstrList = []
      let dblValueOfSeg = 0
      let dblRcptValue = 0

      let strLoanRateDate = await GetLoanRateDate()
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let objVal = getData(thisObj, 'objVal')

      let buyingPt = data?.unlBuyingPtId
      let whseRcpt = data?.wrNum
      let loanRepay = objVal['txtLoanRepayAmtOverride']
      let loanValue = objVal['txtLoanAmt']
      
      let strLstXml = await WarehouseReceiptService.RetrievecalCulatePurchaseValuePreAppliedDetails(buyingPt, whseRcpt, 'U', loanRepay, loanValue, strLoanRateDate, null)
      if(strLstXml?.length > 0){
        let segValue = objVal['txtSeg']
        if(Number(segValue) > 1 &&  strLstXml[0].pricing_line?.trim() == '1'){
          let areaId = getData(thisObj, 'msAreaId')
          let peanutTypeId = getData(thisObj, 'lblPeanutType')
          let crBrPct = objVal['txtCrackBroken']
          let dColShelPct = objVal['txtDisclrd']
          let fanPct = objVal['txtFancy']
          let damPct = objVal['txtTotalDamage']
          let ss_Pct = 0 
          let vicamPct = objVal['txtVICAM']
          let fmPct = objVal['txtFM']
          let smk = objVal['txtSMK']
          let ssPct = objVal['txtSS']
          let okPct = objVal['txtOtherKernels']
          let elkPct = objVal['txtELK']
          let lskPct = objVal['txtLSK']
          let moisturePct = 0
          let netWt = objVal['txtTotalLbs']
          let grossWt = 0
          let lskWt = objVal['txtLSKLbs']
          let foreignMatPct = objVal['txtFM']

          LstrList = await WarehouseReceiptService.RetrieveWareHouseValueCalculationDetails(areaId, peanutTypeId, crBrPct, dColShelPct, fanPct, damPct, ssPct, vicamPct,fmPct, smk, ssPct, okPct, elkPct, lskPct, moisturePct, netWt, grossWt, lskWt, foreignMatPct)
          if(strLstXml?.ware_house_receipt?.length <= 0){
            dblValueOfSeg = Number(Number(LstrList[0].total_value_of_load || 0) * Number(LstrList[0].pct_support || 0))?.toFixed(2)
            dblRcptValue = Number(Number(LstrList[0].total_value_of_load || 0))?.toFixed(2)
          }
          setData(thisObj, 'dblValueOfSeg', dblValueOfSeg)
          // objVal['lblRcptValue'] = dblRcptValue
          // setData(thisObj, 'lblRcptValue', dblRcptValue)

          setData(thisObj, 'objVal', objVal)
          setFieldValues(thisObj, objVal, true)
          await BuildCollectionList(strLstXml, dblRcptValue)
        }
        else{
          await BuildCollectionList(strLstXml)
        }       
      }
    }
    catch(err){
      errorHandler(err, "CalculatePurchaseValueByUnshrunk")
    }
  }

  const CalculateOption = async() => {  
    try{
      let arrContractLine = []
      let arr1007Line = []
      let nListIndex = 0
      let dblPurchTotalWeights = 0
      let dblPurchPriceTimesWt = 0
      let dblWeightedAverage = 0
      let dblVendorPurchSeg = 0
      let lstrxml = []
      let dblLoanPrice = 0
      let lngSpotPounds = 0
      let dblSpotPrice = 0
      let lngPounds = 0
      let lPoundsToDistrubute = 0
      let sItemData = []
      let mcol1007s = []
      let mcolContracts = []
      let mcol1007Contracts = []
      let mcolFinal1007Lists = []
      let lblSpotPounds
      let objVal = getData(thisObj, 'objVal')

      dblPurchTotalWeights = 0
      dblPurchPriceTimesWt = 0
      lngSpotPounds = 0
      dblSpotPrice = 0
      dblLoanPrice = 0

      if(getData(thisObj, 'msMode') !== "TENTATIVE_APPLICATION"){
        return
      }

      setData(thisObj, 'mcol1007s', [])
      setData(thisObj, 'mcol1007Contracts', [])

      lblSpotPounds = 0
      objVal['txtSpotPounds'] = 0
      setData(thisObj, 'txtSpotPounds', 0)

      let gridData = objVal['grid1007s']
      gridData?.forEach(item => {
        mcolContracts = []
        setData(thisObj, 'mcolContracts', [])
        if(item?.txtcolSeedMeetSpecInd == 'Y'){
          if(mcol1007s?.length == 0){
            mcol1007s.push(item?.txtcolSC95Num + "|Y|" + item?.txtcolNetWeight + "|" + item?.txtcolValue)
            mcol1007Contracts.push(mcolContracts)
          }
          else{
            mcol1007s.push(item?.txtcolSC95Num + "|Y|" + item?.txtcolNetWeight + "|" + item?.txtcolValue) // 1
            mcol1007Contracts.push(mcolContracts)  // 1
          }
        }
        else{
          if(mcol1007s?.length == 0){
            mcol1007s.push(item?.txtcolSC95Num + "|N|" + item?.txtcolNetWeight + "|" + item?.txtcolValue)
            mcol1007Contracts.push(mcolContracts)
          }
          else{
            mcol1007s.push(item?.txtcolSC95Num + "|N|" + item?.txtcolNetWeight + "|" + item?.txtcolValue) // mcol1007s
            mcol1007Contracts.push(mcolContracts)  // mcol1007s
          }
        }
      })

      setData(thisObj, 'mcol1007s', mcol1007s)
      setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
      setData(thisObj, 'mcolContracts', mcolContracts)

      let lstNewContractLine = getData(thisObj, 'lstNewContractLine')
      for(var i=0; i<lstNewContractLine?.length; i++){
        arrContractLine = lstNewContractLine[i]?.split("|")
        lPoundsToDistrubute = arrContractLine[2]

        if(arrContractLine[0] !== "SPOT"){
          if(arrContractLine[5] == "Y"){
            for(var j=0; j<mcol1007s?.length; j++){
              lngPounds = 0

              arr1007Line = mcol1007s[j]?.split("|")
              mcol1007Contracts[j]?.forEach(mcolContracts => {
                lngPounds = Number(mcolContracts[0]?.split("|")[2])
              })

              if(Number(lngPounds) < Number(arr1007Line[2])){
                if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                  mcolContracts = []
                  setData(thisObj, 'mcolContracts', [])

                  if(arrContractLine[1] == "Basis" && arrContractLine[6] == "O"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "L"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "M"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Firm" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|F|" + lPoundsToDistrubute?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }

                  setData(thisObj, 'mcolContracts', mcolContracts)
                  setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
                }
                else{
                  mcolContracts = []
                  setData(thisObj, 'mcolContracts', [])

                  if(arrContractLine[1] == "Basis" && arrContractLine[6] == "O"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "L"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "M"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }
                  else if(arrContractLine[1] == "Firm" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))
                  }

                  setData(thisObj, 'mcolContracts', mcolContracts)
                  setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
                }
              }

              if(Number(lPoundsToDistrubute) == 0){
                break;
              }
            }
          }
          else{
            for(var j=mcol1007s?.length - 1; j>=0; j--){
              lngPounds = 0
              arr1007Line = mcol1007s[j]?.split("|")

              mcol1007Contracts[j]?.forEach(mcolContracts => {
                lngPounds += Number(mcolContracts[0]?.split("|")[2])
              })

              if(Number(lngPounds) < Number(arr1007Line[2])){
                if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                  mcolContracts = []
                  setData(thisObj, 'mcolContracts', [])

                  if(arrContractLine[1] == "Basis" && arrContractLine[6] == "O"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "L"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "M"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|" + arrContractLine[4])
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + lPoundsToDistrubute?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  else if(arrContractLine[1] == "Firm" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|F|" + lPoundsToDistrubute?.toString() + "|0")
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute = 0
                  }
                  
                  setData(thisObj, 'mcolContracts', mcolContracts)
                  setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
                }
                else{
                  mcolContracts = []
                  setData(thisObj, 'mcolContracts', mcolContracts)

                  if(arrContractLine[1] == "Basis" && arrContractLine[6] == "O"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])//Fixed as Part of Prod Issue INC5716579
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))//Fixed as Part of Prod Issue INC5716579
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "L"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])//Fixed as Part of Prod Issue INC5716579
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))//Fixed as Part of Prod Issue INC5716579
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "M"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|" + arrContractLine[4])//Fixed as Part of Prod Issue INC5716579
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))//Fixed as Part of Prod Issue INC5716579
                  }
                  else if(arrContractLine[1] == "Basis" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|B|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|0")//Fixed as Part of Prod Issue INC5716579
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))//Fixed as Part of Prod Issue INC5716579
                  }
                  else if(arrContractLine[1] == "Firm" && arrContractLine[6] == "C"){
                    mcolContracts.push(arrContractLine[0] + "|F|" + Number(Number(arr1007Line[2]) - Number(lngPounds))?.toString() + "|0")//Fixed as Part of Prod Issue INC5716579
                    mcol1007Contracts[j].push(mcolContracts)
                    lPoundsToDistrubute -= Number(Number(arr1007Line[2]) - Number(lngPounds))//Fixed as Part of Prod Issue INC5716579
                  }

                  setData(thisObj, 'mcolContracts', mcolContracts)
                  setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)
                }
              }

              if(Number(lPoundsToDistrubute) == 0){
                break
              }

            }
          }
        }
        else{
          for(var j=mcol1007s?.length - 1; j>=0; j--){
            lngPounds = 0
            arr1007Line = mcol1007s[j]?.split("|")

            mcol1007Contracts[j]?.forEach(mcolContracts => {
              lngPounds = Number(mcolContracts[0]?.split("|")[2])
            })

            objVal['txtSpotPrice'] = Number(arrContractLine[4]).toFixed(2)

            if(Number(lngPounds) < Number(arr1007Line[2])){
              if(Number(lPoundsToDistrubute) <= Number(Number(arr1007Line[2]) - Number(lngPounds))){
                mcolContracts = []
                setData(thisObj, 'mcolContracts', [])

                mcolContracts.push(arrContractLine[0] + "||" + lPoundsToDistrubute?.toString() + "|" + Number(arrContractLine[4]).toString())
                mcol1007Contracts[j].push(mcolContracts)

                let value = Number(Number(arrContractLine[4]).toFixed(2)) + Number(lPoundsToDistrubute)
                objVal['txtSpotPrice'] = value

                lPoundsToDistrubute = 0
              }
              else{
                mcolContracts = []
                setData(thisObj, 'mcolContracts', [])

                mcolContracts.push(arrContractLine[0] + "||" + Number(Number(arr1007Line) - Number(lngPounds))?.toString() + "|" + Number(arrContractLine[4]).toString())
                mcol1007Contracts[j].push(mcolContracts)

                let value = Number(Number(arrContractLine[4]).toFixed(2)) + Number(Number(arr1007Line) - Number(lngPounds))
                objVal['txtSpotPrice'] = value

                lPoundsToDistrubute -= Number(Number(arr1007Line) - Number(lngPounds))
              }
            }

            setData(thisObj, 'mcolContracts', mcolContracts)
            setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)

            if(Number(lPoundsToDistrubute) == 0){
              break
            }
          }
        }
      }
      
      mcolFinal1007List = []
      setData(thisObj, 'mcolFinal1007List', [])

      let res = []
      for(var i=0; i<mcol1007Contracts?.length; i++){
      //   if(mcol1007Contracts[i]?.length !== 0){
          res.push(mcol1007Contracts[i])
      //   }
      }
      mcol1007Contracts = res

      setData(thisObj, 'mcol1007Contracts', res)
      nListIndex = 1
      mcol1007Contracts?.forEach(mcolContracts => {
        dblPurchTotalWeights = 0
        dblPurchPriceTimesWt = 0

        mcolContracts?.forEach(mcolContracts2 => {
          dblPurchTotalWeights += Number(mcolContracts2[0]?.toString()?.split('|')[2])
          dblPurchPriceTimesWt += Number(Number(mcolContracts2[0]?.toString()?.split('|')[2]) * Number(mcolContracts2[0]?.toString()?.split('|')[3]))
        })
        dblPurchTotalWeights = dblPurchTotalWeights == 0 ? 1 : dblPurchTotalWeights
        let v1 = Number(Number(dblPurchPriceTimesWt)/Number(dblPurchTotalWeights)).toFixed(2)
        let v2 = Number(Number(Number(v1) * 100) / Number(getData(thisObj, 'mdblSupportPerTon'))).toFixed(2)
        
        mcolFinal1007Lists.push(mcol1007s[nListIndex-1] + "|" + v1
                                                     + "|" + v2
                                                     + "|" + Number(Number(v2) * Number(mcol1007s[nListIndex-1]?.split('|')[3]) * 0.01).toFixed(2)?.toString()
                                                     )         
        nListIndex++
      })
      setData(thisObj, 'mcolFinal1007List', mcolFinal1007Lists)

      dblPurchTotalWeights = 0
      dblPurchPriceTimesWt = 0

      mcolFinal1007Lists?.forEach(sItemData => {
        dblPurchTotalWeights += Number(sItemData?.split("|")[2])
        dblPurchPriceTimesWt += Number(Number(sItemData?.split("|")[2]) * Number(sItemData?.split("|")[5]))
      })

      objVal['txtSupportPercent'] = Number(Number(dblPurchPriceTimesWt) / Number(dblPurchTotalWeights)).toFixed(2)

      dblPurchTotalWeights = 0
      dblPurchPriceTimesWt = 0

      let value = 0

      objVal['txtValueOfSeg'] = value

      mcolFinal1007Lists?.forEach(sItemData => {
        value += Number(sItemData?.split("|")[6] || 0)        
      })

      objVal['txtValueOfSeg'] = value
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "CalculateOption")
    }
  }

  const BuildCollectionList = async(strXML, dblRcptVal = 0) => {
    try{
      let strInspNum = ""
      let strKey = ""
      let intnListIndex = 0
      let dblValueOfSeg = 0
      let dblPctSupport = 0
      let dblValuePerTon = 0
      let strNextXml = ""
      let dblFarmerStockCost = 0
      let dbl1007BGAValue = 0
      let dblpurchase_value = 0
      let dbloption_value = 0
      let v1007List = []
      let LstrList = ""

      let mcol1007Contracts = []
      let mcolContracts = []
      let mcolFinal1007List = []
      setData(thisObj, 'mcol1007Contracts', [])

      let mcol1007s = getData(thisObj, 'mcol1007s')
      mcol1007s?.forEach(v1007List => {
        mcolContracts = []
        setData(thisObj, 'mcolContracts', [])
      })

      strNextXml = strXML
      strNextXml?.forEach(item => {
        mcolContracts = []
        setData(thisObj, 'mcolContracts', [])

        if(Number(item?.pricing_line) == 1){
          mcolContracts.push(item?.contract_num + "|F|" + item?.pounds?.toString() + "|" + item?.price_per_ton)
        }
        else{
          mcolContracts.push(item?.contract_num + "|B|" + item?.pounds?.toString() + "|" + item?.price_per_ton)
        }

        mcol1007Contracts.push(mcolContracts)
      })
      
      setData(thisObj, 'mcolContracts', mcolContracts)
      setData(thisObj, 'mcol1007Contracts', mcol1007Contracts)

      mcolFinal1007List = []
      setData(thisObj, 'mcolFinal1007List', [])

      strNextXml = strXML

      let segValue = objVal['txtSeg']
      for(var i=0; i<mcol1007s?.length; i++){
        strInspNum = mcol1007s[i]?.split("|")[0]
        strKey = mcol1007s[i]?.split("|")[4]

        dbl1007BGAValue = mcol1007s[i]?.split("|")[3]

        strXML?.forEach(item => {
          
          if(strKey == item?.insp_num?.trim() + item?.contract_num?.trim()){
            dblpurchase_value = item?.purchase_value !== "" ? Number(item?.purchase_value) : 0
            dbloption_value = item?.option_value !== "" ? Number(item?.option_value) : 0
            
            dblValueOfSeg = Number(Number(getData(thisObj, 'dblValueOfSeg')) + Number(Number(dblpurchase_value).toFixed(2))).toFixed(2)
            setData(thisObj, 'dblValueOfSeg', dblValueOfSeg)
            
            if(Number(segValue) > 1 && dblRcptVal > 0){
              setData(thisObj, 'dblValueOfSeg', dblRcptVal * Number(Number(item?.pct_support).toFixed(4)) * 0.01 * Number(Number(item?.pct_of_wr_load).toFixed(6)))
            }
          }
        })
        
        // setData(thisObj, 'dblValueOfSeg', dblValueOfSeg)
        dblFarmerStockCost = Number(getData(thisObj, 'dblValueOfSeg')) + Number(dbloption_value)
        setData(thisObj, 'dblFarmerStockCost', dblFarmerStockCost)
        
        if(Number(dblValueOfSeg) > 0){
          dblPctSupport = Number(Number(dblValueOfSeg)/ Number(dbl1007BGAValue) * 100).toFixed(2)
          dblValuePerTon = Number(getData(thisObj, 'mdblSupportPerTon')) * (Number(dblPctSupport) * 0.01)
          mcolFinal1007List.push(mcol1007s[i] + "|" + Number(dblValuePerTon).toFixed(2) + "|" + dblPctSupport?.toString() + "|" + Number(dblFarmerStockCost).toFixed(2) + "|" + dblValueOfSeg?.toString())
        }
        else{
          dblPctSupport = 0
          dblValuePerTon = 0
          mcolFinal1007List.push(mcol1007s[i] + "|" + Number(dblValuePerTon).toFixed(2) + "|" + dblPctSupport?.toString() + "|" + Number(dblFarmerStockCost).toFixed(2) + "|" + dblValueOfSeg?.toString())
        }
        setData(thisObj, 'dblValueOfSeg', 0)
        dblValueOfSeg = 0
        dblPctSupport = 0
        dblValuePerTon = 0
        dblFarmerStockCost = 0
      }
      
      setData(thisObj, 'mcolFinal1007List', mcolFinal1007List)
    }
    catch(err){
      errorHandler(err, "BuildCollectionList")
    }
  }  

  const ValidControl = async(ctrl, ctrlValue, sDefault) => {
    try{
      let dict = {
        'txtConcordia1' : 5 , 'txtWireTransferData4' : 1, 'txtWireTransferData6' : 4
      }
      if(['txtConcordia1', 'txtWireTransferData4', 'txtWireTransferData6'].includes(ctrl)){
        if(ctrlValue?.trim()?.length == dict[ctrl] && (isNumeric(ctrlValue) || ctrlValue == sDefault)){
          return true
        }
        else{
          let objVal = getData(thisObj, 'objVal')
          objVal[ctrl] = sDefault

          setData(thisObj, 'objVal', objVal)
          setFieldValues(thisObj, objVal, true)
          return false
        }
      }
    }
    catch(err){
      errorHandler(err, "ValidControl")
    }
  }

  // buttons start
  const onbtnAcctDistClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      setData(thisObj, 'type', 'WAREHOUSERECEIPT')

      commonContext.setWR_BuyingPointId(data?.unlBuyingPtId)
      commonContext.setWR_WRNumber(data?.wrNum);

      goTo(thisObj, "ContractManagement#AccountDistributionActivity#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnAcctDistClick")
    }
  }
  thisObj.onbtnAcctDistClick = onbtnAcctDistClick

  const onbtnBeginDeliveryClick = async() => {
    try{
      let lstrxml = []

      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let js = {
        buyingPtId      : data?.buyingPtId,
        whseRcptNum     : data?.wrNum,
        peanutType      : getData(thisObj, 'lblPeanutType'),
        lblAgreePresent : '',
        buttonName      : 'beginDelv',
        orgBuyingPtId   : data?.unlBuyingPtId
      }

      setData(thisObj, 'frmDelvAvailAgreementData', js)
      goTo(thisObj, "WarehouseReceipts#DelvAvailAgreement#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnBeginDeliveryClick")
    }
  }
  thisObj.onbtnBeginDeliveryClick = onbtnBeginDeliveryClick

  const onbtnUndoBegDelvOutClick = async() => {
    try{ // Aishwarya
      let lstrxml = []
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      let js = {
        buyingPtId      : data.buyingPtId,
        whseRcptNum     : data.wrNum,
        peanutType      : getData(thisObj, 'lblPeanutType'),
        lblAgreePresent : 'Y',
		    buttonName		  : 'cancelDelv',
		    orgBuyingPtId   : data?.unlBuyingPtId
      }
      setData(thisObj, 'frmDelvAvailAgreementData', js)
      goTo(thisObj, "WarehouseReceipts#DelvAvailAgreement#DEFAULT#true#Click")

      // if(thisObj.values.lblAgreePresent !== 'Y'){
      //   alert("The Delivery out cannot begin unless the delivery agreement is attached to the Warehouse Receipt!")
      //   return
      // }
    }
    catch(err){
      errorHandler(err, "onbtnUndoBegDelvOutClick")
    }
  }
  thisObj.onbtnUndoBegDelvOutClick = onbtnUndoBegDelvOutClick

  const onbtncol1Click = async(event) => {
    try{
      let rowSelected = event.target.parentElement.parentElement.parentElement
      rowSelected.classList.add("activeRow")
      let rowID = rowSelected.id.substring(4, 5);
      
      let objVal = getData(thisObj, 'objVal')
      let gridData = objVal['grid1007s']
      
      let js = {
        inspNum: gridData[rowID].txtcolSC95Num,
        buyPtId: gridData[rowID].txtcolUnloadPoint
      }

      setData(thisObj, 'viewSettlementData', js)
      goTo(thisObj, "Settlements#ViewSettlement#DEFAULT#true#Click");
    }
    catch(err){
      errorHandler(err, "onbtncolClick")
    }
  }
  thisObj.onbtncol1Click = onbtncol1Click;

  const onbtnPremsDeductClick = async() => {
    try {
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let frmViewWRPremiumDeductionsloadData = {
        lblWRNum      : data?.wrNum,
        lblBuyingPtId : data?.unlBuyingPtId
      }
      
      setData(thisObj, "frmViewWRPremiumDeductionsloadData", frmViewWRPremiumDeductionsloadData);
      goTo(thisObj, "WarehouseReceipts#ViewWRPremiumDeductions#DEFAULT#true#Click")
    } 
    catch (err) {
      errorHandler(err, "onbtnPremsDeductClick")
    }
  }
  thisObj.onbtnPremsDeductClick = onbtnPremsDeductClick;

  const onbtnPurchaseClick = async() => {
    try{      
      let LstrList = ""      
      let nHoldCYR = 0

      setLoading(true)
      
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let objVal = getData(thisObj, 'objVal')

      if(objVal['chkboxWireTransfer'] == 1) {
        let repayVendor = objVal['txtRepayVendorNum']
        if(await CheckForCCCVendor(repayVendor)){
          if(repayVendor == "134969"){
            let wireTransfer4 = objVal['txtWireTransferData4']
            if(wireTransfer4 == "" || !isNumeric(wireTransfer4)){
              // document.getElementById('txtWireTransferData4').focus()
              alert("Wire Transfer Check Digit must be numeric.")
              return
            }

            let wireTransfer6 = objVal['txtWireTransferData6']
            if(wireTransfer6 == "" || !isNumeric(wireTransfer6)){
              // document.getElementById('txtWireTransferData6').focus()
              alert("Wire Transfer Log Number must be numeric.")
              return
            }

          }
          else if(repayVendor == "745134"){
            let concordia1 =  objVal['txtConcordia1']
            if(concordia1 == "" || !isNumeric(concordia1)){
              // document.getElementById('txtConcordia1').focus()
              alert("Wire Number must be numeric.")
              return
            }
          }
        }
      }

      let lblForfeited = objVal['txtForfeitedStatus']

      if(lblForfeited == "Forfeited" && !objVal['ddCurrentHolder']?.includes("Golden Peanut")){
        if(!confirm("This receipt is in Forfeited Status. \nDo you wish to continue")){
          return
        }
      }

      LstrList = await WarehouseReceiptService.UpdateUploadSendToEWarehouse(data?.unlBuyingPtId, data?.wrNum, 
        { 
          flag : 'N',
          user_id : useridFromLS()
        })

      if(objVal['chkboxDeferredRedemption'] == 1){
        nHoldCYR = Number(cropYearFromLS())
        if(Number(new Date()?.getFullYear()) <= nHoldCYR){
          nHoldCYR++
          alert("This Warehouse Receipt has been marked as deferred and cannot be redeemed until January 1, " + nHoldCYR)
          return
        }
      }

      if(await bFormValid()){
        if(await bSaveWarehouseReceipt("Update")){
          msMode = "PURCHASE"
          setData(thisObj, 'msMode', "PURCHASE")
          let endDate = objVal['txtEndDate']
          if(endDate?.replace("/", "")?.trim() == ""){
            objVal['txtEndDate'] = moment(new Date()).format("MM/DD/YYYY")
          }

          objVal['txtWireTransfer'] = await BuildWireTransfer(objVal['txtRepayVendorNum'])

          let js = {
            Mode                      : msMode,
            lblUnloadBuyPtIdValue     : data?.buyingPtId,
            lblBuyPtIdValue           : data?.unlBuyingPtId,
            lblBuyPtIdText            : objVal['txtObligationLocation'],
            lblUnloadBuyPtIdText      : data?.buyPointName,
            lblWRNumberValue          : data?.wrNum,
            eWRRcptType               : data?.eWRRcptType,
            methodName                : 'bFillContractApplications',
            vendor                    : data?.vendor,
            wrNum                     : data?.wrNum,
            lblVarietyTag             : objVal['txtVariety']
          }

          setData(thisObj, 'frmWhouseApplications', js)
          goTo(thisObj, "WarehouseReceipts#WhouseApplications#DEFAULT#true#Click")
        }
      }      

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)      
    }
    catch(err){
      errorHandler(err, "onbtnPurchaseClick")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnPurchaseClick = onbtnPurchaseClick

  const frmViewWarehouseReceiptReLoad = async() => {
    try{
      setLoading(true)
      await FormLoad()
    }
    catch(err){
      errorHandler(err, "frmViewWarehouseReceiptReLoad")
    }
  }

  const frmWhouseApplicationPurchaseCall = async(lstNewContractLine) => {
    try{
      let sApplContractList = ""
      let sAppliedList = ""
      let sPrevContract = ""
      let lApplLbs = 0
      let dblStorageRate = 0
      let sSeedContractList = ""
      let sSeedList = ""
      let lSeedLbs = 0
      let dblGrossWtP = 0
      let dblLSKWtP = 0
      let dblNetWtP = 0
      let sInspList = ""
      let sHoldInspNum = ""
      let nHoldRow = 0
      let dblGMASeg = 0
      let s1007ContractPoundsLst = ""
      let v1007
      let vContract
      let vContractString
      let sInspBuyPtId = ""
      let lblFarmerStockCost = ''
      let lblValPerTon = ''
      let arrContractLine = []
      let arrNewContractApps = []
      let mcol1007Contracts = []
      let lSeedPounds
      setLoading(true)

      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      let lstrxml = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(getData(thisObj, 'msFedBuyPtId'), null)
      if(lstrxml?.length <= 0){
        await bFillGrid()
        return
      }
      
      dblStorageRate = Number(Number(lstrxml[0]?.whouse_license_controls[0]?.shorage_rate || 0) * 100)?.toFixed(2)
      
      await CalculateSeg()
      
      let value = objVal['txtSupportPercent']
      objVal['txtSupportPercent'] = isNumeric(value) ? value : "0.00"
      setData(thisObj, 'txtSupportPercent', isNumeric(value) ? value : "0.00")

      if(Number(value) > 999.99){
        alert("The Percent of Support calculated is out of range.  Please verify that the Loan Amount entry is correct and retry.  Contact your system administrator if this problem persists.\n\n")
        await onbtnCancelClick()
        return
      }

      sApplContractList = ""
      sAppliedList = ""
      sPrevContract = ""
      lApplLbs = 0

      sSeedContractList = ""
      sSeedList = ""
      lSeedLbs = 0

      for(var i =0; i<lstNewContractLine?.length; i++){
        arrContractLine = lstNewContractLine[i]?.split('|')

        if(arrContractLine[0] !== 'SPOT'){
          if(sPrevContract !== arrContractLine[0]){
            if(lApplLbs !== 0){
              sAppliedList += (lApplLbs?.toString() + ",")
              sApplContractList += (sPrevContract?.toString() + ",")
              lApplLbs = 0
            }
            
            if(lSeedLbs !== 0){
              sSeedList += (lSeedLbs?.toString() + ",")
              sSeedContractList += (sPrevContract?.toString() + ",")
              lSeedLbs = 0
            }
          }

          if(arrContractLine[3] == 'S'){
            lSeedLbs += arrContractLine[2]
          }
          else{
            lApplLbs += arrContractLine[2]
          }
          sPrevContract = arrContractLine[0]
        }
      }
      
      if(lApplLbs !== 0){
        sAppliedList = (sAppliedList || '') + (Number(lApplLbs) || 0)?.toString() + ','
        sApplContractList = (sApplContractList || '') + (sPrevContract || '') + ','
        lApplLbs = 0
      }
      
      if(lSeedLbs !== 0){
        sSeedList = (sSeedList || '') + (lSeedLbs || 0) + ','
        sSeedContractList = (sSeedContractList || '') + (sPrevContract || '') + ','
        lSeedLbs = 0
      }
      
      dblGrossWtP = 0
      dblLSKWtP = 0
      dblNetWtP = 0

      dblGrossWtP = getData(thisObj, 'lblGrossPounds') || ""
      dblLSKWtP = objVal['txtLSKLbs'] || 0 
      dblNetWtP = objVal['txtTotalLbs'] || 0
      
      sInspList = ""
      sHoldInspNum = ""
      nHoldRow = 1

      let gridData = objVal['grid1007s']
      let mcolFinal1007ListData = getData(thisObj, 'mcolFinal1007List')?.sort(sortArrayFunction)
      
      for(var i=0; i<mcolFinal1007ListData?.length; i++){
        sInspList += (mcolFinal1007ListData[i] + ",")
        if(sHoldInspNum !== mcolFinal1007ListData[i]?.split('|')[0]){
          sInspBuyPtId = gridData[nHoldRow - 1]?.txtcolUnloadPoint
          sHoldInspNum = mcolFinal1007ListData[i]?.split('|')[0]
          nHoldRow++;
        }
      }
      
      sAppliedList = sAppliedList?.length != 0 ? sAppliedList?.slice(0, -1) : "" 
      sSeedList = sSeedList?.length != 0 ? sSeedList?.slice(0, -1) : ""
      sApplContractList = sApplContractList?.length != 0 ? sApplContractList?.slice(0, -1) : ""
      sSeedContractList = sSeedContractList?.length != 0 ? sSeedContractList?.slice(0, -1) : ""
      sInspList = sInspList?.length != 0 ? sInspList?.slice(0, -1) : ""
     
      let rcptValues = ''
      mcolFinal1007ListData?.forEach(item => {
        rcptValues += ( item?.split('|')[0] + "," + 
                        item?.split('|')[5] + "," + 
                        item?.split('|')[6] + "," + 
                        item?.split('|')[7] + "|")
      }) 

      let lblSpotPrice = objVal['txtSpotPrice']
      let lblSpotPounds = objVal['txtSpotPounds']
      let casMaster = ''
      let sgaMaster = ''

      mcol1007Contracts = getData(thisObj, 'mcol1007Contracts')
      for(var i=0; i<lstNewContractLine?.length; i++){
        arrNewContractApps = lstNewContractLine[i]?.split("|")
        
        if(arrNewContractApps[0] !== "SPOT"){
          if(arrNewContractApps[1] !== "SEED"){
            casMaster += (arrNewContractApps[0]?.toString() + ",")
            
            if(arrNewContractApps[1]?.toLocaleUpperCase()?.trim() == "FIRM"){
              casMaster += ("1,")
            }
            else{
              casMaster += ("2,")
            }

            casMaster += (arrNewContractApps[2] + ",")

            lSeedPounds = 0
            setData(thisObj, 'lSeedPounds', 0)
            
            for(var i=0; i<mcol1007Contracts?.length; i++){
              mcol1007Contracts[i]?.forEach(mcolApplications => {
                
                if(mcolApplications[1]?.split("|")[0] == arrNewContractApps[0] 
                            && mcolApplications[1]?.split("|")[1]?.slice(1) == arrNewContractApps[1]?.slice(1)){
                              if(mcolFinal1007ListData[i]?.split('|')[1] == 'Y'){
                                lSeedPounds += (mcolApplications[1]?.split("|")[2])
                              }
                            }
              })
            }
            
            casMaster += (lSeedPounds?.toString() + ",")
            casMaster += ((Number(arrNewContractApps[2]) - Number(lSeedPounds)).toString() + ',')
            casMaster += (arrNewContractApps[7]?.toString() + ",")
            casMaster += (arrNewContractApps[8]?.toString() + "|")
          }
          else{
            sgaMaster += (arrNewContractApps[0]?.toString() + ",")
            sgaMaster += (arrNewContractApps[2]?.toString() + "|")
          }
        }
      }

      let frmPremiumDeductionsWRPurchJs = {
        MstrWHSE_RCPT_NUM : data?.wrNum,                           
        MstrWHOUSE_NUM    : getData(thisObj, 'msWhouseNum'),       
        MstrBIN_NUM       : getData(thisObj, 'msWhouseBin'),       
        MlngNET_WT        : objVal['txtTotalLbs'], 
        MdblSPOT_PRICE    : isNumeric(lblSpotPrice) ? lblSpotPrice : 0,
        MlngSPOT_POUNDS   : isNumeric(lblSpotPounds) ? lblSpotPrice : 0,
        MstrOVERIDE_FARM  : objVal['txtOverrideFarm'], 
        MstrRCPT_VALUES   : rcptValues,         
        MstrCAS_MASTER    : casMaster || "",
        MstrSGA_MASTER    : sgaMaster || "",
        lblRcptStatus     : objVal['txtStatus'], 
        txtRepayAmtOveride: objVal['txtLoanRepayAmtOverride'], 
        lblShrinkPayment  : objVal['txtShrinkPay'], 
        lblOptionPayment  : objVal['txtOptionPayment'], 
        txtEndDate        : objVal['txtEndDate'], 
        optRMOrig1007s    : objVal['radioRedemptionMethod'] == "2" ? true : false, 
        optRMReceiptValue : objVal['radioRedemptionMethod'] == "3" ? true : false, 
        optRMLoanPayoff   : objVal['radioRedemptionMethod'] == "1" ? true : false, 
        optUnShrunk       : objVal['radioRedemptionMethod'] == "4" ? true : false, 
        wireTransferData  : objVal['txtWireTransfer'], 
        lblShrunkRcptValue: objVal['txtShrunkValue'], 
        txtInterestAmt    : objVal['txtInterestAmt'], 
        txtStoreCrAmtOride: objVal['txtStrgCreditAmtOverride']
      }
      
      setData(thisObj, 'frmPremiumDeductionsWRPurchJs', frmPremiumDeductionsWRPurchJs)

      dblGMASeg = 0
      gridData?.forEach(data => {
        dblGMASeg += Number(data?.txtcolValue)
      })
      
      let loanRepayRate = objVal['txtLoanRepayRate'] 
      let mdblSuppPerTon = getData(thisObj, 'mdblSupportPerTon')
      dblGMASeg = Number(Number(dblGMASeg) * Number(Number(loanRepayRate) / Number(mdblSuppPerTon)).toFixed(4))?.toFixed(2)
      
      s1007ContractPoundsLst = ""
      let mcol1007 = getData(thisObj, 'mcol1007s')
      let mcol1007Cont = getData(thisObj, 'mcol1007Contracts')
      let res = []

      mcol1007?.forEach(v1007 => {
        mcol1007Cont?.forEach(vContract => {
          vContract?.forEach(vContractString => {
            s1007ContractPoundsLst = ""
            s1007ContractPoundsLst += (v1007?.split("|")[0] + ",")
            s1007ContractPoundsLst += ("P,")
            s1007ContractPoundsLst += (vContractString?.split("|")[0] + ",")

            if(vContractString?.split("|")[0] == "F"){
              s1007ContractPoundsLst += "1,"
            }
            else{
              s1007ContractPoundsLst += "2,"
            }

            s1007ContractPoundsLst += (v1007?.split("|")[2] + ",")
            s1007ContractPoundsLst += (v1007?.split("|")[1] + "|")
            if(!res.includes(s1007ContractPoundsLst)){
              res.push(s1007ContractPoundsLst)
            }
          })
        })
      })
      
      s1007ContractPoundsLst = res.join("")

      let js = {
        sInspList               : sInspList, 
        sInspBuyPtId            : sInspBuyPtId,
        LstrInspectInd          : "N", 
        s1007ContractPoundsLst  : s1007ContractPoundsLst, 
        lblFarmerStockCost      : objVal['txtFarmerStockCost'],
        dblGMASeg               : dblGMASeg,
        sApplContractList       : sApplContractList, 
        sAppliedList            : sAppliedList,
        lblRcptVendor           : objVal['txtVendor']?.split(' ')[0]?.trim(),
        dblNetWtP               : dblNetWtP, 
        dblLSKWtP               : dblLSKWtP, 
        dblGrossWtP             : dblGrossWtP, 
        LdblSegValue_S          : 0, 
        LstrContract_S          : "",
        LstrApplyLBS_S          : "",
        LstrVendors_S           : "",
        LdblNET_WT_S            : 0, 
        LdblLSK_WT_S            : 0, 
        LdblGROSS_WT_S          : 0, 
        lblValPerTon            : objVal['txtValuePerTon'] || 0, 
        LdblBASIS_GRADE_AMT_S   : 0, 
        txtDMAVendor            : objVal['txtCMAVendorNum'] || '',
        lblWRNum                : data?.wrNum?.trim(),
        sSeedContractList       : sSeedContractList, 
        sSeedList               : sSeedList, 
        MstrOBUY_PT_ID          : data?.unlBuyingPtId,
        msMode                  : "PURCHASE"
      }

      setData(thisObj, 'frmPDWRPurchData', js)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      goTo(thisObj,"WarehouseReceipts#PremiumDeductionsWRPurch#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "frmWhouseApplicationPurchaseCall")
    }
    finally{
      setLoading(false)
    }
  }

  const frmWhouseApplicationTentativeCall = async(lstNewContractLine) => {
    try{
      let lstrxml = []
      let dblStorageRate = 0
      let LstrHTTP = ""
      let arrNewContractApps = []
      let nApplicationCount  = 0
      let sApplContractList = ""
      let sTentativeList = ""
      let lTentativeLBS = 0
      let sPrevContract = ""
      let nListIndex = 0
      let arrContractLine = []
      let dblGrossWt_S = 0
      let dblLSKWt_S = 0
      let dblNetWt_S = 0
      let dblGMASeg = 0
      let sInspList = ""
      let sInspBuyPtId = ""
      let arrContracts = []
      let lSeedPounds = 0
      let mcolApplications = []
      let n1007Index = 0
      let LstrList = ""
      let sSeedContractList = ""     
      let sSeedList = ""             
      let lSeedLbs = 0    
      let sAppliedList = ""
      let s1007ContractPoundsLst = ''
      let v1007 = []
      let vContract = []
      let vContractString = []
      let lblFarmerStockCost = 0
      let lblSpotPrice = 0
      let lblSpotPounds = 0
      let lblValPerTon = 0
      
      setLoading(true)            

      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      lstrxml = await SystemMaintenanceMasterManagementService.RetrieveWareHouseLicenseControlDetails(getData(thisObj, 'msFedBuyPtId'), null)
      if(lstrxml?.length <= 0){
        await bFillGrid()
        return
      }

      dblStorageRate = Number(Number(lstrxml[0]?.whouse_license_controls[0]?.shorage_rate || 0) * 100)?.toFixed(2)
      
      setData(thisObj, 'lstNewContractLine', lstNewContractLine)
      await CalculateOption()

      let value = objVal['txtSupportPercent']
      objVal['txtSupportPercent'] = isNumeric(value) ? value : "0.00"

      if(Number(value) > 999.99){
        alert("The Percent of Support calculated is out of range.  Please verify that the Loan Amount entry is correct and retry.  Contact your system administrator if this problem persists.\n\n")
        await onbtnCancelClick()
        return
      }

      let val = objVal['txtValueOfSeg'] 
      objVal['txtOptionPayment'] = val

      sApplContractList = ""
      sTentativeList = ""
      sPrevContract = ""
      lTentativeLBS = 0

      sSeedContractList = ""
      sSeedList = ""
      lSeedLbs = 0
      
      for(var i =0; i<lstNewContractLine?.length; i++){
        arrContractLine = lstNewContractLine[i]?.split('|')
        
        if(arrContractLine[0] !== 'SPOT'){
          if(sPrevContract !== arrContractLine[0]){
            if(Number(lTentativeLBS) !== 0){
              sTentativeList += (lTentativeLBS?.toString() + ",")
              sApplContractList += (sPrevContract?.toString() + ",")
              lTentativeLBS = 0
            }
            
            if(Number(lSeedLbs) !== 0){
              sSeedList += (lSeedLbs?.toString() + ",")
              sSeedContractList += (sPrevContract?.toString() + ",")
              lSeedLbs = 0
            }
          }

          if(arrContractLine[3] == 'S'){
            lSeedLbs += Number(arrContractLine[2])
          }
          else{
            lTentativeLBS += Number(arrContractLine[2])
          }
          sPrevContract = arrContractLine[0]
        }
      }

      if(Number(lTentativeLBS) !== 0){
        sTentativeList = (sTentativeList || '') + (lTentativeLBS || 0) + ','
        sApplContractList = (sApplContractList || '') + (sPrevContract || '') + ','
        lTentativeLBS = 0
      }
      
      if(Number(lSeedLbs) !== 0){
        sSeedList = (sSeedList || '') + (lSeedLbs || 0) + ','
        sSeedContractList = (sSeedContractList || '') + (sPrevContract || '') + ','
        lSeedLbs = 0
      }
      
      dblGrossWt_S = 0
      dblLSKWt_S = 0
      dblNetWt_S = 0

      dblGrossWt_S = getData(thisObj, 'lblGrossPounds') || "" 
      dblLSKWt_S = objVal['txtLSKLbs'] || 0 
      dblNetWt_S = objVal['txtTotalLbs'] || 0 

      sInspList = ""
      let sHoldInspNum = ""
      let nHoldRow = 1

      let gridData = objVal['grid1007s'] 
      let mcolFinal1007ListData = getData(thisObj, 'mcolFinal1007List')
      
      for(var i=0; i<mcolFinal1007ListData?.length; i++){
        sInspList += (mcolFinal1007ListData[i] + ",")
        sInspBuyPtId = gridData[i]?.txtcolUnloadPoint
      }
      
      sTentativeList = sTentativeList?.length != 0 ? sTentativeList?.slice(0, -1) : ""
      sSeedList = sSeedList?.length != 0 ? sSeedList?.slice(0, -1) : ""
      sApplContractList = sApplContractList?.length != 0 ? sApplContractList?.slice(0, -1) : ""
      sSeedContractList = sSeedContractList?.length != 0 ? sSeedContractList?.slice(0, -1) : ""
      sInspList = sInspList?.length != 0 ? sInspList?.slice(0, -1) : ""
      
      let rcptValues = ''
      mcolFinal1007ListData?.forEach(item => {
        rcptValues += ( item?.split('|')[0] + "," + 
                        item?.split('|')[4] + "," + 
                        item?.split('|')[5] + "," + 
                        item?.split('|')[6] + "|")
      })

      let casMaster = ''
      let sgaMaster = ''
      
      mcol1007Contracts = getData(thisObj, 'mcol1007Contracts')
      for(var i=0; i<lstNewContractLine?.length; i++){
        arrNewContractApps = lstNewContractLine[i]?.split("|")

        if(arrNewContractApps[0] !== "SPOT"){
          if(arrNewContractApps[1] !== "SEED"){
            casMaster += (arrNewContractApps[0]?.toString() + ",")
            
            if(arrNewContractApps[1]?.toLocaleUpperCase()?.trim() == "FIRM"){
              casMaster += ("1,")
            }
            else{
              casMaster += ("2,")
            }

            casMaster += (arrNewContractApps[2] + ",")

            lSeedPounds = 0
            setData(thisObj, 'lSeedPounds', 0)

            for(var i=0; i<mcol1007Contracts?.length; i++){
              mcol1007Contracts[i]?.forEach(mcolApplications => {
                if(mcolApplications[1]?.split("|")[0] == arrNewContractApps[0] 
                            && mcolApplications[1]?.split("|")[1]?.slice(1) == arrNewContractApps[1]?.slice(1)){
                              if(mcolFinal1007ListData[i]?.split('|')[1] == 'Y'){
                                lSeedPounds += (mcolApplications[1]?.split("|")[2])
                              }
                            }
              })
            }

            casMaster += (lSeedPounds?.toString() + ",")
            casMaster += ((Number(arrNewContractApps[2]) - Number(lSeedPounds)).toString() + ',')
            casMaster += (arrNewContractApps[7]?.toString() + ",")
            casMaster += (arrNewContractApps[8]?.toString() + "|")
          }
          else{
            sgaMaster += (arrNewContractApps[0]?.toString() + ",")
            sgaMaster += (arrNewContractApps[2]?.toString() + "|")
          }
        }
      }

      let contAppls = ''
      let mcol1007s = getData(thisObj, 'mcol1007s')
      for(var i=0; i<mcol1007Contracts?.length; i++){
        mcol1007Contracts[i]?.forEach(mcolApplications => {
          contAppls += mcolApplications[0]?.split("|")[0] + ","
 
          if(mcolApplications[0]?.split("|")[1]?.toLocaleUpperCase()?.trim() == "B"){
            contAppls += "2,"
          }
          else{
            contAppls += "1,"
          }
 
          contAppls += mcolApplications[0]?.split('|')[2] + ","
 
          lSeedPounds = 0
          if(mcolFinal1007List[i]?.split("|")[1] == "Y"){
            lSeedPounds += mcolApplications[0]?.split("|")[2]
          }
 
          contAppls += (lSeedPounds?.toString() + ",")
          contAppls += ((Number(mcolApplications[0]?.split("|")[2]) - Number(lSeedPounds))?.toString() + ",")
          contAppls += mcol1007s[i]?.split("|")[0]?.split("|")[0] + "|"
        })
      }

      let frmPremiumDeductionsWRTentJs = {
        MstrWHSE_RCPT_NUM : data?.wrNum,                           
        MstrWHOUSE_NUM    : getData(thisObj, 'msWhouseNum'),       
        MstrBIN_NUM       : getData(thisObj, 'msWhouseBin'),       
        MlngNET_WT        : objVal['txtTotalLbs'], 
        MdblSPOT_PRICE    : isNumeric(lblSpotPrice) ? lblSpotPrice : 0,  
        MlngSPOT_POUNDS   : isNumeric(lblSpotPounds) ? lblSpotPounds : 0,
        MstrOVERIDE_FARM  : objVal['txtOverrideFarm'], 
        MstrRCPT_VALUES   : rcptValues,         
        MstrCAS_MASTER    : casMaster || "",
        MstrSGA_MASTER    : sgaMaster || "",
        MstrCONT_APPLS    : contAppls || "",
        lblRcptStatus     : objVal['txtStatus'],
        txtRepayAmtOveride: objVal['txtLoanRepayAmtOverride'], 
        lblShrinkPayment  : objVal['txtShrinkPay'], 
        lblOptionPayment  : objVal['txtOptionPayment'], 
        txtEndDate        : objVal['txtEndDate'], 
        optRMOrig1007s    : objVal['radioRedemptionMethod'] == "2" ? true : false, 
        optRMReceiptValue : objVal['radioRedemptionMethod'] == "3" ? true : false, 
        optRMLoanPayoff   : objVal['radioRedemptionMethod'] == "1" ? true : false, 
        optUnShrunk       : objVal['radioRedemptionMethod'] == "4" ? true : false, 
        wireTransferData  : objVal['txtWireTransfer'],
        lblShrunkRcptValue: objVal['txtShrunkValue'], 
        txtInterestAmt    : objVal['txtInterestAmt'], 
        txtStoreCrAmtOride: objVal['txtStrgCreditAmtOverride'],
      }
      
      setData(thisObj, 'frmPremiumDeductionsWRTentJs', frmPremiumDeductionsWRTentJs)

      s1007ContractPoundsLst = ''
      v1007 = []
      vContract = []
      vContractString = []

      s1007ContractPoundsLst = ""
      let mcol1007 = getData(thisObj, 'mcol1007s')
      let mcol1007Cont = getData(thisObj, 'mcol1007Contracts')
      let res = []

      mcol1007?.forEach(v1007 => { 
        mcol1007Cont?.forEach(vContract => {  
          vContract?.forEach(vContractString => {
            s1007ContractPoundsLst = ""
            s1007ContractPoundsLst = s1007ContractPoundsLst + v1007?.split('|')[0] + ','
            s1007ContractPoundsLst = s1007ContractPoundsLst + 'S,'
            s1007ContractPoundsLst = s1007ContractPoundsLst + vContractString[0]?.split('|')[0] + ','

            if(vContractString[0]?.split('|')[0] == 'F'){
              s1007ContractPoundsLst = s1007ContractPoundsLst + '1,'
            }
            else{
              s1007ContractPoundsLst = s1007ContractPoundsLst + '2,'
            }

            s1007ContractPoundsLst = s1007ContractPoundsLst + v1007?.split('|')[2] + ","
            s1007ContractPoundsLst = s1007ContractPoundsLst + v1007?.split('|')[1] + "|"
            if(!res.includes(s1007ContractPoundsLst)){
              res.push(s1007ContractPoundsLst)
            }
          })
        })
      })
      
      s1007ContractPoundsLst = res.join("")

      dblGMASeg = 0
      gridData?.forEach(data => {
        dblGMASeg += Number(data?.txtcolValue)
      })
      
      let loanRepayRate = objVal['txtLoanRepayRate']
      let mdblSuppPerTon = getData(thisObj, 'mdblSupportPerTon')
      dblGMASeg = Number(Number(dblGMASeg) * Number(Number(loanRepayRate) / Number(mdblSuppPerTon)).toFixed(4))?.toFixed(2)
      
      let js = {
        sInspList               : sInspList, 
        sInspBuyPtId            : sInspBuyPtId, 
        LstrInspectInd          : "N", 
        s1007ContractPoundsLst  : s1007ContractPoundsLst, 
        lblFarmerStockCost      : lblFarmerStockCost,
        dblGMASeg               : dblGMASeg,
        sApplContractList       : sApplContractList,
        sAppliedList            : sTentativeList,
        lblRcptVendor           : objVal['txtVendor']?.split(' ')[0]?.trim(),
        dblNetWt_S              : dblNetWt_S, 
        dblLSKWt_S              : dblLSKWt_S, 
        dblGrossWt_S            : dblGrossWt_S, 
        LdblSegValue_S          : objVal['txtValueOfSeg'],
        LstrContract_S          : "",
        LstrApplyLBS_S          : "",
        LstrVendors_S           : "",
        LdblNET_WT_S            : 0,
        LdblLSK_WT_S            : 0,
        LdblGROSS_WT_S          : 0,
        lblValPerTon            : lblValPerTon,
        LdblBASIS_GRADE_AMT_S   : 0,
        txtDMAVendor            : objVal['txtCMAVendorNum'],
        lblWRNum                : data?.wrNum?.trim(),
        sSeedContractList       : sSeedContractList,
        sSeedList               : sSeedList,
        MstrOBUY_PT_ID          : data?.unlBuyingPtId,
        msMode                  : "TENTATIVE",
        sTentativeList          : sTentativeList,
        lblValOfSeg             : val
      }
      
      setData(thisObj, 'frmPDWRPurchData', js)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      goTo(thisObj,"WarehouseReceipts#PremiumDeductionsWRPurch#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "frmWhouseApplicationTentativeCall")
    }
    finally{
      setLoading(false)
    }
  }

  const frmBeginDeliveryAfterCall = async(lblAgreePresent, methodName) => {
    try{
      if(methodName == 'cancelDelv'){
        if(lblAgreePresent == 'Y'){
          alert("The Begin Delivery out process cannot be reversed unless the delivery agreement is removed from the Warehouse Receipt!")
          return
        }
      }
      else if(methodName == 'beginDelv'){
        if(lblAgreePresent !== 'Y'){
          alert("The Delivery out cannot begin unless the delivery agreement is attached to the Warehouse Receipt!")
          return
        }
      }

      setLoading(true)
      let lstrxml = []
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      if(await bFormValid()){
        if(await bSaveWarehouseReceipt("Update")){
          if(methodName == 'cancelDelv'){
            lstrxml = await WarehouseReceiptService.UpdateLoadOutDeliveryProcessDetails(data?.unlBuyingPtId, data?.wrNum)
          }
          else if(methodName == 'beginDelv'){
            lstrxml = await WarehouseReceiptService.UpdateLoadoutDeliveryInProcess(data?.unlBuyingPtId, data?.wrNum)
          }
          setLoading(false)

          if(lstrxml?.status !== 200){
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists.\n\nError: \n" + lstrxml?.message || '')
            return
          }
          lstrxml = []
          await frmViewWarehouseReceiptReLoad()
        }
      }

      if(lstrxml == []){
        setLoading(false)
      }
    }
    catch(err){
      errorHandler(err, "frmBeginDeliveryAfterCall")
    }
  }

  const onbtnTentApplicationClick = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      setLoading(true)

      await WarehouseReceiptService.UpdateUploadSendToEWarehouse(data?.unlBuyingPtId, data?.wrNum, 
        { 
          flag : 'N',
          user_id : useridFromLS()
        })
      if(await bFormValid()){
        if(await bSaveWarehouseReceipt("Update")){
          msMode = "TENTATIVE_APPLICATION"
          setData(thisObj, 'msMode', "TENTATIVE_APPLICATION")

          let endDate = objVal['txtEndDate']
          if(endDate?.replace("/", "")?.trim() == ""){
            objVal['txtEndDate'] = moment(new Date()).format("MM/DD/YYYY")
          }

          let js = {
            Mode                      : msMode,
            lblUnloadBuyPtIdValue     : data?.buyingPtId,
            lblBuyPtIdValue           : data?.unlBuyingPtId,
            lblBuyPtIdText            : objVal['txtObligationLocation'],
            lblUnloadBuyPtIdText      : data?.buyPointName,
            lblWRNumberValue          : data?.wrNum,
            eWRRcptType               : data?.eWRRcptType,
            methodName                : 'bFillContractApplicationsTentative',
            vendor                    : data?.vendor,
            lblVarietyTag             : objVal['txtVariety']
          }

          setData(thisObj, 'frmWhouseApplications', js)
          goTo(thisObj, "WarehouseReceipts#WhouseApplications#DEFAULT#true#Click")
        }

        setData(thisObj, 'objVal', objVal)
        setFieldValues(thisObj, objVal, true)
      }
    }
    catch(err){
      errorHandler(err, "onbtnTentApplicationClick")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnTentApplicationClick = onbtnTentApplicationClick
  
  const onbtnPrintFSADocClick = async() => {
    try{
      let data =  getData(thisObj, 'ViewWarehouseRecieptData')
      let js = [{
        buyingPt  : data?.unlBuyingPtId,
        wrRcptNum : data?.wrNum,
        docName   : 'rptWarehouseReceipt_FSADocument'
      }]

      setData(thisObj, 'ReportPreviewWRData', js)
      goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnPrintFSADocClick")
    }
  }
  thisObj.onbtnPrintFSADocClick = onbtnPrintFSADocClick;

  const onbtnPrintWorksheetClick = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      let areaId = getData(thisObj, 'msAreaId')
      let peanutTypeId = getData(thisObj, 'lblPeanutType')
      let crBrPct = objVal['txtCrackBroken'] 
      let dColShelPct = objVal['txtDisclrd'] 
      let fanPct = objVal['txtFancy'] 
      let damPct = objVal['txtTotalDamage'] 
      let ss_Pct = 0
      let vicamPct = objVal['txtVICAM']
      let fmPct = objVal['txtFM'] 
      let smk = objVal['txtSMK']
      let ssPct = objVal['txtSS']
      let okPct = objVal['txtOtherKernels']
      let elkPct = objVal['txtELK']
      let lskPct = objVal['txtLSK']
      let moisturePct = 0
      let netWt = objVal['txtTotalLbs']
      let grossWt = 0
      let lskWt = objVal['txtLSKLbs']
      let foreignMatPct = objVal['txtFM']
      
      let Lstrlist = await WarehouseReceiptService.RetrieveWareHouseValueCalculationDetails(areaId, peanutTypeId, crBrPct, dColShelPct, fanPct, damPct, ssPct, vicamPct, fmPct, smk, ss_Pct, okPct, elkPct, lskPct, moisturePct, netWt, grossWt, lskWt, foreignMatPct)
      if(Number(Lstrlist[0].calculation) !== 0){
        // objVal['lblRcptValue'] = Number(Lstrlist[0].total_value_of_load || 0)?.toFixed(2)        
      }
      
      let data =  getData(thisObj, 'ViewWarehouseRecieptData')
      let js = [{
        buyingPt  : data?.unlBuyingPtId,
        wrRcptNum : data?.wrNum,
        rcptValue : data?.eRcptNum,
        docName   : 'rptWarehouseReceiptWorksheet'
      }]

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setData(thisObj, 'ReportPreviewWRData', js)
      goTo(thisObj, "WarehouseReceipts#ReportPreviewWR#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnPrintWorksheetClick")
    }
  }
  thisObj.onbtnPrintWorksheetClick = onbtnPrintWorksheetClick;

  const onbtnApplicationsClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let objVal = getData(thisObj, 'objVal')

      await WarehouseReceiptService.UpdateUploadSendToEWarehouse(data?.unlBuyingPtId, data?.wrNum, 
        { 
          flag : 'N',
          user_id : useridFromLS()
        })
      let js = {
        lblBuyPtId         : data?.unlBuyingPtId,
        lblBuyPtName       : objVal['txtObligationLocation'],
        lblUnloadBuyPtId   : data?.buyingPtId,
        lblUnloadBuyPtName : data?.buyPointName,
        lblWRNum           : data?.wrNum,
        methodName         : "bFillContractApplications"
      }

      setData(thisObj, 'frmViewWRApplicationsData', js)
      goTo(thisObj, "WarehouseReceipts#ViewWRApplications#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnApplicationsClick")
    }
  }
  thisObj.onbtnApplicationsClick = onbtnApplicationsClick;
  
  const onbtnPaymentsClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let js = {
        lblWRNum      : data?.wrNum, 
        lblBuyingPtId : data?.unlBuyingPtId
      } 
          
      setData(thisObj, 'frmViewWRPaymentsloadData', js);
      goTo(thisObj, "WarehouseReceipts#ViewWRPayments#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnPaymentsClick")
    }
  }
  thisObj.onbtnPaymentsClick = onbtnPaymentsClick;

  const onbtnDeductTrackClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let js = {
        buy_point_id : data?.unlBuyingPtId,
        source_type  : "WHS_RCPT",
        id_number_1  : data?.wrNum,
        id_number_2  : "0"
      }

      setData(thisObj, "ReviewDeductionTrackingFormLoadData", js);
      goTo(thisObj, "Settlements#ReviewDeductionTracking#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnDeductTrackClick")
    }
  }
  thisObj.onbtnDeductTrackClick = onbtnDeductTrackClick;

  const onbtnViewEWRLogClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      const viewEWRLogData = {
        selectedBuyingPoint: data?.unlBuyingPtId,
        txtReceiptNumber: data?.wrNum
      }; 

      setData(thisObj, "viewEWRLogData", viewEWRLogData);
      goTo(thisObj, "WarehouseReceipts#ViewEWRLog#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnViewEWRLogClick")
    }
  }
  thisObj.onbtnViewEWRLogClick = onbtnViewEWRLogClick;

  const onbtnCalculatePaymentClick = async() => {
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let frmWhouseRceiptStorageLoadData = {
        lblBuyingPtId : data?.unlBuyingPtId,
        lblWRNum      : data?.wrNum,
        cropyear      : cropYearFromLS(),
        companyId     : compIdFromLS()
      }

      setData(thisObj, "frmWhouseRceiptStorageLoadData", frmWhouseRceiptStorageLoadData)
      goTo(thisObj, 'WarehouseReceipts#WhouseReceiptStorage#DEFAULT#true#Click')
      await bCalStoragePaid()
    }
    catch(err){
      errorHandler(err, "onbtnCalculatePaymentClick")
    }
  }
  thisObj.onbtnCalculatePaymentClick = onbtnCalculatePaymentClick

  const onbtnCompDelvClick = async() => {
    try{

    }
    catch(err){
      errorHandler(err, "onbtnCompDelvClick")
    }
  }
  thisObj.onbtnCompDelvClick = onbtnCompDelvClick

  const onbtnEnableLoanFieldsClick = async() => {
    try{
      if(Number(cropYearFromLS()) >= 2010){
        enable(thisObj, 'txtLoanForfeitedDt')
        enable(thisObj, 'txtCCCStrgPaidThruDt')
        enable(thisObj, 'txtLoanAmt')
        enable(thisObj, 'txtLoanMaturityDt')

        document.getElementsByClassName('txtLoanForfeitedDt')[0].firstChild.lastChild.firstChild.firstChild.style.backgroundColor = 'white'
        document.getElementsByClassName('txtCCCStrgPaidThruDt')[0].firstChild.lastChild.firstChild.firstChild.style.backgroundColor = 'white'
        document.getElementById('txtLoanAmt').style.backgroundColor = 'white'
        document.getElementsByClassName('txtLoanMaturityDt')[0].firstChild.lastChild.firstChild.firstChild.style.backgroundColor = 'white'

        document.getElementsByClassName('txtLoanForfeitedDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'red'
        document.getElementsByClassName('txtCCCStrgPaidThruDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'red'
        document.getElementById('txtLoanAmt').style.color = 'red'
        document.getElementsByClassName('txtLoanMaturityDt')[0].firstChild.lastChild.firstChild.firstChild.style.color = 'red'
      }
    }
    catch(err){
      errorHandler(err, "onbtnEnableLoanFieldsClick")
    }
  }
  thisObj.onbtnEnableLoanFieldsClick = onbtnEnableLoanFieldsClick 

  const onbtnReverseShrinkPaymentClick = async() => {
    try{
      let LstrList = []
      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(confirm("Are you sure you want to reverse this Shrink Payment?  If you click YES, it will be automatically reversed.")){
        setLoading(true)
        LstrList = await WarehouseReceiptService.UpdateShrinkPaymentsReverse(data?.unlBuyingPtId, data?.wrNum)
        if(LstrList?.status == 200){
          alert("The Shrink Payment has been Reversed.")
          
          objVal['radioLoanRepay'] = "1"
  
          objVal['txtPayShrinkOtherRate'] = ""
          objVal['lblShrinkPayLRR'] = ""
          objVal['txtShrinkPayPct'] = ""
          objVal['lblShrinkPayLRR'] = ""
          
          setData(thisObj, 'objVal', objVal)
          setFieldValues(thisObj, objVal, true)

          await bFillGrid()
          await EnableDisableControls()
        }
        else{
          alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (LstrList?.message || ''))
        }
      }
    }
    catch(err){
      errorHandler(err, "onbtnReverseShrinkPaymentClick")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnReverseShrinkPaymentClick = onbtnReverseShrinkPaymentClick

  const onbtnShrinkPaymentClick = async() => {
    try{
      let LstrList = []
      let strMethodInd = ""
      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(objVal['radioMarketLoan']){   
        strMethodInd = "MLR"
      }
      else if(objVal['radioOtherRate']){
        strMethodInd = "OTH"
        if(!isNumeric(objVal['txtOtherRate'])){
          alert("Please enter a valid Pay Shrink Other Rate.")
          document.getElementById('txtOtherRate').focus()
          return
        }
      }
      else{
        strMethodInd = "LRR"
      }

      let endDate = objVal['txtEndDate'] 
      let lockInDate = objVal['txtLnRpyLockInDt']
      let dataObj = {
        "whsE_RCPT_NUM"         : data?.wrNum,
        "chrG_END_DATE"         : endDate?.trim() == "" ? '' : endDate,
        "lrR_LOCK_DATE"         : lockInDate?.trim() == "" ? '' : lockInDate,
        "shrinK_PAY_METHOD_IND" : strMethodInd,
        "loaN_AMOUNT"           : objVal['txtLoanAmt'] || 0, 
        "shrunK_VALUE"          : objVal['txtShrunkValue'] || 0,
        "shrunK_LBS"            : objVal['txtTotalLbsShrunk'] || 0, 
        "unshrunK_LBS"          : objVal['txtTotalLbs'] || 0, 
        "lrR_OVERRIDE_RATE"     : objVal['txtLoanRepayRate'] || 0,
        "otheR_RATE"            : objVal['txtOtherRate'] || 0,
        "useR_ID"               : useridFromLS()
      }

      LstrList = await WarehouseReceiptService.CreateShrinkPay(data?.unlBuyingPtId, getData(thisObj, 'lblPeanutType'), dataObj)
      if(LstrList?.status !== 200){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (LstrList?.message || ''))
        return
      }

      if(LstrList[0]?.shrink_calc !== 0){
        if((objVal['radioLoanRepay'])){ 
          objVal['lblShrinkPayLRR'] = LstrList?.result[0]?.shrink_pay_lrr_rate          
        }
        else{
          objVal['lblShrinkPayLRR'] = ""
        }

        objVal['txtShrinkPayPct'] = LstrList?.result[0]?.shrink_pay_pct
        objVal['txtShrinkPay'] = LstrList?.result[0]?.shrink_pay_pct
      }

      objVal['txtShrinkPay'] = ""

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      setLoading(true)
      await bFillGrid()
      await EnableDisableControls()

      alert("The Shrink Payment has been Created.")
    }
    catch(err){
      errorHandler(err, "onbtnShrinkPaymentClick")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onbtnShrinkPaymentClick = onbtnShrinkPaymentClick;

  const onbtnStorageHandlingPaymentClick = async() =>{
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      let lstrxml = await WarehouseReceiptService.CreateStorageHandlingPaymentDetails(data?.unlBuyingPtId, data?.wrNum)
      if(lstrxml?.status == 200){
        alert("The Storage and Handling Payment has been Created !")
      }
      else{
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
        return
      }

      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      hideShowControlDict['btnStorageHandlingPayment'] = false
      hideShowControlDict['cmdUndoStorehandlePayment'] = false
      hide(thisObj, 'btnStorageHandlingPayment')
      //hide(thisObj, 'cmdUndoStorehandlePayment', false)

      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
    }
    catch(err){
      errorHandler(err, "onbtnStorageHandlingPaymentClick")
    }
  }
  thisObj.onbtnStorageHandlingPaymentClick = onbtnStorageHandlingPaymentClick

  const onbtnTentApplicationReverseClick = async() =>{
    try{
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(!confirm("Are you sure you want to reverse this Tentative Application?  If you click YES, it will be automatically reversed.")){
        return
      }
          
      let lstrxml = await WarehouseReceiptService.UpdateReverseWarehouseReceiptTentApplicationDeatils(data?.unlBuyingPtId, data?.wrNum, data?.buyingPtId)
      if(lstrxml?.status == 500){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
        return
      }
      else if(lstrxml?.status !== 200){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
        await onbtnCancelClick()
        return
      }

      alert("The Tentative Application has been Reversed.")
      await frmViewWarehouseReceiptReLoad()
    }
    catch(err){
      errorHandler(err, "onbtnTentApplicationReverseClick")
    }
  }
  thisObj.onbtnTentApplicationReverseClick = onbtnTentApplicationReverseClick;

  const onbtnUnPurchaseClick = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      if(!confirm("Are you sure you want to unpurchase this warehouse receipt?  If you click YES, it will be automatically UnPurchased.")){
        return
      }
      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      let dataObj = {
        "user_id"       : useridFromLS(),
        "chrg_end_date" : objVal['txtEndDate'],
        "last_date_time": getData(thisObj, 'lblLastChange'),
        "obl_buy_pt_id" : data?.unlBuyingPtId
      }

      let lstrxml = await WarehouseReceiptService.UpdateUnPurchaseWarehouseReceipt(data?.buyingPtId, data?.wrNum, dataObj)
      if(lstrxml?.status == 500 && lstrxml?.message.includes('The information from your screen is no longer current')){
        dataObj.last_date_time = ConvertDateTime(getData(thisObj, 'lblLastChange'))
        lstrxml = await WarehouseReceiptService.UpdateUnPurchaseWarehouseReceipt(data?.buyingPtId, data?.wrNum, dataObj)
      }
      else if(lstrxml?.status == 500){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
        return
      }
      else if(lstrxml?.status !== 200){
        alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
        await onbtnCancelClick()
        return
      }

      const frmFreightLocToLocSetupDataObj = {
        txtcolStatus: "Issued",
        wrNum       :   data?.wrNum
      }

      setData(thisObj, 'dataComingfromChildScreen',frmFreightLocToLocSetupDataObj);
      setData(thisObj, 'frmWarehouseReceiptInquiryReload', true)

      alert("The Warehouse Receipt has been Unpurchased.")
      await frmViewWarehouseReceiptReLoad()
    }
    catch(err){
      errorHandler(err, "onbtnUnPurchaseClick")
    }
  }
  thisObj.onbtnUnPurchaseClick = onbtnUnPurchaseClick

  const onbtnUpdateClick = async() => {
    try{
      let intSendToEWR = ""
      let LstrList = ""
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(await bFormValid()){
        if(await bSaveWarehouseReceipt("Update")){
          alert("The Warehouse Receipt has been updated.")
          intSendToEWR = confirm("Send Warehouse Receipt changes to EWR?")

          LstrList = await WarehouseReceiptService.UpdateUploadSendToEWarehouse(data?.unlBuyingPtId, data?.wrNum, 
            { 
              flag : intSendToEWR ? 'Y' : 'N',
              user_id : useridFromLS()
            })
          await frmViewWarehouseReceiptReLoad()
          return
        }
      }
    }
    catch(err){
      errorHandler(err, "onbtnUpdateClick")
    }
  }
  thisObj.onbtnUpdateClick = onbtnUpdateClick;

  const onbtnVoidClick = async() => {
    try{
      let lstrxml = []
      let objVal = getData(thisObj, 'objVal')
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(isNumeric(objVal['txtShrinkPay'])){
        alert("You must reverse the Shrink Payment before this Warehouse Receipt can be voided!")
        return 
      }

      // if(isVisible(thisObj, 'cmdUndoStorehandlePayment')){
      //   alert("You must reverse the Storage Handle Payment before this Warehouse Receipt can be voided!")
      //   return
      // }

      if(!confirm("Are you sure you want to void this Warehouse Receipt?")){
        return
      }
      else{
        let dataObj = {
          user_id        : useridFromLS(),
          last_date_time : getData(thisObj, 'lblLastChange')
        }

        lstrxml = await WarehouseReceiptService.UpdateWarehouseReceiptVoid(data?.unlBuyingPtId, data?.wrNum, dataObj)
        if(lstrxml?.status == 200){
          alert("The Warehouse Receipt has been voided.")
          await onbtnCancelClick()
        }
        else if(lstrxml?.status !== 200){
          if(lstrxml?.status == 500 && lstrxml?.message.includes('The information from your screen is no longer current')){
            dataObj.last_date_time = ConvertDateTime(dataObj.last_date_time)
            lstrxml = await WarehouseReceiptService.UpdateWarehouseReceiptVoid(data?.unlBuyingPtId, data?.wrNum, dataObj)
            if(lstrxml?.status == 200){
              alert("The Warehouse Receipt has been voided.")
              await onbtnCancelClick()
            }
            else{
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
              return
            }
          }
          else{
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
            return
          }
        }
      }
    }
    catch(err){
      errorHandler(err, "onbtnVoidClick")
    }
  }
  thisObj.onbtnVoidClick = onbtnVoidClick;

  const onbtnUnVoidClick = async() => {
    try{
      let lstrxml = []
      let data = getData(thisObj, 'ViewWarehouseRecieptData')

      if(!confirm("Are you sure you want to unvoid this Warehouse Receipt?")){
        return
      }
      else{
        let dataObj = {
          user_id        : useridFromLS(),
          last_date_time : getData(thisObj, 'lblLastChange')
        }

        lstrxml = await WarehouseReceiptService.UpdateWhouseReceiptUnvoid(data?.unlBuyingPtId, data?.wrNum, dataObj)
        if(lstrxml?.status == 200){
          alert("The Warehouse Receipt has been unvoided.")
        }
        else if(lstrxml?.status !== 200){
          if(lstrxml?.status == 500 && lstrxml?.message.includes('The information from your screen is no longer current')){
            dataObj.last_date_time = ConvertDateTime(dataObj.last_date_time)
            lstrxml = await WarehouseReceiptService.UpdateWarehouseReceiptVoid(data?.unlBuyingPtId, data?.wrNum, dataObj)
            if(lstrxml?.status == 200){
              alert("The Warehouse Receipt has been unvoided.")
            }
            else{
              alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
              return
            }
          }
          else{
            alert("An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists. \n\nError: \n\n" + (lstrxml?.message || ''))
            return
          }
        }

        await frmViewWarehouseReceiptReLoad()
      }
    }
    catch(err){
      errorHandler(err, "onbtnUnVoidClick")
    }
  }
  thisObj.onbtnUnVoidClick = onbtnUnVoidClick;

  // const onbtnUndoStorageHandlingPaymentClick = async() => {
  //   try{
  //     let data = getData(thisObj, 'ViewWarehouseRecieptData')
  //     let LstrList = WarehouseReceiptService.UpdateStorageHandlingPaymentReverse(data?.buyingPtId, data?.wrNum)
  //     if(LstrList?.status !== 200){
  //       alert("An error occured while communicating with the database.  Please retry.  Contact your system administrator if this problem persists.\n\n" + LstrList?.message)
  //       return
  //     }
  //     else{
  //       alert("The Storage and Handling Payment has been Reversed !")
  //     }

  //     let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
  //     hideShowControlDict['btnStorageHandlingPayment'] = false
  //     hideShowControlDict['btnUndoStorageHandlingPayment'] = false
  //     hide(thisObj, 'btnStorageHandlingPayment')
  //     hide(thisObj, 'btnUndoStorageHandlingPayment')
  //     setData(thisObj, 'hideShowControlDict', hideShowControlDict)
  //   }
  //   catch(err){
  //     errorHandler(err, "onbtnUndoStorageHandlingPaymentClick")
  //   }
  // }
  // thisObj.onbtnUndoStorageHandlingPaymentClick = onbtnUndoStorageHandlingPaymentClick

  const onbtnCancelClick = async() => {
    try{
      document.getElementById("WarehouseReceipts_ViewWarehouseReceiptPopUp").childNodes[0].click()
    }
    catch(err){
      errorHandler(err, "onbtnCancelClick")
    }
  }
  thisObj.onbtnCancelClick = onbtnCancelClick;

  const onbtnVendorClick = async() => {
    try{
      setData(thisObj, 'vendorType', 'Vendor')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnVendorClick")
    }
  }
  thisObj.onbtnVendorClick = onbtnVendorClick;

  const onbtnRepayVendorClick = async() => {
    try{
      setData(thisObj, 'vendorType', 'Repay Vendor')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnbtnRepayVendorClick")
    }
  }
  thisObj.onbtnRepayVendorClick = onbtnRepayVendorClick;
  
  const onbtnCMAVendorClick = async() => {
    try{
      setData(thisObj, 'vendorType', 'CMA Vendor')
      goTo(thisObj, "ContractManagement#VendorLookup#DEFAULT#true#Click")
    }
    catch(err){
      errorHandler(err, "onbtnCMAVendorClick")
    }
  }
  thisObj.onbtnCMAVendorClick = onbtnCMAVendorClick;
  
  // buttons ends

  const onchkboxCMAChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxCMA'] = event?.target?.value ? 1 : 0

      let chkCMA = objVal['chkboxCMA']
      let chkDMA = objVal['chkboxDMA']
      if(chkCMA == 0 && chkDMA == 0){
        objVal['txtVendorNum'] = ''        
        hide(thisObj, 'txtVndr', false)

        thisObj.setState(current => {
          return {
            ...current,
            ddRemit: {
              ...current["ddRemit"],
              valueList: []
            }
          }
        })
        
        objVal['ddRemit'] = ''
      }
      
      if(chkCMA == 1){
        objVal['chkboxDMA'] = 0
        disable(thisObj, 'chkboxDMA')
      }
      else{
        enable(thisObj, 'chkboxDMA')
      }
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkboxCMAChange")
    }
  }
  thisObj.onchkboxCMAChange = onchkboxCMAChange;

  const onchkboxDMAChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxDMA'] = event?.target?.value ? 1 : 0

      let chkCMA = objVal['chkboxCMA'] 
      let chkDMA = objVal['chkboxDMA'] 
      if(chkCMA == 0 && chkDMA == 0){
        objVal['txtVendorNum'] = ''
        objVal['txtVndr'] = ''

        hide(thisObj, 'txtVndr', false)

        thisObj.setState(current => {
          return {
            ...current,
            ddRemit: {
              ...current["ddRemit"],
              valueList: []
            }
          }
        })
        
        objVal['ddRemit'] = ''
      }
      
      if(chkDMA == 1){        
        objVal['chkboxCMA'] = 0
        disable(thisObj, 'chkboxCMA')
      }
      else{
        enable(thisObj, 'chkboxCMA')
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkboxDMAChange")
    }
  }
  thisObj.onchkboxDMAChange = onchkboxDMAChange;

  const onchkboxOverrideLRRChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxOverrideLRR'] = event?.target?.value ? 1 : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      let chkOverrideLRR = objVal['chkboxOverrideLRR']
      if(chkOverrideLRR == 1){
        enable(thisObj, 'txtLoanRepayRate')
        if(!getData(thisObj, 'mbSuppressOverrideLRRClick')){
          document.getElementById('txtLoanRepayRate').focus();
        }
      }
      else{
        await CalcStorage()
        disable(thisObj, 'txtLoanRepayRate')
      }
      setData(thisObj, 'mbSuppressOverrideLRRClick', false)
    }
    catch(err){
      errorHandler(err, "onchkboxOverrideLRRChange")
    }
  }
  thisObj.onchkboxOverrideLRRChange = onchkboxOverrideLRRChange;

  const onchkboxWireTransferChange = async(event) => {
    try{
      let n = 0;
      let sTemp = "";
      
      let objVal = getData(thisObj, 'objVal')
      let hideShowControlDict = getData(thisObj, 'hideShowControlDict')
      
      objVal['chkboxWireTransfer'] = event?.target?.value ? 1 : 0
      let chkWireTransfer = objVal['chkboxWireTransfer']
      if(chkWireTransfer == 0){
        setLoading(true)
        await DisplayWireTransfer("", "")
        setLoading(false)

        enable(thisObj, 'chkboxWireTransfer')
        setData(thisObj, 'chkboxWireTransferBool', true)

        objVal['txtWireTransfer'] = ''
        
        disable(thisObj, 'txtWireTransfer')
        hideShowControlDict['txtWireTransfer'] = true
        show(thisObj, 'txtWireTransfer')
        document.getElementById('txtWireTransfer').style.backgroundColor = '#e4e4e4'
      }
      else{
        enable(thisObj, 'chkboxWireTransfer')
        setData(thisObj, 'chkboxWireTransferBool', true)

        let repayVendor = objVal['txtRepayVendorNum']
        
        setLoading(true)
        await DisplayWireTransfer(repayVendor , getData(thisObj, 'msWireXferDataSaved'))
        setLoading(false)
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setData(thisObj, 'hideShowControlDict', hideShowControlDict)
    }
    catch(err){
      errorHandler(err, "onchkboxWireTransferClick")
    }
  }
  thisObj.onchkboxWireTransferChange = onchkboxWireTransferChange

  const onchkboxDeferredRedemptionChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxDeferredRedemption'] = event?.target?.value ? 1 : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkboxDeferredRedemptionChange")
    }
  }
  thisObj.onchkboxDeferredRedemptionChange = onchkboxDeferredRedemptionChange

  const onchkboxCCC500Change = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxCCC500'] = event?.target?.value ? 1 : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkboxCCC500Change")
    }
  }
  thisObj.onchkboxCCC500Change = onchkboxCCC500Change

  const onchkbox211Change = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkbox211'] = event?.target?.value ? 1 : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkbox211Change")
    }
  }
  thisObj.onchkbox211Change = onchkbox211Change

  const onchkboxCommittedToOtherChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['chkboxCommittedToOther'] = event?.target?.value ? 1 : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onchkbox211Change")
    }
  }
  thisObj.onchkboxCommittedToOtherChange = onchkboxCommittedToOtherChange

  const onradioElectronicPaperChange = async(event) => {
    try{
      setData(thisObj, 'mboolElectronicWarehouseReceipt', true)
      setData(thisObj, 'mbReceiptTypeChanged', true)

    }
    catch(err){
      errorHandler(err, "onradioElectronicPaperChange")
    }
  }
  thisObj.onradioElectronicPaperChange = onradioElectronicPaperChange

  const onradioRedemptionMethodChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['radioRedemptionMethod'] = event?.target?.value
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onradioRedemptionMethodClick")
    }
  }
  thisObj.onradioRedemptionMethodChange = onradioRedemptionMethodChange

  const onradioLoanRepayChange = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtOtherRate'] = ""
      objVal['radioMarketLoan'] = "0"
      objVal['radioOtherRate'] = "0"
      objVal['radioLoanRepay'] = "1"
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setLoading(true)
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err, "onradioLoanRepayChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onradioLoanRepayChange = onradioLoanRepayChange

  const onradioMarketLoanChange = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtOtherRate'] = ""
      
      objVal['radioMarketLoan'] = "1"
      objVal['radioOtherRate'] = "0"
      objVal['radioLoanRepay'] = "0"

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setLoading(true)
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err, "onradioMarketLoanChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onradioMarketLoanChange = onradioMarketLoanChange

  const onradioOtherRateChange = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['radioMarketLoan'] = "0"
      objVal['radioOtherRate'] = "1"
      objVal['radioLoanRepay'] = "0"

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setLoading(true)
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err, "onradioOtherRateChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.onradioOtherRateChange = onradioOtherRateChange

  const ontxtCMAVendorNumChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCMAVendorNum'] = event?.target?.value

      if(document.activeElement == null){
        if(!await bFillDMARemitToList(event?.target?.value)){
          objVal['txtCMAVendorNum'] = event?.target?.value
        }
      }
      else{
        if(document.activeElement?.id !== "txtCMAVendorNum"){  
          if(!await bFillDMARemitToList(event?.target?.value)){
            objVal['txtCMAVendorNum'] = event?.target?.value
          }
        }
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtCMAVendorNumChange")
    }
  }
  thisObj.ontxtCMAVendorNumChange = ontxtCMAVendorNumChange

  const ontxtRepayVendorNumChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtRepayVendorNum'] = event?.target?.value

      if(document.activeElement == null){
        if(!await bFillRemitToList(event?.target?.value)){
          objVal['txtRepayVendorNum'] = event?.target?.value
        }
      }
      else{
        if(document.activeElement?.id !== "txtRepayVendorNum"){  
          if(!await bFillRemitToList(event?.target?.value)){
            objVal['txtRepayVendorNum'] = event?.target?.value
          }
        }
      }

      if(event?.target?.value?.trim()?.length > 5){
        if(await CheckForCCCVendor(event?.target?.value)){
          objVal['chkboxWireTransfer'] = 1

          setLoading(true)
          await DisplayWireTransfer(event?.target?.value, "")
          setLoading(false)
        }
        else{
          objVal['chkboxWireTransfer'] = 0
        }
      }
      else{
        objVal['chkboxWireTransfer'] = 0
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtRepayVendorNumChange")
    }
  }
  thisObj.ontxtRepayVendorNumChange = ontxtRepayVendorNumChange

  const ontxtLoanRepayRateBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanRepayRate'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtLoanRepayRateBlur")
    }
  }
  thisObj.ontxtLoanRepayRateBlur = ontxtLoanRepayRateBlur

  const onddRepayRemitChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['ddRepayRemit'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onddRepayRemitChange")
    }
  }
  thisObj.onddRepayRemitChange = onddRepayRemitChange

  const onddCMARemitChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['ddCMARepayRemit'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onddCMARemitChange")
    }
  }
  thisObj.onddCMARemitChange = onddCMARemitChange

  const onddRemitChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['ddRemit'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onddRemitChange")
    }
  }
  thisObj.onddRemitChange = onddRemitChange
  
  const onddCurrentHolderChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['ddCurrentHolder'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "onddCurrentHolderChange")
    }
  }
  thisObj.onddCurrentHolderChange = onddCurrentHolderChange

  const ontxtLoanRepayAmtOverrideBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanRepayAmtOverride'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtLoanRepayAmtOverrideBlur")
    }
  }
  thisObj.ontxtLoanRepayAmtOverrideBlur = ontxtLoanRepayAmtOverrideBlur

  const ontxtStrgCreditAmtOverrideChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtStrgCreditAmtOverride'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtStrgCreditAmtOverrideChange")
    }
  }
  thisObj.ontxtStrgCreditAmtOverrideChange = ontxtStrgCreditAmtOverrideChange

  const ontxtDelvCompletedDtChange = async(event) => {
    try{
      setLoading(true)
      let objVal = getData(thisObj, 'objVal')
      objVal['txtDelvCompletedDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      await EnableDisableControls()
    }
    catch(err){
      errorHandler(err, "ontxtDelvCompletedDtChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtDelvCompletedDtChange = ontxtDelvCompletedDtChange

  const ontxtDocDelvDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      // if( [ undefined, null, '' ].includes(event?.target?.value?._d)){
      //   return
      // }
      // else if(objVal['txtDocDelvDt'] == moment(event?.target?.value?._d).format('MM/DD/YYYY')){
      //   return
      // }
      objVal['txtDocDelvDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      setLoading(true)
      await EnableDisableControls()
      //setLoading(false)
    }
    catch(err){
      //setLoading(false)
      errorHandler(err, "ontxtDocDelvDtChange")
    }
    finally{
      setLoading(false)
    }
  }
  thisObj.ontxtDocDelvDtChange = ontxtDocDelvDtChange 

  const ontxtInChargesAndAsstCostsChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtHandlingAmt'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtInChargesAndAsstCostsChange")
    }
  }
  thisObj.ontxtInChargesAndAsstCostsChange = ontxtInChargesAndAsstCostsChange 

  const ontxtInterestAmtOverrideChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtInterestAmtOverride'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtInterestAmtOverrideChange")
    }
  }
  thisObj.ontxtInterestAmtOverrideChange = ontxtInterestAmtOverrideChange

  const ontxtInterestAmtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtInterestAmt'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtInterestAmtChange")
    }
  }
  thisObj.ontxtInterestAmtChange = ontxtInterestAmtChange

  const ontxtLoanRepayAmtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanRepayAmt'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtLoanRepayAmtChange")
    }
  }
  thisObj.ontxtLoanRepayAmtChange = ontxtLoanRepayAmtChange

  const ontxtStrgCreditAmtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtStrgCreditAmt'] = isNumeric(event?.target?.value) ? event?.target?.value : 0

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      
      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtStrgCreditAmtChange")
    }
  }
  thisObj.ontxtStrgCreditAmtChange = ontxtStrgCreditAmtChange

  const ontxtHandlingAmtOverrideChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtHandlingAmtOverride'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      await CalcNetRepayment()
    }
    catch(err){
      errorHandler(err, "ontxtHandlingAmtOverrideChange")
    }
  }
  thisObj.ontxtHandlingAmtOverrideChange = ontxtHandlingAmtOverrideChange

  const ontxtRcptNumChange = async() => {
    try{
      // setData(thisObj, 'mbFillGridError', false)

      let data = getData(thisObj, 'ViewWarehouseRecieptData')
      if(data?.buyingPtId !== "" && data?.wrNum !== ""){
        if(!await bFillGrid()){
          // setData(thisObj, 'mbFillGridError', true)
        }
      }
      else{

      }
    }
    catch(err){
      errorHandler(err, "ontxtRcptNumChange")
    }
  }
  thisObj.ontxtRcptNumChange = ontxtRcptNumChange

  const ontxtStatusChange = async(event) => {
    try{
      let value = event?.target?.value || ''
      if(value == "Delv. Out" || value == "Delivery In Process"){
        enable(thisObj, 'txtDelvCompletedDt')
        // document.getElementById('txtDelvCompletedDt').style.backgroundColor = 'white' // &H8000000B
      }
      else{
        disable(thisObj, 'txtDelvCompletedDt')
        // document.getElementById('txtDelvCompletedDt').style.backgroundColor = '#F8F4F4' // &H8000000B
      }
    }
    catch(err){
      errorHandler(err, "ontxtStatusChange")
    }
  }
  thisObj.ontxtStatusChange = ontxtStatusChange

  const ontxtVendorNumChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')      
//PRB0054498 - Searching the vendors with vendor number from vendor textbox and convert to uppercase  
objVal['txtVendorNum'] = event?.target?.value?.toUpperCase()
  
      if(document.activeElement == null){
        if(! await CFillRemitToList(event?.target?.value)){
          objVal['txtVendorNum'] = event?.target?.value
        }
      } 
      else{
        if(document.activeElement?.id !== "txtVendorNum"){
          if(!await CFillRemitToList(event?.target?.value)){
            objVal['txtVendorNum'] = event?.target?.value
          }
        }
      }
  
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    } 
    catch(err){
      errorHandler(err, "ontxtVendorNumChange")
    }
  }
  thisObj.ontxtVendorNumChange = ontxtVendorNumChange  

  const ontxtLoanMaturityDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanMaturityDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtLoanMaturityDtChange")
    }
  }
  thisObj.ontxtLoanMaturityDtChange = ontxtLoanMaturityDtChange

  const ontxtLoanDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtLoanMaturityDtChange")
    }
  }
  thisObj.ontxtLoanDtChange = ontxtLoanDtChange

  const ontxtLoanForfeitedDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanForfeitedDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtLoanForfeitedDtChange")
    }
  }
  thisObj.ontxtLoanForfeitedDtChange = ontxtLoanForfeitedDtChange

  const ontxtCCCStrgPaidThruDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCCCStrgPaidThruDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtCCCStrgPaidThruDtChange")
    }
  }
  thisObj.ontxtCCCStrgPaidThruDtChange = ontxtCCCStrgPaidThruDtChange

  const ontxtProducerBeginDateChange = async(event) =>{
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtProducerBeginDate'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtProducerBeginDateChange")
    }
  }
  thisObj.ontxtProducerBeginDateChange = ontxtProducerBeginDateChange

  const ontxtCCCBeginDateChange = async(event) =>{
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCCCBeginDate'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtCCCBeginDateChange")
    }
  }
  thisObj.ontxtCCCBeginDateChange = ontxtCCCBeginDateChange

  const ontxtEndDateChange = async(event) =>{
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtEndDate'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtEndDateChange")
    }
  }
  thisObj.ontxtEndDateChange = ontxtEndDateChange

  const ontxtStrgPaidThroughDtChange = async(event) =>{
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtStrgPaidThroughDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtStrgPaidThroughDtChange")
    }
  }
  thisObj.ontxtStrgPaidThroughDtCha3nge = ontxtStrgPaidThroughDtChange

  const ontxtLnRpyLockInDtChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLnRpyLockInDt'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtLnRpyLockInDtChange")
    }
  }
  thisObj.ontxtLnRpyLockInDtChange = ontxtLnRpyLockInDtChange

  const ontxtCommittedToChange = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCommittedTo'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtCommittedToChange")
    }
  }
  thisObj.ontxtCommittedToChange = ontxtCommittedToChange

  const ontxtHandlingAmtOverrideBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtHandlingAmtOverride'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtHandlingAmtOverrideBlur")
    }
  }
  thisObj.ontxtHandlingAmtOverrideBlur = ontxtHandlingAmtOverrideBlur

  const ontxtCCCBeginDateBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCCCBeginDate'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
      await CalcStorage()
    }
    catch(err){
      errorHandler(err, "ontxtCCCBeginDateBlur")
    }
  }
  thisObj.ontxtCCCBeginDateBlur = ontxtCCCBeginDateBlur

  const ontxtConcordia1Blur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtConcordia1'] = event?.target?.value

      if(await ValidControl('txtConcordia1', event?.target?.value, "XXXXX")){
        setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
      }
      else{
        alert("Invalid wire transfer information. Field must have 5 digits.")
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtConcordia1Blur")
    }
  }
  thisObj.ontxtConcordia1Blur = ontxtConcordia1Blur

  const ontxtConcordia3Blur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtConcordia3'] = event?.target?.value

      setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtConcordia3Blur")
    }
  }
  thisObj.ontxtConcordia3Blur = ontxtConcordia3Blur

  const ontxtEndDateBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtEndDate'] = DateValidator(event?.target?.value) //[ undefined, null, '' ].includes(event?.target?.value?._d) ? '' : moment(event?.target?.value?._d).format('MM/DD/YYYY')
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)

      await CalcStorage()
    }
    catch(err){
      errorHandler(err, "ontxtEndDateBlur")
    }
  }
  thisObj.ontxtEndDateBlur = ontxtEndDateBlur

  const ontxtInterestAmtOverrideBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtInterestAmtOverride'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtInterestAmtOverrideBlur")
    }
  }
  thisObj.ontxtInterestAmtOverrideBlur = ontxtInterestAmtOverrideBlur

  const ontxtLnNumberBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLnNumber'] = event?.target?.value

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)     
    }
    catch(err){
      errorHandler(err, "ontxtLnNumberBlur")
    }
  }
  thisObj.ontxtLnNumberBlur = ontxtLnNumberBlur

  const ontxtLnCountyBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLnCounty'] = event?.target?.value
      // if(isVisible(thisObj, 'txtwiretransferdata3')){
        objVal['txtWireTransferData3'] = objVal['txtLnCounty']
        setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
      //}  

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)     
    }
    catch(err){
      errorHandler(err, "ontxtLnCountyBlur")
    }
  }
  thisObj.ontxtLnCountyBlur = ontxtLnCountyBlur

  const ontxtLnStateBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLnState'] = event?.target.value
      // if(isVisible(thisObj, 'txtwiretransferdata2')){
        objVal['txtWireTransferData2'] = objVal['txtLnState']
        setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
      // } 

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)               
    }
    catch(err){
      errorHandler(err, "ontxtLnStateBlur")
    }
  }
  thisObj.ontxtLnStateBlur = ontxtLnStateBlur

  const ontxtLoanAmtBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLoanAmt'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)     
    }
    catch(err){
      errorHandler(err, "ontxtLoanAmtBlur")
    }
  }
  thisObj.ontxtLoanAmtBlur = ontxtLoanAmtBlur

  const ontxtLnRpyLockInDtBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtLnRpyLockInDt'] = DateValidator(event?.target?.value)
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)     
      await CalcStorage()
    }
    catch(err){
      errorHandler(err, "ontxtLnRpyLockInDtBlur")
    }
  }
  thisObj.ontxtLnRpyLockInDtBlur = ontxtLnRpyLockInDtBlur

  const ontxtMarketGainBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtMarketGain'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtMarketGainBlur")
    }
  }
  thisObj.ontxtMarketGainBlur = ontxtMarketGainBlur

  const ontxtProducerBeginDateBlur = async(event) => {
    try{
      await CalcStorage()
    }
    catch(err){
      errorHandler(err, "ontxtProducerBeginDateBlur")
    }
  }
  thisObj.ontxtProducerBeginDateBlur = ontxtProducerBeginDateBlur

  const ontxtStrgCreditAmtOverrideBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtStrgCreditAmtOverride'] = ConvertToFloatNumber(event?.target?.value)
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtStrgCreditAmtOverrideBlur")
    }
  }
  thisObj.ontxtStrgCreditAmtOverrideBlur = ontxtStrgCreditAmtOverrideBlur

  const ontxtWireTransferData4Blur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtWireTransferData4'] = event?.target?.value

      if(await ValidControl('txtWireTransferData4', event?.target?.value, "X")){
        setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
      }
      else{
        alert("Invalid wire transfer information. Check Digit must have 1 digits.")
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtWireTransferData4Blur")
    }
  }
  thisObj.ontxtWireTransferData4Blur = ontxtWireTransferData4Blur

  const ontxtWireTransferData6Blur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtWireTransferData6'] = event?.target?.value

      if(await ValidControl('txtWireTransferData6', event?.target?.value, "XXXX")){
        setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
      }
      else{
        alert("Invalid wire transfer information. Log Number must have 4 digits.")
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtWireTransferData6Blur")
    }
  }
  thisObj.ontxtWireTransferData6Blur = ontxtWireTransferData6Blur

  const ontxtWireXferDataBlur = async() => {
    try{
      let objVal = getData(thisObj, 'objVal')
      setData(thisObj, 'msWireXferDataSaved', await BuildWireTransfer(objVal['txtRepayVendorNum']))
    }
    catch(err){
      errorHandler(err, "ontxtWireXferDataBlur")
    }
  }
  thisObj.ontxtWireXferDataBlur = ontxtWireXferDataBlur

  const ontxtRepayVendorNumBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtRepayVendor'] = event?.target?.value

      if(! await bFillRemitToList(event?.target?.value)){
      }      
      
      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtRepayVendorNumBlur")
    }
  }
  thisObj.ontxtRepayVendorNumBlur = ontxtRepayVendorNumBlur

  const ontxtCMAVendorNumBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtCMAVendorNum'] = event?.target?.value
      
      if(! await bFillDMARemitToList(event?.target?.value)){
      }  

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtCMAVendorNumBlur")
    }
  }
  thisObj.ontxtCMAVendorNumBlur = ontxtCMAVendorNumBlur

  const ontxtVendorNumBlur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtRepayVendor'] = event?.target?.value

      if(! await CFillRemitToList(event?.target?.value)){
      }

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtVendorNumBlur")
    }
  }
  thisObj.ontxtVendorNumBlur = ontxtVendorNumBlur

  const ontxtMarketGain1Blur = async(event) => {
    try{
      let objVal = getData(thisObj, 'objVal')
      objVal['txtMarketGain1'] = ConvertToFloatNumber(event?.target?.value)

      setData(thisObj, 'objVal', objVal)
      setFieldValues(thisObj, objVal, true)
    }
    catch(err){
      errorHandler(err, "ontxtMarketGain1Blur")
    }
  }
  thisObj.ontxtMarketGain1Blur = ontxtMarketGain1Blur

  function isNumeric(n) {
    try{
      return !isNaN(parseFloat(n)) && isFinite(n);
    } 
    catch(err){
      errorHandler(err, "isNumeric")
    }
  }

  function sortArrayFunction(a, b) {
    try{
      return (a[0] === b[0])
             ? 0
             : (a[0] < b[0]) ? -1 : 1
    }
    catch(err){
      errorHandler(err, "sortArrayFunction")
    }
  }

  function isDateValid(dateStr) {
    try{
      return !isNaN(new Date(dateStr));
    }
    catch(err){
      errorHandler(err, "isDateValid")
    }
  }

  function ConvertDateTime(dateTime){
    try{
      let h = new Date(dateTime)?.getHours() 
      let m = new Date(dateTime)?.getMinutes()
      let s = new Date(dateTime)?.getSeconds()
      return [ undefined, null, '' ].includes(dateTime) 
              ? '' 
              :   new Date(dateTime)?.getMonth() + 1 + "/"
                + new Date(dateTime)?.getDate()      + "/"
                + new Date(dateTime)?.getFullYear()  + " " 
                + (h == 0 ? 12 : h > 12 ? h -12 : h) + ":"
                + (m <= 9 ? '0'+ m : m)              + ":"
                + (s <= 9 ? '0'+ s : s)              + ".499 " 
                + (['PM', 'AM'].includes(dateTime?.slice(-2)) ? dateTime?.slice(-2) : 'AM')
    }
    catch(err){
      errorHandler(err, "ConvertDateTime")
    }    
  }

  function ConvertToFloatNumber(n){
    try{
      return [ undefined, null, '', NaN].includes(n)
              ? '0.00' 
              : parseFloat(n).toFixed(2)
    }
    catch(err){
      errorHandler(err, "ConvertToFloatNumber")
    }
  }

  function errorHandler(err, action){
    showMessage(thisObj, 
      err instanceof EvalError
        ? err.message
        : "Something went wrong for " + action + ". Please try again later.")
  } 

  function DateValidator(dateValue){
    try{
      if(![ undefined, null, '' ].includes(dateValue)){
        return moment(dateValue).format('MM/DD/YYYY')
      }
      else if(![ undefined, null, '' ].includes(dateValue?._d)){
        return moment(dateValue?._d).format('MM/DD/YYYY')
      }
      else{
        return ''
      }
    }
    catch(err){
      errorHandler(err, "DateValidator")
    }
  }

  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
        setValues
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        thisObj.setValues = setValues = setValues;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <Loading loading={loading}></Loading>
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_ViewWarehouseReceipt*/}

              {/* END_USER_CODE-USER_BEFORE_ViewWarehouseReceipt*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxViewWarehouseReceipt*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxViewWarehouseReceipt*/}

              <GroupBoxWidget
                conf={state.grpbxViewWarehouseReceipt}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo*/}

                <GroupBoxWidget
                  conf={state.grpbxReceiptInfo}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo1*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo1*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceiptInfo1}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblReceiptInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblReceiptInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblReceiptInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblReceiptInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblReceiptInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                    {/* END_USER_CODE-USER_BEFORE_txtObligationLocation*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtObligationLocation}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtObligationLocation*/}

                    {/* END_USER_CODE-USER_AFTER_txtObligationLocation*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWhseBin*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWhseBin*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWhseBin}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWhseBin*/}

                    {/* END_USER_CODE-USER_AFTER_txtWhseBin*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRcptNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRcptNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRcptNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRcptNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtRcptNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtERecptNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtERecptNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtERecptNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtERecptNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtERecptNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCtrlNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCtrlNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCtrlNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCtrlNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtCtrlNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStatus*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStatus}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStatus*/}

                    {/* END_USER_CODE-USER_AFTER_txtStatus*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtForfeitedStatus*/}

                    {/* END_USER_CODE-USER_BEFORE_txtForfeitedStatus*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtForfeitedStatus}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtForfeitedStatus*/}

                    {/* END_USER_CODE-USER_AFTER_txtForfeitedStatus*/}
                    {/* START_USER_CODE-USER_BEFORE_txtIssuedDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtIssuedDate*/}
                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtIssuedDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtIssuedDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtIssuedDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWtdAvgStorageDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWtdAvgStorageDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtWtdAvgStorageDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWtdAvgStorageDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtWtdAvgStorageDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLicenseNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLicenseNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLicenseNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLicenseNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtLicenseNum*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCCCCode*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCCCCode*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCCCCode}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCCCCode*/}

                    {/* END_USER_CODE-USER_AFTER_txtCCCCode*/}
                    {/* START_USER_CODE-USER_BEFORE_txtPeanutType*/}

                    {/* END_USER_CODE-USER_BEFORE_txtPeanutType*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtPeanutType}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtPeanutType*/}

                    {/* END_USER_CODE-USER_AFTER_txtPeanutType*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSeg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSeg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSeg*/}

                    {/* END_USER_CODE-USER_AFTER_txtSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOriginalValue*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOriginalValue*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOriginalValue}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOriginalValue*/}

                    {/* END_USER_CODE-USER_AFTER_txtOriginalValue*/}
                    {/* START_USER_CODE-USER_BEFORE_txtHowStored*/}

                    {/* END_USER_CODE-USER_BEFORE_txtHowStored*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtHowStored}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtHowStored*/}

                    {/* END_USER_CODE-USER_AFTER_txtHowStored*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShelled*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShelled*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShelled}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShelled*/}

                    {/* END_USER_CODE-USER_AFTER_txtShelled*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPct*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPct*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrunkValue*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrunkValue*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrunkValue}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrunkValue*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrunkValue*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo1*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo1*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo2*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo2*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceiptInfo2}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxLstLbl*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxLstLbl*/}

                    <GroupBoxWidget conf={state.grpbxLstLbl} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_lstEWRHolder*/}

                      {/* END_USER_CODE-USER_BEFORE_lstEWRHolder*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.lstEWRHolder}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_lstEWRHolder*/}

                      {/* END_USER_CODE-USER_AFTER_lstEWRHolder*/}
                      {/* START_USER_CODE-USER_BEFORE_lblEWRReceiptType*/}

                      {/* END_USER_CODE-USER_BEFORE_lblEWRReceiptType*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblEWRReceiptType}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblEWRReceiptType*/}

                      {/* END_USER_CODE-USER_AFTER_lblEWRReceiptType*/}
                      {/* START_USER_CODE-USER_BEFORE_lblEWRHolder*/}

                      {/* END_USER_CODE-USER_BEFORE_lblEWRHolder*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblEWRHolder}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblEWRHolder*/}

                      {/* END_USER_CODE-USER_AFTER_lblEWRHolder*/}
                      {/* START_USER_CODE-USER_BEFORE_lstWhouseRemit*/}

                      {/* END_USER_CODE-USER_BEFORE_lstWhouseRemit*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.lstWhouseRemit}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_lstWhouseRemit*/}

                      {/* END_USER_CODE-USER_AFTER_lstWhouseRemit*/}
                      {/* START_USER_CODE-USER_BEFORE_lblEWRTransmissionStatus*/}

                      {/* END_USER_CODE-USER_BEFORE_lblEWRTransmissionStatus*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblEWRTransmissionStatus}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblEWRTransmissionStatus*/}

                      {/* END_USER_CODE-USER_AFTER_lblEWRTransmissionStatus*/}
                      {/* START_USER_CODE-USER_BEFORE_lblRcptValue*/}

                      {/* END_USER_CODE-USER_BEFORE_lblRcptValue*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblRcptValue}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblRcptValue*/}

                      {/* END_USER_CODE-USER_AFTER_lblRcptValue*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxLstLbl*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxLstLbl*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalTons*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalTons*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalTons}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalTons*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalTons*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLSKLbs*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLSKLbs}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLSKLbs*/}

                    {/* END_USER_CODE-USER_AFTER_txtLSKLbs*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrnkPct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrnkPct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrnkPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrnkPct*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrnkPct*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtNetLbsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalLbsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtTotalTonsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtTotalTonsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtTotalTonsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtTotalTonsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtTotalTonsShrunk*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLSKLbsShrunk*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLSKLbsShrunk}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}

                    {/* END_USER_CODE-USER_AFTER_txtLSKLbsShrunk*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo2*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo2*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPayShrink*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPayShrink*/}

                  <GroupBoxWidget
                    conf={state.grpbxPayShrink}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_lblPaymentShrinkUsing*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPaymentShrinkUsing*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPaymentShrinkUsing}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPaymentShrinkUsing*/}

                    {/* END_USER_CODE-USER_AFTER_lblPaymentShrinkUsing*/}
                    {/* START_USER_CODE-USER_BEFORE_radioLoanRepay*/}

                    {/* END_USER_CODE-USER_BEFORE_radioLoanRepay*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioLoanRepay}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioLoanRepay*/}

                    {/* END_USER_CODE-USER_AFTER_radioLoanRepay*/}
                    {/* START_USER_CODE-USER_BEFORE_lblShrinkPayLRR*/}

                    {/* END_USER_CODE-USER_BEFORE_lblShrinkPayLRR*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.lblShrinkPayLRR}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_lblShrinkPayLRR*/}

                    {/* END_USER_CODE-USER_AFTER_lblShrinkPayLRR*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPayPct*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPayPct*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPayPct}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPayPct*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPayPct*/}
                    {/* START_USER_CODE-USER_BEFORE_radioMarketLoan*/}

                    {/* END_USER_CODE-USER_BEFORE_radioMarketLoan*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioMarketLoan}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioMarketLoan*/}

                    {/* END_USER_CODE-USER_AFTER_radioMarketLoan*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPay*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPay*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPay}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPay*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPay*/}
                    {/* START_USER_CODE-USER_BEFORE_radioOtherRate*/}

                    {/* END_USER_CODE-USER_BEFORE_radioOtherRate*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioOtherRate}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioOtherRate*/}

                    {/* END_USER_CODE-USER_AFTER_radioOtherRate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOtherRate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOtherRate*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOtherRate}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOtherRate*/}

                    {/* END_USER_CODE-USER_AFTER_txtOtherRate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPayLRR*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPayLRR*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPayLRR}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPayLRR*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPayLRR*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPayShrink*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPayShrink*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxReceiptInfo3*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxReceiptInfo3*/}

                  <GroupBoxWidget
                    conf={state.grpbxReceiptInfo3}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_chkboxCommittedToOther*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCommittedToOther*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCommittedToOther}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCommittedToOther*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCommittedToOther*/}
                    {/* START_USER_CODE-USER_BEFORE_ddCurrentHolder*/}

                    {/* END_USER_CODE-USER_BEFORE_ddCurrentHolder*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddCurrentHolder}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddCurrentHolder*/}

                    {/* END_USER_CODE-USER_AFTER_ddCurrentHolder*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCommittedTo*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCommittedTo*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCommittedTo}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCommittedTo*/}

                    {/* END_USER_CODE-USER_AFTER_txtCommittedTo*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxCMA*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCMA*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCMA}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCMA*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxCMA*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxDMA*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxDMA*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxDMA}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxDMA*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxDMA*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLastAction*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLastAction*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLastAction}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLastAction*/}

                    {/* END_USER_CODE-USER_AFTER_txtLastAction*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_btnVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVendor*/}

                    <ButtonWidget
                      conf={state.btnVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtVndr*/}

                    {/* END_USER_CODE-USER_BEFORE_txtVndr*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtVndr}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtVndr*/}

                    {/* END_USER_CODE-USER_AFTER_txtVndr*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDateCancelled*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDateCancelled*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDateCancelled}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDateCancelled*/}

                    {/* END_USER_CODE-USER_AFTER_txtDateCancelled*/}
                    {/* START_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                    {/* END_USER_CODE-USER_BEFORE_radioElectronicPaper*/}

                    <RadioButtonGroupWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.radioElectronicPaper}
                      screenConf={state}
                    ></RadioButtonGroupWidget>
                    {/* START_USER_CODE-USER_AFTER_radioElectronicPaper*/}

                    {/* END_USER_CODE-USER_AFTER_radioElectronicPaper*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRemit*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRemit*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRemit}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRemit*/}

                    {/* END_USER_CODE-USER_AFTER_ddRemit*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo3*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo3*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxReceiptInfo*/}

                {/* END_USER_CODE-USER_AFTER_grpbxReceiptInfo*/}
                {/* START_USER_CODE-USER_BEFORE_grpbx1007s*/}

                {/* END_USER_CODE-USER_BEFORE_grpbx1007s*/}

                <GroupBoxWidget conf={state.grpbx1007s} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_lbl1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_lbl1007s*/}

                  <LabelWidget
                    values={values}
                    conf={state.lbl1007s}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lbl1007s*/}

                  {/* END_USER_CODE-USER_AFTER_lbl1007s*/}
                  {/* START_USER_CODE-USER_BEFORE_grid1007s*/}

                  {/* END_USER_CODE-USER_BEFORE_grid1007s*/}

                  <GridWidget
                    conf={state.grid1007s}
                    screenConf={state}
                    linkClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    errors={errors}
                    touched={touched}
                    rows={values.grid1007s}
                    onEvent={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></GridWidget>
                  {/* START_USER_CODE-USER_AFTER_grid1007s*/}

                  {/* END_USER_CODE-USER_AFTER_grid1007s*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbx1007s*/}

                {/* END_USER_CODE-USER_AFTER_grpbx1007s*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxFactorsForAllPeanuts*/}

                <GroupBoxWidget
                  conf={state.grpbxFactorsForAllPeanuts}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_BEFORE_lblFactorsForAllPeanuts*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblFactorsForAllPeanuts}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}

                  {/* END_USER_CODE-USER_AFTER_lblFactorsForAllPeanuts*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtSMK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct1*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct1*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct1}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct1*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct1*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalDamage*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalDamage}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalDamage*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalDamage*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct2*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct2*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct2}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct2*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHullsBright*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHullsBright*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHullsBright}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHullsBright*/}

                  {/* END_USER_CODE-USER_AFTER_txtHullsBright*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct3*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct3*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct3}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct3*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtELK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtELK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtELK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtELK*/}

                  {/* END_USER_CODE-USER_AFTER_txtELK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct4*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct4*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct4}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct4*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct4*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCnclRMD*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCnclRMD*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCnclRMD}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCnclRMD*/}

                  {/* END_USER_CODE-USER_AFTER_txtCnclRMD*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct5*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct5*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct5}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct5*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct5*/}
                  {/* START_USER_CODE-USER_BEFORE_txtSS*/}

                  {/* END_USER_CODE-USER_BEFORE_txtSS*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtSS}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtSS*/}

                  {/* END_USER_CODE-USER_AFTER_txtSS*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct6*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct6*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct6}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct6*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct6*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct7*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct7*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct7}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct7*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct7*/}
                  {/* START_USER_CODE-USER_BEFORE_txtLSK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtLSK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtLSK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtLSK*/}

                  {/* END_USER_CODE-USER_AFTER_txtLSK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct8*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct8*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct8}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct8*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct8*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFancy*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFancy*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFancy}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFancy*/}

                  {/* END_USER_CODE-USER_AFTER_txtFancy*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct9*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct9*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct9}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct9*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct9*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFreezeDam*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFreezeDam*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFreezeDam}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFreezeDam*/}

                  {/* END_USER_CODE-USER_AFTER_txtFreezeDam*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct10*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct10*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct10}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct10*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct10*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalSMK*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalSMK}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalSMK*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalSMK*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct11*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct11*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct11}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct11*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct11*/}
                  {/* START_USER_CODE-USER_BEFORE_txtHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct12*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct12*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct12}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct12*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct12*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFM*/}

                  {/* END_USER_CODE-USER_AFTER_txtFM*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct13*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct13*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct13}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct13*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct13*/}
                  {/* START_USER_CODE-USER_BEFORE_txtCrackBroken*/}

                  {/* END_USER_CODE-USER_BEFORE_txtCrackBroken*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtCrackBroken}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtCrackBroken*/}

                  {/* END_USER_CODE-USER_AFTER_txtCrackBroken*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct14*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct14*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct14}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct14*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct14*/}
                  {/* START_USER_CODE-USER_BEFORE_txtJumbo*/}

                  {/* END_USER_CODE-USER_BEFORE_txtJumbo*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtJumbo}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtJumbo*/}

                  {/* END_USER_CODE-USER_AFTER_txtJumbo*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct15*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct15*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct15}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct15*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct15*/}
                  {/* START_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_BEFORE_txtOtherKernels*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtOtherKernels}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtOtherKernels*/}

                  {/* END_USER_CODE-USER_AFTER_txtOtherKernels*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct16*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct16*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct16}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct16*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct16*/}
                  {/* START_USER_CODE-USER_BEFORE_txtTotalKernHulls*/}

                  {/* END_USER_CODE-USER_BEFORE_txtTotalKernHulls*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtTotalKernHulls}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtTotalKernHulls*/}

                  {/* END_USER_CODE-USER_AFTER_txtTotalKernHulls*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct17*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct17*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct17}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct17*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct17*/}
                  {/* START_USER_CODE-USER_BEFORE_txtMoist*/}

                  {/* END_USER_CODE-USER_BEFORE_txtMoist*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtMoist}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtMoist*/}

                  {/* END_USER_CODE-USER_AFTER_txtMoist*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct18*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct18*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct18}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct18*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct18*/}
                  {/* START_USER_CODE-USER_BEFORE_txtDisclrd*/}

                  {/* END_USER_CODE-USER_BEFORE_txtDisclrd*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtDisclrd}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtDisclrd*/}

                  {/* END_USER_CODE-USER_AFTER_txtDisclrd*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPct19*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPct19*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPct19}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPct19*/}

                  {/* END_USER_CODE-USER_AFTER_lblPct19*/}
                  {/* START_USER_CODE-USER_BEFORE_txtFlavus*/}

                  {/* END_USER_CODE-USER_BEFORE_txtFlavus*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtFlavus}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtFlavus*/}

                  {/* END_USER_CODE-USER_AFTER_txtFlavus*/}
                  {/* START_USER_CODE-USER_BEFORE_txtVICAM*/}

                  {/* END_USER_CODE-USER_BEFORE_txtVICAM*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtVICAM}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtVICAM*/}

                  {/* END_USER_CODE-USER_AFTER_txtVICAM*/}
                  {/* START_USER_CODE-USER_BEFORE_lblPPB*/}

                  {/* END_USER_CODE-USER_BEFORE_lblPPB*/}

                  <LabelWidget
                    values={values}
                    conf={state.lblPPB}
                    screenConf={state}
                  ></LabelWidget>
                  {/* START_USER_CODE-USER_AFTER_lblPPB*/}

                  {/* END_USER_CODE-USER_AFTER_lblPPB*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}

                {/* END_USER_CODE-USER_AFTER_grpbxFactorsForAllPeanuts*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxStrgPrchs*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxStrgPrchs*/}

                <GroupBoxWidget conf={state.grpbxStrgPrchs} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxStorage*/}

                  <GroupBoxWidget conf={state.grpbxStorage} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblStorage*/}

                    {/* END_USER_CODE-USER_BEFORE_lblStorage*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblStorage}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblStorage*/}

                    {/* END_USER_CODE-USER_AFTER_lblStorage*/}
                    {/* START_USER_CODE-USER_BEFORE_txtProducerBeginDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtProducerBeginDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtProducerBeginDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtProducerBeginDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtProducerBeginDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCCCBeginDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCCCBeginDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCCCBeginDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCCCBeginDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtCCCBeginDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtEndDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtEndDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtEndDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtEndDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtEndDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDocDelvDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDocDelvDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDocDelvDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDocDelvDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtDocDelvDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtDelvCompletedDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtDelvCompletedDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtDelvCompletedDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtDelvCompletedDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtDelvCompletedDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStrgPaidThroughDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStrgPaidThroughDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStrgPaidThroughDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStrgPaidThroughDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtStrgPaidThroughDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStorageCharge*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStorageCharge*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStorageCharge}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStorageCharge*/}

                    {/* END_USER_CODE-USER_AFTER_txtStorageCharge*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInCharges*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInCharges*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInCharges}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInCharges*/}

                    {/* END_USER_CODE-USER_AFTER_txtInCharges*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxStoreAndHandleReceived*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxStoreAndHandleReceived*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxStoreAndHandleReceived}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxStoreAndHandleReceived*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxStoreAndHandleReceived*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInspeactionFees*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInspeactionFees*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInspeactionFees}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInspeactionFees*/}

                    {/* END_USER_CODE-USER_AFTER_txtInspeactionFees*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxStoreAndHandlePaid*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxStoreAndHandlePaid*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxStoreAndHandlePaid}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxStoreAndHandlePaid*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxStoreAndHandlePaid*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInChargesAndAsstCosts*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInChargesAndAsstCosts*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInChargesAndAsstCosts}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInChargesAndAsstCosts*/}

                    {/* END_USER_CODE-USER_AFTER_txtInChargesAndAsstCosts*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxStorage*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxStorage*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPurchase*/}

                  <GroupBoxWidget conf={state.grpbxPurchase} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_lblPurchaseInfo*/}

                    {/* END_USER_CODE-USER_BEFORE_lblPurchaseInfo*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblPurchaseInfo}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblPurchaseInfo*/}

                    {/* END_USER_CODE-USER_AFTER_lblPurchaseInfo*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOverrideFarm*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOverrideFarm*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOverrideFarm}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOverrideFarm*/}

                    {/* END_USER_CODE-USER_AFTER_txtOverrideFarm*/}
                    {/* START_USER_CODE-USER_BEFORE_txtFarmerStockCost*/}

                    {/* END_USER_CODE-USER_BEFORE_txtFarmerStockCost*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtFarmerStockCost}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtFarmerStockCost*/}

                    {/* END_USER_CODE-USER_AFTER_txtFarmerStockCost*/}
                    {/* START_USER_CODE-USER_BEFORE_txtValueOfSeg*/}

                    {/* END_USER_CODE-USER_BEFORE_txtValueOfSeg*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtValueOfSeg}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtValueOfSeg*/}

                    {/* END_USER_CODE-USER_AFTER_txtValueOfSeg*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSpotPounds*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSpotPounds*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSpotPounds}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSpotPounds*/}

                    {/* END_USER_CODE-USER_AFTER_txtSpotPounds*/}
                    {/* START_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtOptionPayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtOptionPayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtOptionPayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtOptionPayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                    {/* END_USER_CODE-USER_BEFORE_txtSpotPrice*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtSpotPrice}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtSpotPrice*/}

                    {/* END_USER_CODE-USER_AFTER_txtSpotPrice*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRabatePayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRabatePayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRabatePayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRabatePayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtRabatePayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayRate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayRate*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayRate}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayRate*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayRate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtShrinkPayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtShrinkPayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtShrinkPayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtShrinkPayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtShrinkPayment*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxPrchs1*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxPrchs1*/}

                    <GroupBoxWidget conf={state.grpbxPrchs1} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_chkboxOverrideLRR*/}

                      {/* END_USER_CODE-USER_BEFORE_chkboxOverrideLRR*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkboxOverrideLRR}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkboxOverrideLRR*/}

                      {/* END_USER_CODE-USER_AFTER_chkboxOverrideLRR*/}
                      {/* START_USER_CODE-USER_BEFORE_radioRedemptionMethod*/}

                      {/* END_USER_CODE-USER_BEFORE_radioRedemptionMethod*/}

                      <RadioButtonGroupWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.radioRedemptionMethod}
                        screenConf={state}
                      ></RadioButtonGroupWidget>
                      {/* START_USER_CODE-USER_AFTER_radioRedemptionMethod*/}

                      {/* END_USER_CODE-USER_AFTER_radioRedemptionMethod*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxPrchs1*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxPrchs1*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxPrchs2*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxPrchs2*/}

                    <GroupBoxWidget conf={state.grpbxPrchs2} screenConf={state}>
                      {/* START_USER_CODE-USER_BEFORE_txtValuePerTon*/}

                      {/* END_USER_CODE-USER_BEFORE_txtValuePerTon*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtValuePerTon}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtValuePerTon*/}

                      {/* END_USER_CODE-USER_AFTER_txtValuePerTon*/}
                      {/* START_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                      {/* END_USER_CODE-USER_BEFORE_txtSupportPercent*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtSupportPercent}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtSupportPercent*/}

                      {/* END_USER_CODE-USER_AFTER_txtSupportPercent*/}
                      {/* START_USER_CODE-USER_BEFORE_txtPremiumAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtPremiumAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtPremiumAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtPremiumAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtPremiumAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtDeductAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtDeductAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtDeductAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtDeductAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtDeductAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_txtProceedsAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtProceedsAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtProceedsAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtProceedsAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtProceedsAmt*/}
                      {/* START_USER_CODE-USER_BEFORE_chkboxDeferredRedemption*/}

                      {/* END_USER_CODE-USER_BEFORE_chkboxDeferredRedemption*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkboxDeferredRedemption}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkboxDeferredRedemption*/}

                      {/* END_USER_CODE-USER_AFTER_chkboxDeferredRedemption*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxPrchs2*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxPrchs2*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPurchase*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPurchase*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxStrgPrchs*/}

                {/* END_USER_CODE-USER_AFTER_grpbxStrgPrchs*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxRepay*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxRepay*/}

                <GroupBoxWidget conf={state.grpbxRepay} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxRepayment*/}

                  <GroupBoxWidget
                    conf={state.grpbxRepayment}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_grpbxRepymnt*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxRepymnt*/}

                    <GroupBoxWidget
                      conf={state.grpbxRepymnt}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblRepayment*/}

                    {/* END_USER_CODE-USER_BEFORE_lblRepayment*/}

                    <LabelWidget
                      values={values}
                      conf={state.lblRepayment}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_lblRepayment*/}

                    {/* END_USER_CODE-USER_AFTER_lblRepayment*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanRepayAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanRepayAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanRepayAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanRepayAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanRepayAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStrgCreditAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStrgCreditAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStrgCreditAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStrgCreditAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtStrgCreditAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtStrgCreditAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtStrgCreditAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtStrgCreditAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtStrgCreditAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtStrgCreditAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtHandlingAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtHandlingAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtHandlingAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtHandlingAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtHandlingAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtHandlingAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtHandlingAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtHandlingAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtHandlingAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtHandlingAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInterestAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInterestAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInterestAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInterestAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtInterestAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtInterestAmtOverride*/}

                    {/* END_USER_CODE-USER_BEFORE_txtInterestAmtOverride*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtInterestAmtOverride}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtInterestAmtOverride*/}

                    {/* END_USER_CODE-USER_AFTER_txtInterestAmtOverride*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCalCNetRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCalCNetRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCalCNetRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCalCNetRepayAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtCalCNetRepayAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtNetRepayAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtNetRepayAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtNetRepayAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtNetRepayAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtNetRepayAmt*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxRepymnt*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxRepymnt*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbxForfeitureStorage*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxForfeitureStorage*/}

                    <GroupBoxWidget
                      conf={state.grpbxForfeitureStorage}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblForfeitureStorage*/}

                      {/* END_USER_CODE-USER_BEFORE_lblForfeitureStorage*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblForfeitureStorage}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblForfeitureStorage*/}

                      {/* END_USER_CODE-USER_AFTER_lblForfeitureStorage*/}
                      {/* START_USER_CODE-USER_BEFORE_btnCalculatePayment*/}

                      {/* END_USER_CODE-USER_BEFORE_btnCalculatePayment*/}

                      <ButtonWidget
                        conf={state.btnCalculatePayment}
                        screenConf={state}
                        onClick={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></ButtonWidget>
                      {/* START_USER_CODE-USER_AFTER_btnCalculatePayment*/}

                      {/* END_USER_CODE-USER_AFTER_btnCalculatePayment*/}
                      {/* START_USER_CODE-USER_BEFORE_txtStoragePaidAmt*/}

                      {/* END_USER_CODE-USER_BEFORE_txtStoragePaidAmt*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtStoragePaidAmt}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtStoragePaidAmt*/}

                      {/* END_USER_CODE-USER_AFTER_txtStoragePaidAmt*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxForfeitureStorage*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxForfeitureStorage*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRepayVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRepayVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRepayVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRepayVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtRepayVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnRepayVendor*/}

                    <ButtonWidget
                      conf={state.btnRepayVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnRepayVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnRepayVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtRepayVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtRepayVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtRepayVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtRepayVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                    {/* END_USER_CODE-USER_BEFORE_ddRepayRemit*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddRepayRemit}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddRepayRemit*/}

                    {/* END_USER_CODE-USER_AFTER_ddRepayRemit*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCMAVendorNum*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCMAVendorNum*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMAVendorNum}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCMAVendorNum*/}

                    {/* END_USER_CODE-USER_AFTER_txtCMAVendorNum*/}
                    {/* START_USER_CODE-USER_BEFORE_btnCMAVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_btnCMAVendor*/}

                    <ButtonWidget
                      conf={state.btnCMAVendor}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnCMAVendor*/}

                    {/* END_USER_CODE-USER_AFTER_btnCMAVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCMAVendor*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCMAVendor}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCMAVendor*/}

                    {/* END_USER_CODE-USER_AFTER_txtCMAVendor*/}
                    {/* START_USER_CODE-USER_BEFORE_ddCMARemit*/}

                    {/* END_USER_CODE-USER_BEFORE_ddCMARemit*/}

                    <DropDownWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.ddCMARemit}
                      screenConf={state}
                    ></DropDownWidget>
                    {/* START_USER_CODE-USER_AFTER_ddCMARemit*/}

                    {/* END_USER_CODE-USER_AFTER_ddCMARemit*/}
                    {/* START_USER_CODE-USER_BEFORE_chkboxWireTransfer*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxWireTransfer*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxWireTransfer}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxWireTransfer*/}

                    {/* END_USER_CODE-USER_AFTER_chkboxWireTransfer*/}
                    {/* START_USER_CODE-USER_BEFORE_txtWireTransfer*/}

                    {/* END_USER_CODE-USER_BEFORE_txtWireTransfer*/}

                    <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransfer}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData2*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData2*/}
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData2*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData2*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData3*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData3*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData3*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData3*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData4*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData4*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData4}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData4*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData4*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData5*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData5*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData5}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtWireTransferData6}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_txtWireTransferData5*/}

                  {/* END_USER_CODE-USER_AFTER_txtWireTransferData5*/}
                  {/* START_USER_CODE-USER_BEFORE_txtWireTransferData6*/}

                  {/* END_USER_CODE-USER_BEFORE_txtWireTransferData6*/}

                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia1}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia2}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                  <TextBoxWidget
                    onChange={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    onBlur={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                    values={values}
                    touched={touched}
                    errors={errors}
                    conf={state.txtConcordia3}
                    screenConf={state}
                    onPaste={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtWireTransfer*/}

                    {/* END_USER_CODE-USER_AFTER_txtWireTransfer*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxRepayment*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxRepayment*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxLoan*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxLoan*/}

                  <GroupBoxWidget conf={state.grpbxLoan} screenConf={state}>
                    {/* START_USER_CODE-USER_BEFORE_grpbxLoanNum*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbxLoanNum*/}

                    <GroupBoxWidget
                      conf={state.grpbxLoanNum}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_lblLoanNum*/}

                      {/* END_USER_CODE-USER_BEFORE_lblLoanNum*/}

                      <LabelWidget
                        values={values}
                        conf={state.lblLoanNum}
                        screenConf={state}
                      ></LabelWidget>
                      {/* START_USER_CODE-USER_AFTER_lblLoanNum*/}

                      {/* END_USER_CODE-USER_AFTER_lblLoanNum*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnState*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnState*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnState}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnState*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnState*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnCounty*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnCounty*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnCounty}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnCounty*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnCounty*/}
                      {/* START_USER_CODE-USER_BEFORE_txtLnNumber*/}

                      {/* END_USER_CODE-USER_BEFORE_txtLnNumber*/}

                      <TextBoxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.txtLnNumber}
                        screenConf={state}
                        onPaste={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                      ></TextBoxWidget>
                      {/* START_USER_CODE-USER_AFTER_txtLnNumber*/}

                      {/* END_USER_CODE-USER_AFTER_txtLnNumber*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbxLoanNum*/}

                    {/* END_USER_CODE-USER_AFTER_grpbxLoanNum*/}
                    {/* START_USER_CODE-USER_BEFORE_grpbx211CCC500*/}

                    {/* END_USER_CODE-USER_BEFORE_grpbx211CCC500*/}

                    <GroupBoxWidget
                      conf={state.grpbx211CCC500}
                      screenConf={state}
                    >
                      {/* START_USER_CODE-USER_BEFORE_chkbox211*/}

                      {/* END_USER_CODE-USER_BEFORE_chkbox211*/}

                      <CheckboxWidget
                        onChange={event =>
                          invokeEventHandler(
                            event,
                            handleChange,
                            handleBlur,
                            thisObj
                          )
                        }
                        onBlur={handleBlur}
                        values={values}
                        touched={touched}
                        errors={errors}
                        conf={state.chkbox211}
                        screenConf={state}
                      ></CheckboxWidget>
                      {/* START_USER_CODE-USER_AFTER_chkbox211*/}

                      {/* END_USER_CODE-USER_AFTER_chkbox211*/}
                      {/* START_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                    {/* END_USER_CODE-USER_BEFORE_chkboxCCC500*/}

                    <CheckboxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.chkboxCCC500}
                      screenConf={state}
                    ></CheckboxWidget>
                    {/* START_USER_CODE-USER_AFTER_chkboxCCC500*/}

                      {/* END_USER_CODE-USER_AFTER_chkboxCCC500*/}
                    </GroupBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_grpbx211CCC500*/}

                    {/* END_USER_CODE-USER_AFTER_grpbx211CCC500*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLnRpyLockInDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLnRpyLockInDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLnRpyLockInDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLnRpyLockInDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLnRpyLockInDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanAmt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanAmt*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanAmt}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                     <LabelWidget
                      values={values}
                      conf={state.lblLoanAmtVal}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanAmt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanAmt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtExpDate*/}

                    {/* END_USER_CODE-USER_BEFORE_txtExpDate*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtExpDate}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtExpDate*/}

                    {/* END_USER_CODE-USER_AFTER_txtExpDate*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanForfeitedDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanForfeitedDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanForfeitedDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanForfeitedDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanForfeitedDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanMaturityDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanMaturityDt*/}
                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanMaturityDt}
                      screenConf={state}
                    ></DateWidget>
                     <LabelWidget
                      values={values}
                      conf={state.lblLoanMaturityVal}
                      screenConf={state}
                    ></LabelWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanMaturityDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanMaturityDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtCCCStrgPaidThruDt*/}

                    {/* END_USER_CODE-USER_BEFORE_txtCCCStrgPaidThruDt*/}

                    <DateWidget
                      onChange={event =>
                        invokeEventHandler(event, handleChange, handleBlur, thisObj)
                      }
                      onBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtCCCStrgPaidThruDt}
                      screenConf={state}
                    ></DateWidget>
                    {/* START_USER_CODE-USER_AFTER_txtCCCStrgPaidThruDt*/}

                    {/* END_USER_CODE-USER_AFTER_txtCCCStrgPaidThruDt*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMarketGain1*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMarketGain1*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMarketGain1}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMarketGain1*/}

                    {/* END_USER_CODE-USER_AFTER_txtMarketGain1*/}
                    {/* START_USER_CODE-USER_BEFORE_txtMarketGain2*/}

                    {/* END_USER_CODE-USER_BEFORE_txtMarketGain2*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtMarketGain2}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtMarketGain2*/}

                    {/* END_USER_CODE-USER_AFTER_txtMarketGain2*/}
                    {/* START_USER_CODE-USER_BEFORE_txtLoanReductionPayment*/}

                    {/* END_USER_CODE-USER_BEFORE_txtLoanReductionPayment*/}

                    <TextBoxWidget
                      onChange={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      onBlur={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                      values={values}
                      touched={touched}
                      errors={errors}
                      conf={state.txtLoanReductionPayment}
                      screenConf={state}
                      onPaste={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></TextBoxWidget>
                    {/* START_USER_CODE-USER_AFTER_txtLoanReductionPayment*/}

                    {/* END_USER_CODE-USER_AFTER_txtLoanReductionPayment*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxLoan*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxLoan*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxRepay*/}

                {/* END_USER_CODE-USER_AFTER_grpbxRepay*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxViewWarehouseReceipt*/}

              {/* END_USER_CODE-USER_AFTER_grpbxViewWarehouseReceipt*/}
              {/* START_USER_CODE-USER_BEFORE_grpbxActions*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                {/* START_USER_CODE-USER_BEFORE_btnViewEWRLog*/}

                {/* END_USER_CODE-USER_BEFORE_btnViewEWRLog*/}

                <ButtonWidget
                  conf={state.btnViewEWRLog}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnViewEWRLog*/}

                {/* END_USER_CODE-USER_AFTER_btnViewEWRLog*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintFSADoc*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintFSADoc*/}

                <ButtonWidget
                  conf={state.btnPrintFSADoc}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintFSADoc*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintFSADoc*/}
                {/* START_USER_CODE-USER_BEFORE_btnStorageHandlingPayment*/}

                {/* END_USER_CODE-USER_BEFORE_btnStorageHandlingPayment*/}

                <ButtonWidget
                  conf={state.btnStorageHandlingPayment}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnStorageHandlingPayment*/}

                {/* END_USER_CODE-USER_AFTER_btnStorageHandlingPayment*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxShrinkPayBtnCntnr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxShrinkPayBtnCntnr*/}

                <GroupBoxWidget
                  conf={state.grpbxShrinkPayBtnCntnr}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnShrinkPayment*/}

                {/* END_USER_CODE-USER_BEFORE_btnShrinkPayment*/}

                <ButtonWidget
                  conf={state.btnShrinkPayment}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnShrinkPayment*/}

                  {/* END_USER_CODE-USER_AFTER_btnShrinkPayment*/}
                  {/* START_USER_CODE-USER_BEFORE_btnReverseShrinkPayment*/}

                  {/* END_USER_CODE-USER_BEFORE_btnReverseShrinkPayment*/}

                  <ButtonWidget
                    conf={state.btnReverseShrinkPayment}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnReverseShrinkPayment*/}

                  {/* END_USER_CODE-USER_AFTER_btnReverseShrinkPayment*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxShrinkPayBtnCntnr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxShrinkPayBtnCntnr*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxTentApplBtnCntnr*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxTentApplBtnCntnr*/}

                <GroupBoxWidget
                  conf={state.grpbxTentApplBtnCntnr}
                  screenConf={state}
                >
                  {/* START_USER_CODE-USER_BEFORE_btnTentApplication*/}

                {/* END_USER_CODE-USER_BEFORE_btnTentApplication*/}

                <ButtonWidget
                  conf={state.btnTentApplication}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnTentApplication*/}

                  {/* END_USER_CODE-USER_AFTER_btnTentApplication*/}
                  {/* START_USER_CODE-USER_BEFORE_btnTentApplicationReverse*/}

                  {/* END_USER_CODE-USER_BEFORE_btnTentApplicationReverse*/}

                  <ButtonWidget
                    conf={state.btnTentApplicationReverse}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnTentApplicationReverse*/}

                  {/* END_USER_CODE-USER_AFTER_btnTentApplicationReverse*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxTentApplBtnCntnr*/}

                {/* END_USER_CODE-USER_AFTER_grpbxTentApplBtnCntnr*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxBtnCntnr1*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBtnCntnr1*/}

                <GroupBoxWidget conf={state.grpbxBtnCntnr1} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnUpdate*/}

                  {/* END_USER_CODE-USER_BEFORE_btnUpdate*/}

                  <ButtonWidget
                    conf={state.btnUpdate}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnUpdate*/}

                  {/* END_USER_CODE-USER_AFTER_btnUpdate*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxVoidBtnCntnr*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxVoidBtnCntnr*/}

                  <GroupBoxWidget
                    conf={state.grpbxVoidBtnCntnr}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnVoid*/}

                    {/* END_USER_CODE-USER_BEFORE_btnVoid*/}

                    <ButtonWidget
                      conf={state.btnVoid}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnVoid*/}

                    {/* END_USER_CODE-USER_AFTER_btnVoid*/}
                    {/* START_USER_CODE-USER_BEFORE_btnUnVoid*/}

                    {/* END_USER_CODE-USER_BEFORE_btnUnVoid*/}

                    <ButtonWidget
                      conf={state.btnUnVoid}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnUnVoid*/}

                    {/* END_USER_CODE-USER_AFTER_btnUnVoid*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxVoidBtnCntnr*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxVoidBtnCntnr*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxPrchsBtnCntnr*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxPrchsBtnCntnr*/}

                  <GroupBoxWidget
                    conf={state.grpbxPrchsBtnCntnr}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnPurchase*/}

                    {/* END_USER_CODE-USER_BEFORE_btnPurchase*/}

                    <ButtonWidget
                      conf={state.btnPurchase}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnPurchase*/}

                    {/* END_USER_CODE-USER_AFTER_btnPurchase*/}
                    {/* START_USER_CODE-USER_BEFORE_btnUnPurchase*/}

                    {/* END_USER_CODE-USER_BEFORE_btnUnPurchase*/}

                    <ButtonWidget
                      conf={state.btnUnPurchase}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnUnPurchase*/}

                    {/* END_USER_CODE-USER_AFTER_btnUnPurchase*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxPrchsBtnCntnr*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxPrchsBtnCntnr*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxBeginDelvBtnCntnr*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxBeginDelvBtnCntnr*/}

                  <GroupBoxWidget
                    conf={state.grpbxBeginDelvBtnCntnr}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnBeginDelivery*/}

                    {/* END_USER_CODE-USER_BEFORE_btnBeginDelivery*/}

                    <ButtonWidget
                      conf={state.btnBeginDelivery}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnBeginDelivery*/}

                    {/* END_USER_CODE-USER_AFTER_btnBeginDelivery*/}
                    {/* START_USER_CODE-USER_BEFORE_btnUndoBegDelvOut*/}

                    {/* END_USER_CODE-USER_BEFORE_btnUndoBegDelvOut*/}

                    <ButtonWidget
                      conf={state.btnUndoBegDelvOut}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnUndoBegDelvOut*/}

                    {/* END_USER_CODE-USER_AFTER_btnUndoBegDelvOut*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxBeginDelvBtnCntnr*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxBeginDelvBtnCntnr*/}
                  {/* START_USER_CODE-USER_BEFORE_grpbxCompDelvBtnCntnr*/}

                  {/* END_USER_CODE-USER_BEFORE_grpbxCompDelvBtnCntnr*/}

                  <GroupBoxWidget
                    conf={state.grpbxCompDelvBtnCntnr}
                    screenConf={state}
                  >
                    {/* START_USER_CODE-USER_BEFORE_btnCompDelv*/}

                    {/* END_USER_CODE-USER_BEFORE_btnCompDelv*/}

                    <ButtonWidget
                      conf={state.btnCompDelv}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnCompDelv*/}

                    {/* END_USER_CODE-USER_AFTER_btnCompDelv*/}
                    {/* START_USER_CODE-USER_BEFORE_btnUndoCompDelvout*/}

                    {/* END_USER_CODE-USER_BEFORE_btnUndoCompDelvout*/}

                    <ButtonWidget
                      conf={state.btnUndoCompDelvout}
                      screenConf={state}
                      onClick={event =>
                        invokeEventHandler(
                          event,
                          handleChange,
                          handleBlur,
                          thisObj
                        )
                      }
                    ></ButtonWidget>
                    {/* START_USER_CODE-USER_AFTER_btnUndoCompDelvout*/}

                    {/* END_USER_CODE-USER_AFTER_btnUndoCompDelvout*/}
                  </GroupBoxWidget>
                  {/* START_USER_CODE-USER_AFTER_grpbxCompDelvBtnCntnr*/}

                  {/* END_USER_CODE-USER_AFTER_grpbxCompDelvBtnCntnr*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBtnCntnr1*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBtnCntnr1*/}
                {/* START_USER_CODE-USER_BEFORE_btnPrintWorksheet*/}

                {/* END_USER_CODE-USER_BEFORE_btnPrintWorksheet*/}

                <ButtonWidget
                  conf={state.btnPrintWorksheet}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnPrintWorksheet*/}

                {/* END_USER_CODE-USER_AFTER_btnPrintWorksheet*/}
                {/* START_USER_CODE-USER_BEFORE_grpbxBtnCntnr2*/}

                {/* END_USER_CODE-USER_BEFORE_grpbxBtnCntnr2*/}

                <GroupBoxWidget conf={state.grpbxBtnCntnr2} screenConf={state}>
                  {/* START_USER_CODE-USER_BEFORE_btnPremsDeduct*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPremsDeduct*/}

                  <ButtonWidget
                    conf={state.btnPremsDeduct}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPremsDeduct*/}

                  {/* END_USER_CODE-USER_AFTER_btnPremsDeduct*/}
                  {/* START_USER_CODE-USER_BEFORE_btnPayments*/}

                  {/* END_USER_CODE-USER_BEFORE_btnPayments*/}

                  <ButtonWidget
                    conf={state.btnPayments}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnPayments*/}

                  {/* END_USER_CODE-USER_AFTER_btnPayments*/}
                  {/* START_USER_CODE-USER_BEFORE_btnDeductTrack*/}

                  {/* END_USER_CODE-USER_BEFORE_btnDeductTrack*/}

                  <ButtonWidget
                    conf={state.btnDeductTrack}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnDeductTrack*/}

                  {/* END_USER_CODE-USER_AFTER_btnDeductTrack*/}
                  {/* START_USER_CODE-USER_BEFORE_btnApplications*/}

                  {/* END_USER_CODE-USER_BEFORE_btnApplications*/}

                  <ButtonWidget
                    conf={state.btnApplications}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnApplications*/}

                  {/* END_USER_CODE-USER_AFTER_btnApplications*/}
                  {/* START_USER_CODE-USER_BEFORE_btnAcctDist*/}

                  {/* END_USER_CODE-USER_BEFORE_btnAcctDist*/}

                  <ButtonWidget
                    conf={state.btnAcctDist}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnAcctDist*/}

                  {/* END_USER_CODE-USER_AFTER_btnAcctDist*/}
                  {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                  {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                  <ButtonWidget
                    conf={state.btnCancel}
                    screenConf={state}
                    onClick={event =>
                      invokeEventHandler(
                        event,
                        handleChange,
                        handleBlur,
                        thisObj
                      )
                    }
                  ></ButtonWidget>
                  {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                  {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                </GroupBoxWidget>
                {/* START_USER_CODE-USER_AFTER_grpbxBtnCntnr2*/}

                {/* END_USER_CODE-USER_AFTER_grpbxBtnCntnr2*/}
                {/* START_USER_CODE-USER_BEFORE_btnLoadOut*/}

                {/* END_USER_CODE-USER_BEFORE_btnLoadOut*/}

                <ButtonWidget
                  conf={state.btnLoadOut}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnLoadOut*/}

                {/* END_USER_CODE-USER_AFTER_btnLoadOut*/}
                {/* START_USER_CODE-USER_BEFORE_btnEnableLoanFields*/}

                {/* END_USER_CODE-USER_BEFORE_btnEnableLoanFields*/}

                <ButtonWidget
                  conf={state.btnEnableLoanFields}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnEnableLoanFields*/}

                {/* END_USER_CODE-USER_AFTER_btnEnableLoanFields*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblAddedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblAddedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblAddedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblAddedByValue*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedBy*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedBy*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedBy}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedBy*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedBy*/}
                {/* START_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                {/* END_USER_CODE-USER_BEFORE_lblChangedByValue*/}

                <LabelWidget
                  values={values}
                  conf={state.lblChangedByValue}
                  screenConf={state}
                ></LabelWidget>
                {/* START_USER_CODE-USER_AFTER_lblChangedByValue*/}

                {/* END_USER_CODE-USER_AFTER_lblChangedByValue*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* END_USER_CODE-USER_AFTER_grpbxActions*/}

              {/* START_USER_CODE-USER_AFTER_ViewWarehouseReceipt*/}

              {/* END_USER_CODE-USER_AFTER_ViewWarehouseReceipt*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(WarehouseReceipts_ViewWarehouseReceipt);